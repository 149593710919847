import {AUTOMATION_ACTION, MINI_APPS_ACTION} from './actionType';
import {
  AUTOMATION_ASSIGNEE_WEB_APP_VERSION,
  AUTOMATION_TEMPLATES_TYPE,
  FIELD_TYPE_ID,
  MESSAGE_AUTOMATION,
  SHARE_PERMISSION_TYPE,
  MINI_APPS,
} from '../utils/constant';
import {
  firestore,
  captureError,
  captureInfo,
  logAnalyticsEvent,
  ENV,
  getDeviceVersion,
  isAndroid,
  versionCompare,
  ShowToast,
  storage,
} from '../imports';
import {
  getHeaderTypeMapping,
  getOriginalFieldType,
  getRowObjAtIndex,
  getTimezone,
  handleCloudErrorMsgAndLogging,
  serializeError,
} from '../utils/utils';
import {
  cloneDeep,
  findKey,
  forOwn,
  invert,
  isArray,
  isEmpty,
  isEqual,
  keys,
  omit,
  pick,
  pickBy,
  sortBy,
  union,
} from 'lodash';
import {
  setAutomation,
  getAutomationTemplates,
  updateAutomation,
  deleteAutomation,
  deleteAllPendingAutomationsHelper,
  updatePendingAutomationDocument,
  getPendingAutomationDocument,
  updateAutomationConfigDocument,
  getAutomationConfigDocument,
  getDailyAutomationDocData,
  setDailyAutomationDocData,
  fetchAllGlobalAutomations,
  setPendingAutomationDocument,
  setDateSpecificAutomation,
  deleteDateSpecificAutomation,
  addDateSpecificAutomationTimestamp,
  loadScheduledAutomations,
  deleteScheduledTimestamps,
  // getAutomationFiltersDocument,
  getAutomationWhatsappTemplates,
  getIntegrationsTemplates,
  createInteraktMapping,
  triggerMiniAppAutomationManuallyCF,
} from './actionHelpers/automationActionHelper';
import {
  updateActiveDocMeta,
  setPremiumAutomationBannerShown,
  showInformationModal,
  addNewColumn,
} from './tableAction';
import moment from 'moment';
import {extraActionsHandler} from './actionHelpers/undoRedoActions/undoActions';
import {AVAILABLE_ENTITLEMENTS, LAST_ACTIVE_PLAN} from '../utils/premium';
import {invokePopupModal} from './homeAction';
import {automationConstants, automationUtils} from '../utils/automationUtils';
import {selectDashboardFields} from './dashboardAction';
import FirestoreDB from '../FirestoreHandlers/FirestoreDB';
import DocumentsMethods from '../FirestoreHandlers/Documents/DocumentsMethods';
import * as homeActionHelper from './actionHelpers/homeActionHelper';
import * as miniAppsActionHelper from './actionHelpers/miniAppsActionHelper';
import {
  AUTOMATION_DOC_OPERATIONS,
  AUTOMATION_OPERATION_PROPERTIES,
  AUTOMATION_OPERATION_TYPES,
  AUTOMATION_TRIGGER_OPERATION_MAP,
  DEFAULT_OPERATION_TYPE,
  MAPPING_VERSION,
  NOTIFICATION_OPERATION_USER_TYPES,
} from '../utils/automationConstants';
import {
  getDocDataFromMiniAppState,
  mapMiniAppStates,
} from './actionHelpers/miniAppsActionHelper';
import Features from '../utils/Features';
import {fetchAggregationsForMiniApp} from './aggregationAction';
import {getColumnLikeOptionsFromAggsConfig} from '../utils/aggregationHelper';
import {fetchEndpointsForMiniApp} from './endpointsAction';
import {NotificationService} from '../utils/notifyUsers/notificationUtils';
import {nanoid} from 'nanoid';
import {
  NOTIFICATION_TYPE,
  SERVICE_TYPE,
} from '../utils/notifyUsers/notificationConstant';

// TODO : Handle MESSAGE_AUTOMATION.MANUAL case
const NON_VALUE_AUTOMATION = [
  MESSAGE_AUTOMATION.ROW_NEW_ENTRY,
  MESSAGE_AUTOMATION.ON_SPECIFIC_DATE,
  MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE,
];
const updateNewAutomation = (obj) => (dispatch) => {
  try {
    dispatch({
      type: AUTOMATION_ACTION.SET_AUTOMATION_OBJ,
      payload: obj,
    });
  } catch (error) {
    captureError(error);
  }
};

const clearPrevStateAutomations = () => (dispatch) => {
  try {
    dispatch({
      type: AUTOMATION_ACTION.CLEAR_PREV_STATE_AUTOMATIONS,
    });
  } catch (error) {
    captureError(error);
  }
};

const loadAvailableAutomations = () => async (dispatch, getState) => {
  try {
    const {
      automation,
      remoteConfig: {isOrganisationMode},
    } = getState();

    if (
      !isEmpty(automation?.availableAutomations) &&
      !isEmpty(automation?.filteredByTrigger)
    ) {
      return;
    }

    dispatch({type: AUTOMATION_ACTION.SHOW_AUTOMATION_LOADER});

    const automations = await FirestoreDB.automations.automationsCol().get();
    const appVersion = !ENV
      ? AUTOMATION_ASSIGNEE_WEB_APP_VERSION
      : getDeviceVersion();

    const availableAutomations = {};
    const filteredByTrigger = {};

    if (isOrganisationMode && Features.AUTOMATION_V2) {
      const typeObj = {catId: 'manual', autId: 'manual'};

      filteredByTrigger[MESSAGE_AUTOMATION.MANUAL] = {
        [DEFAULT_OPERATION_TYPE]: typeObj,
        [AUTOMATION_OPERATION_TYPES.COPY_ROW]: typeObj,
        [AUTOMATION_OPERATION_TYPES.MOVE_ROW]: typeObj,
        [AUTOMATION_OPERATION_TYPES.SEND_NOTIFICATION]: typeObj,
        [AUTOMATION_OPERATION_TYPES.SET_VALUE_BY_FILTERS]: typeObj,
        [AUTOMATION_OPERATION_TYPES.SEND_EMAIL]: typeObj,

        // TODO : Enable this after added on firestore
        [AUTOMATION_OPERATION_TYPES.ADD_EMPTY_ROW]: typeObj,
      };

      availableAutomations['manual'] = {
        title: {['EN']: 'Manual'},
        catgName: {['EN']: 'Manual'},
        catgDescription: {['EN']: 'Manually Triggered'},
        availableAutomations: {manual: true},
        meta: {
          type: MESSAGE_AUTOMATION.MANUAL,
        },
      };
    }

    automations.forEach((aut) => {
      const categoryData = aut.data();
      const omitKeys = [];
      Object.keys(categoryData?.availableAutomations ?? {}).forEach((item) => {
        const {meta} = categoryData.availableAutomations[item] ?? {};
        const {minVerWeb, minVerAndroid, minVerIos, type} = meta ?? {};
        const version = !ENV
          ? minVerWeb
          : isAndroid
          ? minVerAndroid
          : minVerIos;

        if (
          !isOrganisationMode && // Remove assignee change automation for non org users
          (type === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE ||
            categoryData?.operationType ===
              AUTOMATION_OPERATION_TYPES.SET_VALUE_BY_FILTERS)
        ) {
          omitKeys.push(item);
        }

        if (isOrganisationMode) {
          const operationType =
            categoryData?.operationType || DEFAULT_OPERATION_TYPE;
          filteredByTrigger[type] = filteredByTrigger[type] || [];
          filteredByTrigger[type][operationType] = {
            catId: aut.id,
            autId: item,
          };
        }

        if (version?.length && !versionCompare(appVersion, version, true)) {
          omitKeys.push(item);
        }
      });
      availableAutomations[aut.id] = Object.assign({}, categoryData, {
        availableAutomations: omit(
          categoryData?.availableAutomations,
          omitKeys,
        ),
      });
    });

    if (Features.AUTOMATION_V2_LOCAL_ADD && !isEmpty(filteredByTrigger)) {
      const prefix = 'mini_app';

      const withoutOnSpecificDate = Object.assign(
        {},
        AUTOMATION_TRIGGER_OPERATION_MAP,
      );

      // ! For now we are only allowing send notification for On Specific date.
      // TODO : Enable after release
      // Object.assign(filteredByTrigger[MESSAGE_AUTOMATION.ON_SPECIFIC_DATE], {
      //   [AUTOMATION_OPERATION_TYPES.SEND_NOTIFICATION]: {
      //     catId: 'mini_app',
      //     autId: 'mini_app_send_notification',
      //   },
      // });

      // Add operation types for given triggers
      Object.keys(withoutOnSpecificDate).forEach((trigger) => {
        filteredByTrigger[trigger] = filteredByTrigger[trigger] || {};

        /**
         * @type {Array<string>}
         */
        const operationTypes = withoutOnSpecificDate[trigger];

        operationTypes.forEach((operationType) => {
          const obj = {
            catId: prefix,
            autId: `${prefix}_${operationType.toLowerCase()}`,
          };

          filteredByTrigger[trigger][operationType] = obj;
        });
      });
    }

    dispatch({
      type: AUTOMATION_ACTION.LOAD_AUMATIONS,
      payload: availableAutomations,
    });

    dispatch({
      type: AUTOMATION_ACTION.AUTOMATION_LOAD_BASED_ON_TRIGGER,
      payload: filteredByTrigger,
    });

    dispatch({type: AUTOMATION_ACTION.HIDE_AUTOMATION_LOADER});
  } catch (error) {
    captureError(error);
  }
};

const setDailyAutomationConfig = (dataObj) => async (dispatch, getState) => {
  try {
    const {
      automation: {dailyAutomationConfig, globalAutomationData},
      auth: {
        user: {uid},
      },
    } = getState();
    const {scheduledTime, selectedFiles} = dataObj;
    const scheduledTimestamp = scheduledTime.substring(10);
    const lastExecutedTimestamp = moment(scheduledTime)
      .subtract(1, 'days')
      .utc()
      .format();
    const selectedFilesCopy = Object.assign([], globalAutomationData.docArray);
    dispatch({
      type: AUTOMATION_ACTION.SET_DAILY_AUTOMATION_CONFIG,
      payload: Object.assign({}, dailyAutomationConfig, {
        timestamp: scheduledTimestamp,
      }),
    });
    for (const item in selectedFiles) {
      const mapping = {},
        nestedObjKey = {};
      const isSharedDoc = selectedFiles[item].documentMeta.collab
        ? true
        : false;
      const documentId = selectedFiles[item].documentId;
      nestedObjKey[uid] = {
        lastExecutedTimestamp: lastExecutedTimestamp,
      };
      if (
        isArray(selectedFilesCopy) &&
        !isEmpty(selectedFilesCopy) &&
        selectedFilesCopy.length > Object.keys(selectedFiles).length
      ) {
        let deleteIndex;
        selectedFilesCopy.filter((element, index) => {
          if (element.documentId === documentId) {
            deleteIndex = index;
          }
          return true;
        });
        selectedFilesCopy.splice(deleteIndex, 1);
      }
      mapping[`${scheduledTimestamp}`] = nestedObjKey;
      if (isSharedDoc) {
        let timestampMapping = {};
        if (!isEmpty(globalAutomationData?.allGlobalAutomationFileData)) {
          const userTimestampMapping =
            globalAutomationData.allGlobalAutomationFileData[documentId]
              ?.userTimestampMapping;
          timestampMapping = Object.assign({}, userTimestampMapping);
          for (const element in userTimestampMapping) {
            if (userTimestampMapping[element][uid]) {
              timestampMapping[element] = {
                [uid]: firestore(true).FieldValue.delete(),
              };
            }
          }
        }
        timestampMapping[`${scheduledTimestamp}`] = nestedObjKey;
        const queryObj = {
          uidArray: firestore(true).FieldValue.arrayUnion(uid),
          timestampArray:
            firestore(true).FieldValue.arrayUnion(scheduledTimestamp),
          userTimestampMapping: timestampMapping,
          docId: documentId,
        };
        setDailyAutomationDocData(documentId, queryObj, true);
      } else {
        const queryObj = {
          uidArray: firestore(true).FieldValue.arrayUnion(uid),
          timestampArray:
            firestore(true).FieldValue.arrayUnion(scheduledTimestamp),
          userTimestampMapping: mapping,
          docId: documentId,
        };
        setDailyAutomationDocData(documentId, queryObj, false);
      }
    }
    if (!isEmpty(selectedFilesCopy)) {
      const selectedFilesCopyArray = Object.keys(selectedFilesCopy);

      const handleItem = async (item) => {
        const documentId = selectedFilesCopy[item]?.documentId;
        if (
          documentId &&
          selectedFiles &&
          Object.keys(selectedFiles).filter(
            (key) => selectedFiles[key]?.documentId === documentId,
          ).length === 0
        ) {
          const snapShot = await getDailyAutomationDocData(documentId);
          const automationDocData = snapShot?.exists ? snapShot.data() : {};
          if (!isEmpty(automationDocData)) {
            try {
              const deleteUidIndex =
                automationDocData?.uidArray?.indexOf?.(documentId);
              automationDocData.uidArray.splice(deleteUidIndex, 1);
              delete automationDocData?.userTimestampMapping?.[
                scheduledTimestamp
              ]?.[uid];
              const queryObj = Object.assign({}, automationDocData);
              setDailyAutomationDocData(documentId, queryObj, true);
            } catch (error) {
              captureError(error);
            }
          }
        }
      };

      selectedFilesCopyArray.forEach(handleItem);
    }
    dispatch(loadGlobalAutomations());
  } catch (error) {
    captureError(error);
  }
};

const setSelectedDocForDailyAutomation = (dataObj) => (dispatch) => {
  try {
    const {selectedFileArray} = dataObj;
    dispatch({
      type: AUTOMATION_ACTION.SET_SELECTED_DOC_DAILY_AUTOMATION,
      payload: selectedFileArray,
    });
  } catch (error) {
    captureError(error);
  }
};

const loadAutomationConfig =
  (
    originalDocumentId,
    headerData,
    fromRedux = false,
    fromMiniApps = false,
    triggerType = null,
  ) =>
  async (dispatch, getState) => {
    try {
      const {
        home: {activeDocumentId}, // Only used if accessed from table scree.
        auth: {user},
        table,
      } = getState();
      const isUserAppAccess =
        triggerType === MESSAGE_AUTOMATION.ON_USER_APP_ACCESS;
      const snapShot = await getAutomationConfigDocument(originalDocumentId);

      const activeDocumentMeta = isUserAppAccess
        ? {}
        : await homeActionHelper.checkIfUserHasDocAccessAndFetchMeta(
            originalDocumentId,
            user.uid,
          );

      const collab = !isEmpty(activeDocumentMeta?.collab)
        ? activeDocumentMeta.collab
        : {};
      const isCustomSharedUser =
        collab.permission === SHARE_PERMISSION_TYPE.CUSTOM;

      const headerDataArr = fromRedux
        ? isCustomSharedUser
          ? table?.originalHeaderData ?? []
          : table?.headerData ?? []
        : Object.values(headerData ?? {});

      const automationConfigData = snapShot.exists ? snapShot.data() : {};

      let dependentCols = [];
      const disabledDueToPrevRowRef = {};
      let updateFirestore = false;
      const columnNames = [];

      const dependentColObj = {};
      if (!isUserAppAccess) {
        Object.keys(automationConfigData).forEach((automationId) => {
          let dependentColsForCurrentAutomation = automationDependentCols(
            automationConfigData[automationId],
            headerDataArr,
            true,
          )?.colIds;
          const automationColumnsWithPrevRowRef =
            automationUtils.getAutomationColumnsWithPrevRowRef(
              dependentColsForCurrentAutomation,
              headerDataArr,
            );
          if (
            dependentColsForCurrentAutomation &&
            automationColumnsWithPrevRowRef.length
          ) {
            disabledDueToPrevRowRef[automationId] = {
              columnsWithPrevRowRef: automationColumnsWithPrevRowRef,
            };

            if (automationConfigData[automationId].isEnabled) {
              automationConfigData[automationId].isEnabled = false;
              updateFirestore = true;
              automationColumnsWithPrevRowRef
                .map((column) => column.name)
                .forEach((columnName) => columnNames.push(columnName));
            }
          }
          dependentColsForCurrentAutomation = automationDependentCols(
            automationConfigData[automationId],
            headerDataArr,
          )?.colIds;
          dependentCols = union(
            dependentCols,
            dependentColsForCurrentAutomation,
          );
          dependentColObj[automationId] = dependentColsForCurrentAutomation;
        });

        if (updateFirestore) {
          updateAutomationConfigDocument(
            originalDocumentId,
            automationConfigData,
          );
          dispatch(
            showInformationModal({
              message: `Automation disabled for '${[
                ...new Set(columnNames),
              ].join(
                ', ',
              )}' as it uses previous row reference. Please contact support for more details.`,
              showOnWeb: true,
            }),
          );
        }
      }

      const {activeAppId, interaktId} = mapMiniAppStates(getState());
      if (fromMiniApps && activeAppId && interaktId) {
        dispatch(loadAllAutomationTemplates('', {fromMiniApps}));
      }

      dispatch({
        type: AUTOMATION_ACTION.SET_AUTOMATIONS_DISABLED_DUE_TO_PREV_ROW_REF,
        payload: disabledDueToPrevRowRef,
      });

      dispatch({
        type: AUTOMATION_ACTION.SET_TABLE_AUTOMATION_CONFIG_DATA,
        payload: {
          fromMiniApps: fromMiniApps,
          automationDocumentId: originalDocumentId,
          automationActiveDocumentId: fromMiniApps
            ? originalDocumentId
            : activeDocumentId,
        },
      });

      dispatch({
        type: AUTOMATION_ACTION.SET_DOC_AUTOMATION_CONFIG_DATA,
        payload: {
          automationConfig: automationConfigData,
          dependentCols,
          dependentColObj,
          fromMiniApps,
        },
      });
    } catch (error) {
      captureError(error);
    }
  };

const loadMiniAppAutomationConfig = () => async (dispatch, getState) => {
  try {
    const state = getState();

    const {activeAppMeta, activeAppId} = mapMiniAppStates(state);
    const {docIds} = activeAppMeta || {};
    const promises = [];

    const docIdsArray = [activeAppId, ...(docIds ?? [])];
    docIdsArray.forEach((docId) => {
      promises.push(
        getAutomationConfigDocument(docId)
          .then((data) => (data?.exists ? data.data() : null))
          .catch(() => null),
      );
    });

    const miniAppAutomationConfig = await Promise.all(promises)
      .then((results) => {
        return results.reduce((acc, item, index) => {
          if (item) {
            acc[docIdsArray[index]] = item;
          }
          return acc;
        }, {});
      })
      .catch(() => ({}));

    dispatch({
      type: AUTOMATION_ACTION.SET_MINI_APP_AUTOMATION_CONFIG_DATA,
      payload: miniAppAutomationConfig,
    });
  } catch (error) {
    captureError(error);
  }
};

const automationDependentCols = (
  automationConfig,
  headerData,
  includeDisabledAutomations = false,
) => {
  const headerTypeMapping = getHeaderTypeMapping(headerData);

  // TODO : Update AUtomation Dependent Cols for PUSH NOTIFICATION type and ROW EDIT/DELETE ENTRY type.
  const {
    userDefined,
    isEnabled,
    type,
    colIdMapping,
    phoneColId: phoneColIdFromConfig,
    triggerCol,

    // Operation Based Params
    operationType,
    headerMapping,

    // For trigger
    columns,
  } = automationConfig ?? {};

  const isCellStatus = type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE;
  const isDocOperation = AUTOMATION_DOC_OPERATIONS.includes(operationType);
  const getColIdsWithListIds = (colIds) => {
    const ids = [...colIds];
    // To handle list columns case
    colIds.forEach((colId) => {
      const {summaryInfo} = headerTypeMapping?.[colId] || {};
      const {listColumnId} = summaryInfo || {};
      if (listColumnId) {
        ids.push(listColumnId);
      }
    });
    return union(ids);
  };

  try {
    if (!isEnabled && !includeDisabledAutomations) {
      return false;
    }

    if (!isEmpty(userDefined)) {
      const {columns: userDefinedColumns} = userDefined || {};
      const colIds = (userDefinedColumns || []).map((item) => `${item.id}`);

      return {colIds: getColIdsWithListIds(colIds)};
    } else if (NON_VALUE_AUTOMATION.includes(type) && isDocOperation) {
      // FORMAT: {[ToColId]: FromColId}
      const colIds =
        automationConfig?.mappingVersion === MAPPING_VERSION
          ? Object.values(headerMapping ?? {})
          : Object.keys(headerMapping ?? {});
      const uniqueColIds = union(...colIds);
      return {colIds: getColIdsWithListIds(uniqueColIds)};
    } else if (NON_VALUE_AUTOMATION.includes(type)) {
      const colIds = Object.keys(colIdMapping).map((id) => `${id}`);

      const phoneColId = `${phoneColIdFromConfig?.id}`;
      const triggerColId = `${triggerCol?.id}`;

      const notAssignee = type !== MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE;
      const SPECIFIC_OR_ASSIGNEE = [
        MESSAGE_AUTOMATION.ON_SPECIFIC_DATE,
        MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE,
      ];

      if (!colIds.includes(phoneColId) && notAssignee) {
        colIds.push(phoneColId);
      }
      if (
        SPECIFIC_OR_ASSIGNEE.includes(type) &&
        !colIds.includes(triggerColId)
      ) {
        colIds.push(triggerColId);
      }

      return {colIds: getColIdsWithListIds(colIds)};
    } else if (isCellStatus && isDocOperation) {
      // FORMAT: {[ToColId]: FromColId}
      const colIds =
        automationConfig?.mappingVersion === MAPPING_VERSION
          ? Object.values(headerMapping ?? {})
          : Object.keys(headerMapping ?? {});
      const uniqueColIds = union(...colIds);
      return {colIds: getColIdsWithListIds(uniqueColIds)};
    } else if (isCellStatus) {
      let colIds = [];
      const phoneColId = `${phoneColIdFromConfig?.id}`;

      if (!colIds.includes(`${phoneColId}`)) {
        colIds.push(`${phoneColId}`);
      }

      // handle trigger automations
      const triggerColIds = Object.keys(columns);
      triggerColIds.forEach((tc) => {
        if (!colIds.includes(`${tc}`)) {
          colIds.push(`${tc}`);
        }
        let {triggers} = columns?.[tc] || {};
        triggers = !isEmpty(triggers) ? triggers : {};
        Object.keys(triggers).forEach((triggerColId) => {
          let {colIdMapping: mapping} = triggers[triggerColId] ?? {};
          mapping = !isEmpty(mapping) ? mapping : {};
          colIds = union(
            colIds,
            Object.keys(mapping).map((id) => `${id}`),
          );
        });
      });

      return {colIds: getColIdsWithListIds(colIds)};
    }
  } catch (error) {
    captureError(error);
  }
};

const setPendingAutomation = (obj, index, colId) => (dispatch, getState) => {
  const extraUndoActions = [];
  const extraRedoActions = [];

  let dateSpecificUndoActions = [];
  let dateSpecificRedoActions = [];

  try {
    const {
      table,
      automation: {
        automationConfig,
        automationActiveDocumentId: activeDocumentId,
        pendingAutomations,
        scheduledAutomation,
      },
      premium: {subscriptions, lastActivePlan},
      table: {isPremiumSetAutomationBannerShown}, // TODO : Change
      auth: {
        user: {uid},
      },
      searchFilter,
    } = getState();

    const activeDocumentMeta = automationUtils.getAutomationDocumentMeta(
      getState(),
    );

    const isPremiumUser = subscriptions.includes(
      AVAILABLE_ENTITLEMENTS.AUTOMATIONS,
    );
    if (!isPremiumUser) {
      return;
    }

    let isDataAvailableForAutomation = false;

    const rowObjAtIndex = getRowObjAtIndex(
      table.tableData,
      searchFilter,
      index,
    );

    const rowId = obj?.rowId ?? rowObjAtIndex?.rowId;
    const prevObj = rowObjAtIndex;

    const removedPendingAutomations = {};
    const addedPendingAutomations = {};

    const addDateSpecificAutomations = {};
    const removeDateSpecificAutomations = {};
    const removeDateSpecificAutomationsRedoObj = {};

    const previousPendingAutomations = {};
    const previousRowValue = rowObjAtIndex ?? {};

    const removeAutomationFirebase = {};

    if (!activeDocumentMeta?.isAutomationEnabled || !rowId) {
      return;
    }
    const collab = !isEmpty(activeDocumentMeta?.collab)
      ? activeDocumentMeta.collab
      : {};
    const isCustomSharedUser =
      collab.permission === SHARE_PERMISSION_TYPE.CUSTOM;

    const headerTypeMapping = getHeaderTypeMapping(
      isCustomSharedUser ? table.originalHeaderData : table.headerData,
    );

    if (!isEmpty(automationConfig)) {
      // ? FOR UNDO REDO
      if (!isEmpty(pendingAutomations[rowId])) {
        previousPendingAutomations[rowId] = pendingAutomations[rowId];
      }
      // FOR UNDO REDO
      Object.keys(automationConfig).forEach((automationId) => {
        const automationCofigData = automationConfig[automationId];
        const automationDataAvailable =
          automationUtils.isAutomationDataAvailable(
            obj,
            automationCofigData,
            headerTypeMapping,
            colId,
            previousRowValue,
            automationId,
            !isEmpty(pendingAutomations?.[rowId]), // pending automation is already available or not.
          );
        // if automation data available then get rowId and save to pending automations
        if (automationDataAvailable) {
          // ? FOR UNDO REDO
          isDataAvailableForAutomation = true;

          if (
            automationCofigData.type === MESSAGE_AUTOMATION.ON_SPECIFIC_DATE
          ) {
            const prevTimestamp = prevObj[automationCofigData.triggerCol?.id]
              ?.val
              ? moment
                  .unix(prevObj[automationCofigData.triggerCol?.id]?.val)
                  .utc()
                  .format()
              : null;
            if (
              !isEqual(
                obj[automationCofigData.triggerCol?.id]?.val,
                prevObj[automationCofigData.triggerCol?.id]?.val,
              ) &&
              prevTimestamp
            ) {
              // Add to redo object if available
              if (scheduledAutomation[prevTimestamp]?.[rowId]?.[automationId]) {
                removeDateSpecificAutomationsRedoObj[prevTimestamp] = {
                  [rowId]: {
                    ...(removeDateSpecificAutomationsRedoObj?.[prevTimestamp]?.[
                      rowId
                    ] ?? {}),
                    [automationId]:
                      scheduledAutomation[prevTimestamp]?.[rowId]?.[
                        automationId
                      ],
                  },
                };
              }

              removeDateSpecificAutomations[prevTimestamp] = {
                [`${rowId}.${automationId}`]:
                  firestore(true).FieldValue.delete(),
              };
            }

            const unixTime = ENV
              ? moment.unix(obj[automationCofigData.triggerCol?.id]?.val)
              : moment
                  .unix(obj[automationCofigData.triggerCol?.id]?.val)
                  .set({second: 0, millisecond: 0})
                  .startOf('day');

            const formatedTimestamp = unixTime.utc().format();

            if (!moment(formatedTimestamp).isBefore(moment())) {
              addDateSpecificAutomations[formatedTimestamp] = {
                ...(addDateSpecificAutomations?.[formatedTimestamp] ?? {}),
                [rowId]: {
                  ...(addDateSpecificAutomations?.[formatedTimestamp]?.[
                    rowId
                  ] ?? {}),
                  [automationId]: {
                    uid,
                    timestamp: moment().valueOf(),
                  },
                },
              };
            }
          } else if (!previousPendingAutomations[rowId]?.[automationId]) {
            addedPendingAutomations[rowId] = {
              ...(addedPendingAutomations?.[rowId] ?? {}),
              ...(previousPendingAutomations?.[rowId] ?? {}),
              [automationId]: {
                uid,
                timestamp: moment().valueOf(),
              },
            };
          }
        } else if (automationDataAvailable === false) {
          if (
            automationCofigData.type === MESSAGE_AUTOMATION.ON_SPECIFIC_DATE
          ) {
            const unixTime = ENV
              ? moment.unix(obj[automationCofigData.triggerCol?.id]?.val)
              : moment
                  .unix(obj[automationCofigData.triggerCol?.id]?.val)
                  .set({second: 0, millisecond: 0})
                  .startOf('day');
            const formatedTimestamp = unixTime.utc().format();

            if (
              scheduledAutomation[formatedTimestamp]?.[rowId]?.[automationId]
            ) {
              // Add to redo object if available
              removeDateSpecificAutomationsRedoObj[formatedTimestamp] = {
                [rowId]: {
                  ...(removeDateSpecificAutomationsRedoObj?.[
                    formatedTimestamp
                  ]?.[rowId] ?? {}),
                  [automationId]:
                    scheduledAutomation[formatedTimestamp]?.[rowId]?.[
                      automationId
                    ],
                },
              };

              removeDateSpecificAutomations[formatedTimestamp] = {
                [`${rowId}.${automationId}`]:
                  firestore(true).FieldValue.delete(),
              };
            }
          } else if (previousPendingAutomations[rowId]?.[automationId]) {
            // ? FOR UNDO REDO
            if (Object.keys(previousPendingAutomations[rowId])?.length > 1) {
              removeAutomationFirebase[`${rowId}.${automationId}`] =
                firestore(true).FieldValue.delete();
            } else {
              removeAutomationFirebase[`${rowId}`] =
                firestore(true).FieldValue.delete();
            }

            removedPendingAutomations[rowId] = {
              ...(previousPendingAutomations?.[rowId] ?? {}),
              ...(removedPendingAutomations?.[rowId] ?? {}),
              [automationId]: {
                ...(previousPendingAutomations?.[rowId]?.[automationId] ?? {}),
                timestamp: moment().valueOf(),
              },
            };
          }

          // ! FOR UNDO REDO
        }
      });

      if (!isPremiumUser && isDataAvailableForAutomation) {
        const isAdminOrOwner =
          !activeDocumentMeta.collab ||
          activeDocumentMeta?.collab?.isOwner ||
          activeDocumentMeta?.collab?.permission === SHARE_PERMISSION_TYPE.ADMIN
            ? true
            : false;
        const source =
          lastActivePlan === LAST_ACTIVE_PLAN.FREE
            ? 'TRIAL_EXPIRED'
            : 'PREMIUM_EXPIRED';

        if (
          (!isAdminOrOwner && !isPremiumSetAutomationBannerShown) ||
          isAdminOrOwner
        ) {
          if (ENV) {
            logAnalyticsEvent('SHOW_RENEW_PREMIUM_MODAL', {
              source,
              docId: activeDocumentId,
            });
            dispatch(
              invokePopupModal({
                docId: activeDocumentId,
                // eslint-disable-next-line local-rules/rb-redux-requires
                imgSrc: require('../../app/assets/images/lio-premium.png'),
                heading: `Your ${
                  lastActivePlan === LAST_ACTIVE_PLAN.FREE ? 'Trial' : 'Premium'
                } Plan is expired.`,
                message: isAdminOrOwner
                  ? 'Lio automation will not work since it is a premium feature. Renew premium to use automation.'
                  : 'This file contains automations which works for premium plan, please ask the file owner to add you on the premium plan else the automation will not work.',
                source,
                secondaryButtonText: isAdminOrOwner
                  ? 'Pause, all automations'
                  : 'Ok',
                type: isAdminOrOwner ? 'PAUSE_ALL_AUTOMATION' : 'DO_NOTHING',
                hidePrimaryButton: !isAdminOrOwner ? true : false,
              }),
            );
          }
          if (!isAdminOrOwner) {
            dispatch(setPremiumAutomationBannerShown());
          }
        }

        // disble all automation for docId
        return;
      } else if (!isPremiumUser) {
        return;
      }
      if (isEmpty(pendingAutomations)) {
        if (!isEmpty(addDateSpecificAutomations)) {
          const {undoActions, redoActions} = dispatch(
            setScheduledAutomation(
              activeDocumentId,
              addDateSpecificAutomations,
            ),
          );

          dateSpecificUndoActions = [
            ...dateSpecificUndoActions,
            ...undoActions,
          ];

          dateSpecificRedoActions = [
            ...dateSpecificRedoActions,
            ...redoActions,
          ];
        } else if (!isEmpty(addedPendingAutomations)) {
          // remove this
          setPendingAutomationDocument(
            activeDocumentId,
            addedPendingAutomations,
          );
          // extraActionsHandler([
          //   {
          //     updateObjType: 'object',
          //     elementType: 'object',
          //     element: addedPendingAutomations[rowId],
          //     action: 'ADD',
          //     collection: 'pendingAutomations',
          //     path: `${activeDocumentId}`,
          //     updatePath: `${rowId}`,
          //     addIdPathIfNotExist: true,
          //   },
          // ]);
        }
      } else {
        if (!isEmpty(addDateSpecificAutomations)) {
          //Update automations
          const {undoActions, redoActions} = dispatch(
            setScheduledAutomation(
              activeDocumentId,
              addDateSpecificAutomations,
            ),
          );
          dateSpecificUndoActions = [
            ...dateSpecificUndoActions,
            ...undoActions,
          ];

          dateSpecificRedoActions = [
            ...dateSpecificRedoActions,
            ...redoActions,
          ];
        }
        if (!isEmpty(addedPendingAutomations)) {
          updatePendingAutomationDocument(
            activeDocumentId,
            addedPendingAutomations,
          );
          // extraActionsHandler([
          //   {
          //     updateObjType: 'object',
          //     elementType: 'object',
          //     element: addedPendingAutomations[rowId],
          //     action: 'ADD',
          //     collection: 'pendingAutomations',
          //     path: `${activeDocumentId}`,
          //     updatePath: `${rowId}`,
          //     addIdPathIfNotExist: true,
          //   },
          // ]);
        }
        if (!isEmpty(removeDateSpecificAutomations)) {
          //Remove automations
          const {undoActions, redoActions} = dispatch(
            deleteScheduledAutomation(
              activeDocumentId,
              removeDateSpecificAutomations,
              removeDateSpecificAutomationsRedoObj,
            ),
          );
          dateSpecificUndoActions = [
            ...dateSpecificUndoActions,
            ...undoActions,
          ];

          dateSpecificRedoActions = [
            ...dateSpecificRedoActions,
            ...redoActions,
          ];
        }
        if (!isEmpty(removedPendingAutomations)) {
          updatePendingAutomationDocument(
            activeDocumentId,
            removeAutomationFirebase,
          );
          // extraActionsHandler([
          //   {
          //     updateObjType: 'object',
          //     elementType: 'object',
          //     element: removedPedngingAutomations[rowId],
          //     action: 'REMOVE',
          //     collection: 'pendingAutomations',
          //     path: `${activeDocumentId}`,
          //     updatePath: `${rowId}`,
          //     removeParentObjIfEmpty: true,
          //   },
          // ]);
        }
      }
    }
    const undoActions = () => {
      const actions = [];
      if (!isEmpty(addedPendingAutomations)) {
        // REMOVE ON UNDO
        actions.push({
          updateObjType: 'object',
          elementType: 'object',
          element: addedPendingAutomations[rowId],
          action: 'REMOVE',
          collection: 'pendingAutomations',
          path: `${activeDocumentId}`,
          updatePath: `${rowId}`,
          removeParentObjIfEmpty: true,
        });
      }
      if (!isEmpty(removedPendingAutomations)) {
        // ADD ON UNDO
        actions.push({
          updateObjType: 'object',
          elementType: 'object',
          element: omit(
            removedPendingAutomations[rowId],
            getAutomationOmitKeysV1(),
          ),
          action: 'ADD',
          collection: 'pendingAutomations',
          path: `${activeDocumentId}`,
          updatePath: `${rowId}`,
          addIdPathIfNotExist: true,

          // Redux
          reduxCheckPath: 'automation.automationConfig',
          reduxElementType: 'object',
          reduxCheckValue: {
            type: 'Array<string>',
            value: Object.keys(removedPendingAutomations[rowId] ?? {}),
          },
          reduxOperation: 'REMOVE',
        });
      }
      return actions;
    };
    const redoActions = () => {
      const actions = [];
      if (!isEmpty(addedPendingAutomations)) {
        // ADD ON REDO
        actions.push({
          updateObjType: 'object',
          elementType: 'object',
          element: omit(
            addedPendingAutomations[rowId],
            getAutomationOmitKeysV1(),
          ),
          action: 'ADD',
          collection: 'pendingAutomations',
          path: `${activeDocumentId}`,
          updatePath: `${rowId}`,
          addIdPathIfNotExist: true,

          // Redux
          reduxCheckPath: 'automation.automationConfig',
          reduxElementType: 'object',
          reduxCheckValue: {
            type: 'Array<string>',
            value: Object.keys(addedPendingAutomations[rowId] ?? {}),
          },
          reduxOperation: 'REMOVE',
        });
      }
      if (!isEmpty(removedPendingAutomations)) {
        // REMOVE ON REDO
        actions.push({
          updateObjType: 'object',
          elementType: 'object',
          element: removedPendingAutomations[rowId],
          action: 'REMOVE',
          collection: 'pendingAutomations',
          path: `${activeDocumentId}`,
          updatePath: `${rowId}`,
          removeParentObjIfEmpty: true,
        });
      }
      return actions;
    };
    return {
      extraUndoActions: [...undoActions(), ...dateSpecificUndoActions],
      extraRedoActions: [...redoActions(), ...dateSpecificRedoActions],
    };
  } catch (error) {
    captureError(error);
    {
      extraUndoActions, extraRedoActions;
    }
  }
};

const activatePendingAutomationsListener =
  (docId, firestoreInstance) => (dispatch, getState) => {
    try {
      firestoreInstance = firestoreInstance ?? firestore;
      const onPendingAutomationChanged = (QuerySnapshot) => {
        try {
          if (!QuerySnapshot.exists) {
            return;
          }
          const {
            automation: {automationActiveDocumentId: activeDocumentId},
          } = getState();
          if (docId === activeDocumentId) {
            const data = QuerySnapshot.data();
            dispatch({
              type: AUTOMATION_ACTION.SET_PENDING_AUTOMATIONS,
              payload: {pendingAutomationsObj: data},
            });
          }
        } catch (err) {
          captureInfo({
            err: serializeError(err),
            QuerySnapshot,
          });
          captureError(
            new Error('Error in activatePendingAutomationsListener'),
          );
        }
      };
      return FirestoreDB.FirestoreListener(
        FirestoreDB.automations.pending(docId, firestoreInstance),
        onPendingAutomationChanged,
      );
    } catch (error) {
      captureError(error);
    }
  };

const getCurrentNotificationTemplateId =
  (isEdit = false) =>
  (dispatch, getState) => {
    const {
      automation: {newAuto, currentAutomation, automationConfig},
    } = getState();
    let returnId = undefined;
    try {
      returnId = isEdit
        ? NON_VALUE_AUTOMATION.includes(currentAutomation?.type)
          ? automationConfig?.[currentAutomation?.automationId]
              ?.notificationTemplateId
          : dispatch(getTriggerCurrentNotificationTemplateID())
        : NON_VALUE_AUTOMATION.includes(currentAutomation?.type)
        ? newAuto?.notificationTemplateId
        : newAuto?.triggerData?.[0]?.notificationTemplateId;
    } catch (error) {
      captureError(error);
    }
    return returnId;
  };

const getTriggerCurrentNotificationTemplateID = () => (dispatch, getState) => {
  const {
    automation: {currentAutomation, automationConfig},
  } = getState();
  let returnId = undefined;
  try {
    const currentAutomationData =
      automationConfig?.[currentAutomation?.automationId];
    const colId = findKey(currentAutomationData?.columns);
    if (colId && automationConfig) {
      const thisStatus = findKey(
        currentAutomationData.columns?.[colId]?.triggers,
      );
      const notificationTemplateId =
        currentAutomationData.columns?.[colId]?.triggers?.[thisStatus]
          ?.notificationTemplateId;
      if (notificationTemplateId) {
        returnId = notificationTemplateId;
      }
    }
  } catch (error) {
    captureError(error);
  }
  return returnId;
};

const setIsEditingColIdMapping = (isEditing) => (dispatch) => {
  dispatch({
    type: AUTOMATION_ACTION.SET_IS_EDITTING_COLUMN_MAPPING,
    payload: isEditing,
  });
};

const getIsEditingColIdMapping = () => (dispatch, getState) => {
  const {
    automation: {isEditingColIdMapping},
  } = getState();
  return isEditingColIdMapping;
};

const changeCurrentToPrevMapping =
  (isEdit = false) =>
  (dispatch, getState) => {
    const {
      automation: {newAuto, currentAutomation},
    } = getState();
    try {
      const updateAutomationObj = NON_VALUE_AUTOMATION.includes(
        currentAutomation?.type,
      )
        ? {
            colIdMapping: formatColIdMappingForRedux(
              isEdit
                ? dispatch(
                    getCurrentAutomationColIdMapping(
                      currentAutomation?.automationId,
                    ),
                  )
                : newAuto?.prevColIdMapping,
            ),
            notificationTemplateId: isEdit
              ? dispatch(getCurrentNotificationTemplateId(isEdit))
              : newAuto?.prevNotificationTemplateId,
          }
        : currentAutomation?.type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE
        ? {
            triggerData: [
              {
                status: newAuto?.triggerData?.[0]?.status,
                colIdMapping: formatColIdMappingForRedux(
                  isEdit
                    ? dispatch(
                        getCurrentAutomationColIdMapping(
                          currentAutomation?.automationId,
                        ),
                      )
                    : newAuto?.triggerData?.[0]?.prevColIdMapping,
                ),
                notificationTemplateId: isEdit
                  ? dispatch(getCurrentNotificationTemplateId(isEdit))
                  : newAuto?.triggerData?.[0]?.prevNotificationTemplateId,
              },
            ],
          }
        : {};
      if (!isEmpty(updateAutomationObj)) {
        dispatch(updateNewAutomation(updateAutomationObj));
      }
    } catch (error) {
      captureError(error);
    }
  };

const formatColIdMappingForRedux = (colIdMapping) => {
  let updatedColIdMapping = {};
  try {
    for (const placeHolderKey in colIdMapping) {
      updatedColIdMapping = Object.assign({}, updatedColIdMapping, {
        [colIdMapping[placeHolderKey].placeHolderKey]: {
          ...colIdMapping[placeHolderKey],
          placeHolderKey,
        },
      });
    }
  } catch (error) {
    captureError(error);
  }
  return updatedColIdMapping;
};

const updateMiniAppsAutomationConfig =
  (docId, automationId, config) => (dispatch, getState) => {
    const {
      automation: {miniAppAutomationConfig},
    } = getState();
    dispatch({
      type: AUTOMATION_ACTION.SET_MINI_APP_AUTOMATION_CONFIG_DATA,
      payload: Object.assign({}, miniAppAutomationConfig, {
        [docId]: {
          ...miniAppAutomationConfig?.[docId],
          [automationId]: config,
        },
      }),
    });
  };

/**
 * Function for creating/updating an automation...
 * @param {boolean} isEdit - Is editing an automation
 * @param {object} extra - Extra variables to be sent to function
 * @returns -
 * ! Adding new variables in automation make sure to add previous values in {autoObj}.
 */
const createAutomation =
  (isEdit, extra = {}) =>
  (dispatch, getState) => {
    // check and update isAutomationEnabled
    try {
      const {
        automation: {
          newAuto,
          automationConfig,
          currentAutomation,
          currentCol,
          editTriggerCol,
          catId,
          constTemplateValues,
          automationTemplates,
          disabledDueToPrevRowRef,
          automationDocumentId: originalDocumentId,
          automationTemplatesType,
          miniAppIntegrationEmails,
        },
        auth: {
          user: {uid},
          userPref,
          userAutomationConfig,
        },
      } = getState();
      const {activeAppId, interaktId} = mapMiniAppStates(getState());
      const activeDocumentMeta = automationUtils.getAutomationDocumentMeta(
        getState(),
      );
      const headerData = automationUtils.getAutomationTableState(
        getState(),
      ).automationHeaderData;
      // create new auto
      const current_automation_id = currentAutomation?.automationId;
      const analyticsObj = !isEmpty(extra?.analyticsObj)
        ? {...extra?.analyticsObj, automationId: current_automation_id}
        : null;

      let updatedColIdMapping = {};

      const userDefined = userAutomationConfig?.useUserWhatsApp
        ? newAuto?.userDefined ?? undefined
        : undefined; // If user has

      let autoObj = {catId};

      const createdTimestamp = moment().utc().valueOf();

      // https://www.figma.com/file/ek2cqWqPZ0KWDUeI8K7Rzu/Automation-Apps?node-id=0-1&t=oc7UgMlrgguZmRIa-0
      // TODO : Add analytics params for mini apps

      if (currentAutomation.type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE) {
        const triggerVals = {};
        const templateMappingAvailable =
          automationUtils.templateMappingContinueEnabled({
            newAuto,
            userPref,
            constTemplateValues,
            currentAutomation,
            automationTemplates,
          });
        newAuto.triggerData?.forEach?.((item) => {
          const statusData = omit(item, ['status']);
          triggerVals[item.status] = {
            ...omit(statusData, [
              'colIdMapping',
              'prevColIdMapping',
              'prevNotificationTemplateId',
            ]),
            colIdMapping: templateMappingAvailable
              ? !isEmpty(statusData?.colIdMapping)
                ? automationUtils.formatColIdMapFirestore(
                    statusData.colIdMapping,
                  )
                : {}
              : !isEmpty(statusData?.prevColIdMapping)
              ? statusData.prevColIdMapping
              : {},
          };
        });
        const columnsData =
          automationConfig?.[current_automation_id]?.columns ?? {};
        const updatedColumnsData =
          `${editTriggerCol.id}` !== `${currentCol.id}`
            ? omit(
                {
                  ...columnsData,
                  [currentCol.id]: {
                    triggers: {
                      ...triggerVals,
                    },
                  },
                },
                [editTriggerCol.id],
              )
            : {
                ...columnsData,
                [currentCol.id]: {
                  triggers: {
                    ...triggerVals,
                  },
                },
              };

        autoObj = Object.assign(
          {},
          {
            isEnabled:
              typeof newAuto.isEnabled !== 'undefined'
                ? newAuto.isEnabled
                : true,
            type: currentAutomation.type,
            createdByUID: newAuto?.createdByUID ?? undefined,
            businessProfileId: newAuto?.businessProfileId ?? undefined,
            useUserWhatsappAPI: newAuto?.useUserWhatsappAPI ?? undefined,
            headerMapping: newAuto?.headerMapping ?? undefined,
            defaultValMap: newAuto?.defaultValMap ?? undefined,
            defaultValConstantMap: newAuto?.defaultValConstantMap ?? undefined,
            idPasteTo: newAuto?.idPasteTo ?? undefined,
            operationType: currentAutomation?.operationType,
            allTriggerConditionType:
              newAuto?.allTriggerConditionType ?? undefined,
            allTriggerConditions: newAuto?.allTriggerConditions ?? undefined,
            customNotificationText: newAuto?.customNotificationText ?? '',
          },
          {
            columns: updatedColumnsData,
            catId,
            constTemplateValues,
          },
        );
      } else {
        if (currentAutomation.type === MESSAGE_AUTOMATION.ROW_EDIT_ENTRY) {
          const newAutoObj = omit(newAuto, [
            'triggerData',
            'colIdMapping',
            'prevColIdMapping',
            'prevNotificationTemplateId',
          ]);

          autoObj = Object.assign({}, newAutoObj, {
            isEnabled:
              typeof newAuto.isEnabled !== 'undefined'
                ? newAuto.isEnabled
                : true,
            createdTimestamp,
            ...currentAutomation,
            editTriggerCols: newAutoObj?.editTriggerCols ?? undefined,
            editTriggerType: newAutoObj?.editTriggerType ?? undefined,
            editTriggerConditionType:
              newAutoObj?.editTriggerConditionType ?? undefined,
            editTriggerConditions:
              newAutoObj?.editTriggerConditions ?? undefined,
            editTriggerOnEmptySet:
              newAutoObj?.editTriggerOnEmptySet ?? undefined,
          });
        } else {
          updatedColIdMapping = automationUtils.formatColIdMapFirestore(
            newAuto.colIdMapping,
          );
          const newAutoObj = omit(newAuto, [
            'triggerData',
            'colIdMapping',
            'prevColIdMapping',
            'prevNotificationTemplateId',
          ]);

          autoObj = Object.assign({}, newAutoObj, {
            colIdMapping: updatedColIdMapping,
            isEnabled:
              typeof newAuto.isEnabled !== 'undefined'
                ? newAuto.isEnabled
                : true,
            createdTimestamp,
            ...currentAutomation,
            catId,
            constTemplateValues,
            allTriggerConditionType:
              newAutoObj?.allTriggerConditionType ?? undefined,
            allTriggerConditions: newAutoObj?.allTriggerConditions ?? undefined,
          });
        }
      }

      // Whatsapp Automation Operation
      if (
        !currentAutomation?.operationType ||
        currentAutomation?.operationType === DEFAULT_OPERATION_TYPE
      ) {
        updatedColIdMapping = automationUtils.formatColIdMapFirestore(
          newAuto.colIdMapping,
        );
        autoObj = Object.assign({}, autoObj, {
          constTemplateValues,
          colIdMapping: updatedColIdMapping,
        });
      }

      if (activeAppId?.length) {
        autoObj = Object.assign({}, autoObj, {miniAppId: activeAppId});
        if (
          automationTemplatesType === AUTOMATION_TEMPLATES_TYPE.INTEGRATIONS
        ) {
          autoObj = Object.assign({}, autoObj, {integrationId: interaktId});
        }
      }

      if (!isEmpty(userDefined)) {
        autoObj = Object.assign({}, autoObj, {userDefined});
      }

      if (Features.AUTOMATION_V2) {
        // TODO : HAndle Edit for `name`
        autoObj = Object.assign({}, autoObj, {
          name: newAuto?.name ?? 'Automation',
          mappingVersion: newAuto?.mappingVersion ?? undefined,
          additionalDataSource: newAuto?.additionalDataSource ?? undefined,
          defaultValConstantMapOptions:
            newAuto?.defaultValConstantMapOptions ?? undefined,
          aggregationColMap: newAuto?.aggregationColMap ?? undefined,
          endpointColMap: newAuto?.endpointColMap ?? undefined,
          endpointMap: newAuto?.endpointMap ?? undefined,
        });

        const aggregationIds = new Set();
        Object.keys(
          Object.assign({}, newAuto?.defaultValConstantMapOptions),
        ).forEach((colId) => {
          const isAggs =
            newAuto.defaultValConstantMapOptions[colId]?.type === 'AGGREGATION';
          if (isAggs) {
            aggregationIds.add(
              newAuto.defaultValConstantMapOptions[colId].aggregationInfo
                .aggregationId,
            );
          }
        });
        Object.keys(Object.assign({}, newAuto?.aggregationColMap)).forEach(
          (colId) => {
            aggregationIds.add(newAuto.aggregationColMap[colId].aggregationId);
          },
        );
        autoObj.aggregationMap = aggregationIds.size
          ? pick(
              Object.assign({}, newAuto?.aggregationMap?.selectedAggregations),
              Array.from(aggregationIds),
            )
          : undefined;

        autoObj = omit(autoObj, ['templateName', 'isEdit']);
      }

      if (
        Features.AUTOMATION_V2 &&
        currentAutomation?.operationType ===
          AUTOMATION_OPERATION_TYPES.SET_VALUE_BY_FILTERS
      ) {
        const filterColToColMap = newAuto?.filterColToColMap || [];

        autoObj = Object.assign({}, autoObj, {
          filterConditionType: newAuto.filterConditionType,
          // TODO : Move to UI
          filterColToColMap,
          toDocOperation: newAuto.toDocOperation,
          updateOnUniqueFail: newAuto.updateOnUniqueFail,
        });
      }

      if (
        newAuto?.type === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE &&
        newAuto?.triggerCol?.id
      ) {
        // TODO : Check If edit issue.
        autoObj = Object.assign({}, autoObj, {
          triggerCol: newAuto.triggerCol,
        });
      }

      if (isEdit) {
        autoObj = Object.assign({}, autoObj, {
          isEnabled: extra?.isEnabled,
        });
        if (
          isEmpty(
            automationUtils.getAutomationColumnsWithPrevRowRef(
              Object.keys(autoObj?.colIdMapping ?? {}),
              headerData,
            ),
          )
        ) {
          dispatch({
            type: AUTOMATION_ACTION.SET_AUTOMATIONS_DISABLED_DUE_TO_PREV_ROW_REF,
            payload: omit(disabledDueToPrevRowRef, [current_automation_id]),
          });
        }
      } else {
        //Save uid for first time when automation is created
        autoObj = Object.assign({}, autoObj, {
          createdByUID: uid,
          businessProfileId: userPref?.businessProfileId ?? undefined,
          useUserWhatsappAPI:
            dispatch(shouldAddToUseUserWhatsappAPI(false)) ?? undefined,
        });
      }

      if (
        currentAutomation?.operationType ===
        AUTOMATION_OPERATION_TYPES.SEND_NOTIFICATION
      ) {
        const {
          userType,
          usersList,
          userPermissions,
          userCustomPermissions,
          messageConfig,
        } = newAuto;
        const obj =
          userType === NOTIFICATION_OPERATION_USER_TYPES.USER_LIST
            ? {
                usersList,
                userPermissions: undefined,
                userCustomPermissions: undefined,
              }
            : userType === NOTIFICATION_OPERATION_USER_TYPES.USER_PERMISSION
            ? {userPermissions, userCustomPermissions, usersList: undefined}
            : {};

        // ** Updated Auto Object
        autoObj = Object.assign({}, autoObj, {
          userType: userType ?? undefined,
          ...(!isEmpty(messageConfig) ? {messageConfig} : {}),
          ...obj,
        });
      }

      autoObj = omit(autoObj, [
        'automationId',
        'prevColIdMapping',
        'prevNotificationTemplateId',
      ]);

      // Filtering out phoneColId for on assignee change automation
      autoObj = {
        ...autoObj,
        ...(automationConfig?.type === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE
          ? {phoneColId: {}}
          : {phoneColId: newAuto.phoneColId}),
      };

      if (autoObj?.operationType === AUTOMATION_OPERATION_TYPES.SEND_EMAIL) {
        autoObj = {
          ...autoObj,
          emailIntegration: newAuto?.emailIntegration,

          userType: newAuto?.userType,

          usersList:
            newAuto?.userType === NOTIFICATION_OPERATION_USER_TYPES.USER_LIST
              ? newAuto?.usersList
              : undefined,

          userPermissions:
            newAuto?.userType ===
            NOTIFICATION_OPERATION_USER_TYPES.USER_PERMISSION
              ? newAuto?.userPermissions
              : undefined,
          userCustomPermissions:
            newAuto?.userType ===
            NOTIFICATION_OPERATION_USER_TYPES.USER_PERMISSION
              ? newAuto?.userCustomPermissions
              : undefined,

          userColumnIds:
            newAuto?.userType ===
            NOTIFICATION_OPERATION_USER_TYPES.EMAIL_COLUMNS
              ? newAuto?.userColumnIds
              : undefined,

          ccUserType: newAuto?.ccUserType,
          ccUserColumnIds:
            newAuto?.ccUserType ===
            NOTIFICATION_OPERATION_USER_TYPES.EMAIL_COLUMNS
              ? newAuto?.ccUserColumnIds
              : undefined,
          ccUsersList:
            newAuto?.ccUserType === NOTIFICATION_OPERATION_USER_TYPES.USER_LIST
              ? newAuto?.ccUsersList
              : undefined,
          ccUserPermissions:
            newAuto?.ccUserType ===
            NOTIFICATION_OPERATION_USER_TYPES.USER_PERMISSION
              ? newAuto?.ccUserPermissions
              : undefined,
          ccUserCustomPermissions:
            newAuto?.ccUserType ===
            NOTIFICATION_OPERATION_USER_TYPES.USER_PERMISSION
              ? newAuto?.ccUserCustomPermissions
              : undefined,

          messageConfig: newAuto?.messageConfig,
        };
      }

      if (current_automation_id && currentAutomation?.type) {
        // https://www.figma.com/file/ek2cqWqPZ0KWDUeI8K7Rzu/Automation-Apps?node-id=0-1&t=oc7UgMlrgguZmRIa-0
        // TODO : Add analytics params for mini apps

        // OMIT START below in case automation added from Automation-V1.
        if (
          [
            MESSAGE_AUTOMATION.CELL_STATUS_CHANGE,
            MESSAGE_AUTOMATION.ROW_NEW_ENTRY,
          ].includes(autoObj?.type) &&
          'triggerCol' in autoObj
        ) {
          autoObj = omit(autoObj, ['triggerCol']);
        }

        const omitObj = {};
        getAutomationOmitKeysV1().forEach((item) => {
          if (automationConfig?.[item]) {
            omitObj[item] = firestore(true).FieldValue.delete();
          }
        });
        // OMIT END
        if (
          editTriggerCol.id &&
          `${editTriggerCol.id}` !== `${currentCol.id}`
        ) {
          updateAutomation(
            {
              [current_automation_id]: autoObj,
              ...(!isEmpty(omitObj) ? omitObj : {}),
            },
            originalDocumentId,
            {
              analyticsObj: analyticsObj,
              isEdit,
            },
          );
        } else {
          if (automationConfig?.[current_automation_id]) {
            updateAutomation(
              {
                [current_automation_id]: autoObj,
                ...(!isEmpty(omitObj) ? omitObj : {}),
              },
              originalDocumentId,
              {
                analyticsObj: analyticsObj,
                isEdit,
              },
            );
          } else {
            if (interaktId && isEdit === false) {
              // add docAutomationConfig to custom whatsapp templates
              createInteraktMapping(
                interaktId,
                `${originalDocumentId}.${current_automation_id}`,
              );
            }
            setAutomation(
              {[current_automation_id]: autoObj},
              originalDocumentId,
              {
                analyticsObj: analyticsObj,
                isEdit,
              },
            );
          }
        }

        const updatedAutoConfig = Object.assign({}, automationConfig, {
          [current_automation_id]: autoObj,
        });

        let dependentCols = [];

        const dependentColObj = {};
        Object.keys(updatedAutoConfig).forEach((automationId) => {
          dependentCols = union(
            dependentCols,
            automationDependentCols(updatedAutoConfig[automationId], headerData)
              ?.colIds,
          );

          dependentColObj[automationId] = automationDependentCols(
            updatedAutoConfig[automationId],
            headerData,
          )?.colIds;
        });

        if (!isEdit && !activeDocumentMeta?.isAutomationEnabled) {
          dispatch(
            updateActiveDocMeta({
              isAutomationEnabled: true,
            }),
          );
        }

        // Update Configuration in MiniApps
        dispatch(
          updateMiniAppsAutomationConfig(
            originalDocumentId,
            current_automation_id,
            autoObj,
          ),
        );

        // Set docId in integration data for Doc Actions count
        if (
          !isEdit &&
          autoObj?.operationType === AUTOMATION_OPERATION_TYPES.SEND_EMAIL &&
          !isEmpty(autoObj?.emailIntegration)
        ) {
          miniAppsActionHelper
            .setAutomationIdInIntegrationData(
              autoObj?.emailIntegration?.integrationId,
              current_automation_id,
              originalDocumentId,
            )
            .then(() => {
              dispatch({
                type: AUTOMATION_ACTION.MINI_APP_SET_EMAILS_FOR_INTEGRATION,
                payload: (miniAppIntegrationEmails || []).map((item) => {
                  if (item.id === autoObj?.emailIntegration?.integrationId) {
                    return {
                      ...item,
                      noOfActions: (item?.noOfActions || 0) + 1,
                    };
                  }
                  return item;
                }),
              });
            })
            .catch((error) => captureError(error));
        }

        dispatch({
          type: AUTOMATION_ACTION.SET_DOC_AUTOMATION_CONFIG_DATA,
          payload: {
            automationConfig: updatedAutoConfig,
            dependentCols,
            dependentColObj,
          },
        });
      }
    } catch (error) {
      captureError(error);
    }
  };

const setCurrentAutomationCategory =
  ({
    automationId = null,
    automationCatId = null,
    isInit = false,
    operationType = null,
    analyticsObject = {},
  }) =>
  (dispatch, getState) => {
    try {
      const {
        automation: {automationConfig, availableAutomations},
      } = getState();

      const catId = automationId
        ? automationConfig[automationId]?.catId
        : automationCatId;

      dispatch({
        type: AUTOMATION_ACTION.UPDATE_CURRENT_AUTOMATION_CAT_ID,
        payload: catId,
      });
      if (isInit) {
        dispatch({
          type: AUTOMATION_ACTION.SET_OPERATION_FILE_DATA,
          payload: {},
        });
      }

      const analyticsObjToSend = {
        ...analyticsObject,
        p_automationType:
          AUTOMATION_OPERATION_PROPERTIES[operationType]?.text ||
          availableAutomations?.[catId]?.catgName?.['EN'] ||
          null,
      };

      if (isInit) {
        logAnalyticsEvent('ADD_NEW_AUTOMATION', analyticsObjToSend);
      } else {
        logAnalyticsEvent('EDIT_AUTOMATION', analyticsObjToSend);
      }
      return dispatch(addDocMetaToAutomationRedux());
    } catch (error) {
      captureError(error);
    }
  };

const deletePendingAutomations = (rowIdsArray) => (dispatch, getState) => {
  try {
    const {
      automation: {
        pendingAutomations,
        automationActiveDocumentId: activeDocumentId,
      },
    } = getState();
    const removeObj = {};
    if (!isEmpty(rowIdsArray) && isArray(rowIdsArray)) {
      rowIdsArray.forEach((item) => {
        removeObj[item] = firestore(true).FieldValue.delete();
      });
      updatePendingAutomationDocument(activeDocumentId, removeObj);
    }
    if ('timestamp' in pendingAutomations) {
      dispatch(deleteScheduledPendingAutomations(rowIdsArray));
    }
  } catch (error) {
    captureError(error);
  }
};

/***
 * Deletes row Ids from scheduled collection
 */
const deleteScheduledPendingAutomations =
  (rowIdsArray) => (dispatch, getState) => {
    try {
      const {
        automation: {
          scheduledAutomation,
          automationActiveDocumentId: activeDocumentId,
        },
      } = getState();
      for (const rowId of rowIdsArray) {
        for (const timestamp of Object.keys(scheduledAutomation)) {
          if (rowId in scheduledAutomation[timestamp]) {
            deleteDateSpecificAutomation({
              docId: activeDocumentId,
              timestamp,
              updateObj: {[rowId]: firestore(true).FieldValue.delete()},
            });
          }
        }
      }
    } catch (error) {
      captureError(error);
    }
  };

const removeAllPendingAutomations = (docId) => (dispatch) => {
  try {
    deleteAllPendingAutomationsHelper(docId);
    dispatch({
      type: AUTOMATION_ACTION.SET_PENDING_AUTOMATIONS,
      payload: {pendingAutomationsObj: {}},
    });
  } catch (error) {
    captureError(error);
  }
};

const manageAutomationTypeChange =
  (prevType, selectedType) => (dispatch, getState) => {
    const {
      automation: {newAuto},
    } = getState();
    try {
      if (
        prevType === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE &&
        NON_VALUE_AUTOMATION.includes(selectedType)
      ) {
        let updatedAuto = {...newAuto};
        updatedAuto = {
          ...omit(updatedAuto, ['triggerData']),
          ...(!isEmpty(updatedAuto?.triggerData?.[0])
            ? {
                ...omit(updatedAuto.triggerData[0], ['status']),
                ...(!isEmpty(updatedAuto?.triggerData?.[0]?.status)
                  ? {prevStatus: updatedAuto.triggerData[0].status}
                  : {}),
                ...(!isEmpty(updatedAuto?.triggerData?.[0]?.prevColIdMapping)
                  ? {
                      prevColIdMapping:
                        updatedAuto.triggerData[0].prevColIdMapping,
                      colIdMapping: formatColIdMappingForRedux(
                        updatedAuto.triggerData[0].prevColIdMapping,
                      ),
                    }
                  : {}),
                ...(!isEmpty(updatedAuto.triggerData[0].notificationTemplateId)
                  ? {
                      notificationTemplateId:
                        updatedAuto.triggerData[0].notificationTemplateId,
                    }
                  : {}),
              }
            : {}),
        };
        dispatch({
          type: AUTOMATION_ACTION.UPDATE_AUTOMATION_OBJ,
          payload: updatedAuto,
        });
        updatedAuto = undefined;
      }
      if (
        NON_VALUE_AUTOMATION.includes(prevType) &&
        selectedType === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE
      ) {
        let updatedAutoRow = {...newAuto};
        updatedAutoRow = {
          ...omit(updatedAutoRow, [
            'colIdMapping',
            'notificationTemplateId',
            'prevColIdMapping',
          ]),
          triggerData: [
            {
              ...(!isEmpty(updatedAutoRow?.notificationTemplateId)
                ? {
                    notificationTemplateId:
                      updatedAutoRow.notificationTemplateId,
                  }
                : {}),
              ...(!isEmpty(updatedAutoRow?.prevStatus)
                ? {status: updatedAutoRow?.prevStatus}
                : {}),
              ...(!isEmpty(updatedAutoRow?.prevColIdMapping)
                ? {
                    prevColIdMapping: updatedAutoRow.prevColIdMapping,
                    colIdMapping: formatColIdMappingForRedux(
                      updatedAutoRow.prevColIdMapping,
                    ),
                  }
                : {}),
              ...(!isEmpty(updatedAutoRow?.colIdMapping)
                ? {colIdMapping: updatedAutoRow.colIdMapping}
                : {}),
            },
          ],
        };
        dispatch({
          type: AUTOMATION_ACTION.UPDATE_AUTOMATION_OBJ,
          payload: updatedAutoRow,
        });
        updatedAutoRow = undefined;
      }
    } catch (error) {
      captureError(error);
    }
  };

const getCurrentAutomationObj = (currentAutomation) => {
  let currentAutomationKeysWithOmit = {};
  try {
    const currentAutomationObj = !isEmpty(currentAutomation)
      ? currentAutomation
      : {};
    currentAutomationKeysWithOmit = omit(
      currentAutomationObj,
      getAutomationOmitKeysV1(),
    );
  } catch (error) {
    captureError(error);
  }
  return currentAutomationKeysWithOmit;
};

const getAutomationOmitKeysV1 = () => {
  return [
    MESSAGE_AUTOMATION.CELL_STATUS_CHANGE.toLowerCase(),
    MESSAGE_AUTOMATION.ROW_NEW_ENTRY.toLowerCase(),
  ];
};

const getCurrentAutomationColIdMapping =
  (automationId) => (dispatch, getState) => {
    let colIdMapping = undefined;
    try {
      const {
        automation: {automationConfig},
      } = getState();
      const automationData = automationConfig?.[automationId];
      if (automationData) {
        if (automationData.type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE) {
          if (!isEmpty(automationData?.columns)) {
            const colId = Object.keys(automationData.columns)?.[0];
            if (!isEmpty(automationData.columns?.[colId]?.triggers)) {
              const triggerStatus = Object.keys(
                automationData?.columns[colId].triggers,
              )[0];
              colIdMapping =
                automationData?.columns[colId].triggers[triggerStatus]
                  ?.colIdMapping;
            }
          }
        }
        if (NON_VALUE_AUTOMATION.includes(automationData?.type)) {
          colIdMapping = automationData?.colIdMapping;
        }
      }
    } catch (error) {
      captureError(error);
    }
    return colIdMapping;
  };

const getUpdatedAutomationColIdMapping = () => (dispatch, getState) => {
  let colIdMapping = undefined;
  try {
    const {
      automation: {newAuto, currentAutomation},
    } = getState();

    if (!isEmpty(newAuto) && !isEmpty(currentAutomation)) {
      if (currentAutomation.type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE) {
        if (!isEmpty(newAuto?.triggerData)) {
          colIdMapping = newAuto?.triggerData?.[0]?.colIdMapping;
        }
      }
      if (NON_VALUE_AUTOMATION.includes(currentAutomation.type)) {
        colIdMapping = newAuto?.colIdMapping;
      }
    }
  } catch (error) {
    captureError(error);
  }
  return colIdMapping;
};

const getUpdatedAutomationNotificationId = () => (dispatch, getState) => {
  let returnId = undefined;
  try {
    const {
      automation: {newAuto, currentAutomation},
    } = getState();
    returnId =
      !isEmpty(newAuto) && !isEmpty(currentAutomation)
        ? currentAutomation.type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE &&
          !isEmpty(newAuto?.triggerData)
          ? newAuto.triggerData[0]?.notificationTemplateId
          : NON_VALUE_AUTOMATION.includes(currentAutomation.type)
          ? newAuto?.notificationTemplateId
          : null
        : null;
  } catch (error) {
    captureError(error);
  }
  return returnId;
};

const setCurrentColumn = (obj) => (dispatch) => {
  try {
    dispatch({
      type: AUTOMATION_ACTION.SET_CURRENT_COL,
      payload: obj,
    });
  } catch (error) {
    captureError(error);
  }
};

const setEditTriggerCol = (obj) => (dispatch) => {
  try {
    dispatch({
      type: AUTOMATION_ACTION.SET_EDIT_TRIGGER_COL,
      payload: obj,
    });
  } catch (error) {
    captureError(error);
  }
};

const loadPendingAutomations = (docId) => async (dispatch, getState) => {
  try {
    const idsToDelete = [];
    const snapShot = await getPendingAutomationDocument(docId);
    const scheduledAutomationObj = {};
    const pendingAutomationsObj = snapShot.exists ? snapShot.data() : {};

    if ('timestamp' in pendingAutomationsObj) {
      const scheduledAutoArray = await loadScheduledAutomations({
        docId,
      });

      scheduledAutoArray.forEach((timestamp) => {
        if (isEmpty(timestamp.data())) {
          idsToDelete.push(timestamp.id);
        }
        scheduledAutomationObj[timestamp.id] = {
          ...timestamp.data(),
        };
      });
    }
    if (idsToDelete.length > 0) {
      deleteScheduledTimestamps(docId, idsToDelete);
    }
    dispatch({
      type: AUTOMATION_ACTION.SET_PENDING_AUTOMATIONS,
      payload: {pendingAutomationsObj, scheduledAutomationObj},
    });
  } catch (error) {
    captureError(error);
  }
};

const resetAutomationState = () => (dispatch) => {
  try {
    dispatch({type: AUTOMATION_ACTION.RESET_AUTOMATION_STATE});
  } catch (error) {
    captureError(error);
  }
};

const resetCurrentAutomationStateWeb = () => (dispatch) => {
  try {
    dispatch({type: AUTOMATION_ACTION.RESET_CURRENT_AUTOMATION_STATE_WEB});
  } catch (error) {
    captureError(error);
  }
};

const resetCurrentAutomationRelatedState = () => (dispatch) => {
  try {
    dispatch({type: AUTOMATION_ACTION.RESET_CURRENT_AUTOMATION_RELATED_STATE});
  } catch (error) {
    captureError(error);
  }
};

const resetAutomationStateMiniAppEdit = () => (dispatch) =>
  dispatch({type: AUTOMATION_ACTION.RESET_AUTOMATION_STATE_MINI_APPS_EDIT});

const setCurrentAutomation = (obj) => (dispatch) => {
  try {
    dispatch({
      type: AUTOMATION_ACTION.SET_CURRENT_AUTOMATION,
      payload: obj,
    });
  } catch (error) {
    captureError(error);
  }
};

const loadUserAutomationWhatsappTemplates =
  () => async (dispatch, getState) => {
    try {
      const {
        auth: {userPref},
      } = getState();

      const businessProfileId = userPref?.businessProfileId;

      if (!businessProfileId?.length) {
        return [];
      }

      const templates = await getAutomationWhatsappTemplates(businessProfileId);

      dispatch({
        type: AUTOMATION_ACTION.LOAD_AUTOMATION_TEMPLATES,
        payload: {templates, type: AUTOMATION_TEMPLATES_TYPE.WHATSAPP},
      });
      return templates;
    } catch (error) {
      captureError(error);
      return [];
    }
  };

const loadAllAutomationTemplates =
  (userBizCategory = '', extra = {}) =>
  async (dispatch, getState) => {
    try {
      const {
        automation: {
          automationConfig,
          automationTemplatesType,
          automationTemplates: reduxTemplates,
        },
      } = getState();

      const {fromMiniApps} = extra ?? {};
      const {activeAppId, interaktId} = mapMiniAppStates(getState());
      const isIntegration = fromMiniApps && interaktId && activeAppId;

      if (interaktId && extra.force) {
        const integrationTemplates = await getIntegrationsTemplates(interaktId);

        dispatch({
          type: AUTOMATION_ACTION.LOAD_AUTOMATION_TEMPLATES,
          payload: {
            templates: integrationTemplates,
            type: AUTOMATION_TEMPLATES_TYPE.INTEGRATIONS,
          },
        });

        return integrationTemplates;
      }

      if (
        isIntegration &&
        automationTemplatesType === AUTOMATION_TEMPLATES_TYPE.INTEGRATIONS
      ) {
        return reduxTemplates;
      }

      if (isIntegration) {
        const integrationTemplates = await getIntegrationsTemplates(interaktId);

        dispatch({
          type: AUTOMATION_ACTION.LOAD_AUTOMATION_TEMPLATES,
          payload: {
            templates: integrationTemplates,
            type: AUTOMATION_TEMPLATES_TYPE.INTEGRATIONS,
          },
        });

        return integrationTemplates;
      }

      const automationTemplates = await getAutomationTemplates(
        userBizCategory,
        automationConfig,
      );

      dispatch({
        type: AUTOMATION_ACTION.LOAD_AUTOMATION_TEMPLATES,
        payload: {
          templates: automationTemplates,
          type: AUTOMATION_TEMPLATES_TYPE.ALL,
        },
      });
      return automationTemplates;
    } catch (error) {
      captureError(error);
      return [];
    }
  };

const clearAllAutomationTemplates = () => async (dispatch) => {
  try {
    dispatch({
      type: AUTOMATION_ACTION.CLEAR_AUTOMATION_TEMPLATES,
    });
  } catch (error) {
    captureError(error);
  }
};

const updatePhoneColId =
  ({phoneColId}) =>
  (dispatch) => {
    try {
      dispatch({
        type: AUTOMATION_ACTION.UPDATE_PHONE_COL,
        payload: {
          phoneColId,
        },
      });
    } catch (error) {
      captureError(error);
    }
  };

const getAutomationPermissions = () => (dispatch, getState) => {
  const obj = {
    canEditAutomations: false,
    canRunAutomations: false,
  };
  try {
    const activeDocumentMeta = automationUtils.getAutomationDocumentMeta(
      getState(),
    );
    const isShared = !isEmpty(activeDocumentMeta?.collab);
    const collab = activeDocumentMeta?.collab;
    const permission = collab?.permission;

    obj.canEditAutomations =
      SHARE_PERMISSION_TYPE.ADMIN === permission ||
      collab?.isOwner ||
      !isShared;

    obj.canRunAutomations =
      [
        SHARE_PERMISSION_TYPE.ENTRY_ONLY,
        SHARE_PERMISSION_TYPE.CUSTOM,
        SHARE_PERMISSION_TYPE.CAN_EDIT,
        SHARE_PERMISSION_TYPE.ADMIN,
      ].includes(permission) ||
      collab?.isOwner ||
      !isShared;
  } catch (error) {
    captureError(error);
  }
  return obj;
};

const setAutomationVisible = (isVisible) => (dispatch) => {
  try {
    dispatch({
      type: AUTOMATION_ACTION.SET_SHOW_AUTOMATION,
      payload: isVisible,
    });
  } catch (error) {
    captureError(error);
  }
};

const deleteSingleAutomation =
  (extra = {}) =>
  (dispatch, getState) => {
    // check and update isAutomationEnabled
    try {
      const {
        automation: {
          automationConfig,
          currentAutomation,
          pendingAutomations,
          dependentColObj,
          automationDocumentId: originalDocumentId,
          automationActiveDocumentId: activeDocumentId,
        },
      } = getState();
      let updatedAutomationConfig = {...automationConfig};
      let updatedDependentColObj = {...dependentColObj};
      let updatedDependentCols = [];
      const ids = currentAutomation?.automationId
        ? [currentAutomation.automationId]
        : [];
      updatedAutomationConfig = omit(updatedAutomationConfig, ids);
      updatedDependentColObj = omit(updatedDependentColObj, ids);
      const updatedPendingAutomations = {};
      Object.keys(pendingAutomations).forEach((rowId) => {
        updatedPendingAutomations[
          isEmpty(
            omit(pendingAutomations[rowId], [currentAutomation.automationId]),
          )
            ? `${rowId}`
            : `${rowId}.${currentAutomation.automationId}`
        ] = firestore(true).FieldValue.delete();
      });
      deleteAutomation({docId: originalDocumentId, ids}, extra);
      extraActionsHandler([
        {
          updateObjType: 'object',
          elementType: 'object',
          element: updatedPendingAutomations,
          action: 'REMOVE',
          collection: 'pendingAutomations',
          path: `${activeDocumentId}`,
          updateAtPath: true,
          removeParentObjIfEmpty: true,
          description: 'TO REMOVE ALL PENDING OPTIONS',
        },
      ]);
      // deleteSelectedIdPendingAutomationsHelper(
      //   activeDocumentId,
      //   updatedPendingAutomations,
      // );
      if (isEmpty(updatedAutomationConfig)) {
        dispatch(
          updateActiveDocMeta({
            isAutomationEnabled: false,
          }),
        );
      } else {
        const headerData = automationUtils.getAutomationTableState(
          getState(),
        ).automationHeaderData;
        let dependentColsForCurrentAutomation = [];
        for (const automationId of Object.keys(updatedAutomationConfig)) {
          dependentColsForCurrentAutomation = automationDependentCols(
            updatedAutomationConfig[automationId],
            headerData,
          )?.colIds;
          updatedDependentCols = union(
            updatedDependentCols,
            dependentColsForCurrentAutomation,
          );
        }
      }
      dispatch({
        type: AUTOMATION_ACTION.SET_DOC_AUTOMATION_CONFIG_DATA,
        payload: {
          automationConfig: cloneDeep(updatedAutomationConfig),
          dependentCols: updatedDependentCols,
          dependentColObj: updatedDependentColObj,
        },
      });
    } catch (error) {
      captureError(error);
    }
  };

const removePendingAutomations =
  (automationId = null) =>
  (dispatch, getState) => {
    try {
      const {
        automation: {
          pendingAutomations,
          automationActiveDocumentId: activeDocumentId,
        },
      } = getState();

      if (!automationId) return;

      const updatedPendingAutomations = {};

      Object.keys(pendingAutomations ?? {}).forEach((rowId) => {
        const removedObj = omit(pendingAutomations[rowId], [automationId]);
        updatedPendingAutomations[rowId] = isEmpty(removedObj)
          ? firestore(true).FieldValue.delete()
          : removedObj;
      });

      !isEmpty(updatedPendingAutomations) &&
        updatePendingAutomationDocument(
          activeDocumentId,
          updatedPendingAutomations,
        );
    } catch (error) {
      captureError(error);
    }
  };

const deleteAutomationForDependentCol =
  (colId, extra = {}) =>
  (dispatch, getState) => {
    try {
      const {
        automation: {
          dependentColObj,
          automationConfig,
          pendingAutomations,
          automationActiveDocumentId: activeDocumentId,
        },
      } = getState();

      const {fromMiniApps} = extra ?? {};

      let updatedAutomationConfig = {...automationConfig};
      const removedAutomationIds = [];
      const updatedPendingAutomations = {};

      Object.keys(dependentColObj).forEach((id) => {
        if (dependentColObj[id].includes(`${colId}`)) {
          removedAutomationIds.push(id);
          updatedAutomationConfig = omit(updatedAutomationConfig, [id]);
        }
      });

      Object.keys(pendingAutomations ?? {}).forEach((rowId) => {
        const removedObj = omit(
          pendingAutomations[rowId],
          removedAutomationIds,
        );
        updatedPendingAutomations[rowId] = isEmpty(removedObj)
          ? firestore(true).FieldValue.delete()
          : removedObj;
      });

      /** Delete automations which were dependent on colId column. */
      if (!fromMiniApps) {
        deleteAutomation({docId: activeDocumentId, ids: removedAutomationIds});
      }

      let dependentCols = [];
      const updatedDependentColObj = {};
      const headerData = automationUtils.getAutomationTableState(
        getState(),
      ).automationHeaderData;
      Object.keys(updatedAutomationConfig).forEach((automationId) => {
        dependentCols = union(
          dependentCols,
          automationDependentCols(
            updatedAutomationConfig[automationId],
            headerData,
          ).colIds,
        );

        updatedDependentColObj[automationId] = automationDependentCols(
          updatedAutomationConfig[automationId],
          headerData,
        ).colIds;
      });

      //shared
      if (isEmpty(updatedAutomationConfig) && !fromMiniApps) {
        dispatch(updateActiveDocMeta({isAutomationEnabled: false}));
      }

      /** Remove pending automation ids after deleting the automations */
      if (!isEmpty(updatedPendingAutomations) && !fromMiniApps) {
        updatePendingAutomationDocument(
          activeDocumentId,
          updatedPendingAutomations,
        );
      }

      dispatch({
        type: AUTOMATION_ACTION.SET_DOC_AUTOMATION_CONFIG_DATA,
        payload: {
          automationConfig: cloneDeep(updatedAutomationConfig),
          dependentCols,
          dependentColObj: cloneDeep(updatedDependentColObj),
        },
      });

      return removedAutomationIds;
    } catch (error) {
      captureError(error);
    }
  };

const updateConstValues = (obj) => (dispatch) => {
  dispatch({
    type: AUTOMATION_ACTION.UPDATE_CUSTOM_KEY_VALUES,
    payload: obj,
  });
};

const miniAppsDeleteAutomation =
  (automationId, docId) => (dispatch, getState) => {
    const {
      automation: {miniAppAutomationConfig, miniAppIntegrationEmails},
    } = getState();
    let updatedConfigs = Object.assign({}, miniAppAutomationConfig);
    const config = updatedConfigs?.[docId]?.[automationId];

    // Remove automation from doc
    deleteAutomation({docId, ids: [automationId]});

    if (config?.messageConfig?.customAttachments?.length) {
      const promises = [];
      config.messageConfig.customAttachments.forEach(({docRef}) => {
        promises.push(() => storage().ref(docRef).delete());
      });

      Promise.all(promises.map((p) => p())).catch((error) =>
        captureError(error),
      );
    }

    // Remove automation from integration data
    miniAppsActionHelper
      .deleteAutomationIdFromIntegrationData(
        config?.emailIntegration?.integrationId,
        automationId,
        docId,
      )
      .then(() => {
        dispatch({
          type: AUTOMATION_ACTION.MINI_APP_SET_EMAILS_FOR_INTEGRATION,
          payload: (miniAppIntegrationEmails || []).map((item) => {
            if (item.id === config?.emailIntegration?.integrationId) {
              const noOfActions = (item?.noOfActions || 0) - 1;
              return {
                ...item,
                noOfActions: noOfActions >= 0 ? noOfActions : 0,
              };
            }
            return item;
          }),
        });
      })
      .catch((error) => captureError(error));

    if (updatedConfigs?.[docId]?.[automationId]) {
      // Remove automation from mini apps config
      updatedConfigs[docId] = omit(updatedConfigs[docId], [automationId]);

      // Remove docId from mini apps config if no automation is left
      if (isEmpty(updatedConfigs[docId])) {
        updatedConfigs = omit(updatedConfigs, [docId]);
      }
    }

    dispatch({
      type: AUTOMATION_ACTION.SET_MINI_APP_AUTOMATION_CONFIG_DATA,
      payload: updatedConfigs,
    });
  };
const miniAppsToggleAutomation =
  (automationId, docId, isEnabled) => (dispatch, getState) => {
    try {
      const {
        automation: {miniAppAutomationConfig},
      } = getState();
      const updatedConfigs = Object.assign({}, miniAppAutomationConfig);
      const docRef = FirestoreDB.automations.config(docId);
      if (updatedConfigs?.[docId]?.[automationId]) {
        updatedConfigs[docId][automationId].isEnabled = isEnabled;
      }

      docRef.update({[`${automationId}.isEnabled`]: isEnabled});

      dispatch({
        type: AUTOMATION_ACTION.SET_MINI_APP_AUTOMATION_CONFIG_DATA,
        payload: updatedConfigs,
      });
    } catch (error) {
      captureError(error);
    }
  };

const enableDisableAllAutomations =
  (isEnabled = false) =>
  (dispatch, getState) => {
    const batch = firestore().batch();
    try {
      const {
        automation: {
          automationConfig,
          automationActiveDocumentId: activeDocumentId,
        },
      } = getState();
      const updatedAutoConfig = {};
      for (const [key, value] of Object.entries(automationConfig)) {
        logAnalyticsEvent('PAUSE_AUTOMATION_INIT', {
          docId: activeDocumentId,
          automationId: key,
          source: 'PREMUIM EXPIRED',
        });
        updatedAutoConfig[key] = {...value, isEnabled};
        const docRef = FirestoreDB.automations.config(activeDocumentId);
        batch.update(docRef, {[`${key}.isEnabled`]: isEnabled});
        logAnalyticsEvent('PAUSE_AUTOMATION_SUCCESS', {
          docId: activeDocumentId,
          automationId: key,
          source: 'PREMUIM EXPIRED',
        });
      }

      batch.commit();
      dispatch({
        type: AUTOMATION_ACTION.UPDATE_DOC_AUTOMATION_CONFIG,
        payload: updatedAutoConfig,
      });
    } catch (error) {
      captureError(error);
    }
  };

const loadGlobalAutomations = () => async (dispatch, getState) => {
  try {
    const {
      auth: {
        user: {uid},
      },
      home: {files},
    } = getState();
    const automationData = await fetchAllGlobalAutomations({uid});
    if (automationData) {
      const docArray = files.filter((item) =>
        automationData.Documents.includes(item.documentId),
      );
      dispatch({
        type: AUTOMATION_ACTION.LOAD_GLOBAL_AUTOMATIONS,
        payload: {
          docArray,
          timestamp: automationData.timestamp,
          allGlobalAutomationFileData:
            automationData.allGlobalAutomationFileData,
        },
      });
    }
  } catch (error) {
    captureError(error);
  }
};

const setScheduledAutomation = (docId, obj) => (dispatch, getState) => {
  const {
    automation: {
      scheduledAutomation,
      pendingAutomations,
      automationDocumentId: originalDocumentId,
    },
  } = getState();
  const activeDocumentMeta = automationUtils.getAutomationDocumentMeta(
    getState(),
  );
  const undoActions = [];
  const redoActions = [];
  try {
    const updatedObj = {};
    for (const timestamp of Object.keys(obj)) {
      setDateSpecificAutomation({
        docId,
        timestamp,
        pendingAutomation: obj[timestamp],
      });

      const removeObj = {};

      keys(obj[timestamp]).forEach((rowId) => {
        keys(obj[timestamp][rowId]).forEach((automationId) => {
          removeObj[`${rowId}.${automationId}`] =
            firestore(true).FieldValue.delete();
        });
      });

      updatedObj[timestamp] = {
        ...scheduledAutomation[timestamp],
        ...obj[timestamp],
      };
      const isSet = 'timestamp' in pendingAutomations ? false : true;
      const metaObj = activeDocumentMeta?.pageObj?.enabled
        ? {meta: {originalDocumentId}}
        : {};
      addDateSpecificAutomationTimestamp({
        docId,
        timestamp,
        isSet,
        isMetaObj: activeDocumentMeta?.pageObj?.enabled ? true : false,
        metaObj,
      });

      // REMOVE ON UNDO
      undoActions.push({
        updateObjType: 'object',
        elementType: 'object',
        element: removeObj,
        action: 'REMOVE',
        collection: 'pendingAutomations',
        path: `${docId}/scheduled/${timestamp}`,
        updateAtPath: true,
        removeParentObjIfEmpty: true,
        // runAfterParentObjRemove: [
        //   {
        //     updateObjType: 'array',
        //     elementType: 'string',
        //     element: timestamp,
        //     action: 'REMOVE',
        //     collection: 'pendingAutomations',
        //     path: `${docId}`,
        //     updatePath: 'timestamp',
        //   },
        // ],
      });

      // ADD ON REDO
      redoActions.push({
        updateObjType: 'object',
        elementType: 'object',
        element: obj[timestamp],
        action: 'ADD',
        collection: 'pendingAutomations',
        path: `${docId}/scheduled/${timestamp}`,
        updateAtPath: true,
        addIdPathIfNotExist: true,
        // runAfterParentObjAdd: [
        //   {
        //     updateObjType: 'array',
        //     elementType: 'string',
        //     element: timestamp,
        //     action: 'ADD',
        //     collection: 'pendingAutomations',
        //     path: `${docId}`,
        //     updatePath: 'timestamp',
        //     addIdPathIfNotExist: true,
        //   },
        // ],
        // Redux
        reduxCheckPath: 'automation.automationConfig',
        reduxElementType: 'object',
        reduxCheckValue: {
          type: 'object',
          value: obj[timestamp] ?? {},
        },
        reduxOperation: 'REMOVE_NESTED_OBJ',
      });
    }

    dispatch({
      type: AUTOMATION_ACTION.SET_SCHEDULED_PENDING_AUTOMATION,
      payload: Object.assign({}, scheduledAutomation, updatedObj),
    });
  } catch (err) {
    captureError(err);
  }
  return {
    undoActions,
    redoActions,
  };
};

const deleteScheduledAutomation = (docId, obj, objWithValues) => () => {
  const undoActions = [];
  const redoActions = [];
  try {
    for (const timestamp of Object.keys(obj)) {
      deleteDateSpecificAutomation({
        docId,
        timestamp,
        updateObj: obj[timestamp],
      });

      const removeObj = obj[timestamp];

      // REMOVE ON REDO
      redoActions.push({
        updateObjType: 'object',
        elementType: 'object',
        element: removeObj,
        action: 'REMOVE',
        collection: 'pendingAutomations',
        path: `${docId}/scheduled/${timestamp}`,
        updateAtPath: true,
        removeParentObjIfEmpty: true,
      });

      // ADD ON UNDO
      undoActions.push({
        updateObjType: 'object',
        elementType: 'object',
        element: objWithValues[timestamp],
        action: 'ADD',
        collection: 'pendingAutomations',
        path: `${docId}/scheduled/${timestamp}`,
        updateAtPath: true,
        addIdPathIfNotExist: true,

        //Redux
        reduxCheckPath: 'automation.automationConfig',
        reduxElementType: 'object',
        reduxCheckValue: {
          type: 'object',
          value: obj[timestamp] ?? {},
        },
        reduxOperation: 'REMOVE_NESTED_OBJ',
      });
    }
  } catch (err) {
    captureError(err);
  }

  return {undoActions, redoActions};
};

const shouldAddToUserDefined = (isEdit) => (dispatch, getState) => {
  const {
    auth: {userAutomationConfig},
    automation: {newAuto},
  } = getState();
  const activeDocumentMeta = automationUtils.getAutomationDocumentMeta(
    getState(),
  );
  const {isOwner} = activeDocumentMeta?.collab ?? {};
  const useUserWhatsapp = userAutomationConfig?.useUserWhatsApp;

  return !isEmpty(activeDocumentMeta?.collab) && newAuto.userDefined
    ? (isEdit // Only Allow Custom Template if its edit.
        ? dispatch(getAutomationPermissions())?.canEditAutomations
        : false) ||
        (isOwner && useUserWhatsapp)
    : useUserWhatsapp;
};

const shouldAddToUseUserWhatsappAPI = () => (dispatch, getState) => {
  const {
    auth: {userAutomationConfig},
  } = getState();

  const {useUserWhatsappAPI = false} = userAutomationConfig ?? {};
  return useUserWhatsappAPI;
};

const automationEditNewEntry =
  (currentAutomationObj) => (dispatch, getState) => {
    try {
      const {
        automation: {currentAutomation, automationConfig, newAuto},
      } = getState();

      const editAutomationId = currentAutomation?.automationId;
      const config = automationConfig?.[editAutomationId] ?? {};
      const phoneColId = newAuto?.phoneColId ?? config?.phoneColId;

      const shouldAddToUserDefinedBool = dispatch(shouldAddToUserDefined(true));
      const shouldAddToUserWhatsappAPI = config?.useUserWhatsappAPI;
      const shouldAddPhoneCol =
        currentAutomationObj?.type !== MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE ||
        config?.operationType === AUTOMATION_OPERATION_TYPES.SEND_NOTIFICATION;
      dispatch(
        updateNewAutomation({
          notificationTemplateId: currentAutomationObj?.notificationTemplateId,
          prevNotificationTemplateId:
            currentAutomationObj?.notificationTemplateId,
          ...(shouldAddPhoneCol ? {phoneColId: phoneColId} : {}),
          colIdMapping: formatColIdMappingForRedux(config?.colIdMapping),

          createdByUID: config?.createdByUID,
          businessProfileId: config?.businessProfileId,
          userType: config?.userType,
          usersList: config?.usersList,
          userPermissions: config?.userPermissions,
          userCustomPermissions: config?.userCustomPermissions,
          prevColIdMapping: config?.colIdMapping,

          userDefined: shouldAddToUserDefinedBool
            ? config?.userDefined ?? undefined
            : undefined,
          useUserWhatsappAPI: shouldAddToUserWhatsappAPI ? true : undefined,
          headerMapping: config?.headerMapping,
          defaultValMap: config?.defaultValMap,
          idPasteTo: config?.idPasteTo,
          constTemplateValues: config?.constTemplateValues,
          triggerCol: config?.triggerCol ?? {},
        }),
      );
    } catch (error) {
      captureError(error);
    }
  };

const automationEditTrigger =
  (currentAutomationObj) => (dispatch, getState) => {
    try {
      const {
        automation: {currentAutomation, automationConfig, newAuto},
      } = getState();
      const headerData = automationUtils.getAutomationTableState(
        getState(),
      ).automationHeaderData;
      const headerTypeMapping = getHeaderTypeMapping(headerData);
      const editAutomationId = currentAutomation?.automationId;

      const config = automationConfig?.[editAutomationId] ?? {};
      const phoneColId =
        currentAutomationObj?.type === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE
          ? {}
          : newAuto?.phoneColId ?? currentAutomationObj?.phoneColId;

      const colId = findKey(currentAutomationObj.columns);

      /**
       * Set current column data.
       */
      dispatch(
        setCurrentColumn({
          ...headerTypeMapping?.[colId],
        }),
      );

      const colTriggers = currentAutomationObj?.columns?.[colId]?.triggers;

      const triggerData = !isEmpty(colTriggers)
        ? keys(colTriggers).map((item) => {
            return {
              status: item,
              ...omit(colTriggers[item], ['colIdMapping']),
              colIdMapping: formatColIdMappingForRedux(
                colTriggers?.[item]?.colIdMapping,
              ),
              prevColIdMapping: colTriggers[item].colIdMapping,
              prevNotificationTemplateId:
                colTriggers[item].notificationTemplateId,
            };
          })
        : [];

      /**
       * Set Trigger Col for edit
       */
      dispatch(
        setEditTriggerCol({
          id: colId,
          ...currentAutomationObj?.columns?.[colId],
        }),
      );

      const shouldAddToUserDefinedBool = dispatch(shouldAddToUserDefined(true));
      const shouldAddToUserWhatsappAPI = config?.useUserWhatsappAPI;
      /**
       * Set Redux Automation Data
       */
      dispatch(
        updateNewAutomation({
          triggerData: triggerData,
          phoneColId: phoneColId,
          userType: config?.userType,
          usersList: config?.usersList,
          userPermissions: config?.userPermissions,
          userCustomPermissions: config?.userCustomPermissions,
          headerMapping: config?.headerMapping,
          defaultValMap: config?.defaultValMap,
          idPasteTo: config?.idPasteTo,
          constTemplateValues: config?.constTemplateValues,
          triggerCol: config?.triggerCol ?? {},
          createdByUID: config?.createdByUID,
          businessProfileId: config?.businessProfileId,
          userDefined: shouldAddToUserDefinedBool
            ? config?.userDefined ?? undefined
            : undefined,
          useUserWhatsappAPI: shouldAddToUserWhatsappAPI ? true : undefined,
        }),
      );
    } catch (error) {
      captureError(error);
    }
  };

const addDocMetaToAutomationRedux = () => async (dispatch, getState) => {
  const {
    auth: {user},
    automation: {automationDocumentId, automationDocumentMeta},
  } = getState();

  if (!user.uid) {
    return;
  }

  const hasDifferentDocId =
    automationDocumentId &&
    automationDocumentMeta?.originalDocumentId &&
    automationDocumentId !== automationDocumentMeta.originalDocumentId;

  if (isEmpty(automationDocumentMeta) || hasDifferentDocId) {
    const documentMeta =
      await homeActionHelper.checkIfUserHasDocAccessAndFetchMeta(
        automationDocumentId,
        user.uid,
      );

    dispatch({
      type: AUTOMATION_ACTION.SET_DOCUMENT_META_FOR_AUTOMATION,
      payload: {
        ...documentMeta,
        originalDocumentId: automationDocumentId,
      },
    });
  }

  return;
};

const onAutomationEditLoad = () => (dispatch, getState) => {
  let returnAutomation = {};
  let docMetaObj = {};
  try {
    const {
      automation: {currentAutomation, automationConfig},
      home: {files},
    } = getState();

    const automationId = currentAutomation?.automationId;

    if (automationId && automationId in automationConfig) {
      returnAutomation = automationConfig[automationId];
      if (
        AUTOMATION_DOC_OPERATIONS.includes(returnAutomation?.operationType) &&
        returnAutomation?.idPasteTo
      ) {
        const filesArr = files.slice();
        docMetaObj = filesArr.find(
          (item) => item.documentId === returnAutomation.idPasteTo,
        );

        if (docMetaObj) {
          DocumentsMethods.getUserDocumentDataWithoutRows(
            docMetaObj?.documentId,
          ).then((data) => {
            const objToSet = {
              meta: {
                data: {
                  documentId: `${docMetaObj?.documentId}`,
                  documentMeta: Object.assign({}, docMetaObj?.documentMeta),
                },
              },
              fileData: data,
            };
            dispatch(addCopyFileDetails(objToSet));
          });
        }
      }

      if (returnAutomation?.type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE) {
        /**
         * Set Edit - Automation Data in Redux
         * For - CELL_STATUS_CHANGE
         */
        dispatch(automationEditTrigger(returnAutomation));
      }

      /**
       * Set Edit - Automation Data in Redux
       * For - ROW_NEW_ENTRY / ON_SPECIFIC_DATE
       */
      if (
        [
          MESSAGE_AUTOMATION.ROW_NEW_ENTRY,
          MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE,
          MESSAGE_AUTOMATION.ON_SPECIFIC_DATE,
          MESSAGE_AUTOMATION.ROW_DELETE_ENTRY,
          MESSAGE_AUTOMATION.ROW_EDIT_ENTRY,
          MESSAGE_AUTOMATION.ON_NEW_COMMENT,
        ].includes(returnAutomation?.type)
      ) {
        dispatch(automationEditNewEntry(returnAutomation));
      }
    }
    return {automationData: returnAutomation, automationId, docMetaObj};
  } catch (error) {
    captureError(error);
    return {
      automationData: returnAutomation,
      automationId: undefined,
      docMetaObj: {},
    };
  }
};

const onAutomationEntryTypeSelected =
  (currentAutomationObj) => (dispatch, getState) => {
    try {
      if (currentAutomationObj) {
        const {
          automation: {automationConfig, currentAutomation, newAuto},
        } = getState();

        const automationData =
          automationConfig?.[
            currentAutomationObj?.automationId ??
              currentAutomation?.automationId
          ];
        // auto select phone col
        const phoneColId = automationUtils.getCurrentPhoneColId(
          currentAutomation,
          automationConfig,
          newAuto,
        );
        const headerData = automationUtils.getAutomationTableState(
          getState(),
        ).automationHeaderData;

        dispatch(
          updateNewAutomation({
            notificationTemplateId: automationData?.notificationTemplateId,
            colIdMapping: formatColIdMappingForRedux(
              automationData?.colIdMapping,
            ),
            prevColIdMapping: automationData?.colIdMapping,
            triggerCol:
              currentAutomation.type === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE
                ? {}
                : automationData?.triggerCol ?? {},
            phoneColId:
              currentAutomation.type === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE
                ? {}
                : !isEmpty(phoneColId)
                ? phoneColId
                : headerData.filter(
                    (item) =>
                      getOriginalFieldType(item) === FIELD_TYPE_ID.CONTACT,
                  )?.[0] ?? {},
          }),
        );
      }
    } catch (error) {
      captureError(error);
    }
  };

/**
 * Get Status Values List of CELL_STATUS_CHANGE type Automation
 */
const getTriggerStatusList =
  ({selectedId}) =>
  (dispatch, getState) => {
    const {
      automation: {currentCol, newAuto},
    } = getState();
    const headerData = automationUtils.getAutomationTableState(
      getState(),
    ).automationHeaderData;
    const triggerData = newAuto?.triggerData ?? [];
    const colId = currentCol?.id;
    const selectId = (headerData ?? []).find((item) => item.id == colId);
    const filterIds = triggerData
      .filter((item) => (item?.status ? item.status != selectedId : false))
      .map((item) => item.status);

    return selectId.selectElements
      .filter((item) => {
        return !filterIds.includes(item.val);
      })
      .map((item) => {
        return {
          ...item,
          id: item.val,
        };
      });
  };

const onAutomationTriggerTypeSelected =
  (currentAutomation) => (dispatch, getState) => {
    try {
      const headerData = automationUtils.getAutomationTableState(
        getState(),
      ).automationHeaderData;
      if (currentAutomation) {
        const item = {
          colId: Object.keys(currentAutomation.columns)[0],
        };
        const colId = item.colId;
        const headerTypeMapping = getHeaderTypeMapping(headerData);

        dispatch(
          setCurrentColumn({
            ...headerTypeMapping[item.colId],
          }),
        );

        const colTriggers = currentAutomation?.columns?.[colId]?.triggers;

        const triggerData = !isEmpty(colTriggers)
          ? Object.keys(colTriggers).map((item) => {
              return {
                status: item,
                ...omit(colTriggers[item], ['colIdMapping']),
                colIdMapping: formatColIdMappingForRedux(
                  colTriggers[item].colIdMapping,
                ),
                prevColIdMapping: colTriggers[item].colIdMapping,
                prevNotificationTemplateId:
                  colTriggers[item].notificationTemplateId,
              };
            })
          : [];
        dispatch(
          setEditTriggerCol({
            id: item.colId,
            ...currentAutomation[colId],
          }),
        );
        dispatch(
          updateNewAutomation({
            triggerData: triggerData,
          }),
        );
      }
    } catch (error) {
      captureError(error);
    }
  };

const onAutomationV2TypeSelected =
  (operationType = null, type = null) =>
  (dispatch, getState) => {
    const {
      automation: {
        automationConfig,
        currentAutomation,
        automationDocumentId: originalDocumentId,
        newAuto,
      },
    } = getState();

    const selectedAutomationType = type;
    const selectedAutomationTitle_EN =
      MINI_APPS.AUTOMATION.TRIGGER_TYPE_PROPERTIES?.[type]?.text;
    //prettier-ignore
    const isAssigneeChange = selectedAutomationType === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE;

    const getAssignTaskCol = () =>
      automationUtils
        .getAutomationTableState(getState())
        .automationHeaderData.find(
          (item) => getOriginalFieldType(item) === FIELD_TYPE_ID.ASSIGN_TASK,
        );
    logAnalyticsEvent('AUTOMATION_ADD_TRIGGER', {
      docId: originalDocumentId,
      triggerName: selectedAutomationTitle_EN,
    });

    const currentAutomationId = currentAutomation?.automationId;

    const isEdit = !isEmpty(automationConfig?.[currentAutomationId] ?? {});
    const automationData = automationConfig?.[currentAutomationId];
    //prettier-ignore
    const isPrevCellStatus = automationData?.type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE;

    const isEditAndHasAutomationData = isEdit && automationData;

    if (isEditAndHasAutomationData) {
      if (selectedAutomationType !== automationData?.type) {
        dispatch(
          manageAutomationTypeChange(
            automationData?.type,
            selectedAutomationType,
          ),
        );
      }

      if (selectedAutomationType === automationData?.type && isPrevCellStatus) {
        dispatch(onAutomationTriggerTypeSelected(automationData));
      }

      if (isAssigneeChange) {
        const triggerCol = isAssigneeChange ? getAssignTaskCol() : {};
        const updatedNewAuto = {
          ...(!isEmpty(triggerCol) ? {triggerCol} : {}),
          phoneColId: {},
        };
        dispatch(updateNewAutomation(updatedNewAuto));
      }

      if (
        selectedAutomationType === automationData?.type &&
        [
          MESSAGE_AUTOMATION.ROW_NEW_ENTRY,
          MESSAGE_AUTOMATION.ON_SPECIFIC_DATE,
        ].includes(automationData?.type)
      ) {
        dispatch(onAutomationEntryTypeSelected(automationData));
      }

      dispatch(
        setCurrentAutomation({
          operationType: operationType || null,
          type: selectedAutomationType,
          automationName: selectedAutomationTitle_EN ?? '',
        }),
      );
    } else if (!isEdit) {
      if (selectedAutomationType !== currentAutomation?.type) {
        dispatch(
          manageAutomationTypeChange(
            currentAutomation?.type,
            selectedAutomationType,
          ),
        );
      }

      if (!currentAutomation?.type && isAssigneeChange) {
        const triggerCol = isAssigneeChange ? getAssignTaskCol() : {};
        const updatedNewAuto = !isEmpty(triggerCol) ? {triggerCol} : {};
        dispatch(updateNewAutomation(updatedNewAuto));
      }

      if (!currentAutomation?.type && isPrevCellStatus) {
        dispatch(updateNewAutomation(newAuto));
      }

      //prettier-ignore
      const setObj = {
        operationType: operationType || null,
        type: selectedAutomationType,
        automationId: currentAutomation?.automationId ?? automationUtils.firestoreAutoId(),
        automationName: selectedAutomationTitle_EN ?? '',
      };

      dispatch(setCurrentAutomation(setObj));
    }
  };

const onAutomationTypeSelected =
  (autId, categoryId = null, operationType = null, type = null) =>
  (dispatch, getState) => {
    const {
      automation: {
        automationConfig,
        currentAutomation,
        availableAutomations,
        catId: reduxCatId,
        automationDocumentId: originalDocumentId,
        newAuto,
      },
    } = getState();

    const catId = reduxCatId ?? categoryId;

    const catAvailableAutomations =
      availableAutomations?.[catId]?.availableAutomations ?? {};
    operationType =
      operationType || availableAutomations?.[catId]?.operationType;
    if (!isEmpty(catAvailableAutomations)) {
      const catAutomationData = catAvailableAutomations?.[autId] ?? {};
      const selectedAutomationType = type || catAutomationData?.meta?.type;
      const selectedAutomationTitle_EN = catAutomationData?.title?.['EN'];

      logAnalyticsEvent('AUTOMATION_ADD_TRIGGER', {
        docId: originalDocumentId,
        triggerName: selectedAutomationTitle_EN,
      });

      const currentAutomationId = currentAutomation?.automationId;

      const isEdit = !isEmpty(automationConfig?.[currentAutomationId] ?? {});

      if (isEdit && currentAutomationId) {
        const automationData = automationConfig?.[currentAutomationId];

        if (automationData) {
          if (selectedAutomationType !== automationData?.type) {
            dispatch(
              manageAutomationTypeChange(
                automationData?.type,
                selectedAutomationType,
              ),
            );
          }
          if (
            selectedAutomationType === automationData?.type &&
            automationData?.type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE
          ) {
            dispatch(onAutomationTriggerTypeSelected(automationData));
          }
          // if (
          //   selectedAutomationType === automationData?.type &&
          //   automationData?.type === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE
          // ) {
          //   // TODO : Handle Type Changes - CELL_STATUS_CHANGE / ROW_NEW_ENTRY / ON_SPECIFIC_DATE
          //   dispatch(onAutomationAssigneeTypeSelected(automationData));
          // }

          if (
            selectedAutomationType === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE
          ) {
            const assignTask =
              selectedAutomationType === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE
                ? automationUtils
                    .getAutomationTableState(getState())
                    .automationHeaderData.find(
                      (item) =>
                        getOriginalFieldType(item) ===
                        FIELD_TYPE_ID.ASSIGN_TASK,
                    )
                : {};
            dispatch(
              updateNewAutomation({
                ...(!isEmpty(assignTask) ? {triggerCol: assignTask} : {}),
                phoneColId: {},
              }),
            );
          }

          if (
            selectedAutomationType === automationData?.type &&
            [
              MESSAGE_AUTOMATION.ROW_NEW_ENTRY,
              MESSAGE_AUTOMATION.ON_SPECIFIC_DATE,
            ].includes(automationData?.type)
          ) {
            dispatch(onAutomationEntryTypeSelected(automationData));
          }
          //  else if (
          //   [
          //     MESSAGE_AUTOMATION.ROW_NEW_ENTRY,
          //     MESSAGE_AUTOMATION.ON_SPECIFIC_DATE,
          //   ].includes(selectedAutomationType)
          // ) {
          //   dispatch(onAutomationEntryTypeSelected(automationData));
          // }

          dispatch(
            setCurrentAutomation({
              operationType: operationType || null,
              type: selectedAutomationType,
              automationName: selectedAutomationTitle_EN ?? '',
              catAutomationDatautomationNameLangObj:
                catAutomationData?.title ?? '',
            }),
          );
        }
      } else {
        if (selectedAutomationType !== currentAutomation?.type) {
          dispatch(
            manageAutomationTypeChange(
              currentAutomation?.type,
              selectedAutomationType,
            ),
          );
        }

        if (
          !currentAutomation?.type &&
          selectedAutomationType === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE
        ) {
          const assignTask =
            selectedAutomationType === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE
              ? automationUtils
                  .getAutomationTableState(getState())
                  .automationHeaderData.find(
                    (item) =>
                      getOriginalFieldType(item) === FIELD_TYPE_ID.ASSIGN_TASK,
                  )
              : {};
          dispatch(
            updateNewAutomation({
              ...(!isEmpty(assignTask) ? {triggerCol: assignTask} : {}),
            }),
          );
        }

        if (
          !currentAutomation?.type &&
          selectedAutomationType === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE
        ) {
          dispatch(updateNewAutomation(newAuto));
        }

        const setObj = {
          operationType: operationType || null,
          type: selectedAutomationType,
          automationId:
            currentAutomation?.automationId ??
            automationUtils.firestoreAutoId(),
          automationName: selectedAutomationTitle_EN ?? '',
          catAutomationDatautomationName:
            catAutomationData?.title?.['EN'] ?? '',
          automationNameLangObj: catAutomationData?.title ?? '',
        };

        dispatch(setCurrentAutomation(setObj));
      }
    }
  };

const onColumnElementSelected = (colObj, extraData) => (dispatch, getState) => {
  const {
    automation: {
      currentAutomation,
      currentCol,
      automationConfig,
      newAuto,
      constTemplateValues,
      automationDocumentId: originalDocumentId,
    },
  } = getState();

  const {
    // DashboardRelated
    isDashboardOperation,
    source,
    docId,
    isDashboardColumn,
    // Automation
    selectType,
    updatePhoneCol,
    // Template Key
    selectedKey,
  } = extraData ?? {};
  if (isDashboardOperation) {
    /**
     * On Dashboard Operation
     */
    dispatch(selectDashboardFields({selectedOperation: colObj}));

    logAnalyticsEvent('DASHBOARD_ADD_OPERATION', {
      operation: colObj.id,
      source: source,
      docId: docId,
    });

    // GO BACK
    return {shouldGoBack: true};
  } else if (isDashboardColumn) {
    /**
     * On Dashboard Select Column
     */
    dispatch(selectDashboardFields({selectedColumn: colObj}));

    logAnalyticsEvent('DASHBOARD_ADD_COLUMN', {
      source: source,
      docId: docId,
    });

    // GO BACK
    return {shouldGoBack: true};
  }

  const selectVal = `${colObj.val}`; // Ensuring every value is in string, so weak type checking is easier.

  /**
   * Update ON_SPECIFIC_DATE automation Trigger Column
   */
  if (selectType === MESSAGE_AUTOMATION.ON_SPECIFIC_DATE) {
    dispatch(updateNewAutomation({triggerCol: colObj}));
  } else if (selectType === 'NOTIFICATION_COLUMN') {
    /**
     * Update Notification Mapping Columns
     */

    /**
     * For Status Update
     */
    if (automationUtils.checkIsTriggerAutomation(currentAutomation)) {
      const currentMapping =
        (newAuto?.triggerData ?? [])?.[0]?.colIdMapping ?? {};

      const colIdMapping = Object.assign({}, currentMapping, {
        [selectedKey]: colObj,
      });

      dispatch(
        updateNewAutomation({
          triggerData: newAuto.triggerData.map((item, index) => {
            return index === automationConstants.addToTriggerDataIndex
              ? {
                  ...item,
                  colIdMapping,
                }
              : {
                  ...item,
                };
          }),
        }),
      );
    } else {
      /**
       * For Entry Based Column Update
       */
      const colIdMapping = newAuto?.colIdMapping
        ? Object.assign({}, newAuto.colIdMapping, {
            [selectedKey]: colObj,
          })
        : {[selectedKey]: colObj};

      dispatch(updateNewAutomation({colIdMapping}));
    }

    /**
     * Update Constant Value for Template Key Mapping
     */
    if (selectedKey in constTemplateValues) {
      const updateValues = omit(constTemplateValues, [selectedKey]);
      dispatch(updateConstValues(updateValues));
    }
  } else if (selectType === 'TRIGGER_COLUMN') {
    /**
     * Update Trigger Column
     */
    if (currentCol?.id && currentCol.id != colObj.id) {
      const colId = colObj.id;

      const automationDataColumns =
        automationConfig?.[currentAutomation?.automationId]?.columns;

      const colTriggers = automationDataColumns?.[colId]?.triggers;

      // const prevColTrigger = !isEmpty(colTriggers)
      //   ? Object.keys(colTriggers)[0]
      //   : null;

      const triggerData = !isEmpty(colTriggers)
        ? Object.keys(colTriggers).map((item) => {
            return {
              status: item,
              ...colTriggers[item],
            };
          })
        : [
            !isEmpty(newAuto?.triggerData?.[0])
              ? omit(newAuto?.triggerData?.[0], ['status'])
              : {},
          ];

      dispatch(
        updateNewAutomation({
          triggerData: triggerData,
        }),
      );
    }

    dispatch(setCurrentColumn(colObj));

    logAnalyticsEvent('AUTOMATION_ADD_TRIGGER_COLUMN', {
      docId: originalDocumentId,
    });
  } else if (selectType === 'TRIGGER_STATUS') {
    logAnalyticsEvent('AUTOMATION_ADD_TRIGGER_VALUE', {
      docId: originalDocumentId,
    });

    const triggerData = newAuto?.triggerData ?? [];
    const hasPrevNotificationData = newAuto?.notificationTemplateId
      ? {
          notificationTemplateId: newAuto?.notificationTemplateId,
          prevColIdMapping: newAuto?.prevColIdMapping ?? undefined,
          colIdMapping: newAuto?.colIdMapping ?? undefined,
        }
      : {};

    dispatch(
      updateNewAutomation({
        triggerData: !triggerData.length
          ? [{status: selectVal, ...hasPrevNotificationData}]
          : (newAuto?.triggerData ?? []).map((item, index) => {
              return index === automationConstants.addToTriggerDataIndex
                ? {...item, status: selectVal}
                : item;
            }),
      }),
    );
  } else {
    if (currentAutomation?.type === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE) {
      return;
    }
    dispatch(updateNewAutomation({phoneColId: colObj}));
    logAnalyticsEvent('AUTOMATION_ADD_MOBILE_COLUMN', {
      docId: originalDocumentId,
    });
  }

  /**
   * Update Phone Column
   */
  if (updatePhoneCol) {
    logAnalyticsEvent('AUTOMATION_ADD_MOBILE_COLUMN', {
      docId: originalDocumentId,
    });

    dispatch(
      updatePhoneColId({
        phoneColId: colObj,
      }),
    );
  }

  const {automationName, automationId} = currentAutomation;
  logAnalyticsEvent('AUTOMATION_COLUMN_EDIT_DONE', {
    docId: originalDocumentId,
    automationName,
    automationId,
  });
  // GO BACK
  return {shouldGoBack: true};
};

const automationTemplateConstValueSubmit =
  (constValue, extraData) => (dispatch, getState) => {
    const {
      automation: {newAuto, constTemplateValues, currentAutomation},
    } = getState();

    const {selectedKey} = extraData ?? {};

    if (automationUtils.checkIsTriggerAutomation(currentAutomation)) {
      const currentMapping = (newAuto?.triggerData ?? [])?.[0]?.colIdMapping;

      const colIdMapping = !isEmpty(currentMapping)
        ? omit(currentMapping, [selectedKey])
        : {};

      dispatch(
        updateNewAutomation({
          triggerData: newAuto.triggerData.map((item, index) => {
            return index === automationConstants.addToTriggerDataIndex
              ? {
                  ...item,
                  colIdMapping,
                }
              : {
                  ...item,
                };
          }),
        }),
      );
    } else {
      const colIdMapping = newAuto?.colIdMapping
        ? omit(newAuto.colIdMapping, [selectedKey])
        : {};
      dispatch(updateNewAutomation({colIdMapping}));
    }

    dispatch(
      updateConstValues({
        ...constTemplateValues,
        [selectedKey]: constValue,
      }),
    );
  };

const useNotificationTemplate = (templateId) => (dispatch, getState) => {
  const {
    automation: {
      automationConfig,
      constTemplateValues,
      currentAutomation,
      newAuto,
    },
  } = getState();

  const automationId = currentAutomation?.automationId;
  const automationData = automationConfig?.[automationId] ?? {};

  const current_template_id = automationUtils.getCurrentNotificationTemplateId(
    automationConfig,
    currentAutomation,
  );

  const updated_template_id = automationUtils.getUpdatedNotificationTemplateId(
    currentAutomation,
    newAuto,
  );

  const editCols =
    templateId === current_template_id
      ? automationUtils.getCurrentAutomationColIdMappingUtils(
          automationId,
          automationConfig,
        )
      : templateId === updated_template_id
      ? automationUtils.getUpdatedAutomationColIdMapping(
          newAuto,
          currentAutomation,
        )
      : {};

  const selectedTemplateChanged = templateId !== updated_template_id;

  const colIdMapping =
    templateId === current_template_id
      ? formatColIdMappingForRedux(editCols)
      : templateId === updated_template_id
      ? editCols
      : undefined;

  if (automationUtils.checkIsTriggerAutomation(currentAutomation)) {
    dispatch(
      updateNewAutomation({
        constTemplateValues: selectedTemplateChanged
          ? {}
          : automationData?.constTemplateValues,
        triggerData: [
          {
            ...Object.assign({}, newAuto.triggerData?.[0]),
            notificationTemplateId: templateId,
            colIdMapping: selectedTemplateChanged ? undefined : colIdMapping,
          },
        ],
      }),
    );
  } else {
    dispatch(
      updateNewAutomation({
        notificationTemplateId: templateId,
        colIdMapping: isEmpty(colIdMapping) ? undefined : colIdMapping,
        constTemplateValues: selectedTemplateChanged ? {} : constTemplateValues,
        ...Object.assign({}, automationData?.triggerCol),
      }),
    );
  }

  /**
   * Set isEditingColIdMapping flag "true"
   */
  dispatch(setIsEditingColIdMapping(true));
};

const setNotificationTemplate = (templateId) => (dispatch, getState) => {
  const {
    automation: {
      automationConfig,
      constTemplateValues,
      currentAutomation,
      newAuto,
    },
  } = getState();

  const automationId = currentAutomation?.automationId;
  const automationData = automationConfig?.[automationId] ?? {};

  const current_template_id = automationUtils.getCurrentNotificationTemplateId(
    automationConfig,
    currentAutomation,
  );

  const updated_template_id = automationUtils.getUpdatedNotificationTemplateId(
    currentAutomation,
    newAuto,
  );

  const editCols =
    templateId === current_template_id
      ? automationUtils.getCurrentAutomationColIdMappingUtils(
          automationId,
          automationConfig,
        )
      : templateId === updated_template_id
      ? automationUtils.getUpdatedAutomationColIdMapping(
          newAuto,
          currentAutomation,
        )
      : {};

  const selectedTemplateChanged = templateId !== updated_template_id;

  const colIdMapping =
    templateId === current_template_id
      ? formatColIdMappingForRedux(editCols)
      : templateId === updated_template_id
      ? editCols
      : undefined;
  let objToSend = {};
  if (automationUtils.checkIsTriggerAutomation(currentAutomation)) {
    objToSend = {
      constTemplateValues: selectedTemplateChanged
        ? {}
        : automationData?.constTemplateValues,
      triggerData: [
        {
          ...Object.assign({}, newAuto.triggerData?.[0]),
          notificationTemplateId: templateId,
          colIdMapping: selectedTemplateChanged ? undefined : colIdMapping,
        },
      ],
    };
    dispatch(updateNewAutomation(objToSend));
  } else {
    objToSend = {
      notificationTemplateId: templateId,
      colIdMapping: isEmpty(colIdMapping) ? undefined : colIdMapping,
      constTemplateValues: selectedTemplateChanged ? {} : constTemplateValues,
      ...Object.assign({}, automationData?.triggerCol),
    };
    dispatch(updateNewAutomation(objToSend));
  }

  /**
   * Set isEditingColIdMapping flag "true"
   */
  dispatch(setIsEditingColIdMapping(true));
  return Object.assign({}, newAuto, objToSend);
};

const onNotificationPreviewDone =
  (extra = {}) =>
  (dispatch, getState) => {
    try {
      const {
        automation: {
          newAuto,
          currentAutomation,
          automationConfig,
          automationDocumentId: originalDocumentId,
        },
      } = getState();
      const currentAutomationId = currentAutomation?.automationId;
      const template_id = dispatch(getUpdatedAutomationNotificationId());

      logAnalyticsEvent('AUTOMATION_ADD_NOTIF_MSG', {
        docId: originalDocumentId,
        templateId: template_id,
      });

      // Early return if from WhatsApp Configure
      if (extra?.fromWhatsAppConfigure) {
        return;
      }

      const isEdit = !isEmpty(automationConfig?.[currentAutomationId] ?? {});
      const newAutoObj = {
        prevNotificationTemplateId: isEdit
          ? dispatch(getCurrentNotificationTemplateId(isEdit))
          : template_id,
        prevColIdMapping: automationUtils.formatColIdMapFirestore(
          isEdit
            ? dispatch(
                getCurrentAutomationColIdMapping(
                  currentAutomation?.automationId,
                ),
              )
            : dispatch(getUpdatedAutomationColIdMapping()),
        ),
      };

      const updatedTriggerData = [
        {
          ...(newAuto?.triggerData?.[0] ?? {}),
          ...newAutoObj,
        },
      ];

      dispatch(
        updateNewAutomation(
          currentAutomation?.type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE
            ? {triggerData: updatedTriggerData}
            : newAutoObj,
        ),
      );

      dispatch(setIsEditingColIdMapping(false));
    } catch (error) {
      captureError(error);
    }
  };

const fetchAutomationFilters = () => async (dispatch, getState) => {
  try {
    const {
      automation: {filters},
    } = getState();

    const snapShot = await FirestoreDB.automations.filters().get();
    if (!snapShot.exists || filters.length) {
      return;
    }
    const data = snapShot.data();
    let filtersArray = [];
    Object.keys(data ?? {}).forEach((filterId) => {
      filtersArray.push({
        name: data[filterId].name,
        priority: data[filterId].priority,
        type: filterId,
      });
    });
    filtersArray = sortBy(filtersArray, ['priority']);
    dispatch({
      type: AUTOMATION_ACTION.SET_AUTOMATION_FILTERS,
      payload: filtersArray,
    });
    return filtersArray;
  } catch (error) {
    captureError(error);
    return [];
  }
};

const triggerMiniAppAutomationManually =
  ({
    automationId,
    screenId,
    rowId,
    name,
    actionBtnId,
    updateBtnStatus,
    runTimeValueMap,
  }) =>
  async (dispatch, getState) => {
    const {
      miniApps: {activeAppId},
      auth: {userPref, user},
      organisation: {activeOrganisationId},
    } = getState();

    const updateActionBtnStatus = (updatedObj) => {
      if (updateBtnStatus) {
        dispatch({
          type: MINI_APPS_ACTION.UPDATE_ACTION_BUTTONS_STATUS,
          payload: {
            appId: activeAppId,
            screenId,
            rowId,
            actionBtnId,
            updatedObj,
          },
        });
      }
    };

    try {
      const {documentData, activeAppMeta, activeDocId} = mapMiniAppStates(
        getState(),
        false,
        false,
        screenId,
      );

      const rowObj = documentData.rowIdDataMap[rowId];

      // Initialize Notification for automation
      const notificationServiceInstance = new NotificationService();
      const notificationId = `AUTORUNID_${nanoid()}_ACTION_BUTTON`;
      const setObj = {
        appId: activeAppId,
        service: SERVICE_TYPE.AUTOMATION,
        forAppId: activeAppId,
        forUID: [user.uid],

        orgId: activeOrganisationId,
        initiatedBy: user.uid,
        notificationId: notificationId,
        type: NOTIFICATION_TYPE.REQUESTED,
        isRunning: true,
        data: {
          toast: {
            body: `${name || 'Action'} Requested`,
          },
          displayDocId: activeDocId,
        },
      };
      notificationServiceInstance.addOrUpdateNotification(setObj);

      const dataObj = {
        miniAppId: activeAppId,
        screenId,
        miniAppData: activeAppMeta,
        timezone: userPref?.timezone ?? getTimezone(),
        table: pick(documentData, ['headerData', 'footerData', 'fileObj']),
        rowData: rowObj,
        automationId,
        docId: activeDocId,
        actionBtnId,
        initAutoRunId: notificationId,
        actionBtnMeta: activeAppMeta.screens[screenId].actionBtns.find(
          (actionBtnMeta) => actionBtnMeta.id === actionBtnId,
        ),
        runTimeValueMap,
      };

      updateActionBtnStatus({
        status: MINI_APPS.ACTION_BUTTONS.BTN_STATUS.RUNNING,
      });

      const data = await triggerMiniAppAutomationManuallyCF(dataObj);

      const isSuceess = data?.success;

      updateActionBtnStatus({
        status: isSuceess
          ? MINI_APPS.ACTION_BUTTONS.BTN_STATUS.SUCCESS
          : MINI_APPS.ACTION_BUTTONS.BTN_STATUS.FAILED,
      });

      if (!isSuceess) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return false;
      }

      // success
      return true;
    } catch (error) {
      updateActionBtnStatus({
        status: MINI_APPS.ACTION_BUTTONS.BTN_STATUS.FAILED,
        error: error?.message,
      });
      captureError(error);
      return false;
    }
  };

const addCopyFileDetails = (fileData) => async (dispatch, getState) => {
  try {
    const {
      automation: {newAuto},
    } = getState();
    if (
      fileData?.meta?.data?.documentId &&
      newAuto.idPasteTo !== fileData.meta.data.documentId
    ) {
      dispatch(
        updateNewAutomation({
          headerMapping: undefined,
          idPasteTo: fileData.meta.data.documentId
            ? `${fileData.meta.data.documentId}`
            : null,
        }),
      );
    }
    dispatch({
      type: AUTOMATION_ACTION.SET_OPERATION_FILE_DATA,
      payload: fileData,
    });
  } catch (error) {
    captureError(error);
  }
};

const automationAddNewColumn = () => async (dispatch, getState) => {
  const {
    automation: {
      automationDocumentId,
      automationActiveDocumentId,
      fromMiniApps,
      automationDocumentMeta,
    },
    auth: {userPref},
  } = getState();

  if (fromMiniApps) {
    return ShowToast('Miniapps dont support adding new columns.', userPref);
  }

  const headerData = fromMiniApps
    ? await FirestoreDB.documents
        .documentRef(automationDocumentId)
        .get()
        .then((data) =>
          data.exists ? Object.values(data.data()?.headerData ?? {}) : [],
        )
    : automationUtils.getAutomationTableState(getState()).automationHeaderData;

  return dispatch(
    addNewColumn({
      headerData,
      activeDocumentMeta: automationDocumentMeta,
      fromMiniApps,
      colName: '',
      noReduxAction: fromMiniApps,
      originalDocumentId: automationDocumentId,
      activeDocumentId: automationActiveDocumentId,
      type: FIELD_TYPE_ID.CONTACT,
    }),
  );
};

const setNotificationParamsForRedirectionWeb =
  (payload) => async (dispatch) => {
    try {
      dispatch({
        type: AUTOMATION_ACTION.SET_NOTIFICATION_REDIRECTION_PARAMS_WEB,
        payload: payload,
      });
    } catch (error) {
      captureError(error);
    }
  };

const clearNotificationParamsForRedirectionWeb = () => async (dispatch) => {
  try {
    dispatch({
      type: AUTOMATION_ACTION.RESET_NOTIFICATION_REDIRECTION_PARAMS_WEB,
      payload: {},
    });
  } catch (error) {
    captureError(error);
  }
};

const miniAppsAutomationsSetEditData =
  ({automationId, docId}) =>
  async (dispatch, getState) => {
    // Reset Previous Automation Data
    dispatch(resetAutomationStateMiniAppEdit());
    const MINI_APPBASED_TRIGGERS = [MESSAGE_AUTOMATION.ON_USER_APP_ACCESS];
    const reduxData = getState();
    const {
      automation: {miniAppAutomationConfig},
      home: {files},
    } = reduxData;

    const config = miniAppAutomationConfig?.[docId]?.[automationId];
    const IS_MINI_APPBASED_TRIGGER = MINI_APPBASED_TRIGGERS.includes(
      config?.type,
    );
    const docData = IS_MINI_APPBASED_TRIGGER
      ? {}
      : getDocDataFromMiniAppState(docId, reduxData);

    // Set Automation Config Data
    await Promise.all([
      dispatch(
        loadAutomationConfig(
          docId,
          docData?.headerData || [],
          false,
          true,
          config?.type,
        ),
      ),
      dispatch(
        loadAllAutomationTemplates('', {fromMiniApps: true, force: true}),
      ),
    ]);

    const {type, operationType} = config || {};
    const IS_AUTO_MESSAGE =
      operationType === DEFAULT_OPERATION_TYPE || !operationType;
    const newAutoObject = {};
    let constTemplateValues = {};

    if (type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE) {
      const colId = keys(config?.columns)[0];

      // Set Trigger col for Edit
      dispatch(
        setEditTriggerCol({
          id: colId,
          ...config?.columns?.[colId],
        }),
      );

      // Set Currently selected - Select Box Column
      if (colId && docData.headerDataAsObj[colId]) {
        dispatch(setCurrentColumn(docData.headerDataAsObj[colId]));
      }

      const triggerStatus = keys(config?.columns?.[colId]?.triggers ?? {})[0];

      newAutoObject.triggerData = [
        {
          status: triggerStatus,
        },
      ];
    }

    if (config?.idPasteTo?.length) {
      const doc =
        (files || []).find((item) => item.documentId === config.idPasteTo) ??
        {};

      if (doc?.documentId) {
        await DocumentsMethods.getUserDocumentDataWithoutRows(
          config.idPasteTo,
        ).then((documentData) => {
          const meta = {
            data: {
              documentId: config.idPasteTo,
              documentMeta: doc.documentMeta,
            },
          };
          const objToSet = {meta, fileData: documentData};
          dispatch(addCopyFileDetails(objToSet));
        });
      }
    }

    // Set Current Automation Data
    dispatch(
      setCurrentAutomation({
        type: config?.type,
        automationId: automationId,
        operationType: config?.operationType,
        automationName: config?.name,
      }),
    );

    if (
      [
        AUTOMATION_OPERATION_TYPES.COPY_ROW,
        AUTOMATION_OPERATION_TYPES.MOVE_ROW,
        AUTOMATION_OPERATION_TYPES.SET_VALUE_BY_FILTERS,
        AUTOMATION_OPERATION_TYPES.ADD_EMPTY_ROW,
      ].includes(operationType)
    ) {
      newAutoObject.idPasteTo = config?.idPasteTo;
    }

    if (
      [
        AUTOMATION_OPERATION_TYPES.COPY_ROW,
        AUTOMATION_OPERATION_TYPES.MOVE_ROW,
        AUTOMATION_OPERATION_TYPES.SET_VALUE_BY_FILTERS,
      ].includes(operationType)
    ) {
      if (
        !isEmpty(config?.aggregationColMap) ||
        Object.values(config?.defaultValConstantMapOptions ?? {}).some(
          ({type: mappingType}) => mappingType === 'AGGREGATION',
        )
      ) {
        if (!reduxData.aggregation.aggregationsList?.length) {
          await dispatch(fetchAggregationsForMiniApp());
        }
        const aggregationsList = getState().aggregation.aggregationsList;
        const selectedAggregations = pick(
          config.aggregationMap ?? {},
          aggregationsList.map((aggs) => aggs?.aggregationId),
        );
        newAutoObject.aggregationMap = {
          selectedAggregations,
          columns: Object.keys(selectedAggregations).reduce(
            (acc, aggregationId) => {
              return [
                ...acc,
                ...getColumnLikeOptionsFromAggsConfig(
                  aggregationsList.find(
                    (aggsObj) => aggsObj.aggregationId === aggregationId,
                  )?.aggregationConfig,
                  aggregationId,
                ),
              ];
            },
            [],
          ),
        };
        newAutoObject.aggregationColMap = pickBy(
          config.aggregationColMap ?? {},
          (value) =>
            aggregationsList.some(
              (aggs) => aggs?.aggregationId === value?.aggregationId,
            ),
        );
      }

      if (
        !isEmpty(config?.endpointMap?.selectedEndpoints) ||
        Object.values(config?.defaultValConstantMapOptions ?? {}).some(
          ({type: mappingType}) => mappingType === 'ENDPOINT',
        )
      ) {
        if (!reduxData.miniApps.endpointsList?.length) {
          await dispatch(fetchEndpointsForMiniApp());
        }
        const endpointsList = getState().miniApps.endpointsList;
        const selectedEndpoints = pick(
          config.endpointMap?.selectedEndpoints ?? {},
          endpointsList.map((endpointObj) => endpointObj?.id),
        );
        newAutoObject.endpointMap = {selectedEndpoints};
        newAutoObject.endpointColMap = pickBy(
          config.endpointColMap ?? {},
          (value) =>
            endpointsList.some(
              (endpointObj) => endpointObj?.id === value?.endpointId,
            ),
        );
      }

      newAutoObject.defaultValMap = config?.defaultValMap;
      newAutoObject.defaultValConstantMap = config?.defaultValConstantMap;
      newAutoObject.defaultValConstantMapOptions =
        config?.defaultValConstantMapOptions;

      newAutoObject.additionalDataSource =
        config?.additionalDataSource ?? undefined;

      if (operationType === AUTOMATION_OPERATION_TYPES.SET_VALUE_BY_FILTERS) {
        newAutoObject.filterConditionType = config?.filterConditionType;
        newAutoObject.filterColToColMap = config?.filterColToColMap;
        newAutoObject.toDocOperation = config?.toDocOperation;
        newAutoObject.updateOnUniqueFail = config?.updateOnUniqueFail;
      }
    }

    if (
      IS_AUTO_MESSAGE ||
      [
        AUTOMATION_OPERATION_TYPES.COPY_ROW,
        AUTOMATION_OPERATION_TYPES.MOVE_ROW,
        AUTOMATION_OPERATION_TYPES.SET_VALUE_BY_FILTERS,
        AUTOMATION_OPERATION_TYPES.SEND_NOTIFICATION,
      ].includes(operationType)
    ) {
      if (config?.mappingVersion !== MAPPING_VERSION) {
        newAutoObject.headerMapping = invert(config?.headerMapping);
      } else {
        newAutoObject.headerMapping = config?.headerMapping;
      }
    }

    newAutoObject.mappingVersion = config?.mappingVersion || MAPPING_VERSION;

    if (
      IS_AUTO_MESSAGE ||
      (operationType === AUTOMATION_OPERATION_TYPES.SEND_NOTIFICATION &&
        config?.userType === NOTIFICATION_OPERATION_USER_TYPES.ASSIGNEE)
    ) {
      newAutoObject.phoneColId = config?.phoneColId;
    }

    if (IS_AUTO_MESSAGE) {
      if (type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE) {
        const colId = keys(config?.columns)[0];
        const triggerStatus = keys(config?.columns?.[colId]?.triggers ?? {})[0];
        const triggerStatusData =
          config?.columns?.[colId]?.triggers?.[triggerStatus] || {};

        const colIdMapping = formatColIdMappingForRedux(
          triggerStatusData?.colIdMapping || {},
        );

        // Trigger Data is already set above (if type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE)
        newAutoObject.triggerData[0].colIdMapping = colIdMapping;
        newAutoObject.triggerData[0].notificationTemplateId =
          triggerStatusData?.notificationTemplateId;
      } else {
        const colIdMapping = formatColIdMappingForRedux(
          config?.colIdMapping || {},
        );
        newAutoObject.notificationTemplateId = config?.notificationTemplateId;
        newAutoObject.colIdMapping = colIdMapping;
      }
      constTemplateValues = config?.constTemplateValues;
    }

    if (
      [
        AUTOMATION_OPERATION_TYPES.SEND_NOTIFICATION,
        AUTOMATION_OPERATION_TYPES.SEND_EMAIL,
      ].includes(operationType)
    ) {
      newAutoObject.messageConfig = config?.messageConfig;
      newAutoObject.userType = config?.userType;
      newAutoObject.usersList = config?.usersList;
      newAutoObject.userPermissions = config?.userPermissions;
      newAutoObject.userCustomPermissions = config?.userCustomPermissions;

      if (operationType === AUTOMATION_OPERATION_TYPES.SEND_EMAIL) {
        newAutoObject.emailIntegration = config?.emailIntegration;

        newAutoObject.userColumnIds = config?.userColumnIds || [];

        newAutoObject.ccUserType = config?.ccUserType;
        newAutoObject.ccUserColumnIds = config?.ccUserColumnIds || [];
        newAutoObject.ccUsersList = config?.ccUsersList;
        newAutoObject.ccUserPermissions = config?.ccUserPermissions;
        newAutoObject.ccUserCustomPermissions = config?.ccUserCustomPermissions;
      }
    }

    if (
      type !== MESSAGE_AUTOMATION.ROW_EDIT_ENTRY &&
      type !== MESSAGE_AUTOMATION.ON_USER_APP_ACCESS
    ) {
      newAutoObject.allTriggerConditions = config?.allTriggerConditions;
      newAutoObject.allTriggerConditionType = config?.allTriggerConditionType;
    }

    if (type === MESSAGE_AUTOMATION.ROW_EDIT_ENTRY) {
      newAutoObject.editTriggerCols = config?.editTriggerCols;
      newAutoObject.editTriggerType = config?.editTriggerType;
      newAutoObject.editTriggerConditionType = config?.editTriggerConditionType;
      newAutoObject.editTriggerConditions = config?.editTriggerConditions;
      newAutoObject.editTriggerOnEmptySet = config?.editTriggerOnEmptySet;
    }

    if (
      type === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE ||
      type === MESSAGE_AUTOMATION.ON_SPECIFIC_DATE
    ) {
      newAutoObject.triggerCol = config?.triggerCol;
    }

    newAutoObject.name = config?.name || '';
    newAutoObject.customNotificationText = config?.customNotificationText || '';

    // ! omit from create automation
    newAutoObject.isEdit = true;
    newAutoObject.isEnabled = config.isEnabled;

    // Default - NO CHANGE
    // useUserWhatsappAPI: false, // `automationConfig?.useUserWhatsappAPI`
    // integrationId: "", // `automationConfig?.integrationId`
    //     // ! -------> TO SET `automationTemplatesTypes` TO `AUTOMATION_TEMPLATES_TYPE.INTEGRATIONS`.
    //       // -  AND FETCH TEMPLATES FOR THAT INTEGRATION ID
    // createdByUID: 'asdfasdfasd', // `automationConfig?.createdByUID`
    // businessProfileId: null, // null
    // createdTimestamp: '', // `automationConfig?.createdTimestamp`
    // isEnabled: true, // `automationConfig?.isEnabled`

    // ? To get updated notification templates
    const {
      automation: {automationTemplates},
    } = getState();

    if (IS_AUTO_MESSAGE) {
      const notificationTemplateId =
        type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE
          ? newAutoObject?.triggerData?.[0]?.notificationTemplateId
          : newAutoObject?.notificationTemplateId;

      // ! omit from create automation
      newAutoObject.templateName =
        (automationTemplates || []).find((item) => {
          return item.templateId === notificationTemplateId;
        })?.title || 'Template';
    }

    dispatch(updateNewAutomation(newAutoObject));
    dispatch(updateConstValues(constTemplateValues));
    return {
      triggerType: config?.type,
    };
  };

const miniAppSetTemplateDataForWhatsapp =
  (colIdMapping, constTemplateValues, templateId) =>
  async (dispatch, getState) => {
    try {
      const {
        automation: {currentAutomation, automationTemplates, newAuto},
      } = getState();
      const newAutoObj = {};
      if (currentAutomation?.type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE) {
        newAutoObj.triggerData = newAuto.triggerData ?? [];
        newAutoObj.triggerData[0] = newAutoObj.triggerData[0] ?? {};
        newAutoObj.triggerData[0].colIdMapping = colIdMapping;
        newAutoObj.triggerData[0].notificationTemplateId = templateId;
      } else {
        newAutoObj.colIdMapping = colIdMapping;
        newAutoObj.notificationTemplateId = templateId;
      }

      newAutoObj.templateName =
        automationTemplates.find((item) => item.templateId === templateId)
          ?.title || 'Template';

      dispatch(updateNewAutomation(newAutoObj));
      dispatch(updateConstValues(constTemplateValues));
    } catch (error) {
      captureError(error);
    }
  };

const clearHeaderMappingFromAdditionalSource =
  (colIdsArr = [], restrictedDocIdsToRemove = []) =>
  (dispatch, getState) => {
    try {
      const {
        automation: {
          newAuto: {additionalDataSource},
        },
      } = getState();

      const obj = {};

      if (!colIdsArr) {
        forOwn(additionalDataSource ?? {}, (value, key) => {
          obj[key] = Object.assign(
            {},
            omit(
              value,
              'headerMapping',
              'defaultValConstantMap',
              'defaultValConstantMapOptions',
            ),
          );
        });
      } else {
        forOwn(additionalDataSource ?? {}, (value, key) => {
          if (restrictedDocIdsToRemove?.includes(key)) {
            return;
          }
          obj[key] = Object.assign({}, value, {
            headerMapping: omit(value?.headerMapping, colIdsArr),
            defaultValConstantMap: omit(
              value?.defaultValConstantMap,
              colIdsArr,
            ),
            defaultValConstantMapOptions: omit(
              value?.defaultValConstantMapOptions,
              colIdsArr,
            ),
          });
        });
      }

      dispatch({
        type: AUTOMATION_ACTION.SET_AUTOMATION_OBJ,
        payload: {additionalDataSource: obj},
      });
    } catch (error) {
      captureError(error);
    }
  };

const setHeaderMappingInAdditionalSource =
  (mapping, docId) => (dispatch, getState) => {
    try {
      if (isEmpty(mapping)) {
        return;
      }
      const {
        automation: {
          newAuto: {additionalDataSource},
        },
      } = getState();

      const headerMappingObj = Object.assign(
        {},
        additionalDataSource?.[docId]?.headerMapping || {},
        mapping,
      );

      const obj = Object.assign({}, additionalDataSource, {
        [docId]: Object.assign({}, additionalDataSource?.[docId], {
          headerMapping: headerMappingObj,
        }),
      });

      dispatch({
        type: AUTOMATION_ACTION.SET_AUTOMATION_OBJ,
        payload: {additionalDataSource: obj},
      });
    } catch (error) {
      captureError(error);
    }
  };

const setDefaultValConstMappingInAdditionalSource =
  (mapping, docId) => (dispatch, getState) => {
    try {
      if (isEmpty(mapping)) {
        return;
      }
      const {
        automation: {
          newAuto: {additionalDataSource},
        },
      } = getState();

      const defaultValConstantMapObj = Object.assign(
        {},
        additionalDataSource?.[docId]?.defaultValConstantMap || {},
        mapping?.defaultValConstantMap,
      );

      const defaultValConstantMapOptionsObj = Object.assign(
        {},
        additionalDataSource?.[docId]?.defaultValConstantMapOptions || {},
        mapping?.defaultValConstantMapOptions,
      );

      const obj = Object.assign({}, additionalDataSource, {
        [docId]: Object.assign({}, additionalDataSource?.[docId], {
          defaultValConstantMap: defaultValConstantMapObj,
          defaultValConstantMapOptions: defaultValConstantMapOptionsObj,
        }),
      });

      dispatch({
        type: AUTOMATION_ACTION.SET_AUTOMATION_OBJ,
        payload: {additionalDataSource: obj},
      });
    } catch (error) {
      captureError(error);
    }
  };

const setFilterColToColMapInAdditionalSource =
  (conditionMapping, docId) => (dispatch, getState) => {
    try {
      if (isEmpty(conditionMapping)) {
        return;
      }
      const {
        automation: {
          newAuto: {additionalDataSource},
        },
      } = getState();

      const obj = {
        [docId]: Object.assign(
          {},
          additionalDataSource?.[docId] ?? {},
          conditionMapping,
        ),
      };

      dispatch({
        type: AUTOMATION_ACTION.SET_AUTOMATION_OBJ,
        payload: {additionalDataSource: obj},
      });
    } catch (error) {
      captureError(error);
    }
  };

export {
  addCopyFileDetails,
  onAutomationEditLoad,
  automationAddNewColumn,
  deleteScheduledPendingAutomations,
  enableDisableAllAutomations,
  updateConstValues,
  activatePendingAutomationsListener,
  automationDependentCols,
  changeCurrentToPrevMapping,
  clearPrevStateAutomations,
  createAutomation,
  deleteAutomationForDependentCol,
  deletePendingAutomations,
  deleteSingleAutomation,
  formatColIdMappingForRedux,
  getAutomationOmitKeysV1,
  getAutomationPermissions,
  getCurrentAutomationColIdMapping,
  getCurrentAutomationObj,
  getCurrentNotificationTemplateId,
  getUpdatedAutomationColIdMapping,
  getUpdatedAutomationNotificationId,
  loadAllAutomationTemplates,
  fetchAutomationFilters,
  loadAvailableAutomations,
  loadPendingAutomations,
  manageAutomationTypeChange,
  removeAllPendingAutomations,
  resetAutomationState,
  resetCurrentAutomationRelatedState,
  loadAutomationConfig,
  setAutomationVisible,
  setCurrentAutomation,
  setCurrentAutomationCategory,
  setCurrentColumn,
  setEditTriggerCol,
  setIsEditingColIdMapping,
  setPendingAutomation,
  updateNewAutomation,
  updatePhoneColId,
  getIsEditingColIdMapping,
  setDailyAutomationConfig,
  shouldAddToUserDefined,
  shouldAddToUseUserWhatsappAPI,
  loadUserAutomationWhatsappTemplates,
  setSelectedDocForDailyAutomation,
  clearAllAutomationTemplates,
  loadGlobalAutomations,
  automationEditNewEntry,
  automationEditTrigger,
  onAutomationTypeSelected,
  getTriggerStatusList,
  onColumnElementSelected,
  useNotificationTemplate,
  setNotificationTemplate,
  automationTemplateConstValueSubmit,
  triggerMiniAppAutomationManually,
  onNotificationPreviewDone,
  removePendingAutomations,
  setNotificationParamsForRedirectionWeb,
  clearNotificationParamsForRedirectionWeb,
  loadMiniAppAutomationConfig,
  miniAppsToggleAutomation,
  miniAppsDeleteAutomation,
  resetCurrentAutomationStateWeb,
  onAutomationV2TypeSelected,
  miniAppsAutomationsSetEditData,
  miniAppSetTemplateDataForWhatsapp,
  clearHeaderMappingFromAdditionalSource,
  setHeaderMappingInAdditionalSource,
  setDefaultValConstMappingInAdditionalSource,
  setFilterColToColMapInAdditionalSource,
};
