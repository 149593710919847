import {
  CLOUD_FUNCTION_PATHS,
  FOOTER_OPERATION_TYPES,
  FIELD_TYPE_ID,
  DASHBOARD_ERRORS,
  DASHBOARD_LABEL_HELPER,
  SHARE_PERMISSION_TYPE,
} from '../../utils/constant';
import {captureError, firestore, functions} from '../../imports';
import {
  checkIfPlainText,
  checkIfCellHasTextualData,
  getLabelValueArray,
  getSelectBoxCell,
  handleCloudError,
  getCreatedInfoCellValue,
} from '../../utils/utils';
import {
  orderBy,
  isEmpty,
  isEqual,
  isArray,
  isNil,
  isPlainObject,
  forOwn,
  pick,
  omitBy,
} from 'lodash';
import {
  calculateSplitByTotal,
  solveEqnStr,
  getOperationValue,
  calculateTotal,
} from '../../utils/equationHelper';
import moment from 'moment';
import DocumentsMethods from '../../FirestoreHandlers/Documents/DocumentsMethods';
import {mapFilterArrFromFirestore} from './searchFilterActionHelper';

const createNewDashboardHelper = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.CREATE_NEW_DASHBOARD,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const deleteDashboardHelper = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.DELETE_DASHBOARD,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const migrateUserOlderDashboardsCloud = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.MIGRATE_USER_OLDER_DASHBOARDS,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const sortPagesForDashboards = (pages) => {
  try {
    const unsortedPages = Object.keys(pages).map((docId) => {
      return {
        ...pages[docId],
        docId,
      };
    });
    return orderBy(unsortedPages, ['createdTimestamp'], ['asc']);
  } catch (error) {
    captureError(error);
    return [];
  }
};

const processDashboardSplitByDisplayData = (valueArr, splitByObj) => {
  try {
    if (isArray(valueArr) && valueArr.length) {
      if (splitByObj?.fieldType === FIELD_TYPE_ID.DATE) {
        valueArr = valueArr
          .slice()
          .sort(
            (a, b) =>
              moment(b.title, 'DD-MM-YYYY').unix() -
              moment(a.title, 'DD-MM-YYYY').unix(),
          ); //sort by date
      } else {
        valueArr.sort((a, b) => {
          const x =
            typeof a?.title === 'string' ? a.title.toUpperCase() : a.title;
          const y =
            typeof b?.title === 'string' ? b.title.toUpperCase() : b.title;
          return x === y ? 0 : x > y ? 1 : -1;
        });
      }
    }
  } catch (error) {
    captureError(error);
  }
  return valueArr;
};

const updateSplitByDashboard = async (
  calculatedTotalObj,
  dashboardId,
  dashboardMeta,
  activeDocumentId,
) => {
  //check and update dashboards for : dashboards with split by
  //can be used to update a single dashboard
  //used when we have a totalObj having count and total keys and a dashboard id to update data
  try {
    const updateObj = {};
    forOwn(calculatedTotalObj, (res, key) => {
      if (res?.count === 0) {
        //ignore values with 0 occurance
        return;
      }
      const val = getOperationValue(dashboardMeta?.operation, res);
      if (!isNil(val)) {
        updateObj[key] = val;
      }
    });
    updateDashboardFirestore(dashboardId, dashboardMeta.uid, {
      [`pages.${activeDocumentId}.splitByVal`]: updateObj,
    });
  } catch (error) {
    captureError(error);
  }
};

const calculateDashboardValueAndUpdateToFirestore = async (
  totalChanged,
  countChanged,
  invalidCountChanged,
  dashboardMeta,
  newFooterObj,
  dashboardId,
  activeDocumentId,
  isFooterNotAvailable = false,
) => {
  //check and update dashboards for : dashboards without split by
  //can be used to update a single dashboard
  try {
    if (
      totalChanged ||
      countChanged ||
      invalidCountChanged ||
      isFooterNotAvailable
    ) {
      let val;
      if (isFooterNotAvailable) {
        val = DASHBOARD_ERRORS.DASHBOARD_COLUMN_NOT_FOUND;
      } else {
        if (
          (dashboardMeta.operation === FOOTER_OPERATION_TYPES.TOTAL &&
            totalChanged) ||
          (dashboardMeta.operation === FOOTER_OPERATION_TYPES.COUNT &&
            countChanged) ||
          dashboardMeta.operation === FOOTER_OPERATION_TYPES.AVERAGE
        ) {
          val = getOperationValue(dashboardMeta?.operation, newFooterObj);
        }
      }
      if (!isNil(val)) {
        updateDashboardFirestore(dashboardId, dashboardMeta.uid, {
          [`pages.${activeDocumentId}.val`]: val,
        });
      }
    }
  } catch (error) {
    captureError(error);
  }
};

const checkAndUpdateDashboardForColumn = (
  prevFooterObj, //oldFooterData[columnId]
  newFooterObj, //newFooterData[columnId]
  dashboardColumnData, //dashboardObj[columnId]
  activeDocumentId,
) => {
  try {
    //check and update all dashboards for a single column (for dashboard without splitBy only)
    if (!isEmpty(dashboardColumnData)) {
      const isFooterNotAvailable = isEmpty(newFooterObj);
      const totalChanged = !isEqual(prevFooterObj?.total, newFooterObj?.total);
      const countChanged = !isEqual(prevFooterObj?.count, newFooterObj?.count);
      const invalidCountChanged = !isEqual(
        prevFooterObj?.invalidCount,
        newFooterObj?.invalidCount,
      );
      if (
        totalChanged ||
        countChanged ||
        invalidCountChanged ||
        isFooterNotAvailable
      ) {
        //if either of total or count is changed can iterate on all dashboard ids
        forOwn(dashboardColumnData, (dashboardMeta, dashboardId) => {
          if (!dashboardMeta?.isV2Dashboard) {
            return;
          }
          if (isArray(dashboardMeta.splitBy) && dashboardMeta.splitBy.length) {
            //if splitBy return
            return;
          }
          calculateDashboardValueAndUpdateToFirestore(
            totalChanged,
            countChanged,
            invalidCountChanged,
            dashboardMeta,
            newFooterObj,
            dashboardId,
            activeDocumentId,
            isFooterNotAvailable,
          );
        });
      }
    }
  } catch (error) {
    captureError(error);
  }
};

const getLabelAsSortedString = (labelArr) => {
  /**
   * !IMP : Any change in this function should also be made in cloud-functions
   */
  let val = '';
  orderBy(labelArr, [(obj) => `${obj.val}`]).forEach((item, index) => {
    const labelVal = `${item.val}`;
    if (labelVal.length) {
      val += `${DASHBOARD_LABEL_HELPER.ENCODER}${labelVal}${
        DASHBOARD_LABEL_HELPER.ENCODER
      }${index < labelArr.length - 1 ? DASHBOARD_LABEL_HELPER.SEPARATOR : ''}`;
    }
  });
  return val;
};

const getDashboardFormattedVal = (splitByColumnObj, rowObj) => {
  /**
   * !IMP : Any change in this function should also be made in cloud-functions
   */
  const splitByFieldType = splitByColumnObj?.fieldType;

  // TODO : Check USer Column HERE

  const splitByColId =
    splitByFieldType === FIELD_TYPE_ID.CREATED_INFO
      ? 'rowProperties'
      : splitByColumnObj?.id;
  const cellObj = rowObj?.[splitByColId];
  if (isNil(splitByColId) || isNil(splitByFieldType) || isEmpty(cellObj)) {
    return null;
  }
  let val;
  switch (splitByFieldType) {
    case FIELD_TYPE_ID.SELECT_POP_UP: {
      if (checkIfPlainText(cellObj.val)) {
        const cellValue = getSelectBoxCell(cellObj.val, cellObj, 'EN', true);
        val = cellValue;
      }
      break;
    }
    case FIELD_TYPE_ID.DATE: {
      if (checkIfPlainText(cellObj.val)) {
        val = moment.unix(cellObj.val).startOf('day').unix();
      }
      break;
    }
    case FIELD_TYPE_ID.LABEL: {
      if (isArray(cellObj.val)) {
        val = getLabelAsSortedString(getLabelValueArray(cellObj.val));
      }
      break;
    }
    // TODO : CHeck User Column HERE
    case FIELD_TYPE_ID.CREATED_INFO: {
      val = getCreatedInfoCellValue(
        cellObj.val,
        cellObj,
        false,
        false,
        null,
        true,
      );
      break;
    }
    default: {
      if (checkIfPlainText(cellObj.val)) {
        val = cellObj.val;
      }
    }
  }
  if (val === '') {
    //important for dashboard key
    val = null;
  }
  return val;
};

const checkAndUpdateDashboard = async (
  {activeDocumentId, activeDocumentMeta},
  headerData, //current headerData data,
  splitByCalculation, //current splitByCalculation data
  prevFooterData, //footerData before action/edit/recalculation
  newFooterData, //footerData after action/edit/recalculation
  rowDataChangeArr, //array of prev and updated row data [{prev, updated}]
) => {
  //check and update all dashboards available on the activedocument
  //updates dashboards for both with and without splitBy
  //IMP: Updates data for activeDocumentId passed here as a argument in home state
  try {
    activeDocumentMeta = Object.assign({}, activeDocumentMeta);
    splitByCalculation = Object.assign({}, splitByCalculation); //currently available splitByCalculation
    const isSplitByCalculationEmpty = isEmpty(splitByCalculation); //
    rowDataChangeArr = Array.isArray(rowDataChangeArr) ? rowDataChangeArr : [];
    const splitByUpdateObj = {};
    const updatedSplitByCalculation = {};
    forOwn(activeDocumentMeta.dashboards, (dashboardColumnData, columnId) => {
      const columnAvailable = headerData.some(
        (colObj) => `${colObj?.id}` === `${columnId}`,
      ); //to check column is deleted or not present in header of this page/document
      const totalChanged =
        columnAvailable &&
        !isEqual(
          prevFooterData?.[columnId]?.total,
          newFooterData?.[columnId]?.total,
        );
      const countChanged =
        columnAvailable &&
        !isEqual(
          prevFooterData?.[columnId]?.count,
          newFooterData?.[columnId]?.count,
        );
      const invalidCountChanged =
        columnAvailable &&
        !isEqual(
          prevFooterData?.[columnId]?.invalidCount,
          newFooterData?.[columnId]?.invalidCount,
        );
      forOwn(dashboardColumnData, (dashboardMeta, dashboardId) => {
        if (!isEmpty(dashboardMeta) && dashboardMeta.isV2Dashboard) {
          const isWithSplit =
            isArray(dashboardMeta.splitBy) && dashboardMeta.splitBy.length;
          if (isWithSplit) {
            //if dashboard with splitBy
            const splitByColumnObj = Object.assign(
              {},
              dashboardMeta.splitBy[0],
            );
            const splitByColumnId = splitByColumnObj.id;

            if (
              isPlainObject(
                updatedSplitByCalculation[columnId]?.[splitByColumnId],
              )
            ) {
              //already calculated for this columnId and splitByColumnId
              return updateSplitByDashboard(
                updatedSplitByCalculation[columnId][splitByColumnId],
                dashboardId,
                dashboardMeta,
                activeDocumentId,
              );
            }

            const addToSplitByCalculationVariable = (calculatedData) => {
              //helper
              Object.assign(updatedSplitByCalculation, {
                [columnId]: Object.assign(
                  {},
                  updatedSplitByCalculation[columnId],
                  {[splitByColumnId]: calculatedData},
                ),
              });
              Object.assign(splitByUpdateObj, {
                [`splitByCalculation.${columnId}.${splitByColumnId}`]:
                  calculatedData,
              });
            };

            const splitByCurrentHeader = splitByColumnId
              ? headerData.find(
                  (colObj) => `${colObj.id}` === `${splitByColumnId}`,
                )
              : null;
            const splitByColumnAvailable = !isEmpty(splitByCurrentHeader);

            if (!columnAvailable || !splitByColumnAvailable) {
              //update error value as header is missing/deleted
              updateDashboardFirestore(dashboardId, dashboardMeta.uid, {
                [`pages.${activeDocumentId}.splitByVal`]: {
                  val: DASHBOARD_ERRORS.DASHBOARD_COLUMN_NOT_FOUND,
                },
              });
              return addToSplitByCalculationVariable({});
            } else if (
              splitByCurrentHeader.fieldType === FIELD_TYPE_ID.TABLE
                ? splitByCurrentHeader.subType !== splitByColumnObj.subType
                : splitByCurrentHeader.fieldType !== splitByColumnObj.fieldType
            ) {
              //update error value as header is of mismatched fieldType
              updateDashboardFirestore(dashboardId, dashboardMeta.uid, {
                [`pages.${activeDocumentId}.splitByVal`]: {
                  val: DASHBOARD_ERRORS.DASHBOARD_SPLIT_BY_FIELDTYPE_MISMATCH,
                },
              });
              return addToSplitByCalculationVariable({});
            }

            const currSplitByData = Object.assign(
              {},
              splitByCalculation[columnId]?.[splitByColumnId],
            );
            const updatedSplitByData = {};
            const getPrevSplitByCalculatedData = (key) => {
              const splitByData = Object.assign(
                {},
                Object.assign({}, currSplitByData, updatedSplitByData)[key],
              );
              return {
                total: splitByData.total ?? 0,
                count: splitByData.count ?? 0,
                invalidCount: splitByData.invalidCount ?? 0,
              };
            };

            let isUpdateRequired = isSplitByCalculationEmpty;
            rowDataChangeArr.forEach((rowDataChangeObj) => {
              const currRowObj = Object.assign({}, rowDataChangeObj?.prev);
              const updatedRowObj = Object.assign(
                {},
                rowDataChangeObj?.updated,
              );

              const splitByCellPrevVal = getDashboardFormattedVal(
                splitByColumnObj,
                currRowObj,
              );
              const splitByCellNewVal = getDashboardFormattedVal(
                splitByColumnObj,
                updatedRowObj,
              );
              if (isNil(splitByCellPrevVal) && isNil(splitByCellNewVal)) {
                //if splitByColCellValue is nil then nothing to do
                return;
              }
              const dashboardColCellPrevVal = currRowObj[columnId]?.val;
              const dashboardColCellNewVal = updatedRowObj[columnId]?.val;

              if (
                splitByCellPrevVal !== splitByCellNewVal ||
                dashboardColCellPrevVal !== dashboardColCellNewVal
              ) {
                //if any change in splitByColCellValue or dashboardColCellValue

                const isValueAvailableBeforeEdit = checkIfCellHasTextualData(
                  dashboardColCellPrevVal,
                ); //cell has some value before this edit
                const isValueValidBeforeEdit =
                  isValueAvailableBeforeEdit && !isNaN(dashboardColCellPrevVal);
                const isValueAvailableAfterEdit = checkIfCellHasTextualData(
                  dashboardColCellNewVal,
                ); //cell has some value after this edit
                const isValueValidAfterEdit =
                  isValueAvailableAfterEdit && !isNaN(dashboardColCellNewVal);

                if (!isNil(splitByCellPrevVal)) {
                  const prevCalculatedData =
                    getPrevSplitByCalculatedData(splitByCellPrevVal);

                  Object.assign(updatedSplitByData, {
                    [splitByCellPrevVal]: {
                      total: solveEqnStr(
                        `${prevCalculatedData.total}-${Number(
                          isValueValidBeforeEdit ? dashboardColCellPrevVal : 0,
                        )}`,
                      ),
                      count: prevCalculatedData.count - 1,
                      invalidCount:
                        prevCalculatedData.invalidCount -
                        (isValueAvailableBeforeEdit
                          ? isValueValidBeforeEdit
                            ? 0
                            : 1
                          : 0),
                    },
                  });

                  isUpdateRequired =
                    isUpdateRequired ||
                    !isEqual(
                      updatedSplitByData[splitByCellPrevVal],
                      currSplitByData[splitByCellPrevVal],
                    );
                }
                if (!isNil(splitByCellNewVal)) {
                  const prevCalculatedData =
                    getPrevSplitByCalculatedData(splitByCellNewVal);

                  Object.assign(updatedSplitByData, {
                    [splitByCellNewVal]: {
                      total: solveEqnStr(
                        `${prevCalculatedData.total}+${Number(
                          isValueValidAfterEdit ? dashboardColCellNewVal : 0,
                        )}`,
                      ),
                      count: prevCalculatedData.count + 1,
                      invalidCount:
                        prevCalculatedData.invalidCount +
                        (isValueAvailableAfterEdit
                          ? isValueValidAfterEdit
                            ? 0
                            : 1
                          : 0),
                    },
                  });

                  isUpdateRequired =
                    isUpdateRequired ||
                    !isEqual(
                      updatedSplitByData[splitByCellNewVal],
                      currSplitByData[splitByCellNewVal],
                    );
                }
              }
            });
            const finalSplitByData = omitBy(
              Object.assign({}, currSplitByData, updatedSplitByData),
              (val) => val?.count === 0,
            );
            if (isUpdateRequired) {
              addToSplitByCalculationVariable(finalSplitByData);
              updateSplitByDashboard(
                finalSplitByData,
                dashboardId,
                dashboardMeta,
                activeDocumentId,
              );
            }
          } else {
            //dashboard without split-by
            if (!columnAvailable) {
              updateDashboardFirestore(dashboardId, dashboardMeta.uid, {
                [`pages.${activeDocumentId}.val`]:
                  DASHBOARD_ERRORS.DASHBOARD_COLUMN_NOT_FOUND,
              }); //continue next iteration
            } else if (totalChanged || countChanged || invalidCountChanged) {
              calculateDashboardValueAndUpdateToFirestore(
                totalChanged,
                countChanged,
                invalidCountChanged,
                dashboardMeta,
                newFooterData[columnId],
                dashboardId,
                activeDocumentId,
              );
            }
          }
        }
      });
    });
    return !isEmpty(splitByUpdateObj) || isSplitByCalculationEmpty
      ? DocumentsMethods.updateFooterPropertiesData(
          activeDocumentId,
          isSplitByCalculationEmpty
            ? updatedSplitByCalculation
            : splitByUpdateObj,
          !isSplitByCalculationEmpty,
        )
      : Promise.resolve();
  } catch (error) {
    captureError(error);
  }
};

const checkAndUpdateUndoRedoDashboards = async (
  prevFooterData,
  newFooterData,
  prevSplitByCalculation,
  newSplitByCalculation,
  docId,
  activeDocumentMeta,
  headerData,
) => {
  //dashboards calculations and updation for undo-redo
  try {
    if (!isEmpty(activeDocumentMeta?.dashboards)) {
      const withoutSplitDashboardsMightUpdate =
        isPlainObject(newFooterData) && !isEqual(newFooterData, prevFooterData);
      const withSplitDashboardsMightUpdate =
        isPlainObject(newSplitByCalculation) &&
        !isEqual(prevSplitByCalculation, newSplitByCalculation);

      let updateSplitByCalculation = false;
      const splitByUpdateObj = {};
      forOwn(activeDocumentMeta.dashboards, (dashboardColumnData, columnId) => {
        const columnAvailable = isArray(headerData)
          ? headerData.some((obj) => `${obj?.id}` === `${columnId}`)
          : true;
        forOwn(dashboardColumnData, (dashboardMeta, dashboardId) => {
          if (!isEmpty(dashboardMeta) && dashboardMeta.isV2Dashboard) {
            const isSplitBy =
              isArray(dashboardMeta.splitBy) && dashboardMeta.splitBy.length;
            if (isSplitBy) {
              //dashboard with split
              const splitByColumnObj = Object.assign(
                {},
                dashboardMeta.splitBy[0],
              );
              const splitByColumnAvailable = !isEmpty(splitByColumnObj)
                ? isArray(headerData)
                  ? headerData.some(
                      (obj) => `${obj?.id}` === `${splitByColumnObj?.id}`,
                    )
                  : true
                : false;
              if (!columnAvailable || !splitByColumnAvailable) {
                updateDashboardFirestore(dashboardId, dashboardMeta.uid, {
                  [`pages.${docId}.splitByVal`]: {
                    val: DASHBOARD_ERRORS.DASHBOARD_COLUMN_NOT_FOUND,
                  },
                });
                Object.assign(splitByUpdateObj, {
                  [`splitByCalculation.${columnId}.${splitByColumnObj.id}`]: {},
                });
                updateSplitByCalculation = true;
                return;
              }
              if (!withSplitDashboardsMightUpdate) {
                //putting this condition here after checking that header might have deleted
                return;
              }
              if (
                isPlainObject(
                  newSplitByCalculation[columnId]?.[splitByColumnObj.id],
                ) &&
                !isEqual(
                  prevSplitByCalculation?.[columnId]?.[splitByColumnObj.id],
                  newSplitByCalculation[columnId][splitByColumnObj.id],
                )
              ) {
                //checking if split by calculations changed (sure)
                updateSplitByDashboard(
                  newSplitByCalculation[columnId][splitByColumnObj.id],
                  dashboardId,
                  dashboardMeta,
                  docId,
                );
                Object.assign(splitByUpdateObj, {
                  [`splitByCalculation.${columnId}.${splitByColumnObj.id}`]:
                    newSplitByCalculation[columnId]?.[splitByColumnObj.id],
                });
                updateSplitByCalculation = true;
              }
            } else {
              //dashboard without split
              if (!columnAvailable) {
                updateDashboardFirestore(dashboardId, dashboardMeta.uid, {
                  [`pages.${docId}.val`]:
                    DASHBOARD_ERRORS.DASHBOARD_COLUMN_NOT_FOUND,
                });
                return; //continue next iteration
              }
              if (
                !withoutSplitDashboardsMightUpdate ||
                isEmpty(newFooterData[columnId])
              ) {
                return;
              }
              const totalChanged = !isEqual(
                prevFooterData?.[columnId]?.total,
                newFooterData[columnId]?.total,
              );
              const countChanged = !isEqual(
                prevFooterData?.[columnId]?.count,
                newFooterData[columnId]?.count,
              );
              const invalidCountChanged = !isEqual(
                prevFooterData?.[columnId]?.invalidCount,
                newFooterData[columnId]?.invalidCount,
              );
              calculateDashboardValueAndUpdateToFirestore(
                totalChanged,
                countChanged,
                invalidCountChanged,
                dashboardMeta,
                newFooterData[columnId],
                dashboardId,
                docId,
              );
            }
          }
        });
      });
      return updateSplitByCalculation
        ? DocumentsMethods.updateFooterPropertiesData(docId, splitByUpdateObj)
        : Promise.resolve();
    }
  } catch (error) {
    captureError(error);
  }
};

const checkIfColumnHasWithoutSplitByDashboard = (colId, dashboards) => {
  try {
    return (
      !isEmpty(dashboards?.[colId]) &&
      Object.values(dashboards[colId]).some(
        (dashboardData) =>
          !isEmpty(dashboardData) &&
          !(isArray(dashboardData.splitBy) && dashboardData.splitBy.length),
      )
    );
  } catch {
    return true;
  }
};

const updateAllDashboardsProperty = (dashboards, updateObj) => {
  //update some property of all dashboards available on the active document
  //example: updateObj = {[`pages.${docId}.val`]: DASHBOARD_ERRORS.DASHBOARD_COLUMN_NOT_FOUND}
  try {
    forOwn(dashboards, (dashboardColumnData) => {
      forOwn(dashboardColumnData, (dashboardMeta, dashboardId) => {
        if (!isEmpty(dashboardMeta) && dashboardMeta.isV2Dashboard) {
          updateDashboardFirestore(dashboardId, dashboardMeta.uid, updateObj);
        }
      });
    });
  } catch (error) {
    captureError(error);
  }
};

const updateDashboardFirestore = async (
  dashboardId,
  uid,
  updateObj,
  isMerge = false,
) => {
  //to update fields in dashboard document (uid/user/dashboards/dashboardId)
  try {
    const docRef = firestore().collection('dashboards').doc(dashboardId);
    updateObj = Object.assign({}, updateObj, {
      updatedAt: firestore(true).FieldValue.serverTimestamp(),
    });
    if (isMerge) {
      docRef.set(updateObj, {merge: true});
    } else {
      docRef.update(updateObj);
    }
  } catch (error) {
    captureError(error);
  }
};

const calculateDataForDashboardHelper = (
  {tableData, headerData, fileObj},
  {columnObj, selectedOperationVal, singleSplitColumnObj},
  {userPref, userCountry},
  isCumulativeValue = false,
) => {
  //this calculates realtime data for sample dashboard card
  const {formatFooterCell} = require('../../utils/formatDataHelper');
  const {getPrintDate} = require('../../utils/utils');
  const isSplitBy = !isNil(singleSplitColumnObj?.id);
  const getFormattedVal = (totalObj, operation = selectedOperationVal) =>
    formatFooterCell(
      {
        val: getOperationValue(operation, totalObj),
        type: operation,
      },
      columnObj,
      userPref,
      userCountry,
      Object.assign({}, fileObj),
    );
  let displayVal;
  if (isSplitBy) {
    displayVal = [];
    const splitByObj = {
      id: singleSplitColumnObj.id,
      fieldType:
        singleSplitColumnObj.fieldType === FIELD_TYPE_ID.TABLE
          ? singleSplitColumnObj.subType
          : singleSplitColumnObj.fieldType,
    };
    const splitByTotalObj = calculateSplitByTotal(
      tableData,
      columnObj.id,
      splitByObj,
    );
    let cumulativeVal = 0;
    forOwn(splitByTotalObj, (totalObj, key) => {
      const title =
        splitByObj.fieldType === FIELD_TYPE_ID.DATE ? getPrintDate(key) : key;
      if (isCumulativeValue) {
        const formattedVal = getOperationValue(selectedOperationVal, totalObj);
        cumulativeVal = solveEqnStr(
          `${cumulativeVal}+${!isNaN(formattedVal) ? formattedVal : 0}`,
        );
      } else {
        displayVal.push({title, value: getFormattedVal(totalObj)});
      }
    });
    displayVal = isCumulativeValue
      ? getFormattedVal(
          {
            total: cumulativeVal,
            count: Object.keys(splitByTotalObj).length,
            invalidCount: 0,
          },
          selectedOperationVal !== FOOTER_OPERATION_TYPES.AVERAGE
            ? FOOTER_OPERATION_TYPES.TOTAL
            : selectedOperationVal,
        )
      : processDashboardSplitByDisplayData(displayVal, splitByObj);
  } else {
    const totalObj = calculateTotal(tableData, columnObj.id, headerData);
    displayVal = getFormattedVal(totalObj);
  }
  return displayVal;
};

const isDashboardSuggestionAvailableForFile = (
  file,
  dashboardTemplateSuggestions,
) => {
  return !isNil(file?.documentMeta?.fileData?.templateAnalyticsName) &&
    isArray(
      dashboardTemplateSuggestions?.[
        file.documentMeta.fileData.templateAnalyticsName
      ],
    ) &&
    ![SHARE_PERMISSION_TYPE.ENTRY_ONLY, SHARE_PERMISSION_TYPE.CUSTOM].includes(
      file.documentMeta.collab?.permission,
    )
    ? true
    : false;
};

const fetchDashboardTemplateSuggestionFirestore = async (templateId) => {
  try {
    const docRef = firestore()
      .collection('dashboardTemplateSuggestions')
      .doc(templateId);
    const snap = await docRef.get();
    if (snap.exists) {
      return snap.data().suggestions;
    }
    return [];
  } catch (err) {
    captureError(err);
    return [];
  }
};

let globalIndex = 0;
const buildChildDashboardsArrayObject = ({
  selectedFileDetails,
  selectedColumnId,
  selectedOperation,
  splitBy,
}) => {
  try {
    const {documentId, documentMeta} = selectedFileDetails.selectedFile;
    return {
      id: `custom-key-${globalIndex++}`,
      selectedFileDetails,
      columnId: selectedColumnId,
      columnName:
        (selectedFileDetails.fileData.headerData ?? []).find(
          ({id}) => `${id}` === `${selectedColumnId}`,
        )?.val ?? 'N/A',
      splitBy: Array.isArray(splitBy) ? splitBy : [],
      docId: documentId,
      operation: selectedOperation,
      docName: documentMeta.name,
      currentPageName:
        documentMeta.pageObj?.enabled && documentMeta.pages?.length
          ? documentMeta.pages.find(({id}) => id === documentId)?.pageName
          : null,
      calculatedVal: '0',
    };
  } catch (err) {
    captureError(err);
  }
};

const processFetchedDashboardConfig = (dashboardConfig) => {
  if (!isEmpty(dashboardConfig)) {
    if (Array.isArray(dashboardConfig.filters)) {
      dashboardConfig.filters = mapFilterArrFromFirestore(
        dashboardConfig.filters,
      );
    }
    if (!isEmpty(dashboardConfig.screenDetails)) {
      forOwn(dashboardConfig.screenDetails, (screenDetailsObj) => {
        if (Array.isArray(screenDetailsObj?.columnDetailsArr)) {
          screenDetailsObj.columnDetailsArr.forEach((columnDetailsObj) => {
            if (Array.isArray(columnDetailsObj?.filters)) {
              columnDetailsObj.filters = mapFilterArrFromFirestore(
                columnDetailsObj.filters,
              );
            }
          });
        }
        if (Array.isArray(screenDetailsObj?.filters)) {
          screenDetailsObj.filters = mapFilterArrFromFirestore(
            screenDetailsObj.filters,
          );
        }
      });
    }
  }
  return dashboardConfig;
};

const getValuesFromSplitByObj = (obj) =>
  pick(Object.assign({}, obj), ['id', 'val', 'fieldType', 'subType']);

export {
  createNewDashboardHelper,
  sortPagesForDashboards,
  updateDashboardFirestore,
  checkAndUpdateDashboard,
  checkAndUpdateDashboardForColumn,
  deleteDashboardHelper,
  migrateUserOlderDashboardsCloud,
  updateSplitByDashboard,
  checkAndUpdateUndoRedoDashboards,
  processDashboardSplitByDisplayData,
  processFetchedDashboardConfig,
  updateAllDashboardsProperty,
  checkIfColumnHasWithoutSplitByDashboard,
  getDashboardFormattedVal,
  getLabelAsSortedString,
  calculateDataForDashboardHelper,
  isDashboardSuggestionAvailableForFile,
  fetchDashboardTemplateSuggestionFirestore,
  buildChildDashboardsArrayObject,
  getValuesFromSplitByObj,
};
