import {isNil, omit, pick} from 'lodash';
import {LIST_COLUMN_ACTION} from '../actions/actionType';
import {
  getKeyForParentDocRowMap,
  getListColumnData,
  rowOperations,
} from '../actions/actionHelpers/listColumnsActionHelper';

const initialState = {
  isLoading: {},
  activeParentDocId: '',
  activeSublistScreenId: '',
  activeParentScreenId: '',
  activeParentRowId: '',
  activeListColId: '',
  dataMap: {},
  orderMap: {},
  path: [],
  root: {},
};

const listColumnReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_COLUMN_ACTION.UPDATE_LOADING_SUBLIST_DATA: {
      const {instanceId, isDataLoading} = action.payload;
      let newIsLoadingObj = Object.assign({}, state.isLoading);
      if (isDataLoading) {
        newIsLoadingObj = Object.assign({}, newIsLoadingObj, {
          [instanceId]: true,
        });
      } else {
        newIsLoadingObj = Object.assign(
          {},
          omit(newIsLoadingObj, [instanceId]),
        );
      }
      return {
        ...state,
        isLoading: newIsLoadingObj,
      };
    }
    case LIST_COLUMN_ACTION.UPDATE_ACTIVE_SUBLIST_SCREEN: {
      return {
        ...state,
        activeSublistScreenId: action?.payload?.sublistScreenId,
      };
    }
    case LIST_COLUMN_ACTION.UPDATE_ACTIVE_PARENT_SCREEN: {
      return {
        ...state,
        activeParentScreenId: action?.payload?.parentScreenId,
      };
    }
    case LIST_COLUMN_ACTION.UPDATE_ACTIVE_PARENT_ROW: {
      return {
        ...state,
        activeParentRowId: action?.payload?.rowId,
      };
    }
    case LIST_COLUMN_ACTION.LOAD_SUBLIST_DATA: {
      const {
        listColumnColId = '',
        activeListColumnMeta = {},
        tableData,
        rowIdDataMap = {},
        originalRowIdDataMap = {},
        rowIdOpsMapping = {},
        documentData = {},
        activeParentScreenId,
        activeParentRowId,
        activeSublistScreenId,
        activeParentDocId,
        doNotUpdateActiveListMeta,
        activeParentRowData,
      } = action?.payload ?? {};
      //TODO : MODIFY DATA ACCESSFROM documentData FOR MOBILE
      if (isNil(activeParentScreenId || isNil(activeParentRowId))) {
        throw new Error(
          "Didn't find activeParentScreenId or activeParentRowId",
        );
      }
      const parentDoc_rowId_key = getKeyForParentDocRowMap(
        activeParentDocId,
        activeParentRowId,
      );
      return {
        ...state,
        ...(!doNotUpdateActiveListMeta
          ? {
              activeListColId: listColumnColId,
              activeParentScreenId: activeParentScreenId,
              activeParentRowId: activeParentRowId,
              activeSublistScreenId: activeSublistScreenId,
              activeParentDocId: activeParentDocId,
            }
          : {}),
        dataMap: {
          ...state?.dataMap,
          [parentDoc_rowId_key]: {
            ...state?.dataMap?.[parentDoc_rowId_key],
            listColumnMapping: {
              ...state?.dataMap?.[parentDoc_rowId_key]?.listColumnMapping,
              [listColumnColId]: {
                listColumnMeta: Object.assign({}, activeListColumnMeta),
                sublistTableData: [...(tableData ?? [])],
                rowIdDataMap: Object.assign({}, rowIdDataMap),
                originalRowIdDataMap: Object.assign({}, originalRowIdDataMap),
                rowIdOpsMapping: Object.assign({}, rowIdOpsMapping),
                documentData: Object.assign({}, documentData),
              },
            },
          },
        },
        ...(Array.isArray(state.path) && state.path.length === 0
          ? {root: activeParentRowData}
          : {}),
        // ^root should only be updated when in
        // from level 0 to level 1
      };
    }
    case LIST_COLUMN_ACTION.ADD_ROW: {
      const {
        activeParentScreenId,
        activeParentRowId,
        activeListColId,
        activeParentDocId,
      } = state;
      const {
        sublistTableData = [],
        rowIdDataMap = {},
        rowIdOpsMapping = {},
      } = getListColumnData(
        state,
        activeParentScreenId,
        activeParentRowId,
        activeListColId,
        activeParentDocId,
      );

      const {rowId, rowData, updatedRoot = []} = action?.payload ?? {};
      const parentDoc_rowId_key = getKeyForParentDocRowMap(
        activeParentDocId,
        activeParentRowId,
      );
      return {
        ...state,
        dataMap: {
          ...state?.dataMap,
          [parentDoc_rowId_key]: {
            ...state?.dataMap?.[parentDoc_rowId_key],
            listColumnMapping: {
              ...state?.dataMap?.[parentDoc_rowId_key]?.listColumnMapping,
              [activeListColId]: {
                ...(state?.dataMap?.[parentDoc_rowId_key]?.listColumnMapping?.[
                  activeListColId
                ] ?? {}),
                sublistTableData: [rowId, ...sublistTableData],
                rowIdDataMap: Object.assign({}, rowIdDataMap ?? {}, {
                  [rowId]: rowData,
                }),
                rowIdOpsMapping: Object.assign({}, rowIdOpsMapping ?? {}, {
                  [rowId]: rowOperations.ADD,
                }),
              },
            },
          },
        },
        ...(!isNil(updatedRoot) ? {root: updatedRoot} : {}),
      };
    }
    case LIST_COLUMN_ACTION.EDIT_ROW: {
      const {
        activeParentScreenId,
        activeParentRowId,
        activeListColId,
        activeParentDocId,
      } = state;
      const {rowIdDataMap = {}, rowIdOpsMapping = {}} = getListColumnData(
        state,
        activeParentScreenId,
        activeParentRowId,
        activeListColId,
        activeParentDocId,
      );

      const {rowId, rowData, updatedRoot = {}} = action?.payload ?? {};
      const parentDoc_rowId_key = getKeyForParentDocRowMap(
        activeParentDocId,
        activeParentRowId,
      );
      return {
        ...state,
        dataMap: {
          ...state?.dataMap,
          [parentDoc_rowId_key]: {
            ...state?.dataMap?.[parentDoc_rowId_key],
            listColumnMapping: {
              ...state?.dataMap?.[parentDoc_rowId_key]?.listColumnMapping,
              [activeListColId]: {
                ...(state?.dataMap?.[parentDoc_rowId_key]?.listColumnMapping?.[
                  activeListColId
                ] ?? {}),
                rowIdDataMap: Object.assign({}, rowIdDataMap ?? {}, {
                  [rowId]: rowData,
                }),
                rowIdOpsMapping: Object.assign({}, rowIdOpsMapping ?? {}, {
                  [rowId]:
                    rowIdOpsMapping?.[rowId] === rowOperations.ADD
                      ? rowOperations.ADD
                      : rowOperations.EDIT,
                }),
              },
            },
          },
        },
        ...(!isNil(updatedRoot) ? {root: updatedRoot} : {}),
      };
    }
    case LIST_COLUMN_ACTION.DELETE_ROW: {
      const {activeParentScreenId} = state;
      let {activeParentRowId, activeListColId, activeParentDocId} = state;

      const {options, extra} = action?.payload ?? {};

      if (options?.forceSkipActiveStatesCheck) {
        const {parentDocId, parentRowId, listColId} = extra;
        activeListColId = listColId;
        activeParentDocId = parentDocId;
        activeParentRowId = parentRowId;
      }

      const {
        sublistTableData = [],
        rowIdDataMap = {},
        rowIdOpsMapping = {},
      } = getListColumnData(
        state,
        activeParentScreenId,
        activeParentRowId,
        activeListColId,
        activeParentDocId,
      );

      const {rowId, updatedRoot = {}} = action?.payload ?? {};
      const isNewAddedRow = rowIdOpsMapping?.[rowId] === rowOperations.ADD;
      const parentDoc_rowId_key = getKeyForParentDocRowMap(
        activeParentDocId,
        activeParentRowId,
      );
      return {
        ...state,
        dataMap: {
          ...state?.dataMap,
          [parentDoc_rowId_key]: {
            ...state?.dataMap?.[parentDoc_rowId_key],
            listColumnMapping: {
              ...state?.dataMap?.[parentDoc_rowId_key]?.listColumnMapping,
              [activeListColId]: {
                ...(state?.dataMap?.[parentDoc_rowId_key]?.listColumnMapping?.[
                  activeListColId
                ] ?? {}),
                rowIdDataMap: Object.assign(
                  {},
                  omit(rowIdDataMap ?? {}, [rowId]),
                ),
                sublistTableData: [
                  ...sublistTableData.filter((element) => element !== rowId),
                ],
                rowIdOpsMapping: isNewAddedRow
                  ? omit(rowIdOpsMapping, [rowId])
                  : Object.assign({}, rowIdOpsMapping ?? {}, {
                      [rowId]: rowOperations.DELETE,
                    }),
              },
            },
          },
        },
        ...(!isNil(updatedRoot) ? {root: updatedRoot} : {}),
      };
    }
    case LIST_COLUMN_ACTION.RESET_LIST_COLUMN_STATE: {
      return initialState;
    }
    case LIST_COLUMN_ACTION.UPDATE_ACTIVE_META: {
      return {
        ...state,
        ...pick(action.payload, [
          'activeListColId',
          'activeParentScreenId',
          'activeParentRowId',
          'activeSublistScreenId',
          'activeParentDocId',
        ]),
      };
    }
    case LIST_COLUMN_ACTION.UPDATE_ORDER_MAP_AND_PATH: {
      const {path, orderMap} = action?.payload ?? {};
      return {
        ...state,
        path,
        orderMap,
      };
    }
    case LIST_COLUMN_ACTION.UPDATE_MULTIPLE_LIST_COLUMNS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default listColumnReducer;
