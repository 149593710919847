import moment from 'moment';
import {
  COLOR_CODE_FOR_FILES,
  DEFAULT_FILES_SORT,
  PAGE_TYPE,
  SHARED_DOC_META_FIELDS,
  REGISTERBOOK_DEVELOPMENT_COLLECTIONS,
  CLOUD_FUNCTION_PATHS,
  ARCH_V2_FLAGS,
  SHARE_PERMISSION_TYPE,
  ROOT_FOLDER,
} from '../../utils/constant';
import {isEmpty, omit, forOwn} from 'lodash';
import {
  firestore,
  captureError,
  getDeviceVersion,
  captureInfo,
} from '../../imports';
import {functions} from 'rb-redux/imports';
import {firestoreServerTimestamp, handleCloudError} from '../../utils/utils';

import FirestoreDB from '../../FirestoreHandlers/FirestoreDB';
import UsersMethods from '../../FirestoreHandlers/Users/UsersMethods';
import TemplatesMethods from '../../FirestoreHandlers/Templates/TemplatesMethods';

const handleGetRef = (ref) => {
  return new Promise((resolve) => {
    if (ref.get) {
      ref
        .get()
        .then((snap) => {
          resolve(snap.exists ? snap.data() : {});
        })
        .catch((error) => {
          captureError(error);
          resolve({});
        });
    } else {
      resolve({});
    }
  });
};

const checkIfUserHasDocAccessAndFetchMeta = (documentId, uid) => {
  try {
    return Promise.all([
      handleGetRef(FirestoreDB.users.userDocumentsMetaRef(uid, documentId)),
      handleGetRef(FirestoreDB.users.userSharedDocsMetaRef(uid, documentId)),
    ]).then((results) => {
      const [docMetaPromise, sharedDocMetaPromise] = results;

      const docMeta = docMetaPromise;
      const sharedDocMeta = sharedDocMetaPromise;

      const returnObject = !isEmpty(sharedDocMeta) ? sharedDocMeta : docMeta;

      const ALLOWED_PERMISSIONS = [
        SHARE_PERMISSION_TYPE.ADMIN,
        SHARE_PERMISSION_TYPE.CAN_EDIT,
        SHARE_PERMISSION_TYPE.OWNER,
        SHARE_PERMISSION_TYPE.CAN_VIEW,
        SHARE_PERMISSION_TYPE.CUSTOM,
        SHARE_PERMISSION_TYPE.ENTRY_ONLY,
      ];

      const collabCheck = !isEmpty(returnObject?.collab)
        ? ALLOWED_PERMISSIONS.includes(returnObject?.collab?.permission)
        : true;

      if (isEmpty(returnObject) && !collabCheck) {
        return {};
      }

      if (isEmpty(returnObject?.collab)) {
        return returnObject;
      } else {
        return handleGetRef(
          FirestoreDB.users.sharedDocumentsMetaRef(documentId),
        ).then((data) => Object.assign({}, data, returnObject));
      }
    });
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

const fetchPersonalCategoriesCall = () => {
  try {
    return firestore()
      .collection('masterCategories')
      .where('isPersonal', '==', true)
      .get();
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const fetchGreetingsCategoriesCall = (lang) => {
  try {
    return firestore()
      .collection(`greetings_${lang}`)
      .where('isVisible', '==', true)
      .orderBy('priority');
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const fetchSharedDocsMeta = (docId, extraOptions = {}) => {
  try {
    return firestore()
      .collection('sharedDocsMeta')
      .doc(docId)
      .get(extraOptions);
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const fetchUserTemplateDocumentData = (uid, templateId) => {
  try {
    return UsersMethods.fetchUserTemplatesDocumentData(uid, templateId);
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const setUserTemplateDocumentData = (uid, templateId, obj, merge = true) => {
  try {
    return UsersMethods.setUserTemplateDocumentData(
      uid,
      templateId,
      obj,
      merge,
    );
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const setTemplateDocumentData = (templateId, obj, merge = true) => {
  try {
    return TemplatesMethods.setTemplateDocumentData(templateId, obj, merge);
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const fetchWhatsNewData = () => {
  try {
    return firestore().collection('whatsNew').get();
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const fetchHelpDeskVideosData = () => {
  try {
    return firestore().collection('helpDeskVideos').get();
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const fetchBusinessTypesData = () => {
  try {
    return firestore().collection('dynamicData').doc('businessTypes').get();
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const getUsersSubCollectionDataWithQuery = (
  uid,
  collectionname,
  options = {},
) => {
  const {firestoreInstance = null, returnRef = false} = options;
  try {
    return returnRef
      ? FirestoreDB.users.usersSubCollectionDataWithQueryRef(
          uid,
          collectionname,
          firestoreInstance,
        )
      : UsersMethods.getUserSubCollectionDataWithQuery(
          uid,
          collectionname,
          firestoreInstance,
        );
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const getUserTemplatesData = (uid) => {
  try {
    return getUsersSubCollectionDataWithQuery(uid, 'templates_ARCH_V2');
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const fetchCommunitiesData = () => {
  try {
    return firestore()
      .collection(REGISTERBOOK_DEVELOPMENT_COLLECTIONS.communities)
      .get();
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const fetchGreetingsListWithLanguage = (
  greeting_id,
  lang,
  returnRef = false,
) => {
  try {
    const ref = firestore()
      .collection(`greetings_${lang}`)
      .doc(greeting_id)
      .collection('greetingImages')
      .where('isVisible', '==', true)
      .orderBy('priority');
    return returnRef ? ref : ref.get();
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const setFileDetails = (...args) => {
  /**
   * pass only those keys/fields(along with collab field) which needs to be updated
   * [//!IMP: collab key should always be passed (as it checks for collection in which file is present)]
   */
  const [uid, docId, fileObj, isUpdate = true] = args;
  try {
    let updateObj;
    const isShared = !isEmpty(fileObj?.collab);
    const collectionName =
      isShared && !fileObj.collab.isOwner ? 'sharedDocs' : 'documents';
    if (isShared) {
      //omit all keys which will be present in sharedDocsMeta for a collab doc
      updateObj = omit(Object.assign({}, fileObj), SHARED_DOC_META_FIELDS);
    } else {
      //omit collab key for a non-shared doc
      updateObj = omit(Object.assign({}, fileObj), ['collab']);
    }
    if (isUpdate) {
      //omit collab key for update query
      updateObj = omit(updateObj, ['collab']);
    }
    if (isEmpty(updateObj)) {
      return;
    }
    const docRef = firestore()
      .collection('users')
      .doc(uid)
      .collection(collectionName)
      .doc(docId);
    if (isUpdate) {
      docRef.update(updateObj).catch((error) => {
        captureInfo({args});
        captureError(error);
      });
    } else {
      docRef.set(updateObj);
    }
  } catch (error) {
    captureInfo({args});
    captureError(error);
  }
};

const setSharedDocMeta = (docId, fileObj) => {
  const updateObj = {};
  forOwn(Object.assign({}, fileObj), (val, key) => {
    if (SHARED_DOC_META_FIELDS.includes(key)) {
      //allow only keys available in SHARED_DOC_META_FIELDS
      updateObj[key] = val;
    }
  });
  try {
    firestore()
      .collection('sharedDocsMeta')
      .doc(docId)
      .set(updateObj, {merge: true});
  } catch (error) {
    captureError(error);
  }
};

const setSampleFileDetails = async (lang, docId, fileObj) => {
  try {
    const obj = {};
    obj[docId] = {
      fileMeta: fileObj,
    };
    await firestore()
      .collection('sampleFiles')
      .doc(lang)
      .set(obj, {merge: true});
  } catch (error) {
    captureError(error);
  }
};

const addToUserDefinedUnits = (uid, arrayElement) => {
  try {
    const updateObj = {
      userDefinedUnits: firestore(true).FieldValue.arrayUnion(arrayElement),
    };
    firestore().collection('users').doc(uid).update(updateObj);
  } catch (error) {
    captureError(error);
  }
};

const markUserStoryAsViewed = (uid, key) => {
  try {
    firestore()
      .collection('users')
      .doc(uid)
      .update({
        [`userStories.${key}.isViewed`]: true,
      });
  } catch (error) {
    captureError(error);
  }
};

const removeUserStoriesFromFirebase = (updatedUserStoryObj, uid) => {
  try {
    firestore().collection('users').doc(uid).update(updatedUserStoryObj);
  } catch (error) {
    captureError(error);
  }
};

const replaceUserDefinedUnits = (uid, userDefinedUnits) => {
  try {
    firestore()
      .collection('users')
      .doc(uid)
      .set({userDefinedUnits}, {merge: true});
  } catch (error) {
    captureError(error);
  }
};

const removeFromUserDefinedUnits = (uid, arrayElement) => {
  try {
    const updateObj = {
      userDefinedUnits: firestore(true).FieldValue.arrayRemove(arrayElement),
    };
    firestore().collection('users').doc(uid).update(updateObj);
  } catch (error) {
    captureError(error);
  }
};

const generateNewFileMeta = (
  fileName,
  uid,
  parent,
  isDuplicate = false,
  oldMeta = null,
) => {
  const timestamp = moment().unix();
  const docId = `${uid}_${moment().valueOf()}`;
  const colorIndex = Math.floor(Math.random() * 9);
  const colorCode = COLOR_CODE_FOR_FILES[colorIndex];
  parent = parent ?? ROOT_FOLDER;

  // Comman meta for NEW and DUPLICATE files
  const commonMeta = {
    name: fileName,
    timestamp: timestamp,
    colorCode,
    viewType: 'table',
    fileData: {},
    isDocumentLock: false,
    parent,
    version: getDeviceVersion(),
    isARCH_V2: true,
    minSupportedVersion: ARCH_V2_FLAGS.minSupportedVersion,
  };

  if (isDuplicate) {
    // DUPLICATE file extra meta
    return {
      docId,
      meta: Object.assign({}, commonMeta, {
        linkedDocIds: oldMeta?.linkedDocIds || [],
        minSupportedVersion: ARCH_V2_FLAGS.minSupportedVersion,
      }),
    };
  } else {
    // NEW file extra meta
    const lastVersionServerTimestamp = firestoreServerTimestamp();
    return {
      docId,
      meta: Object.assign({}, commonMeta, {
        lastModifiedTimestamp: timestamp,
        lastOpenedTimestamp: timestamp,
        lastVersionServerTimestamp,
        minSupportedVersion: ARCH_V2_FLAGS.minSupportedVersion,
      }),
    };
  }
};

const generateNewFolderMeta = (fileName, uid, parent) => {
  const timestamp = moment().unix();
  const folderId = `${uid}_${moment().valueOf()}`;

  return {
    folderId,
    meta: {
      name: fileName,
      timestamp: timestamp,
      isFolder: true,
      parent,
      sortBy: DEFAULT_FILES_SORT,
    },
  };
};

const getPageNameStr = (filePageType) => {
  let pageNameStr;

  if (filePageType === PAGE_TYPE.DAILY) {
    pageNameStr = moment().format('D MMM YY');
  } else if (filePageType === PAGE_TYPE.WEEKLY) {
    pageNameStr = `${moment().startOf('week').format('D MMM')} - ${moment()
      .endOf('week')
      .format('D MMM YY')}`;
  } else if (filePageType === PAGE_TYPE.MONTHLY) {
    pageNameStr = moment().format('MMM YY');
  } else if (filePageType === PAGE_TYPE.CLIENT) {
    pageNameStr = 'Page 1';
  }

  return pageNameStr;
};

const checkParticipantVersion = async (docId) => {
  const response = await functions().httpsCallable(
    CLOUD_FUNCTION_PATHS.CHECK_PARTICIPANT_APP_VERSION,
  )({
    docId,
  });
  if (response?.data?.success && response.data.failedDueToVersionArr) {
    return response.data.failedDueToVersionArr;
  } else {
    return [];
  }
};

const callOnBoardingAutoCreation = async (obj) => {
  try {
    const response = await functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.ON_BOARDING_AUTO_CREATE,
    )(obj);
    return response.data;
  } catch (error) {
    handleCloudError(error);
    return {};
  }
};

const deleteFileCloud = async (obj) => {
  try {
    const response = await functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.DELETE_FILES_FOLDERS_AND_MOVE_TO_TRASH,
    )(obj);
    return response.data;
  } catch {
    return {
      success: false,
    };
  }
};

const manageDuplicateFile = async (obj) => {
  try {
    const response = await functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.MANAGE_DUPLICATE_FILE,
    )(obj);
    return response.data;
  } catch {
    return {
      success: false,
    };
  }
};

const migrateDocumentToArchV2 = async (obj) => {
  try {
    const response = await functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.MIGRATE_DOC_TO_ARCH_V2,
      {
        timeout: 500000, // its in milliseconds and need 500 seconds
      },
    )(obj);
    return response.data;
  } catch {
    return {
      success: false,
    };
  }
};
/**
 * Function to updated shared keys in all user's in `/users/<uid>/sharedDocs/<docId>`, including owner.
 * @param {{
 * originalDocId: string;
 * updateKeys: string;
 * }} obj - An object of `originalDocId, updateKeys`
 * @returns
 */
const updateSharedFileMetaKeyValues = async (obj) => {
  try {
    const response = await functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.UPDATE_SHARED_FILE_META_KEY_VALUES,
    )(obj);
    return response.data;
  } catch {
    return {
      success: false,
    };
  }
};

const migrateMyTemplatesFunction = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.MIGRATE_USER_OLDER_TEMPLATES,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

export {
  addToUserDefinedUnits,
  migrateDocumentToArchV2,
  callOnBoardingAutoCreation,
  manageDuplicateFile,
  checkParticipantVersion,
  fetchBusinessTypesData,
  fetchCommunitiesData,
  fetchGreetingsCategoriesCall,
  fetchHelpDeskVideosData,
  fetchPersonalCategoriesCall,
  fetchSharedDocsMeta,
  fetchUserTemplateDocumentData,
  fetchWhatsNewData,
  generateNewFileMeta,
  generateNewFolderMeta,
  getPageNameStr,
  fetchGreetingsListWithLanguage,
  getUsersSubCollectionDataWithQuery,
  getUserTemplatesData,
  removeFromUserDefinedUnits,
  replaceUserDefinedUnits,
  setFileDetails,
  setSampleFileDetails,
  setSharedDocMeta,
  setTemplateDocumentData,
  setUserTemplateDocumentData,
  markUserStoryAsViewed,
  deleteFileCloud,
  removeUserStoriesFromFirebase,
  updateSharedFileMetaKeyValues,
  migrateMyTemplatesFunction,
  checkIfUserHasDocAccessAndFetchMeta,
};
