import {PREMIUM_REDUCER} from '../actions/actionType';
import {ASYNC_STORAGE_KEY} from '../utils/constant';
import {ENV, isAndroid, captureError, captureInfo, ShowToast} from '../imports';
import {
  getPremiumFeaturesList,
  getFreeTrialCloudFunction,
  getOfferingLocalisation,
  getPlanSharedWith,
  sharePlanWithContactCloudCall,
  removeContactFromPlanCloudCall,
} from './actionHelpers/premiumActionHelper';
import {
  FREE_TRIAL_ENTITLEMENT,
  PROMO_PREFIX,
  LAST_ACTIVE_PLAN,
  BUSINESS_PLAN_IDENTIFY_KEY,
  BUSINESS_PRO_PLAN_IDENTIFY_KEY,
  PLAN_MONTLY_IDENTYFY_KEY,
  PERSONAL_PLAN_IDENTIFY_KEY,
  PREMIUM_NEW_OFFERING,
  IS_SHARED_PLAN_IDENTIFY_KEY,
  PREMIUM_PLAN_TYPES,
  PLANS_PRIORITY,
  getPlanShareMemberCount,
} from '../utils/premium';
import {getLocalText} from '../utils/utils';
import {isEmpty, orderBy, isNil} from 'lodash';
import {setVisibleDashboardPremiumLimitingObj} from './dashboardAction.js';

const getUserEntitlements = (activePlan, options = {}) => {
  const {isUserHavingTrialSubscription = false, isOldPremium = () => false} =
    options ?? {};
  const activePlanStr = (activePlan ?? '').toLowerCase();

  let entitlements = [];
  let activePlanType = PREMIUM_PLAN_TYPES.NON_PREMIUM;

  if (
    isUserHavingTrialSubscription ||
    activePlanStr.includes(BUSINESS_PRO_PLAN_IDENTIFY_KEY.toLowerCase())
  ) {
    entitlements = PREMIUM_NEW_OFFERING.ENTITLEMENTS.businessPro;
    activePlanType = isUserHavingTrialSubscription
      ? PREMIUM_PLAN_TYPES.TRIAL
      : PREMIUM_PLAN_TYPES.BUSINESS_PRO;
  } else if (activePlanStr.includes(BUSINESS_PLAN_IDENTIFY_KEY)) {
    entitlements = PREMIUM_NEW_OFFERING.ENTITLEMENTS.business;
    activePlanType = PREMIUM_PLAN_TYPES.BUSINESS;
  } else if (activePlanStr.includes(PERSONAL_PLAN_IDENTIFY_KEY)) {
    entitlements = PREMIUM_NEW_OFFERING.ENTITLEMENTS.personal;
    activePlanType = PREMIUM_PLAN_TYPES.PERSONAL;
  } else {
    if (isOldPremium()) {
      activePlanType = PREMIUM_PLAN_TYPES.BUSINESS;
      entitlements = PREMIUM_NEW_OFFERING.ENTITLEMENTS.business;
    }
  }

  return {
    entitlements,
    activePlanType,
    isMonthly: activePlanStr.includes(PLAN_MONTLY_IDENTYFY_KEY),
  };
};

const loadSubscriptions =
  (subscriptionObj, options = {}) =>
  (dispatch, getState) => {
    try {
      const {
        remoteConfig: {isOrganisationMode},
      } = getState();

      subscriptionObj = subscriptionObj ?? {};
      const payload = {
        isUserSubscribed: false,
        isTrial: false,
        isEligibleForTrial: false,
        willRenew: false,
        subscriptions: [],
        subscriptionObj,
        lastActivePlan: null,
        isSharedUserPlan: false,
        activePlanType: PREMIUM_PLAN_TYPES.NON_PREMIUM,
        activeProductIdentifier: null,
        isMonthly: true,
        planShareMemberCount: 0,
      };

      const {fromWeb = false, forceSet = false} = Object.assign({}, options);

      if (isOrganisationMode) {
        if (forceSet !== true) {
          //if organisation mode then don't set premium data from revenuecat
          return payload;
        }
      }

      const activeEntitlements = Object.assign(
        {},
        subscriptionObj.entitlements?.active,
      );
      if (!isEmpty(activeEntitlements)) {
        //user is subscribed to a package or some promo entitlement i.e. some free trial
        let willRenew = false;
        let activePlan = null; //plan that is active (if on paid plan)
        let activeProductIdentifier = null; //product identifier of the active plan
        /**
         * Function - to check if old premium user for web only.
         * As the revenuecat entitlements are not dynamically updated on web.
         * Scenario:
         * 1. When auth "revenueCatEntitlements" are not having new premium plan entitlement.
         */
        const isOldPremium = () =>
          fromWeb
            ? Object.keys(activeEntitlements).some((entitlement) =>
                PREMIUM_NEW_OFFERING.ENTITLEMENTS.business.includes(
                  entitlement,
                ),
              )
            : false;
        const isUserHavingPaidSubscription =
          PLANS_PRIORITY.some((planIdentifyKey) =>
            Object.keys(activeEntitlements).some((entitlement) => {
              if (entitlement.includes(planIdentifyKey)) {
                activePlan = entitlement;
                activeProductIdentifier =
                  activeEntitlements[entitlement].productIdentifier;
                return true;
              }
              return false;
            }),
          ) || isOldPremium(); //user is subscribed to a paid package

        const isUserHavingTrialSubscription =
          !isUserHavingPaidSubscription &&
          FREE_TRIAL_ENTITLEMENT in activeEntitlements; //ignore trial subscription if user has paid subscription

        if (isUserHavingTrialSubscription) {
          activeProductIdentifier =
            activeEntitlements[FREE_TRIAL_ENTITLEMENT].productIdentifier;
        }

        if (isUserHavingPaidSubscription && activePlan) {
          //set latest expiry date of the pack based on current paid plan
          const latestExpirationDate =
            activeEntitlements[activePlan].expirationDate;

          if (latestExpirationDate) {
            Object.assign(subscriptionObj, {latestExpirationDate});
          }

          //will this subscription auto renew
          willRenew = activeEntitlements[activePlan].willRenew ? true : false;

          if (activePlan.includes(IS_SHARED_PLAN_IDENTIFY_KEY)) {
            Object.assign(payload, {isSharedUserPlan: true});
          }
        }

        const {
          entitlements: subscriptions,
          activePlanType,
          isMonthly,
        } = getUserEntitlements(activePlan, {
          isUserHavingTrialSubscription,
          isOldPremium: isOldPremium,
        });

        Object.assign(payload, {
          isUserSubscribed: isUserHavingPaidSubscription,
          isTrial: isUserHavingTrialSubscription,
          willRenew,
          subscriptions,
          activePlanType,
          activeProductIdentifier,
          isMonthly,
          planShareMemberCount: getPlanShareMemberCount(
            activeEntitlements,
            subscriptions,
            isUserHavingPaidSubscription,
            payload.isSharedUserPlan,
          ),
        });
      } else if (ENV) {
        const wasUserActiveOnSomePlan = subscriptionObj
          .allPurchasedProductIdentifiers?.length
          ? true
          : false;
        if (
          wasUserActiveOnSomePlan &&
          !isEmpty(subscriptionObj.allExpirationDates)
        ) {
          for (const packageId in subscriptionObj.allExpirationDates) {
            if (
              subscriptionObj.latestExpirationDate ===
              subscriptionObj.allExpirationDates[packageId]
            ) {
              Object.assign(payload, {
                lastActivePlan: packageId.startsWith(
                  `${PROMO_PREFIX}_${FREE_TRIAL_ENTITLEMENT}`,
                )
                  ? LAST_ACTIVE_PLAN.FREE
                  : LAST_ACTIVE_PLAN.PAID,
              });
            }
          }
        }
        Object.assign(payload, {
          isEligibleForTrial: !wasUserActiveOnSomePlan,
        });
      }
      dispatch({
        type: PREMIUM_REDUCER.LOAD_SUBSCRIPTIONS,
        payload,
      });
      if (ENV && isAndroid) {
        const {SharedPreferenceStorage} = require('../imports');
        SharedPreferenceStorage().set(
          ASYNC_STORAGE_KEY.REVENUE_CAT_SUBSCRIPTION_DATA,
          JSON.stringify(payload.subscriptions),
        );
      }
      dispatch(setVisibleDashboardPremiumLimitingObj());
      return payload.subscriptions ?? [];
    } catch (error) {
      captureError(error);
    }
    return [];
  };

const loadOfferings = (offeringsObj) => (dispatch) => {
  try {
    dispatch({
      type: PREMIUM_REDUCER.LOAD_OFFERINGS,
      payload: Object.assign({}, offeringsObj),
    });
  } catch (error) {
    captureError(error);
  }
};

const loadOfferingLocalisation = () => (dispatch) =>
  getOfferingLocalisation()
    .then((localisation) => {
      dispatch({
        type: PREMIUM_REDUCER.LOAD_OFFERINGS_LOCALISATION,
        payload: Object.assign({}, localisation),
      });
      return Promise.resolve();
    })
    .catch((error) => Promise.reject(error));

const loadFeaturesList = () => (dispatch) => {
  try {
    return getPremiumFeaturesList().then((res) => {
      dispatch({
        type: PREMIUM_REDUCER.LOAD_FEATURES_LIST,
        payload: res,
      });
    });
  } catch (error) {
    captureError(error);
  }
};

const loadPlanSharedWith =
  (premiumSharedWithObj = null) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {user, userPref},
      } = getState();
      const dataAvailable = !isNil(premiumSharedWithObj);
      if (!dataAvailable) {
        dispatch({type: PREMIUM_REDUCER.START_PLAN_SHARED_WITH_LOADING});
      }
      const dataToProcess = dataAvailable
        ? premiumSharedWithObj
        : await getPlanSharedWith(user.uid);
      const data = orderBy(
        Object.entries(Object.assign({}, dataToProcess)).map(
          ([key, val]) => {
            return Object.assign({}, val, {uid: key});
          },
          'timestamp',
          ['desc'],
        ),
      );
      const userOwnObj = {
        name: getLocalText(userPref, 'Me'),
        contact: user.phoneNumber || user.email,
        isOwn: true,
      };
      dispatch({
        type: PREMIUM_REDUCER.UPDATE_PLAN_SHARED_WITH_ARRAY,
        payload: [userOwnObj, ...data],
      });
    } catch (error) {
      captureError(error);
    }
  };

const sharePlanWithMember = (dataObj) => async (dispatch, getState) => {
  try {
    const {
      auth: {userPref, user},
    } = getState();

    Object.assign(dataObj, {ownerContact: user.phoneNumber || user.email});

    const data = await sharePlanWithContactCloudCall(dataObj);
    if (!data || !data.success) {
      let message;
      if (data?.message) {
        message = data.message;
      } else {
        message = 'Something went wrong, please try again';
      }
      if (data?.error) {
        captureInfo({log: data.log});
        captureError(new Error(data.error), true);
      }
      return {
        success: false,
        message,
      };
    }
    //success
    dispatch(loadPlanSharedWith(data.premiumSharedWith));
    ShowToast('Plan shared with member successfully.', userPref);
    return {
      success: true,
      data,
    };
  } catch (error) {
    captureError(error);
  }
};

const removeMemberFromPlan = (dataObj) => async (dispatch, getState) => {
  try {
    const {
      auth: {userPref},
    } = getState();
    const data = await removeContactFromPlanCloudCall(dataObj);
    if (!data || !data.success) {
      let message;
      if (data?.message) {
        message = data.message;
      } else {
        message = 'Something went wrong, please try again';
      }
      if (data?.error) {
        captureInfo({log: data.log});
        captureError(new Error(data.error), true);
      }
      return {
        success: false,
        message,
      };
    }
    //success
    dispatch(loadPlanSharedWith(data.premiumSharedWith));
    ShowToast('Member removed from plan successfully.', userPref);
    return {
      success: true,
    };
  } catch (error) {
    captureError(error);
  }
};

const requestFreeTrial = () => async (dispatch, getState) => {
  try {
    const {
      premium: {isEligibleForTrial},
      auth: {user},
    } = getState();
    if (!user?.uid) {
      return;
    }
    if (isEligibleForTrial) {
      const dataObj = {
        callerUID: user.uid,
      };
      const data = await getFreeTrialCloudFunction(dataObj);
      if (!data || !data.success) {
        //failed
        let message;
        if (data?.message) {
          message = data.message;
        } else {
          message = 'Something went wrong, please try again';
        }
        if (data?.error) {
          captureInfo({
            log: data.log,
            dataObj: JSON.stringify(dataObj),
          });
          captureError(new Error(data.error), true);
        }
        return {
          success: false,
          message,
        };
      }
      return {
        success: true,
      };
    }
  } catch (error) {
    captureError(error);
    return {
      success: false,
      message: 'Something went wrong, please try again',
    };
  }
};

export {
  loadSubscriptions,
  loadOfferings,
  loadOfferingLocalisation,
  loadFeaturesList,
  loadPlanSharedWith,
  requestFreeTrial,
  sharePlanWithMember,
  removeMemberFromPlan,
};
