export const AUTH_ACTION = {
  LOAD_USER_AUTH: 'LOAD_USER_AUTH',
  LOAD_USER_AUTOMATION_CONFIG: 'LOAD_USER_AUTOMATION_CONFIG',
  LOAD_USER_ACTIVE_DEVICES: 'LOAD_USER_ACTIVE_DEVICES',
  SET_LOGIN_LISTENER: 'SET_LOGIN_LISTENER',
  LOAD_USER_PREF: 'LOAD_USER_PREF',
  RESET_STATE: 'RESET_STATE',
  SET_NEW_USER_FLAG: 'SET_NEW_USER_FLAG',
  SET_USER_COUNTRY: 'SET_USER_COUNTRY',
  SET_PROFILE_PERCENT: 'SET_PROFILE_PERCENT',
  UPDATE_WHATSAPP_NUMBER: 'UPDATE_WHATSAPP_NUMBER',
  LOAD_FLIPKART_META: 'LOAD_FLIPKART_META',
  UPDATE_DISABLED_TEMPLATES: 'UPDATE_DISABLED_TEMPLATES',
  SET_MULTI_LOGIN_MODAL: 'SET_MULTI_LOGIN_MODAL',
  SET_USER_ORG_INFO: 'SET_USER_ORG_INFO',
};

export const TABLE_ACTION = {
  MOVE_ROW: 'MOVE_ROW',
  COPY_PASTE_DATA: 'COPY_PASTE_DATA',
  LOAD_TABLE_BACKUP: 'LOAD_TABLE_BACKUP',
  LOAD_TABLE_VERSION_DATA: 'LOAD_TABLE_VERSION_DATA',
  RESTORED_TABLE_DATA: 'RESTORED_TABLE_DATA',
  LOAD_TABLE_DATA: 'LOAD_TABLE_DATA',
  LOAD_ENTRY_ONLY_DATA: 'LOAD_ENTRY_ONLY_DATA',
  EDIT_ROW: 'EDIT_ROW',
  SORT_COL: 'SORT_COL',
  DELETE_COL_OR_FORMULA: 'DELETE_COL_OR_FORMULA',
  EDIT_COL: 'EDIT_COL',
  SHOW_TOTAL: 'SHOW_TOTAL',
  CLEAR_LAST: 'CLEAR_LAST',
  DELETE_ROW: 'DELETE_ROW',
  DELETE_ROW_COL_DATA: 'DELETE_ROW_COL_DATA',
  START_TABLE_LOADING: 'START_TABLE_LOADING',
  STOP_TABLE_LOADING: 'STOP_TABLE_LOADING',
  RESET_STATE: 'RESET_STATE',
  SET_SPLIT_BY_CALCULATIONS: 'SET_SPLIT_BY_CALCULATIONS',
  ADD_EMPTY_ROW: 'ADD_EMPTY_ROW',
  UPDATE_COLLABORATIVE_TABLE_DATA: 'UPDATE_COLLABORATIVE_TABLE_DATA',
  ADD_ROW_IN_BETWEEN: 'ADD_ROW_IN_BETWEEN',
  UNDO: 'UNDO',
  REDO: 'REDO',
  UPDATE_HELPER_FUNCTION_REFS: 'UPDATE_HELPER_FUNCTION_REFS',
  ADD_PDF_HEADER: 'ADD_PDF_HEADER',
  ADD_PDF_FOOTER: 'ADD_PDF_FOOTER',
  EDIT_FORMULA_HEADER: 'EDIT_FORMULA_HEADER',
  ADD_COLUMN_AT_POS: 'ADD_COLUMN_AT_POS',
  ADD_CLIENT_INFO_FOR_PDF: 'ADD_CLIENT_INFO_FOR_PDF',
  TABLE_DATA_UPDATE_PAGE_CHANGE: 'TABLE_DATA_UPDATE_PAGE_CHANGE',
  UPDATE_TABLE_STATES: 'UPDATE_TABLE_STATES',
  UPDATE_SMS_OBJ: 'UPDATE_SMS_OBJ',
  SET_FROZEN_COLUMN_IDS: 'SET_FROZEN_COLUMN_IDS',
  UNFREEZE_COLUMNS: 'UNFREEZE_COLUMNS',
  MOVE_COLUMN: 'MOVE_COLUMN',
  UPDATE_INITIAL_DATA_FETCH_OBJ: 'UPDATE_INITIAL_DATA_FETCH_OBJ',
  SET_PARTICIPANT_LIST: 'setParticipantList',
  CLEAR_PARTICIPANT_LIST: 'clearParticiantList',
  SET_SHOW_AUTOMATION_BANNER: 'SET_SHOW_AUTOMATION_BANNER',
  UPDATE_HEADER_DATA: 'UPDATE_HEADER_DATA',
  ADD_UNIQUE_VALUE: 'ADD_UNIQUE_VALUE',
  APPEND_DATA_IN_BULK: 'APPEND_DATA_IN_BULK',
  UPDATE_ALL_ROWS_FETCHED_STATE: 'UPDATE_ALL_ROWS_FETCHED_STATE',
  UPDATE_IS_LOADING_MORE_ROWS: 'UPDATE_IS_LOADING_MORE_ROWS',
  SET_HEADER_MAPPED_VALUES: 'SET_HEADER_MAPPED_VALUES',
  SHOW_INFORMAITON_MODAL: 'SHOW_INFORMAITON_MODAL',
};

export const ROW_HEIGHT_ACTION = {
  SAVE_TABLE_ROW__HEIGHT: 'SAVE_TABLE_ROW__HEIGHT',
  RESET_HEIGHT_VALUES: 'RESET_HEIGHT_VALUES',
};

export const HOME_ACTION = {
  SET_USER_STORIES: 'SET_USER_STORIES',
  CREATE_NEW_FILE: 'CREATE_NEW_FILE',
  LOAD_FILES: 'LOAD_FILES',
  OPEN_FILE: 'OPEN_FILE',
  DELETE_FILE: 'DELTE_FILE',
  ADD_TEMPLATE: 'ADD_TEMPLATE',
  LOAD_TEMPLATES: 'LOAD_TEMPLATES',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  RESET_STATE: 'RESET_STATE',
  START_SHARED_PAGES_META_LOADING: 'START_SHARED_PAGES_META_LOADING',
  STOP_SHARED_PAGES_META_LOADING: 'STOP_SHARED_PAGES_META_LOADING',
  UPDATE_HOME_LISTENER_OBJ: 'UPDATE_HOME_LISTENER_OBJ',
  UPDATE_ACTIVE_FILE_INDEX: 'UPDATE_ACTIVE_FILE_INDEX',
  UPDATE_DOCUMENT_META: 'UPDATE_DOCUMENT_META',
  DELETE_MY_TEMPLATE: 'DELETE_MY_TEMPLATE',
  DUPLICATE_FILE: 'DUPLICATE_FILE',
  RENAME_MY_TEMPLATE: 'RENAME_MY_TEMPLATE',
  UPDATE_VISIBLE_FOLDER: 'UPDATE_VISIBLE_FOLDER',
  UPDATE_HOME_STATES: 'UPDATE_HOME_STATES',
  CLEAR_LAST_HOME_STATE: 'CLEAR_LAST_HOME_STATE',
  CLEAR_HOME_STATE: 'CLEAR_HOME_STATE',
  SET_GREETINGS: 'SET_GREETINGS',
  UPDATE_SELECTED_PAGE: 'UPDATE_SELECTED_PAGE',
  SET_PAGEID_CACHE_FOR_VIEW_ENTRY: 'SET_PAGEID_CACHE_FOR_VIEW_ENTRY',
  ADD_NEW_PAGE: 'ADD_NEW_PAGE',
  DELETE_PAGE: 'DELETE_PAGE',
  MODIFY_SMART_TEMPLATE_DOCS: 'MODIFY_SMART_TEMPLATE_DOCS',
  TOGGLE_DOCUMENT_PAGES: 'TOGGLE_DOCUMENT_PAGES',
  UPDATE_GLOBAL_LOADING_STATE: 'UPDATE_GLOBAL_LOADING_STATE',
  SET_USER_UNITS: 'SET_USER_UNITS',
  GET_VIDEOS: 'GET_VIDEOS',
  GET_COMMUNITIES: 'GET_COMMUNITIES',
  GET_WHATS_NEW: 'GET_WHATS_NEW',
  FETCH_BUSINESS_TYPES: 'FETCH_BUSINESS_TYPES',
  FETCH_BUSINESS_CATEGORIES: 'FETCH_BUSINESS_CATEGORIES',
  UPDATE_FOLDER_STACK: 'UPDATE_FOLDER_STACK',
  SET_TUTORIAL_VIDEOS: 'SET_TUTORIAL_VIDEOS',
  SET_ALL_FILES_FETCHED: 'SET_ALL_FILES_FETCHED',
  SET_FILES_BEING_DELETED: 'SET_FILES_BEING_DELETED',
  SHARE_ON_WHATSAPP: 'SHARE_ON_WHATSAPP',
  INVOKE_POP_UP_MODAL: 'INVOKE_POP_UP_MODAL',
  CLOSE_POP_UP_MODAL: 'CLOSE_POP_UP_MODAL',
  LOAD_NEW_CREATED_FILE_DATA: 'LOAD_NEW_CREATED_FILE_DATA',
  UPDATE_TEMPLATES_MIGRATION_LOADER: 'UPDATE_TEMPLATES_MIGRATION_LOADER',
};

export const TEMPLATE_ACTION = {
  LOAD_TEMPLATES_CATEGORY: 'LOAD_TEMPLATES_CATEGORY',
  LOAD_EXPLORE_MORE_TEMPLATES_CATEGORY: 'LOAD_EXPLORE_MORE_TEMPLATES_CATEGORY',
  LOAD_TEMPLATE_LIST: 'LOAD_TEMPLATE_LIST',
  LOAD_EXPLORE_TEMPLATE_LIST: 'LOAD_EXPLORE_TEMPLATE_LIST',
  RESET_TEMPLATE_LIST: 'RESET_TEMPLATE_LIST',
  SET_CURRENT_TEMP_DETAILS: 'SET_CURRENT_TEMP_DETAILS',
  CLEAR_CURRENT_TEMP_DETAILS: 'CLEAR_CURRENT_TEMP_DETAILS',
  SET_RECENTLY_USED_TEMPLATES: 'SET_RECENTLY_USED_TEMPLATES',
  RESET_STATE: 'RESET_STATE',
  UPDATE_SMART_TEMPLATE_CATEGORY_INDEX: 'UPDATE_SMART_TEMPLATE_CATEGORY_INDEX',
  SET_PREFERRED_TEMPLATE_CATGS: 'SET_PREFERRED_TEMPLATE_CATGS',
};

export const SEARCH_ACTION = {
  SET_ACTIVE_STATE: 'SET_ACTIVE_STATE',
  BEGIN_SEARCH: 'BEGIN_SEARCH',
  CLEAR_SEARCH: 'CLEAR_SEARCH',
  FILE_SEARCH_RESULT: 'FILE_SEARCH_RESULT',
  SET_FOLDERS_PARENT_MAP: 'SET_FOLDERS_PARENT_MAP',
};

export const SEARCH_FILTER_ACTION = {
  CHANGE_ACTIVE_STATE: 'CHANGE_ACTIVE_STATE',
  UPDATE_CURRENT_SEARCHED_TEXT: 'UPDATE_CURRENT_SEARCHED_TEXT',
  UPDATE_SEARCH_FILTER_LOADING_STATE: 'UPDATE_SEARCH_FILTER_LOADING_STATE',
  UPDATE_SEARCH_FILTER_DATA: 'UPDATE_SEARCH_FILTER_DATA',
  UPDATE_FILTER_COLUMN_INFO: 'UPDATE_FILTER_COLUMN_INFO',
  UPDATE_NON_EMPTY_ROW_COUNT: 'UPDATE_NON_EMPTY_ROW_COUNT',
  CLEAR_SEARCH_FILTER: 'CLEAR_SEARCH_FILTER',
  RESET_STATE: 'RESET_STATE',
};

export const CONTACTS_ACTION = {
  FETCH_CALL_LOGS_STOP: 'FETCH_CALL_LOGS_STOP',
  FETCH_CALL_LOGS_START: 'FETCH_CALL_LOGS_START',
  FETCH_START: 'FETCH_START',
  LOAD_CONTACTS: 'LOAD_CONTACTS',
  FETCH_END: 'FETCH_END',
  SET_RECENT_SHARED_CONTACT: 'SET_RECENT_SHARED_CONTACT',
  SET_RECENT_SHARED_FORMATTED_CONTACT: 'SET_RECENT_SHARED_FORMATTED_CONTACT',
  LOAD_ALL_CONTACTS: 'LOAD_ALL_CONTACTS',
  RESET_STATE: 'RESET_STATE',
  SET_CALL_LOGS: 'SET_CALL_LOGS',
};

export const VERSION_ACTION = {
  BACKUP_TABLE_DATA: 'BACKUP_TABLE_DATA',
  LOAD_VERSION_FILES: 'LOAD_VERSION_FILES',
  SET_BACKUP: 'SET_BACKUP',
  CLEAR_VERSION_DATA: 'CLEAR_VERSION_DATA',
  RESET_STATE: 'RESET_STATE',
  VERSION_FILES_LOADED: 'VERSION_FILES_LOADED',
  UPDATE_TABLE_LINKING_VERSION_DATA: 'UPDATE_TABLE_LINKING_VERSION_DATA',
  UPDATE_IS_LOADING_MORE_ROWS: 'VERSION_UPDATE_IS_LOADING_MORE_ROWS',
  UPDATE_ALL_ROWS_FETCHED_STATE: 'VERSION_UPDATE_ALL_ROWS_FETCHED_STATE',
  LOAD_TABLE_VERSION_DATA: 'VERSION_LOAD_TABLE_VERSION_DATA',
  RESET_PREVIEW_DATA: 'RESET_PREVIEW_DATA',
  START_TABLE_LOADING: 'VERSION_START_TABLE_LOADING',
  STOP_TABLE_LOADING: 'VERSION_STOP_TABLE_LOADING',
};

export const COPY_PASTE_ACTION = {
  COPY: 'COPY',
  PASTE: 'PASTE',
  RESET_STATE: 'RESET_STATE',
};

export const NETINFO_ACTION = {
  UPDATE_NETINFO_STATE: 'UPDATE_NETINFO_STATE',
  UPDATE_NETINFO_CALLBACKS: 'UPDATE_NETINFO_CALLBACKS',
};

export const COLLABORATION_ACTION = {
  SET_COLLAB_DOC_TRUE: 'SET_COLLAB_DOC_TRUE',
  START_COLLAB_LOADING: 'START_COLLAB_LOADING',
  LOAD_COLLAB_META: 'LOAD_COLLAB_META',
  ACTIVATE_DEACTIVATE_LISTENERS: 'ACTIVATE_DEACTIVATE_LISTENERS',
  UPDATE_OWNER_DETAILS: 'UPDATE_OWNER_DETAILS',
  UPDATE_ACTIVE_USERS: 'UPDATE_ACTIVE_USERS',
  UPDATE_ACTIVE_USERS_DETAILS: 'UPDATE_ACTIVE_USERS_DETAILS',
  UPDATE_SHARED_WITH: 'UPDATE_SHARED_WITH',
  STOP_COLLAB_LOADING: 'STOP_COLLAB_LOADING',
  CLEAR_LAST_COLLAB_STATE: 'CLEAR_LAST_COLLAB_STATE',
  RESET_STATE: 'RESET_STATE',
};

export const DASHBOARD_ACTION = {
  LOAD_ALL_DASHBOARDS: 'LOAD_ALL_DASHBOARDS',
  SET_DASHBOARD_SUGGESTED_FILES: 'SET_DASHBOARD_SUGGESTED_FILES',
  SET_DASHBOARD_TEMPLATE_SUGGESTIONS: 'SET_DASHBOARD_TEMPLATE_SUGGESTIONS',
  START_FETCHING_DASHBOARDS: 'START_FETCHING_DASHBOARDS',
  UPDATE_DASHBOARDS_MIGRATION_LOADER: 'UPDATE_DASHBOARDS_MIGRATION_LOADER',
  STOP_FETCHING_DASHBOARDS: 'STOP_FETCHING_DASHBOARDS',
  CLEAR_DASHBOARD_STATE: 'CLEAR_DASHBOARD_STATE',
  RESET_STATE: 'RESET_STATE',
  SET_SELECTED_FILE: 'SET_SELECTED_FILE',
  SET_SELECTED_OPERATION: 'SET_SELECTED_OPERATION',
  SET_SELECTED_COLUMN: 'SET_SELECTED_COLUMN',
  SET_SUGGESTED_DASHBOARDS: 'SET_SUGGESTED_DASHBOARDS',
  CLEAR_SELECTION: 'CLEAR_SELECTION',
  SET_VISIBLE_DASHBOARDS: 'SET_VISIBLE_DASHBOARDS',
  SET_EDIT_DASHBOARD_CONFIGS: 'SET_EDIT_DASHBOARD_CONFIGS',
  UPDATE_DASHBOARD_LIMITING_OBJECT: 'UPDATE_DASHBOARD_LIMITING_OBJECT',
};

export const MODAL_ACTION = {
  MODAL_OPENED: 'modal_opened',
  MODAL_CLOSED: 'modal_closed',
};

export const PAGINATION_ACTION = {
  UPDATE_TRASH_CURRENT_PAGE: 'update_trash_current_page',
  UPDATE_DOCUMENT_CURRENT_PAGE: 'update_document_current_page',
};

export const NEW_USER_ACTIONS = {
  SET_NEW_USER: 'setNewUser',
};

export const FILE_FOLDER_SEARCH = {
  START_SEARCH: 'START_SEARCH',
  STOP_SEARCH: 'STOP_SEARCH',
  SEARCH_INPUT_CHANGE: 'SEARCH_INPUT_CHANGE',
};

export const TABLE_LINKS_ACTION = {
  UPDATE_COLUMN_DEPENDENCY_MAPPING: 'UPDATE_COLUMN_DEPENDENCY_MAPPING',
  CREATE_TABLE_LINK: 'CREATE_TABLE_LINK',
  RESET_STATE: 'RESET_STATE',
  UPDATE_PREVIOUS_PARENT_DATA: 'UPDATE_PREVIOUS_PARENT_DATA',
  FETCH_PARENT_DOC_DATA: 'FETCH_PARENT_DOC_DATA',
  UPDATE_ACTIVE_STATE: 'UPDATE_TABLE_LINK_ACTIVE_STATE',
  UPDATE_PARENT_TABLE_DATA: 'UPDATE_PARENT_TABLE_DATA',
  FETCH_AUTOLINKED_DATA: 'FETCH_AUTOLINKED_DATA',
  UPDATE_PARENT_ROW_META: 'UPDATE_PARENT_ROW_META',
  CLEAR_TABLE_LINK_STATE: 'CLEAR_TABLE_LINK_STATE',
  SHOW_LOADER_ON_DATA_FETCH: 'SHOW_LOADER_ON_DATA_FETCH',
  PARENT_FILE_DATA_FETCH_STATUS: 'PARENT_FILE_DATA_FETCH_STATUS',
  FETCH_AND_REPLACE_PARENT_DOC_DATA: 'FETCH_AND_REPLACE_PARENT_DOC_DATA',
  SET_ROW_FETCH_FLAG: 'SET_ROW_FETCH_FLAG',
  SET_SEARCH_ACTIVE: 'SET_SEARCH_ACTIVE',
  SET_SEARCH_LOADING: 'SET_SEARCH_LOADING',
  SET_SEARCH_RESULT: 'SET_SEARCH_RESULT',
  CLEAR_SEARCH: 'CLEAR_SEARCH',
  COPY_PREVIOUS_TABLE_LINK_STATE: 'COPY_PREVIOUS_TABLE_LINK_STATE',
  REAPPLY_PREV_STATE: 'REAPPLY_PREV_STATE',
  CLEAR_COLUMN_DEPENDENCY_MAPPING: 'CLEAR_COLUMN_DEPENDENCY_MAPPING',
  UPDATE_FORCE_STOP_DEPENDENCY_ARR_UPDATE:
    'UPDATE_FORCE_STOP_DEPENDENCY_ARR_UPDATE',
  UPDATE_PARENT_DATA_FETCHED_FLAG: 'UPDATE_PARENT_DATA_FETCHED_FLAG',
};

export const TEAMS_ACTION = {
  SET_TEAMS: 'SET_TEAMS',
  UPDATE_SELECTED_MEMBER: 'UPDATE_SELECTED_MEMBER',
  EMPTY_SELECT_MEMBERS: 'EMPTY_SELECT_MEMBERS',
  CHANGE_MEMBER_PERMISSION: 'CHANGE_MEMBER_PERMISSION',
  STOP_LOADING_TEAMS: 'STOP_LOADING_TEAMS',
  START_LOADING_TEAMS: 'START_LOADING_TEAMS',
  UPDATE_TEAMS_MIGRATION_LOADER: 'UPDATE_TEAMS_MIGRATION_LOADER',
  RESET_STATE: 'RESET_STATE',
};

export const DOCS_FILTER_ACTIONS = {
  SET_DOCS_FILTER_TYPE: 'SET_DOCS_FILTER_TYPE',
  SET_DOCS_FILTER_TEAM: 'SET_DOCUMENT_FILTER_TEAM',
};

export const TRASH_ACTIONS = {
  LOAD_TRASH_FILES: 'LOAD_TRASH_FILES',
  ADD_TO_TRASH: 'ADD_TO_TRASH',
  RESTORE_FROM_TRASH: 'RESTORE_FROM_TRASH',
  DELETE_PERMANENTLY_FROM_TRASH: 'DELETE_PERMANENTLY_FROM_TRASH',
};

export const PREMIUM_REDUCER = {
  LOAD_SUBSCRIPTIONS: 'LOAD_SUBSCRIPTIONS',
  LOAD_OFFERINGS: 'LOAD_OFFERINGS',
  LOAD_OFFERINGS_LOCALISATION: 'LOAD_OFFERINGS_LOCALISATION',
  LOAD_FEATURES_LIST: 'LOAD_FEATURES_LIST',
  UPDATE_PLAN_SHARED_WITH_ARRAY: 'UPDATE_PLAN_SHARED_WITH_ARRAY',
  START_PLAN_SHARED_WITH_LOADING: 'START_PLAN_SHARED_WITH_LOADING',
  RESET_STATE: 'RESET_STATE',
  SET_SUBSCRIPTIONS: 'SET_SUBSCRIPTINOS',
};

export const AUTOMATION_ACTION = {
  SET_OPERATION_FILE_DATA: 'SET_OPERATION_FILE_DATA',
  SET_TABLE_AUTOMATION_CONFIG_DATA: 'SET_TABLE_AUTOMATION_CONFIG_DATA',
  SET_DOCUMENT_META_FOR_AUTOMATION: 'SET_DOCUMENT_META_FOR_AUTOMATION',
  UPDATE_PHONE_COL: 'UPDATE_PHONE_COL',
  UPDATE_CURRENT_AUTOMATION_CAT_ID: 'UPDATE_CURRENT_AUTOMATION_CAT_ID',
  SET_EDIT_TRIGGER_COL: 'SET_EDIT_TRIGGER_COL',
  LOAD_AUMATIONS: 'LOAD_AUMATIONS',
  AUTOMATION_LOAD_BASED_ON_TRIGGER: 'AUTOMATION_LOAD_BASED_ON_TRIGGER',
  CLEAR_PREV_STATE_AUTOMATIONS: 'CLEAR_PREV_STATE_AUTOMATIONS',
  SET_AUTOMATION_OBJ: 'SET_AUTOMATION_OBJ',
  SET_DOC_AUTOMATION_CONFIG_DATA: 'SET_DOC_AUTOMATION_CONFIG_DATA',
  SET_PENDING_AUTOMATIONS: 'SET_PENDING_AUTOMATIONS',
  CREATE_TRIGGER: 'CREATE_TRIGGER',
  UPDATE_TRIGGER_COLS: 'UPDATE_TRIGGER_COLS',
  SET_CURRENT_COL: 'SET_CURRENT_COL',
  UPDATE_AUTOMATION_OBJ: 'UPDATE_AUTOMATION_OBJ',
  RESET_AUTOMATION_STATE: 'RESET_AUTOMATION_STATE',
  RESET_CURRENT_AUTOMATION_RELATED_STATE:
    'RESET_CURRENT_AUTOMATION_RELATED_STATE',
  SET_IS_EDITTING_COLUMN_MAPPING: 'SET_IS_EDITTING_COLUMN_MAPPING',
  SET_CURRENT_AUTOMATION: 'SET_CURRENT_AUTOMATION',
  SET_AUTOMATION_FILTERS: 'SET_AUTOMATION_FILTERS',
  LOAD_AUTOMATION_TEMPLATES: 'LOAD_AUTOMATION_TEMPLATES',
  UPDATE_DOC_AUTOMATION_CONFIG: 'UPDATE_DOC_AUTOMATION_CONFIG',
  SHOW_AUTOMATION_LOADER: 'SHOW_AUTOMATION_LOADER',
  HIDE_AUTOMATION_LOADER: 'HIDE_AUTOMATION_LOADER',
  SET_SHOW_AUTOMATION: 'SET_SHOW_AUTOMATION',
  UPDATE_CUSTOM_KEY_VALUES: 'UPDATE_CUSTOM_KEY_VALUES',
  SET_DAILY_AUTOMATION_CONFIG: 'SET_DAILY_AUTOMATION_CONFIG',
  SET_SELECTED_DOC_DAILY_AUTOMATION: 'SET_SELECTED_DOC_DAILY_AUTOMATION',
  SET_SCHEDULED_PENDING_AUTOMATION: 'SET_SCHEDULED_PENDING_AUTOMATION',
  CLEAR_AUTOMATION_TEMPLATES: 'CLEAR_AUTOMATION_TEMPLATES',
  LOAD_GLOBAL_AUTOMATIONS: 'LOAD_GLOBAL_AUTOMATIONS',
  SET_AUTOMATIONS_DISABLED_DUE_TO_PREV_ROW_REF:
    'SET_AUTOMATIONS_DISABLED_DUE_TO_PREV_ROW_REF',
  SET_NOTIFICATION_REDIRECTION_PARAMS_WEB:
    'SET_NOTIFICATION_REDIRECTION_PARAMS_WEB',
  RESET_NOTIFICATION_REDIRECTION_PARAMS_WEB:
    'RESET_NOTIFICATION_REDIRECTION_PARAMS_WEB',
  SET_MINI_APP_AUTOMATION_CONFIG_DATA: 'SET_MINI_APP_AUTOMATION_CONFIG_DATA',
  RESET_CURRENT_AUTOMATION_STATE_WEB: 'RESET_CURRENT_AUTOMATION_STATE_WEB',
  MINI_APP_UPDATE_AUTOMATION_RUNNING_STATUS:
    'MINI_APP_UPDATE_AUTOMATION_RUNNING_STATUS',
  RESET_AUTOMATION_STATE_MINI_APPS_EDIT:
    'RESET_AUTOMATION_STATE_MINI_APPS_EDIT',
  MINI_APP_SET_EMAILS_FOR_INTEGRATION: 'MINI_APP_SET_EMAILS_FOR_INTEGRATION',
};

export const TASKS_ACTION = {
  GET_ALL_TASKS: 'GET_ALL_TASKS',
  SET_FETCH_META: 'SET_FETCH_META',
  SET_DOC_NAME_MAPPED_STATE: 'SET_DOC_NAME_MAPPED_STATE',
  SET_LOADING: 'SET_LOADING',
  SET_VISIBLE_TASKS: 'SET_VISIBLE_TASKS',
  RESET_STATE: 'RESET_STATE',
  UPADATE_TASK_DETAILS: 'UPADATE_TASK_DETAILS',
  SET_VISIBLE_DOCUMENT_IDS: 'SET_VISIBLE_DOCUMENT_IDS',
  UPDATE_TASK_SEARCH_TEXT: 'UPDATE_SEARCH_TEXT',
  UPDATE_TASK_FILTER: 'UPDATE_TASK_FILTER',
  UPDATE_LOADING_TASKS: 'UPDATE_LOADING_TASKS',
};

export const REMOTE_CONFIG_ACTION = {
  RESET_STATE: 'RESET_STATE',
  UPDATE_RESTRICTION_STATUS: 'UPDATE_RESTRICTION_STATUS',
  SET_RESTRICTION_DATA: 'SET_RESTRICTION_DATA',
  SET_SHOW_NEW_DASHBOARD_FLOW: 'SET_SHOW_NEW_DASHBOARD_FLOW',
  SET_SHOW_ACTIVITY_LOG: 'SET_SHOW_ACTIVITY_LOG',
  SET_ORGANISATION_MODE_VALUE: 'SET_ORGANISATION_MODE_VALUE',
};

export const AGGREGATION_ACTION = {
  LOAD_AGGREGATIONS: 'LOAD_AGGREGATIONS',
  CLEAR_AGGREGATION_STATE: 'CLEAR_AGGREGATION_STATE',
  RESET_STATE: 'RESET_STATE',
};

export const TABLE_VIEW_ACTIONS = {
  UPDATE_VIEW_MODES: 'TABLE_VIEW_UPDATES_MODES',
  RESET_VIEW_MODES: 'TABLE_VIEW_RESET_MODES',
  RESET_STATE: 'RESET_STATE',
};

export const MESSAGE_TEMPLATES_ACTIONS = {
  RESET_STATE: 'RESET_STATE',
  LOAD_MESSAGE_TEMPLATE: 'LOAD_MESSAGE_TEMPLATE',
  SET_SAVED_STATE: 'SET_SAVED_STATE',
};
export const WEBINARS_ACTIONS = {
  LOAD_WEBINARS: 'LOAD_WEBINARS',
  REGISTER_FOR_WEBINAR: 'REGISTER_FOR_WEBINAR',
  LOAD_RECORDED_WEBINARS: 'LOAD_RECORDED_WEBINARS',
  LOAD_REGISTERED_WEBINARS: 'LOAD_REGISTERED_WEBINARS',
  RESET_STATE: 'RESET_STATE',
};

export const ACTIVITY_LOG_ACTION = {
  LOAD_DOCUMENT_ACTIVITY: 'LOAD_DOCUMENT_ACTIVITY',
  LOAD_ACCOUNT_ACTIVITY: 'LOAD_ACCOUNT_ACTIVITY',
  RESET_STATE: 'RESET_STATE',
  START_LOADING: 'START_LOADING',
  UPDATE_FILTER: 'UPDATE_FILTER',
  RESET_FILTER: 'RESET_FILTER',
};

export const BUSINESS_ACTION = {
  SET_BUSINESS_PROFILE: 'SET_BUSINESS_PROFILE',
  GET_LIO_CREDITS: 'GET_LIO_CREDITS',
  RESET_STATE: 'RESET_STATE',
};

export const PERSISTED_DATA_ACTION = {
  RESET_DOC_ACTIVITY_LOG: 'RESET_DOC_ACTIVITY_LOG',
  SET_DOC_ACTIVITY_LOG: 'SET_DOC_ACTIVITY_LOG',
  RESET_ACCOUNT_ACTIVITY_LOG: 'RESET_ACCOUNT_ACTIVITY_LOG',
  SET_ACCOUNT_ACTIVITY_LOG: 'SET_ACCOUNT_ACTIVITY_LOG',
  SET_UNIQUE_DEVICE_ID: 'SET_UNIQUE_DEVICE_ID',
  RESET_STATE: 'RESET_STATE',
};

export const MINI_APPS_REPORTS_ACTION = {
  SET_MINI_APPS_REPORTS_DATA: 'SET_MINI_APPS_REPORTS_DATA',
};

export const MINI_APPS_ACTION = {
  LOAD_MINI_APPS: 'LOAD_MINI_APPS',
  UPDATE_LOADING_MINI_APPS: 'UPDATE_LOADING_MINI_APPS',
  OPEN_APP: 'OPEN_APP',
  CHANGE_SCREEN: 'CHANGE_SCREEN',
  CLOSE_APP: 'CLOSE_APP',
  UPDATE_MINI_APPS_DOCS_DATA: 'UPDATE_MINI_APPS_DOCS_DATA',
  UPDATE_MINI_APPS_FILTERED_DOC_DATA: 'UPDATE_MINI_APPS_FILTERED_DOC_DATA',
  REMOVE_MINI_APPS_FILTERED_DOC_DATA: 'REMOVE_MINI_APPS_FILTERED_DOC_DATA',
  SET_MINI_APP_ACCESS_INSTANCE: 'SET_MINI_APP_ACCESS_INSTANCE',
  UPDATE_MINI_APPS_SEARCH_FILTER_DATA: 'UPDATE_MINI_APPS_SEARCH_FILTER_DATA',
  UPDATE_MINI_APPS_LISTENERS: 'UPDATE_MINI_APPS_LISTENERS',
  UPDATE_CUSTOM_ROLES_STATE: 'UPDATE_CUSTOM_ROLES_STATE',
  UPDATE_EXPORTS_DATA_LIST: 'UPDATE_EXPORTS_DATA_LIST',
  UPDATE_IMPORTS_DATA_LIST: 'UPDATE_IMPORTS_DATA_LIST',
  SET_ALL_TEMPLATE_DATA: 'SET_ALL_TEMPLATE_DATA',
  UPDATE_ACTIVE_CUSTOM_ROLE_INFO: 'UPDATE_ACTIVE_CUSTOM_ROLE_INFO',
  UPDATE_FUNCTION_REFS: 'UPDATE_FUNCTION_REFS',
  UPDATE_SCREEN_LOCAL_CONFIG: 'UPDATE_SCREEN_LOCAL_CONFIG',
  UPDATE_ACTION_BUTTONS_STATUS: 'UPDATE_ACTION_BUTTONS_STATUS',
  INITIAL_SCREEN_LOCAL_CONFIG: 'INITIAL_SCREEN_LOCAL_CONFIG',
  RESET_STATE: 'RESET_STATE',
  LOAD_MINI_APPS_ENDPOINTS: 'LOAD_MINI_APPS_ENDPOINTS',
  LOAD_MINI_APPS_BUSINESS_CATEGORY: 'LOAD_MINI_APPS_BUSINESS_CATEGORY',
  LOAD_MINI_APPS_USECASE_CATEGORY: 'LOAD_MINI_APPS_USECASE_CATEGORY',
  UPDATE_CHECKED_VALUES: 'UPDATE_CHECKED_VALUES',
  UPDATE_IS_SELECT_RECORDS: 'UPDATE_IS_SELECT_RECORDS',
  UPDATE_QUICK_FILTER_COLUMNS: 'UPDATE_QUICK_FILTER_COLUMNS',
  UPDATE_NAVIGATION_STATE: 'UPDATE_NAVIGATION_STATE',
  UPDATE_ENTRY_CONFIG: 'UPDATE_ENTRY_CONFIG',
  UPDATE_RUNNING_PROCESSES: 'UPDATE_RUNNING_PROCESSES',
  UPDATE_PROGRESS_NOTIFICATIONS: 'UPDATE_PROGRESS_NOTIFICATIONS',
};

export const MINI_APPS_STORE_ACTION = {
  CLEAR_SELECTED_APP: 'CLEAR_SELECTED_APP',
  CLEAR_SELECTED_APP_CATEGORY: 'CLEAR_SELECTED_APP_CATEGORY',
  SET_LOADING: 'SET_LOADING',
  SET_SELECTED_APP_DETAILS: 'SET_SELECTED_APP_DETAILS',
  LOAD_STORE_APPS_CATEGORY: 'LOAD_STORE_APPS_CATEGORY',
  LOAD_STORE_APPS_INIATIAL_LIST: 'LOAD_STORE_APPS_INIATIAL_LIST',
  LOAD_STORE_APPS_LIST: 'LOAD_STORE_APPS_LIST',
  SET_APPS_SECTION_LIST: 'SET_APPS_SECTION_LIST',
  RESET_STATE: 'RESET_STATE',
};

export const CAMPAIGN_ACTION = {
  LOAD_EMAIL_TEMPLATES: 'LOAD_EMAIL_TEMPLATES',
  SET_CAMPAIGN_TEST_NUMBERS: 'SET_CAMPAIGN_TEST_NUMBERS',
  LOAD_CAMPAIGNS: 'LOAD_CAMPAIGNS',
  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  SET_CAMPAIGN_TEMPLATE_ID: 'SET_CAMPAIGN_TEMPLATE_ID',
  EDIT_CAMPAIGN: 'EDIT_CAMPAIGN',
  DELETE_CAMPAIGN: 'DELETE_CAMPAIGN',
  RESET_STATE: 'RESET_STATE',
  SET_DOC_DETAILS: 'SET_DOC_DETAILS',
  SET_PHONE_COL: 'SET_PHONE_COL',
  SET_FILTER_DETAILS: 'SET_FILTER_DETAILS',
  SET_SCHEDULE_DETAILS: 'SET_SCHEDULE_DETAILS',
  SET_TEMPLATE_DETAILS: 'SET_TEMPLATE_DETAILS',
  SET_TO_CURRENT_CAMPAIGN: 'SET_TO_CURRENT_CAMPAIGN',
  LOAD_CURRENT_CAMPAIGN: 'LOAD_CURRENT_CAMPAIGN',
  CLEAR_CAMPAIGN_STATE: 'CLEAR_CAMPAIGN_STATE',
  LOAD_USER_TEMPLATES: 'LOAD_USER_TEMPLATES',
  LOAD_LIO_TEMPLATES: 'LOAD_LIO_TEMPLATES',
  SET_CREDIT_CONFIG: 'SET_CREDIT_CONFIG',
  GET_RECIPIENTS: 'GET_RECIPIENTS',
  LOADING_RECIPIENTS: 'LOADING_RECIPIENTS',
  FINISHED_RECIPIENTS_LOADING: 'FINISHED_RECIPIENTS_LOADING',
  LOAD_CAMPAIGN_STATS: 'LOAD_CAMPAIGN_STATS',
  CLEAR_FILTER_FOR_CAMPAIGN: 'CLEAR_FILTER_FOR_CAMPAIGN',
};

export const ORGANISATION_ACTION = {
  CREATE_PROFILE: 'CREATE_PROFILE',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  SET_USER_ORG_INFO: 'SET_USER_ORG_INFO',
  ADD_MEMBERS_ORGANISATION: 'ADD_MEMBERS_ORGANISATION',
  REMOVE_MEMBERS_ORGANISATION: 'REMOVE_MEMBERS_ORGANISATION',
  LOAD_ALL_MEMBERS: 'LOAD_ALL_MEMBERS',
  LOAD_ORGANISATION_DATA: 'LOAD_ORGANISATION_DATA',
  SET_ACCOUNT_LOCKED: 'SET_ACCOUNT_LOCKED',
  START_ORGANISATION_SUBSCRIPTION_LOADING:
    'START_ORGANISATION_SUBSCRIPTION_LOADING',
  UPDATE_ORGANISATION_SUBSCRIPTION: 'UPDATE_ORGANISATION_SUBSCRIPTION',
  RESET_STATE: 'RESET_STATE',
  HIDE_SUBSCRIPTION_BANNER: 'HIDE_SUBSCRIPTION_BANNER',
  FETCH_ALL_TEAMS: 'FETCH_ALL_TEAMS',
  ADD_OR_EDIT_TEAM: 'ADD_OR_EDIT_TEAM',
  DELETE_TEAM: 'DELETE_TEAM',
  FETCH_ALL_ORGANISATION_APPS: 'FETCH_ALL_ORGANISATION_APPS',
};

export const ELASTIC_DASHBOARDS_ACTION = {
  UPDATE_LOADING_ELASTIC_DASHBOARD: 'UPDATE_LOADING_ELASTIC_DASHBOARD',
  START_LOADING_DASHBOARD: 'START_LOADING_DASHBOARD',
  SET_DASHBOARD_VALUE: 'SET_DASHBOARD_VALUE',
  SET_ALL_DASHBOARDS: 'SET_ALL_DASHBOARDS',
  SET_EDIT_DASHBOARD_ID: 'SET_EDIT_DASHBOARD_ID',
  RESET_ELASTIC_DASHBOARDS_STATE: 'RESET_ELASTIC_DASHBOARDS_STATE',
  SET_DASHBOARD_TIME_FILTER: 'SET_DASHBOARD_TIME_FILTER',
  UPDATE_DASHBOARD_CONFIG: 'UPDATE_DASHBOARD_CONFIG',
  SET_DASHBOARD_QUICK_FILTER_VALUE: 'SET_DASHBOARD_QUICK_FILTER_VALUE',
};

export const INTEGRATIONS_ACTION = {
  SET_MINI_APP_INTEGRATIONS: 'SET_MINI_APP_INTEGRATIONS',
  SET_INTEGRATIONS: 'SET_INTEGRATIONS',
  SET_INTEGRATION: 'SET_INTEGRATION',
  ADD_INTEGRATION: 'ADD_INTEGRATION',
  SET_FACEBOOK_PAGES: 'SET_FACEBOOK_PAGES',
  SET_FACEBOOK_PAGE_FORMS: 'SET_FACEBOOK_PAGE_FORMS',
  SET_FACEBOOK_PAGE_FORM: 'SET_FACEBOOK_PAGE_FORM',
  SET_DOCUMENTS: 'SET_DOCUMENTS',
  SET_DOCUMENT: 'SET_DOCUMENT',
  SET_SUCCESS: 'SET_SUCCESS',
  SET_BUSINESSES: 'SET_BUSINESSES',
  SET_WHATSAPP_BUSINESSES: 'SET_WHATSAPP_BUSINESSES',
};
export const LIST_COLUMN_ACTION = {
  LOAD_SUBLIST_DATA: 'LOAD_SUBLIST_DATA',
  UPDATE_LOADING_SUBLIST_DATA: 'UPDATE_LOADING_SUBLIST_DATA',
  ADD_ROW: 'ADD_ROW_IN_SUBLIST',
  EDIT_ROW: 'EDIT_ROW_IN_SUBLIST',
  DELETE_ROW: 'DELETE_ROW_IN_SUBLIST',
  RESET_LIST_COLUMN_STATE: 'RESET_LIST_COLUMN_STATE',
  UPDATE_MULTIPLE_LIST_COLUMNS: 'UPDATE_MULTIPLE_LIST_COLUMNS',
  // NEW ACTIONS
  UPDATE_ACTIVE_SUBLIST_SCREEN: `UPDATE_ACTIVE_SUBLIST_SCREEN`,
  UPDATE_ACTIVE_PARENT_SCREEN: `UPDATE_ACTIVE_PARENT_SCREEN`,
  UPDATE_ACTIVE_PARENT_ROW: `UPDATE_ACTIVE_PARENT_ROW`,
  UPDATE_ACTIVE_META: `UPDATE_ACTIVE_META`,
  UPDATE_ORDER_MAP_AND_PATH: 'UPDATE_ORDER_MAP_AND_PATH',
};

export const PUSH_NOTIFICATIONS_ACTION = {
  UPDATE_NOTIFICATIONS_LIST: 'UPDATE_NOTIFICATIONS_LIST',
  SET_NOTFICATION_LAST_FETCHED_TIME: 'SET_NOTFICATION_LAST_FETCHED_TIME',
  RESET_STATE: 'RESET_STATE',
  UPDATE_CUSTOM_CALLBACKS: 'UPDATE_CUSTOM_CALLBACKS',
};

export const ENTRY_STATUS_ACTION = {
  INITIALIZE_STATE: 'INITIALIZE_STATE',
  RESET_STATE: 'RESET_STATE',
  UPDATE_REQUEST_STATUS_OBJECT: 'UPDATE_REQUEST_STATUS_OBJECT',
  RESET_LAST_OPENED_NOTIFY_COUNT: 'RESET_LAST_OPENED_NOTIFY_COUNT',
  TOGGLE_WEB_ACTIVITY_MENU_OPEN_STATUS: 'TOGGLE_WEB_ACTIVITY_MENU_OPEN_STATUS',
  UPDATE_REQUEST_STATUS_OBJECT_FOR_KANBAN:
    'UPDATE_REQUEST_STATUS_OBJECT_FOR_KANBAN',
};
