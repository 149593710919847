import {omit} from 'lodash';
import {ORGANISATION_ACTION} from '../actions/actionType';
import {
  DEFAULT_ORG_SUBSCRIPTION_STATE,
  ORGANISATION_ROLES,
} from '../utils/organisation';

const initialState = {
  membersList: {},
  profileData: {
    address: '',
    orgContact: '',
    orgDomain: '',
    orgEmail: '',
    orgLogo: '',
    orgName: '',
    orgDomainId: '',
  },
  isOrgSubscriptionsFetched: false,
  isOrgDataFetched: false,
  orgSubscriptions: DEFAULT_ORG_SUBSCRIPTION_STATE(), //subscription object related to current user's organisation
  organisationIds: {}, //orgId and orgObj[with role info] mapping
  activeOrganisationId: null, //user's current organisation
  isUserOrganisationOwner: false, //whether user is the owner of activeOrganisationId or not
  isAccountLocked: false, //if user is not the owner and the organisation don't have a proper active subscription for premium features
  elasticIndex: null,
  minimumPaidSeats: 0, //minimum number of seats an organisation is required to buy
  isSubscriptionBannerHidden: false,
  isCreateCustomAppDisabled: true,
  apiKey: '',
  teams: {},
  access_level: [],
  allOrganisationApps: {},
};

const organisationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORGANISATION_ACTION.HIDE_SUBSCRIPTION_BANNER: {
      return {
        ...state,
        isSubscriptionBannerHidden: true,
      };
    }
    case ORGANISATION_ACTION.SET_USER_ORG_INFO: {
      const {orgId, role, elasticIndex} = Object.assign({}, action.payload);
      const organisationIds = Object.assign({}, state.organisationIds, {
        [orgId]: {role},
      });
      return {
        ...state,
        organisationIds,
        activeOrganisationId: orgId,
        isUserOrganisationOwner: role === ORGANISATION_ROLES.OWNER_ROLE,
        ...(typeof elasticIndex === 'string' ? {elasticIndex} : {}),
      };
    }
    case ORGANISATION_ACTION.LOAD_ORGANISATION_DATA: {
      return {
        ...state,
        profileData: omit(action.payload, ['members', 'customFields']),
        membersList: Object.assign({}, action.payload.members),
        customFields: Object.assign({}, action.payload.customFields),
        isCreateCustomAppDisabled: Boolean(
          action.payload.isCreateCustomAppDisabled,
        ),
        isOrgDataFetched: true,
        apiKey: action.payload.apiKey,
        minimumPaidSeats: action.payload.minimumPaidSeats ?? 0,
        chargebeeCustomerId: action.payload.chargebeeCustomerId,
      };
    }
    case ORGANISATION_ACTION.CREATE_PROFILE: {
      return {
        ...state,
        profileData: Object.assign({}, action.payload?.profileData),
      };
    }
    case ORGANISATION_ACTION.SET_ACCOUNT_LOCKED: {
      return {
        ...state,
        isAccountLocked: Boolean(action.payload),
      };
    }
    case ORGANISATION_ACTION.UPDATE_ORGANISATION_SUBSCRIPTION: {
      return {
        ...state,
        orgSubscriptions: Object.assign({}, action.payload),
        isOrgSubscriptionsFetched: true,
      };
    }
    case ORGANISATION_ACTION.FETCH_ALL_TEAMS: {
      return {
        ...state,
        teams: Object.assign({}, action.payload),
      };
    }
    case ORGANISATION_ACTION.ADD_OR_EDIT_TEAM: {
      return {
        ...state,
        teams: Object.assign({}, action.payload),
      };
    }
    case ORGANISATION_ACTION.DELETE_TEAM: {
      return {
        ...state,
        teams: Object.assign({}, action.payload),
      };
    }
    case ORGANISATION_ACTION.FETCH_ALL_ORGANISATION_APPS: {
      return {
        ...state,
        allOrganisationApps: Object.assign({}, action.payload),
      };
    }
    case ORGANISATION_ACTION.RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default organisationsReducer;
