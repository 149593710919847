import {firestore, captureError, isAndroid, getReduxState} from '../../imports';
import {
  getUserCallingCode,
  formatPhoneNumber,
  formatAndValidateEmail,
} from '../../utils/utils';
import {isArray, isEmpty, isNil} from 'lodash';

const loadAllContactsHelper = async (uid) => {
  try {
    const contactArr = await firestore()
      .collection('users')
      .doc(uid)
      .collection('usersData')
      .doc('contacts')
      .get();
    const arr = contactArr.data();
    if (arr) {
      return arr;
    }
    return {emails: [], contacts: []};
  } catch (error) {
    captureError(error);
  }
};

const getDisplayName = (contactObj) => {
  let name = '';
  try {
    const getStr = (str) => (str?.length ? str + ' ' : '');
    name = isAndroid
      ? contactObj.displayName ?? ''
      : getStr(contactObj.givenName) +
        getStr(contactObj.middleName) +
        contactObj.familyName;
  } catch (e) {}
  return name;
};

const uploadContactsToFirestore = async (uid, data) => {
  try {
    await firestore()
      .collection('users')
      .doc(uid)
      .collection('usersData')
      .doc('contacts')
      .set(data);
  } catch (error) {
    captureError(error);
  }
};

const getContactInfo = (contactsArr, formattingRequired = true) => {
  const result = [];
  try {
    if (isArray(contactsArr)) {
      const {
        contacts: {contactInfoIndexMapping, contactsAndEmailsArr},
        auth: {userCountry},
      } = getReduxState();
      const countryCode = getUserCallingCode(userCountry ?? 'IN');
      contactsArr.forEach((contact) => {
        try {
          if (formattingRequired) {
            contact = contact.includes('@')
              ? formatAndValidateEmail(contact)[1] //email
              : formatPhoneNumber(contact, countryCode); //phoneNumber
          }
          const index = contactInfoIndexMapping[contact];
          if (!isNil(index) && !isEmpty(contactsAndEmailsArr[index])) {
            result.push({isPresent: true, info: contactsAndEmailsArr[index]});
          }
        } catch {
          result.push({isPresent: false, info: {}});
        }
      });
    }
  } catch (err) {
    captureError(err);
  }
  return result;
};

export {
  loadAllContactsHelper,
  getDisplayName,
  uploadContactsToFirestore,
  getContactInfo,
};
