import {
  CONTACTS_ACTION,
  TEMPLATE_ACTION,
  HOME_ACTION,
  AUTH_ACTION,
} from './actionType';
import {
  ASYNC_STORAGE_KEY,
  PROFILE_FIELDS,
  EXPLORE_LIO_MINI_APP_STEPS,
  EXPLORE_LIO_STEPS,
  EXPLORE_LIO_STEPS_ARR,
} from '../utils/constant';
import {
  ENV,
  isAndroid,
  firestore,
  captureError,
  AsyncStorage,
  database,
  getDeviceID,
  logAnalyticsEvent,
} from '../imports';
import {isObject, isEmpty, omit, forOwn, isFunction} from 'lodash';
import moment from 'moment';
import {authActionHelper} from './actionHelpers/authActionHelper';
import {formatRecentlySharedContacts} from './contactAction';
import {uploadLogsCall} from './persistedDataActions';
import FirestoreDB from '../FirestoreHandlers/FirestoreDB';

const loadUserAuth = (userObj) => (dispatch) => {
  try {
    dispatch({
      type: AUTH_ACTION.LOAD_USER_AUTH,
      payload: userObj,
    });
    AsyncStorage().setItem(
      ASYNC_STORAGE_KEY.USER_ID,
      JSON.stringify(userObj.uid),
    );
  } catch (error) {
    captureError(error);
  }
};

const loadUserPref = (prefObj) => (dispatch) =>
  dispatch({
    type: AUTH_ACTION.LOAD_USER_PREF,
    payload: prefObj,
  });

const setUserAutomationConfig = (obj) => async (dispatch) => {
  // Update data to Async Storage
  await AsyncStorage().setItem(
    ASYNC_STORAGE_KEY.USER_AUTOMATION_CONFIG,
    JSON.stringify(obj),
  );

  dispatch({
    type: AUTH_ACTION.LOAD_USER_AUTOMATION_CONFIG,
    payload: obj,
  });
};

const updateDisabledTemplateVisibility =
  (
    templateType = null,
    isRemove = false,
    initialFetch = false,
    availableData = null,
  ) =>
  async (dispatch, getState) => {
    try {
      const {auth} = getState();
      const user = auth?.user;
      if (!user?.uid) {
        return;
      }
      if (initialFetch) {
        let disabledTemplates = auth.disabledTemplates;

        const disabledTemplatesRemote = availableData
          ? availableData
          : await authActionHelper.getUserDocument(user.uid);

        disabledTemplates = Object.assign(
          {},
          disabledTemplates,
          disabledTemplatesRemote?.disabledTemplates ?? {},
        );

        // Update data to redux
        dispatch({
          type: AUTH_ACTION.UPDATE_DISABLED_TEMPLATES,
          payload: disabledTemplates,
        });
      } else if (templateType) {
        let disabledTemplates = isObject(auth?.disabledTemplates)
          ? auth.disabledTemplates
          : {};

        const firestoreDisabledTemplates = {};

        if (isRemove) {
          firestoreDisabledTemplates[`disabledTemplates.${templateType}`] =
            firestore(true).FieldValue.delete();
          disabledTemplates = omit(disabledTemplates, [templateType]);
        } else {
          disabledTemplates[templateType] = true;
        }

        // Update data to redux
        dispatch({
          type: AUTH_ACTION.UPDATE_DISABLED_TEMPLATES,
          payload: disabledTemplates,
        });

        // Update data to firebase
        if (isRemove) {
          await authActionHelper.updateUserDocument(
            user.uid,
            firestoreDisabledTemplates,
          );
        } else {
          await authActionHelper.setUserDocument(user.uid, {
            disabledTemplates,
          });
        }
      }
    } catch (error) {
      captureError(error);
    }
  };

const setUserPref =
  (prefObj, isLangUpdate = false) =>
  async (dispatch, getState) => {
    try {
      const {auth} = getState();
      const currentUserPref = auth.userPref;
      const updateUserPref = Object.assign({}, currentUserPref, prefObj);

      // Update data to Redux
      dispatch({
        type: AUTH_ACTION.LOAD_USER_PREF,
        payload: updateUserPref,
      });

      // Update data to Async Storage
      await AsyncStorage().setItem(
        ASYNC_STORAGE_KEY.USER_PREF,
        JSON.stringify(updateUserPref),
      );

      // Update data to Shared Preference
      if (isLangUpdate && ENV && isAndroid) {
        const {SharedPreferenceStorage} = require('../imports');
        SharedPreferenceStorage().set(
          ASYNC_STORAGE_KEY.USER_LANG,
          updateUserPref?.lang ?? 'EN',
        );
      }

      // Update data to firebase
      if (auth.user?.uid) {
        await authActionHelper.setUserDocument(auth.user.uid, {
          pref: updateUserPref,
        });
      }
    } catch (error) {
      captureError(error);
    }
  };

const setFCMToken = (token) => (dispatch, getState) => {
  const user = getState().auth.user;
  if (!user?.uid) {
    return;
  }
  // Update Data to firebase
  authActionHelper.updateUserDocument(user.uid, {
    tokens: firestore(true).FieldValue.arrayUnion(token),
  });
};

const logOut =
  (lang = 'EN') =>
  (dispatch) =>
    dispatch({type: AUTH_ACTION.RESET_STATE, payload: {lang}});

const setNewUser = (isNewUser) => (dispatch) =>
  dispatch({
    type: AUTH_ACTION.SET_NEW_USER_FLAG,
    payload: isNewUser,
  });

const setUserCountry = (countryCode) => (dispatch) =>
  dispatch({
    type: AUTH_ACTION.SET_USER_COUNTRY,
    payload: {country: countryCode},
  });

const setProfileCompletePercent = () => (dispatch, getState) => {
  try {
    const {auth} = getState();
    let completeCount = 0;
    if (isEmpty(auth?.userPref) || isEmpty(auth.user)) {
      return;
    }
    const {
      name,
      businessType,
      businessName,
      address,
      profilePicture,
      ownBusiness,
    } = auth.userPref;

    const {email, phoneNumber} = auth.user;

    const detailsObj = Object.assign(
      {},
      {
        name,
        businessName,
        businessType,
        address,
        profilePicture,
        email,
        phoneNumber,
        ownBusiness,
      },
    );

    for (let i = 0; i < PROFILE_FIELDS.length; i++) {
      const profileFieldName = PROFILE_FIELDS[i];

      if (
        profileFieldName === 'ownBusiness' &&
        detailsObj[profileFieldName] === false
      ) {
        completeCount = completeCount + 4;
      } else if (
        profileFieldName in detailsObj &&
        detailsObj[profileFieldName] &&
        detailsObj[profileFieldName] !== ''
      ) {
        completeCount++;
      }
    }

    let profilePercent = Math.round(
      (completeCount * 100) / PROFILE_FIELDS.length,
    );

    // Update Data to redux
    if (profilePercent > 0 && profilePercent <= 100) {
      dispatch({
        type: AUTH_ACTION.SET_PROFILE_PERCENT,
        payload: profilePercent,
      });
    } else if (profilePercent > 100) {
      profilePercent = 100;
      dispatch({
        type: AUTH_ACTION.SET_PROFILE_PERCENT,
        payload: profilePercent,
      });
    }
  } catch (error) {
    captureError(error);
  }
};

const updateWhatsappNumber = (whatsappNumber) => (dispatch) =>
  dispatch({
    type: AUTH_ACTION.UPDATE_WHATSAPP_NUMBER,
    payload: whatsappNumber,
  });

const setLoginToken =
  (uid, isRemove = false) =>
  () => {
    try {
      const token = isRemove
        ? ''
        : `${uid}_session_${ENV ? 'mobile' : 'web'}_${moment().valueOf()}`;
      const dbRef = database().ref(`/loginSessionDetails/${uid}`);
      const dataObj = ENV ? {mobileToken: token} : {webToken: token};

      // Update data to Real Database - Firebase
      dbRef.set(dataObj);

      // Update data to Async Storage
      AsyncStorage().setItem(ASYNC_STORAGE_KEY.MULTI_LOGIN_TOKEN, token);
    } catch (error) {
      captureError(error);
    }
  };

const getLoginToken = (uid) => async () => {
  try {
    const dbRef = database().ref(`/loginSessionDetails/${uid}`);
    const result = await dbRef.once('value');
    return result?.val?.()?.mobileToken || null;
  } catch (error) {
    captureError(error);
    return null;
  }
};

const removeCurrentDevice = (uid) => async () => {
  try {
    const deviceId = await getDeviceID();
    return authActionHelper.updateUserUserDataDevicesDocumentRef(uid, {
      [`devices.${deviceId}`]: firestore(true).FieldValue.delete(),
    });
  } catch (error) {
    captureError(error);
    return Promise.resolve({});
  }
};

const checkUserActiveDevices = () => async (dispatch, getState) => {
  try {
    const {
      auth: {user},
    } = getState();
    if (!user?.uid) {
      return;
    }
    const devicesObj = await authActionHelper.getUserUserDataDevicesDocumentRef(
      user.uid,
    );
    const devicesObjRes = devicesObj.exists
      ? devicesObj.data()?.devices ?? {}
      : {};

    const deviceId = await getDeviceID();

    authActionHelper.onUserPrefChanges(
      devicesObjRes,
      deviceId,
      {},
      dispatch,
      getState,
    );
  } catch (error) {
    captureError(error);
  }
};

const setLoginListener =
  (listener = {}) =>
  (dispatch) =>
    dispatch({
      type: AUTH_ACTION.SET_LOGIN_LISTENER,
      payload: Object.assign({}, listener),
    });

const deactivateLoginListener = () => (dispatch, getState) => {
  try {
    const {
      auth: {loginListener = {}},
    } = getState();
    const allPromises = [];

    forOwn(loginListener, (listener) => {
      if (isObject(listener)) {
        allPromises.push(listener);
      }
      if (isFunction(listener)) {
        listener();
      }
    });
    dispatch({
      type: AUTH_ACTION.SET_LOGIN_LISTENER,
      payload: {},
    });
    return Promise.all(allPromises).then((results) => {
      results.forEach((listener) => {
        if (isFunction(listener)) {
          listener();
        }
      });
    });
  } catch (error) {
    captureError(error);
  }
};

const activateLoginDevicesListener =
  (firestoreInstance, options) => async (dispatch, getState) => {
    try {
      const {
        auth: {user},
      } = getState();

      if (!user?.uid) {
        return () => {};
      }

      const deviceId = await getDeviceID();

      const multiDeviceListener = FirestoreDB.FirestoreListener(
        authActionHelper.createUserDataDevicesDocumentRef(
          user.uid,
          firestoreInstance,
        ),
        (snapshot) => {
          const devicesObj = snapshot.exists
            ? snapshot.data()?.devices ?? {}
            : {};
          authActionHelper.onUserPrefChanges(
            devicesObj,
            deviceId,
            options,
            dispatch,
            getState,
          );
        },
      );

      dispatch(setLoginListener({multiDeviceListener}));
    } catch (error) {
      captureError(error);
    }
  };

const logoutFromAllDevices = () => async (dispatch, getState) => {
  try {
    const {
      auth: {user},
    } = getState();

    if (!user?.uid) {
      return;
    }
    const deviceId = await getDeviceID();
    const timestamp = moment().utc().valueOf();
    const deviceData = {
      lastLoginTimestamp: timestamp,
    };
    const devices = {
      [deviceId]: deviceData,
    };
    authActionHelper.updateUserUserDataDevicesDocumentRef(user.uid, {
      devices: devices,
    });
    dispatch({
      type: AUTH_ACTION.LOAD_USER_ACTIVE_DEVICES,
      payload: devices,
    });
  } catch (error) {
    captureError(error);
  }
};

const setFlipkartMeta =
  (setFlag = false) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {user, flipkartLoginSuccess},
      } = getState();
      const isFlipkartFileCreated = setFlag;
      if (!user?.uid) {
        return;
      }
      // Update data to firebase
      await authActionHelper.updateUserDocument(user.uid, {
        isFlipkartFileCreated: setFlag,
      });

      dispatch(
        loadFlipkartMetaHelper(flipkartLoginSuccess, isFlipkartFileCreated),
      );
    } catch (error) {
      captureError(error);
    }
  };

const loadFlipkartMetaHelper =
  (booleanFlag = false, isFlipkartFileCreated = false) =>
  (dispatch) => {
    // Update data to Async Storage
    AsyncStorage().multiSet([
      [ASYNC_STORAGE_KEY.FLIPKART_LOGIN_SUCCESS, JSON.stringify(booleanFlag)],
      [
        ASYNC_STORAGE_KEY.FLIPKART_FILE_CREATED,
        JSON.stringify(isFlipkartFileCreated),
      ],
    ]);

    // Update data to Redux
    dispatch({
      type: AUTH_ACTION.LOAD_FLIPKART_META,
      payload: {
        flipkartLoginSuccess: booleanFlag,
        isFlipkartFileCreated,
      },
    });
  };

const fetchFlipkartMeta =
  (userObj = null) =>
  async (dispatch, getState) => {
    try {
      const user = getState().auth.user;
      if (!user?.uid) {
        return;
      }
      const responseData = userObj
        ? userObj
        : await authActionHelper.getUserDocument(user.uid);

      if (responseData) {
        const isFlipkartFileCreated =
          responseData.isFlipkartFileCreated ?? false;
        const isFlipkartAppIdAvailable =
          responseData.integration?.FLIPKART?.flipkartAppId;
        dispatch(
          loadFlipkartMetaHelper(
            isFlipkartAppIdAvailable ? true : false,
            isFlipkartFileCreated,
          ),
        );
      }
    } catch (error) {
      captureError(error);
    }
  };

const updateMultiLoginModalVisibility =
  (isVisible = false) =>
  (dispatch) => {
    dispatch({
      type: AUTH_ACTION.SET_MULTI_LOGIN_MODAL,
      payload: {isVisible},
    });
  };

const restoreUserAccount = () => async (dispatch, getState) => {
  const {
    auth: {user},
  } = getState();
  if (!user?.uid) {
    return;
  }
  logAnalyticsEvent('ACCOUNT_RESTORE_INIT', {
    source: 'LOGIN',
  });

  try {
    await authActionHelper.deleteDeactivatedAccountDocumentRef(user.uid);
  } catch (error) {
    captureError(error);
  }
  logAnalyticsEvent('ACCOUNT_RESTORE_SUCCESS', {
    source: 'LOGIN',
  });
  dispatch(uploadLogsCall());
  return dispatch(setUserPref({accountDeleteDetails: null}));
};

const deleteUserAccount =
  ({navigation, loaderShow, handleLogout, loaderHide, deleteAccountDetails}) =>
  async (dispatch, getState) => {
    const {
      auth: {userPref, user},
    } = getState();
    if (!user?.uid) {
      return;
    }

    try {
      await authActionHelper.setDeactivatedAccountDocumentRef(
        user.uid,
        deleteAccountDetails,
        {
          merge: false,
        },
      );

      /**
       * Clear all active devices of user
       */
      const timestamp = moment().utc().valueOf();

      const deviceData = {
        lastLoginTimestamp: timestamp,
      };

      await authActionHelper.updateUserUserDataDevicesDocumentRef(user.uid, {
        devices: {
          [deleteAccountDetails.accountDeleteDetails.device.id]: deviceData,
        },
      });

      logAnalyticsEvent('ACCOUNT_DELETE_SUCCESS', {
        source: 'SIDEBAR',
      });

      dispatch(uploadLogsCall());
      return handleLogout(
        {
          loaderShow: loaderShow,
          loaderHide: loaderHide,
          setLoginToken: (...params) => dispatch(setLoginToken(...params)),
          deactivateLoginListener: (...params) =>
            dispatch(deactivateLoginListener(...params)),
          removeCurrentDevice: (...params) =>
            dispatch(removeCurrentDevice(...params)),
          logOut: (...params) => dispatch(logOut(...params)),
          setUserPref: (...params) => dispatch(setUserPref(...params)),
          deleteAccountDetails: deleteAccountDetails,
        },
        {
          uid: user.uid,
          userPref: userPref,
          isDeleteAccount: true,
        },
        {navigation},
      );
    } catch (error) {
      captureError(error);
    }
  };

const fetchUserObjFirebase = () => (dispatch, getState) => {
  try {
    const {
      auth: {user},
    } = getState();
    if (!user?.uid) {
      return;
    }

    return authActionHelper
      .getUserDocument(user.uid)
      .then((userObj) => {
        if (!isEmpty(userObj)) {
          if (userObj.recentlyUsedTemplates?.length) {
            dispatch({
              type: TEMPLATE_ACTION.SET_RECENTLY_USED_TEMPLATES,
              payload: userObj.recentlyUsedTemplates,
            });
          }
          if (userObj.recentlySharedContacts?.length) {
            dispatch({
              type: CONTACTS_ACTION.SET_RECENT_SHARED_CONTACT,
              payload: userObj.recentlySharedContacts,
            });
            dispatch(formatRecentlySharedContacts());
          }
          if (userObj.userDefinedUnits?.length) {
            dispatch({
              type: HOME_ACTION.SET_USER_UNITS,
              payload: userObj.userDefinedUnits,
            });
          }
          if (userObj.whatsappNumber?.length) {
            dispatch({
              type: AUTH_ACTION.UPDATE_WHATSAPP_NUMBER,
              payload: userObj.whatsappNumber,
            });
          }
          if (!isEmpty(userObj.pref)) {
            dispatch({
              type: AUTH_ACTION.LOAD_USER_PREF,
              payload: userObj.pref,
            });
          }
          if (userObj.preferredTemplateCatgs?.length) {
            dispatch({
              type: TEMPLATE_ACTION.SET_PREFERRED_TEMPLATE_CATGS,
              payload: userObj.preferredTemplateCatgs,
            });
          }
          dispatch(fetchFlipkartMeta(userObj));
          dispatch(updateDisabledTemplateVisibility(null, null, true, userObj));
          return userObj;
        }
      })
      .catch((error) => {
        captureError(error);
      });
  } catch (error) {
    captureError(error);
  }
  return Promise.resolve();
};

const setExploreForApps =
  (event, isMiniAppsExplore = false) =>
  (dispatch, getState) => {
    const {
      auth: {userPref},
      organisation: {isUserOrganisationOwner},
      miniApps: {miniApps, activeAppId},
    } = getState();
    const exploreLioArr = userPref?.exploreLioArr?.slice?.() ?? [];

    if (
      isMiniAppsExplore &&
      event in EXPLORE_LIO_MINI_APP_STEPS &&
      !userPref.isMiniAppExplored
    ) {
      if (activeAppId && miniApps[activeAppId]) {
        const exploreMiniAppArr =
          miniApps[activeAppId].exploreMiniAppArr?.slice?.() ?? [];
        if (!exploreMiniAppArr.includes(event)) {
          exploreMiniAppArr.push(event);
          FirestoreDB.miniApps.app(activeAppId).update({exploreMiniAppArr});
          logAnalyticsEvent('ONBOARDING_EXPLORE_APP_STEPS', {
            stepName: EXPLORE_LIO_MINI_APP_STEPS[event].text,
            pendingSteps:
              Object.keys(EXPLORE_LIO_MINI_APP_STEPS)?.length -
              exploreMiniAppArr.length,
            p_appId: activeAppId,
            p_appName: miniApps[activeAppId]?.appName,
          });
        }
        if (
          !userPref.isMiniAppExplored &&
          exploreMiniAppArr.length ===
            Object.keys(EXPLORE_LIO_MINI_APP_STEPS)?.length
        ) {
          dispatch(setUserPref({isMiniAppExplored: true}));
        }
      }
    } else if (
      isUserOrganisationOwner &&
      exploreLioArr.length < EXPLORE_LIO_STEPS_ARR.length &&
      event in EXPLORE_LIO_STEPS
    ) {
      if (!exploreLioArr.includes(event)) {
        exploreLioArr.push(event);
        dispatch(setUserPref({exploreLioArr}));
        logAnalyticsEvent('ONBOARDING_EXPLORE_APP_STEPS', {
          stepName: EXPLORE_LIO_STEPS[event].text,
          pendingSteps: EXPLORE_LIO_STEPS_ARR.length - exploreLioArr.length,
          p_appId: activeAppId,
          p_appName: miniApps[activeAppId]?.appName,
        });
      }
    }
  };

export {
  loadUserAuth,
  loadUserPref,
  setUserPref,
  logOut,
  setLoginListener,
  setNewUser,
  setUserCountry,
  setFCMToken,
  setProfileCompletePercent,
  updateWhatsappNumber,
  setLoginToken,
  getLoginToken,
  fetchFlipkartMeta,
  loadFlipkartMetaHelper,
  setFlipkartMeta,
  updateDisabledTemplateVisibility,
  updateMultiLoginModalVisibility,
  activateLoginDevicesListener,
  logoutFromAllDevices,
  removeCurrentDevice,
  checkUserActiveDevices,
  deactivateLoginListener,
  deleteUserAccount,
  restoreUserAccount,
  fetchUserObjFirebase,
  setUserAutomationConfig,
  setExploreForApps,
};
