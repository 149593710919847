/* eslint-disable local-rules/rb-redux-requires */
import moment from 'moment';
import {
  ASSIGN_TASK_DETAILS,
  BOOLEAN_FILTER_FIELDS,
  CLOUD_FUNCTION_COMMON_PARAMS,
  COLUMN_PROPERTIES,
  DASHBOARD_LABEL_HELPER,
  DATE_FORMAT,
  FIELD_TYPE_ID,
  FOOTER_OPERATION_TYPES,
  INVALID_INPUT,
  ORG_MEMBER_PROFILE_FIELDS,
  RANGE_FILTER_FIELDS,
  REMINDER_FORMAT,
  RESTRICTION_TYPES_FOR_NON_PREMIUM_USERS,
  TABLE_LIMITS,
  TABLE_LINK_DISABLED_COLOUMN_TYPES,
  TEXT_VALUE_TYPE_FILTER_FIELDS,
  TIME_FORMAT,
  USER_COLUMN_DEFAULT_FIELD_CONFIG_FIELDS,
  COLUMNS_SUPPORTED_FOR_QUICK_FILTER,
  ALLOWED_FIELD_TYPES_FOR_SUMMARY_COLUMN,
  USER_INFO_COLUMN,
  CREATED_INFO_COLUMN,
  CHECK_USERPREF_FOR_SELF_SERVING,
  SORT_BY_COLUMN,
  MINI_APPS,
  DATA_FILTERS,
  ALLOWED_PERMISSIONS_FOR_AUTO_CAPTURE_CHANGE,
  COLUMN_PROPERTY_KEYS,
  DASHBOARD_FORMULA,
  RESTRICTED_FIELDS_FOR_IMPORT_EXCEL,
  PURE_NUMBER_FIELDS,
  CLOUD_FUNCTION_PATHS,
  DATE_FORMATS,
  OTHERS_SECTION_ID,
  INVALID_REORDER_TYPES,
  MINI_APPS_DATE_RECURRING_TYPES,
  SETVALUE_COLUMN_VALUE_TYPES,
  COLUMN_VALIDATION_NON_SUPPORTED_COLUMNS,
  MINI_APPS_FIELDS_ORDER,
  MINI_APPS_FIELDS,
  ORG_ROLE_CONSTANT,
  OTP_STATUS_ARR,
} from './constant';
import {
  GetWebHtml,
  getPagesHtml,
  getHtmlBlockView,
  getPagesHtmlBlockView,
} from './webHtml';
import {
  isArray,
  isPlainObject,
  isNil,
  isEmpty,
  every,
  forOwn,
  isString,
  isInteger,
  trim,
  isObject,
  sortBy,
  isNumber,
  omit,
  isFunction,
  isEqual,
  isFinite,
  has,
  pick,
  capitalize,
  isBoolean,
} from 'lodash';
import {
  captureError,
  functions,
  getReduxState,
  firestore,
  ShowToast,
  ENV,
  captureInfo,
  getDeviceVersion,
  getPlatform,
  isProduction,
  firebaseAuth,
  MixPanelInstance,
  logAnalyticsEvent,
} from '../imports';
import {typeOf} from './mathjs';
import DocumentsMethods from '../FirestoreHandlers/Documents/DocumentsMethods';
import {
  getRowObjStructured,
  getTableSortingOrder,
  processRowsDataForMappedRowId,
} from '../actions/actionHelpers/tableActionHelper';
import {
  getLayoutMappedValues,
  getOrderedHeaderDataBasedOnSections,
  getSortedMiniAppsScreens,
  mapMiniAppStates,
} from '../actions/actionHelpers/miniAppsActionHelper';
import {API_TYPES} from './apiConstants';
import {checkAndUpdatePrimaryColumnMapping} from './tableLinkUtils';
import {
  AUTOMATION_COLUMN_VALUE_TYPES,
  MAPPING_DEFAULT_VALUES_DETAILS,
} from './automationConstants';
import ColorValues from 'values.js';
import {getKeyForParentDocRowMap} from '../actions/actionHelpers/listColumnsActionHelper';
import {convertConditionsToIdValStructure} from './conditionalUtils';
import ColumnUtility from './ColumnUtility';
import {
  NOTIFICATION_TYPE,
  SERVICE_TYPE,
} from './notifyUsers/notificationConstant';
import {getOrganisationUserInfo} from './organistionUtils';

// Incase where reducer is executing and we try to access redux state it will throw error. Used this variable to avoid such error
let residueAuth = {};

const getNewRowPropertiesObject = (rowPropObj = {}, isFirstDataAdded) => {
  //!IMP : any change here should also be done in cloud repositories
  try {
    try {
      const {auth} = getReduxState();
      residueAuth = auth;
    } catch {}
    const timestamp = moment.utc().format();
    const createdContactName = residueAuth.user?.displayName
      ? residueAuth.user.displayName
      : residueAuth.userPref.name
      ? residueAuth.userPref.name
      : '';
    const contact = residueAuth.user.phoneNumber
      ? residueAuth.user.phoneNumber
      : residueAuth.user.email;

    if (isEmpty(rowPropObj)) {
      const createObj = {
        createdByUID: residueAuth.user.uid,
        createdByContact: contact,
        createdTimestamp: timestamp,
        createdInfoContactName: createdContactName || contact,
      };
      return Object.assign(
        {},
        createObj,
        isFirstDataAdded && {
          firstAddedTimestamp: timestamp,
          firstAddedByUID: residueAuth.user.uid,
          firstAddedByContact: contact,
          firstAddedByContactName: createdContactName || contact,
        },
      );
    } else {
      const lastEditedObj = {
        lastEditedTimestamp: timestamp,
        lastEditedByUID: residueAuth.user.uid,
        lastEditedByContact: contact,
      };

      return Object.assign(
        {},
        rowPropObj,
        isFirstDataAdded && {
          firstAddedTimestamp: timestamp,
          firstAddedByUID: residueAuth.user.uid,
          firstAddedByContact: contact,
          firstAddedByContactName: createdContactName || contact,
        },
        lastEditedObj,
      );
    }
  } catch (error) {
    captureError(error);
    return Object.assign({}, rowPropObj);
  }
};

const solveEqn = ({eqn, rowObj}) => {
  if (!rowObj[eqn[0]] || !rowObj[eqn[0]].val) {
    return null;
  }
  let response = null;
  let result = parseInt(rowObj[eqn[0]].val, 10);
  let operator = eqn[1];
  for (let i = 2; i < eqn.length; i++) {
    if (i % 2 === 1) {
      operator = eqn[i];
    } else {
      if (!rowObj[eqn[i]] || !rowObj[eqn[i]].val) {
        break;
      }
      const val = parseInt(rowObj[eqn[i]].val, 10);
      switch (operator) {
        case '+':
          result += val;
          break;
        case '-':
          result -= val;
          break;
        case 'x':
          result *= val;
          break;
        case '/':
          result /= val;
          break;
      }
      if (i === eqn.length - 1) {
        response = result;
      }
    }
  }
  return response;
};

const getFooterVisiblityAndType = (footerData, columnId) => {
  //checks if the footer @ columnId is visible or not & what type of footer it is

  /***
   * isVisible -> undefined or true -> true
   * undefined is treated as total (backward Compatibility)
   */
  const isTotalVisible =
    !isEmpty(footerData) &&
    !isNil(columnId) &&
    !isEmpty(footerData[columnId]) &&
    [undefined, true].includes(footerData[columnId].isVisible);
  const totalType = isTotalVisible
    ? isNil(footerData[columnId].type)
      ? FOOTER_OPERATION_TYPES.TOTAL
      : footerData[columnId].type
    : null;
  return {
    isTotalVisible,
    totalType,
  };
};

const getLocalText = (prefObj, text) => {
  if (isEmpty(prefObj)) {
    return text;
  }
  const langKey = prefObj.lang;
  if (langKey === 'EN') {
    return text;
  } else {
    try {
      if (typeof langKey === 'string' && langKey !== '') {
        const localisationData = (lang) => {
          switch (lang) {
            case 'HN': {
              return require('./languages/localisation_HN.json');
            }
            case 'VN': {
              return require('./languages/localisation_VN.json');
            }
            case 'BE': {
              return require('./languages/localisation_BE.json');
            }
            case 'GU': {
              return require('./languages/localisation_GU.json');
            }
            case 'ID': {
              return require('./languages/localisation_ID.json');
            }
            case 'KN': {
              return require('./languages/localisation_KN.json');
            }
            case 'MA': {
              return require('./languages/localisation_MA.json');
            }
            case 'ML': {
              return require('./languages/localisation_ML.json');
            }
            case 'PT': {
              return require('./languages/localisation_PT.json');
            }
            case 'TA': {
              return require('./languages/localisation_TA.json');
            }
            case 'TE': {
              return require('./languages/localisation_TE.json');
            }
            case 'UR': {
              return require('./languages/localisation_UR.json');
            }
            default: {
              return null;
            }
          }
        };
        const localizedData = localisationData(langKey);
        if (localizedData) {
          const translatedText = localizedData[text];
          if (translatedText?.length) {
            return translatedText;
          }
        }
      }
    } catch {}
  }
  return text;
};

const formatPhoneNumber = (phoneNumber, countryDiallingCode = '+91') => {
  try {
    let onlyDigitsAndPlus = `${phoneNumber}`.replace(/[^0-9|+]/g, ''); //phoneNumber with only digits and '+'
    if (onlyDigitsAndPlus.startsWith('0')) {
      onlyDigitsAndPlus = onlyDigitsAndPlus.substring(1); //removes leading '0' if any
    }
    if (!onlyDigitsAndPlus.startsWith('+')) {
      return countryDiallingCode + onlyDigitsAndPlus; //if it doesn't startswith '+',append countryDiallingCode of user
    }
    return onlyDigitsAndPlus;
  } catch (e) {}
  return phoneNumber; //if some error thrown
};

const getCountryData = (country) => {
  const countryData = require('./countryInfo.json');
  try {
    if (country in countryData) {
      return {
        ...countryData[country],
        currency: countryData[country]?.currency?.[0],
      };
    }
  } catch (e) {}
  return {...countryData.IN, currency: countryData.IN?.currency?.[0]}; //default return INDIA's data
};

const getUserCallingCode = (country) => {
  try {
    const callingCode = `+${getCountryData(country).callingCode[0]}`;
    if (callingCode && callingCode?.length > 1) {
      return callingCode;
    }
  } catch (e) {}
  return '+91'; //if some error thrown or callingCode is not valid
};

const getUserCurrency = (
  country,
  isFullCurrencyName = false,
  isCode = false,
) => {
  if (!country) {
    return isFullCurrencyName ? 'Rupee' : '₹';
  }
  try {
    const countryCurrency = getCountryData(country)?.currency ?? 'INR';
    const currencyData = require('./commonCurrency.json');
    return isFullCurrencyName
      ? currencyData?.[countryCurrency]?.name
      : isCode
      ? currencyData?.[countryCurrency]?.code
      : currencyData?.[countryCurrency]?.symbol ?? '₹';
  } catch (e) {
    return isFullCurrencyName ? 'Rupee' : '₹';
  }
};

const formatCurrency = (
  number,
  country = 'IN',
  colObj = {},
  showCurrencySymbol = true,
  inRawFormat = false,
) => {
  if (number === '-') {
    let currency = getUserCurrency(country) ?? '₹';
    if (!isEmpty(colObj?.CURRENCY)) {
      currency = colObj?.CURRENCY?.symbol;
    }
    return `${currency} -`;
  }
  try {
    if (typeOf(number) === 'string') {
      number = parseFloat(number);
    }
    if (isNaN(number)) {
      return INVALID_INPUT;
    }
    const getPrecision = (precision) => {
      if (isNaN(parseInt(`${precision}`.trim()))) {
        return 2;
      }
      return parseInt(`${precision}`.trim());
    };
    if (inRawFormat) {
      if (showCurrencySymbol) {
        let currency = getUserCurrency(country);
        if (colObj?.columnProperties?.currency) {
          const countryData = require('./commonCurrency.json');
          currency =
            countryData[colObj?.columnProperties?.currency]?.symbol_native ||
            countryData[colObj?.columnProperties?.currency]?.symbol ||
            countryData[colObj?.columnProperties?.currency?.[0]]?.symbol;
        }
        let tempRes = number;
        const {uptoDecimalPlaces} = colObj;
        const precision = getPrecision(uptoDecimalPlaces);
        if (isNil(precision)) {
          return `${currency}${tempRes}`;
        }
        if (
          (country !== 'IN' && isEmpty(colObj)) ||
          colObj?.columnProperties?.currency !== 'INR'
        ) {
          let parts = number.toFixed(precision).split('.');
          parts[0] = parts?.[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          tempRes = parts?.join('.');
        } else {
          let parts = number.toFixed(precision).split('.');
          parts[0] = parts?.[0]?.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
          tempRes = parts?.join('.');
        }
        return `${currency}${tempRes}`;
      }
      return number;
    }
    let res;
    const {uptoDecimalPlaces} = colObj;
    const precision = getPrecision(uptoDecimalPlaces);
    if (
      // country === 'IN' , CURRENCY ==== 'USD'
      (country !== 'IN' && isEmpty(colObj)) ||
      colObj?.columnProperties?.currency !== 'INR'
    ) {
      let parts = number.toFixed(precision).split('.');
      parts[0] = parts?.[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      res = parts?.join('.');
      // res = number.toFixed(precision).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else {
      // res = number.toFixed(precision).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
      let parts = number.toFixed(precision).split('.');
      parts[0] = parts?.[0]?.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
      res = parts?.join('.');
    }
    // if (res.includes('.00')) {
    //   res = res.slice(0, -3);
    // }

    if (!showCurrencySymbol) {
      return res;
    }
    if (!isEmpty(colObj)) {
      let currency = getUserCurrency(country);
      if (colObj?.columnProperties?.currency) {
        const countryData = require('./commonCurrency.json');
        currency =
          countryData[colObj?.columnProperties?.currency]?.symbol_native ||
          countryData[colObj?.columnProperties?.currency]?.symbol ||
          countryData[colObj?.columnProperties?.currency?.[0]]?.symbol;
      }
      return `${currency}${res}`;
    }
    return `₹${inRawFormat ? number : res}`;
  } catch (error) {
    return INVALID_INPUT;
  }
};

const getUnit = (colFileObj) =>
  colFileObj?.UNIT?.unitSymbol ? colFileObj.UNIT.unitSymbol : '';

const formatUnit = (number, colObj, country = 'IN', headerObj = {}) => {
  try {
    if (number === '' || isNil(number)) {
      return '-';
    }
    const unit = getUnit(colObj);
    if (typeof number === 'string') {
      number = parseFloat(number);
    }
    if (isNaN(number)) {
      return INVALID_INPUT;
    }
    let res;
    const {uptoDecimalPlaces} = headerObj;
    const precision = uptoDecimalPlaces;
    if (isNil(precision)) {
      return `${number ?? ''}${unit?.length ? ' ' + unit : ''}`;
    }
    res = number.toFixed(precision);

    return `${res?.length ? res : ''}${unit?.length ? ' ' + unit : ''}`;
  } catch (error) {
    return INVALID_INPUT;
  }
};

const formatTimeDiff = (obj, userPref) => {
  try {
    if (!obj || !isPlainObject(obj) || isEmpty(obj)) {
      return '';
    }
    if (obj.err) {
      return obj.val ?? '';
    }
    let result = '';
    result +=
      obj.hours > 0 ? `${obj.hours} ${getLocalText(userPref, 'Hour')} ` : '';
    result += `${obj.minutes ?? 0} ${getLocalText(userPref, 'Minute')} `;
    return result;
  } catch (e) {
    return '';
  }
};

// Used for WEB Only
const findUserCountry = () => {
  const country = 'IN'; //default
  try {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (
      require('rb-redux/utils/timezoneCountryMapping.json')[userTimezone] ??
      'IN'
    );
  } catch (err) {
    return country;
  }
};

const formatDateDiff = (
  obj,
  userPref,
  isHourMinReq = false,
  isDaysReq = true,
  daysInRawFormat = false,
) => {
  try {
    if (!obj || !isPlainObject(obj) || isEmpty(obj)) {
      return '';
    }
    if (obj.err) {
      return obj.val ?? '';
    }
    let result = '';
    if (isHourMinReq) {
      //time diff total
      result +=
        obj.years > 0 ? `${obj.years} ${getLocalText(userPref, 'Year')} ` : '';
      result +=
        obj.months > 0
          ? `${obj.months} ${getLocalText(userPref, 'Month')} `
          : '';
      result +=
        obj.days > 0 ? `${obj.days} ${getLocalText(userPref, 'Day')} ` : '';
      result +=
        (result.length === 0 || isHourMinReq) && obj.hours > 0
          ? `${obj.hours} ${getLocalText(userPref, 'Hour')} `
          : '';
      result +=
        result.length === 0 || (isHourMinReq && obj.minutes > 0)
          ? `${obj.minutes ?? 0} ${getLocalText(userPref, 'Minute')} `
          : '';
      result += obj.ago ? `${getLocalText(userPref, 'Ago')}` : '';
      if (isDaysReq && (obj.years > 0 || obj.months > 0)) {
        const start = moment();
        const end = moment(start).add(obj.duration);
        const res = start.diff(end, 'days');
        result += ` = ${Math.abs(res)} ${getLocalText(userPref, 'Day')}`;
      }
    } else {
      //date diff and date diff total
      if ('duration' in obj) {
        const start = moment();
        const end = moment(start).add(obj.duration);
        const res = start.diff(end, 'days');
        if (daysInRawFormat) {
          result += `${Math.abs(res)}`;
        } else {
          result += `${Math.abs(res)} ${getLocalText(userPref, 'Day')}`;
          result += obj.ago ? ` ${getLocalText(userPref, 'Ago')}` : '';
        }
      }
    }
    return result;
  } catch (e) {
    return '';
  }
};

const getBooleanFieldValue = (rowValue) => {
  try {
    return rowValue === true;
  } catch (e) {
    return false;
  }
};

const getSelectBoxCell = (
  cellValue,
  cellObj,
  lang = 'EN',
  isPrintingValue = false, // for empty cell return's empty string instead of 'Select'
) => {
  /**
   * !IMP : Any change in this function should also be made in cloud-functions
   */
  let displayName = getLocalText({lang: lang}, 'Select'),
    userSelected = false;
  try {
    if (
      checkIfPlainText(cellValue) &&
      !isEmpty(cellObj && (cellObj.displayName || cellObj?.val))
    ) {
      const val =
        !isNil(cellObj?.val) && !cellObj?.displayName
          ? cellObj?.val
          : lang && checkIfPlainText(cellObj.displayName[lang])
          ? cellObj.displayName[lang]
          : cellObj.displayName.EN;
      displayName = val;
      userSelected = true;
    }
  } catch (e) {}
  return isPrintingValue && displayName === 'Select' && !userSelected
    ? ''
    : displayName;
};

const getAssignTaskCellData = (cellValue, isMyTask = false) => {
  try {
    const assigneeKey = isMyTask ? 'assignedTo' : 'assignee';
    const {
      [assigneeKey]: {name, contact, uid},
      priority,
      note,
      isCompleted,
      dueDate,
    } = cellValue;
    const isValidDueDate = dueDate != null && moment.unix(dueDate).isValid();
    return {
      name: name || contact || '',
      contact,
      priority: priority in ASSIGN_TASK_DETAILS.PRIORITY ? priority : null,
      dueDate: isValidDueDate ? moment.unix(dueDate).format('DD MMM YYYY') : '',
      isCompleted: isCompleted ? true : false,
      note: note || '',
      isOverdue:
        isValidDueDate &&
        !isCompleted &&
        moment.unix(dueDate).diff(moment(), 'days') < 0
          ? true
          : false,
      uid,
    };
  } catch (err) {
    captureError(err);
  }
  return {
    name: '',
    priority: null,
    dueDate: '',
    isCompleted: false,
    note: '',
    isOverdue: false,
  };
};

const checkCellContainsAssignTask = (cellValue) => {
  return !isEmpty(cellValue?.assignee) ? true : false;
};

const getUrlAsPlainText = (cellValue, isHtml = false) => {
  const url = isObject(cellValue?.val) ? '' : cellValue?.val;

  let urlSrc = url;
  if (url !== '' && !url?.startsWith('http')) {
    urlSrc = 'http://' + url;
  }
  const customText = cellValue?.customText ? cellValue?.customText : '';
  if (isHtml) {
    return `<div style='text-align:left'><a href="${urlSrc}">${url}</a>${
      customText && `<br/>(${customText})`
    }</div>`;
  }
  return url + (customText?.length ? '\n(' + customText + ')' : '');
};

const checkCellContainsCreatedInfo = (cellValue, cellObj) => {
  return cellValue && cellObj?.createdTimestamp ? true : false;
};
const checkCellContainesUserColumn = (cellValue, cellObj) => {
  return !isEmpty(cellValue) &&
    !isEmpty(cellObj) &&
    cellObj?.m_uid?.length &&
    (cellObj?.m_id?.length ||
      cellObj?.m_department?.length ||
      cellObj?.m_phone?.length ||
      cellObj?.m_email?.length ||
      cellObj?.m_name?.length ||
      !isEmpty(cellObj?.m_custom))
    ? true
    : false;
};

const getAssignTaskAsPlainText = (
  rowValue,
  userPref,
  isHtml = false,
  separator = null,
  isOrganisationMode = false,
  getInRawFormat = false,
) => {
  try {
    if (checkCellContainsAssignTask(rowValue)) {
      const {name, uid, priority, dueDate, note, isCompleted} =
        getAssignTaskCellData(rowValue);
      const assignedTxt = isOrganisationMode ? `${name}` : `${name}`;
      // : `${getLocalText(userPref, 'Assignee')} : ${name}`;
      // const dueDateTxt = dueDate?.length
      //   ? getLocalText(userPref, 'Due Date') + ' : ' + dueDate
      //   : '';
      // const priorityTxt = priority?.length
      //   ? getLocalText(userPref, 'Priority') +
      //     ' : ' +
      //     getLocalText(userPref, capitalize(priority))
      //   : '';
      // const noteTxt = note?.length
      //   ? getLocalText(userPref, 'Note') + ' : ' + note
      //   : '';
      // const statusTxt = isOrganisationMode
      //   ? ''
      //   : getLocalText(userPref, 'Status') +
      //     ' : ' +
      //     getLocalText(userPref, isCompleted ? 'Completed' : 'Incomplete');
      const elementsArr = [
        assignedTxt,
        // dueDateTxt,
        // priorityTxt,
        // noteTxt,
        // statusTxt,
      ];
      const joinWithSeparator = (separator) => {
        let res = '';
        elementsArr.forEach((element) => {
          res += element?.length
            ? `${res.length ? separator : ''}${element}`
            : '';
        });
        return res;
      };
      if (getInRawFormat) {
        return uid;
      }
      if (isHtml) {
        return `<div style='text-align:left'>${joinWithSeparator(
          '<br/>',
        )}</div>`;
      }
      return joinWithSeparator(separator || '\n');
    }
  } catch (e) {
    captureError(e);
  }
  return '';
};

const getReminderCellValue = (cellValue, cellObj) => {
  let reminderDisplayText = '';
  try {
    if (cellValue && !isEmpty(cellObj) && cellObj.reminderId) {
      //only a valid reminder if it has a reminderId
      reminderDisplayText =
        getPrintTime(cellValue) + '\n' + getPrintDate(cellValue);
    }
  } catch (e) {}
  return reminderDisplayText;
};

const getContactCellValue = (cellValue, contactName) =>
  cellValue + (contactName?.length ? '\n(' + contactName + ')' : '');

const getListCellValue = (rowValue, headerObj, rowObj) =>
  (!isEmpty(headerObj?.listConfig) &&
    !isEmpty(rowObj?.[headerObj?.listConfig?.primaryColId]?.childLinks)) ||
  Boolean(rowValue)
    ? 'View List Items'
    : '';

const getUrlCellValue = (cellValue, customText) => {
  return `${cellValue}${customText?.length ? `\n(${customText})` : ''}`;
};
const getCommentCellValue = (cellValue) => {
  return cellValue?.length ? `${cellValue}` : '';
};

const getCommentAddedByName = (
  commentData,
  isOrganisationMode = false,
  organisationUserInfo = {},
) => {
  if (
    isOrganisationMode &&
    !isEmpty(organisationUserInfo[ORG_MEMBER_PROFILE_FIELDS.NAME])
  ) {
    return organisationUserInfo[ORG_MEMBER_PROFILE_FIELDS.NAME];
  }

  return commentData.addedBy?.name;
};

const getCreatedInfoCellValue = (
  cellValue,
  cellObj,
  isObj = false,
  isHtml = false,
  separator = ' \n ',
  isPlainText = false,
  isOrganisationMode = false,
  organisationUserInfo = {},
) => {
  //has html condition
  let createdInfoDisplayText = '';
  try {
    if (!isEmpty(cellObj)) {
      const name =
        isOrganisationMode &&
        !isEmpty(organisationUserInfo[ORG_MEMBER_PROFILE_FIELDS.NAME])
          ? organisationUserInfo[ORG_MEMBER_PROFILE_FIELDS.NAME]
          : cellObj.firstAddedByContactName;
      const timestamp = cellObj.firstAddedTimestamp;
      if (name && timestamp) {
        //only a valid Last edited if it has a timestamp
        createdInfoDisplayText = isPlainText
          ? `${name}`
          : isHtml
          ? name + '<br>' + getDisplayTimeStamp(timestamp)
          : isObj
          ? {
              createdByContact: name,
              createdTimestamp: getDisplayTimeStamp(timestamp),
            }
          : `${name}${separator}${getDisplayTimeStamp(timestamp)}`;
      }
    }
  } catch {}
  return createdInfoDisplayText;
};

const getCellValueOtp = ({cellObj = {}, isOrganisationMode}) => {
  const {vMeta, val} = cellObj;

  if (val && !isEmpty(vMeta)) {
    const {timeStamp, verifiedByUID} = Object.assign({}, vMeta);
    const {organisation} = getReduxState();
    const {profileData, membersList} = organisation || {};
    const organisationUserInfo = getOrganisationUserInfo(
      verifiedByUID,
      profileData.ownerUID,
      profileData.owner,
      membersList,
    );
    const name =
      isOrganisationMode &&
      !isEmpty(organisationUserInfo[ORG_MEMBER_PROFILE_FIELDS.NAME])
        ? organisationUserInfo[ORG_MEMBER_PROFILE_FIELDS.NAME]
        : '';
    const verifiedOnTimestamp = getDisplayTimeStamp(timeStamp);

    return `Verified by, ${name}, ${verifiedOnTimestamp}`;
  }
  return 'Not Verified';
};

const getUserColumnCellValue = (
  rowProperties,
  isObj = false,
  isHtml = false,
  separator = ' \n ',
  isPlainText = false,
) => {
  //has html condition
  let userInfoDisplayText = '';
  try {
    if (!isEmpty(rowProperties)) {
      let {m_id, m_department, m_email, m_name, m_phone} = rowProperties ?? {};
      m_id = m_id?.length ? m_id : '';
      m_department = m_department?.length ? m_department : '';
      m_email = m_email?.length ? m_email : '';
      m_name = m_name?.length ? m_name : '';
      m_phone = m_phone?.length ? m_phone : '';

      if (
        m_id?.length ||
        m_department?.length ||
        m_email?.length ||
        m_name?.length ||
        m_phone?.length
      ) {
        const displayVal = m_name?.length
          ? `${m_name}`
          : m_email?.length
          ? `${m_email}`
          : m_phone?.length
          ? `${m_phone}`
          : '';
        //only a valid Last edited if it has a timestamp
        userInfoDisplayText = isPlainText
          ? displayVal
          : isHtml
          ? // ? m_name + '<br>' + getDisplayTimeStamp(timestamp)
            displayVal
          : isObj
          ? {m_name, m_id, m_department, m_email, m_phone}
          : `${displayVal}`;
      }
    }
  } catch {}
  return userInfoDisplayText;
};

const getDisplayTimeStamp = (timestamp) => {
  try {
    return `${moment.utc(timestamp).local().format('DD MMM YYYY, hh:mm a')}`;
  } catch (e) {}
  return '';
};

const checkCellContainsAudio = (cellValue) => {
  let res = false;
  try {
    res =
      isArray(cellValue) &&
      cellValue.length &&
      cellValue.every((obj) => obj?.audioRef); //all array elements have audioRef key
  } catch (e) {}
  return res;
};

const checkCellContainsImage = (cellValue) => {
  let res = false;
  try {
    res =
      isArray(cellValue) &&
      cellValue.length &&
      cellValue.every((obj) => obj?.imageRef); //all array elements have imageRef key
  } catch (e) {}
  return res;
};

const checkCellContainsAttachment = (cellValue) => {
  let res = false;
  try {
    res =
      isArray(cellValue) &&
      cellValue.length &&
      cellValue.every((obj) => obj?.docRef); //all array elements have docRef key
  } catch (e) {}
  return res;
};

const checkCellContainsVideo = (cellValue) => {
  let res = false;
  try {
    res =
      isArray(cellValue) &&
      cellValue.length &&
      cellValue.every((obj) => obj?.videoRef); //all array elements have docRef key
  } catch (e) {}
  return res;
};

const checkCellContainsComment = (cellValue, rowData) => {
  let res = false;
  try {
    res = Boolean(
      checkIfPlainText(cellValue) &&
        rowData?.uid &&
        rowData?.timestamp &&
        rowData?.addedByName,
    );
  } catch {}
  return res;
};

const checkCellContainsMap = (cellValue, rowData) => {
  let res = false;
  try {
    res =
      cellValue && checkIfPlainText(cellValue) && !isEmpty(rowData.coordinate); // ROW DATA is data which contains "val" of cell.
  } catch (e) {}
  return res;
};

const checkCellContainsContact = (cellValue, rowData) => {
  let res = false;
  try {
    res = cellValue && checkIfPlainText(cellValue);
  } catch (e) {}
  return res;
};

const checkCellContainsUrl = (cellValue, rowData) => {
  let res = false;
  try {
    res = cellValue && isString(cellValue) && 'customText' in rowData;
  } catch (e) {}
  return res;
};

const checkCellContainsReminder = (cellObj) => {
  return getReminderCellValue(cellObj?.val, cellObj) !== '';
};

const checkCellContainsDateTime = (cellObj, isTime = false) => {
  let res = false;
  try {
    const cellValue = cellObj?.val;
    res =
      cellValue &&
      isNumber(cellValue) &&
      (isTime
        ? 'displayTime' in cellObj && !isEmpty(cellObj['displayTime'])
        : 'displayDate' in cellObj && !isEmpty(cellObj['displayDate']));
  } catch (e) {}
  return res;
};

const getAddRowLimit = (
  isSharedDoc,
  currentLength,
  applyUserRestrictions,
  restrictionConfig,
  activeDocumentMeta,
) => {
  try {
    const defaultLimit = 5;
    const config = Object.assign({}, restrictionConfig);
    if (
      ((isSharedDoc && activeDocumentMeta.applyDocRestrictions_v2) ||
        (!isSharedDoc && applyUserRestrictions)) &&
      config?.maxRows
    ) {
      if (currentLength < config.maxRows) {
        const updatedLimit = config.maxRows - currentLength;
        if (updatedLimit <= defaultLimit) {
          return updatedLimit;
        } else {
          return defaultLimit;
        }
      } else if (currentLength >= config.maxRows) {
        return 0;
      }
    } else {
      return defaultLimit;
    }
  } catch (error) {
    captureError(error);
  }
};

/**
 * Returns true if {rowData} contians value for column in {colData}.
 * @param {object} rowData - Cell Object
 * @param {object} colData - Column Object for c data in .
 * @returns
 */
const checkIfValueExistOnCell = (
  rowData,
  colData,
  rowObj,
  getInRawFormat = false,
) => {
  try {
    if (!rowData) return false;
    let cellValue = rowData?.val;
    const colType =
      colData?.fieldType === FIELD_TYPE_ID.TABLE
        ? colData?.subType
        : colData?.fieldType;
    const subType = colData?.subType;
    const parentSubType = colData?.parentSubType;
    if (!cellValue && colType === FIELD_TYPE_ID.LIST) {
      const listConfig = colData?.listConfig ?? {};
      const primaryColId = listConfig?.primaryColId ?? null;
      if (primaryColId) {
        if (
          !isEmpty(rowObj?.[primaryColId]) &&
          'childLinks' in rowObj[primaryColId] &&
          !isEmpty(rowObj?.[primaryColId]?.childLinks)
        ) {
          cellValue = true;
        } else {
          cellValue = false;
        }
      } else {
        cellValue = false;
      }
    }

    const TEXT_SEARCH_TYPES = [
      FIELD_TYPE_ID.MAPS,
      FIELD_TYPE_ID.TEXT,
      FIELD_TYPE_ID.COMMENT,
      FIELD_TYPE_ID.NUMBER,
      FIELD_TYPE_ID.AUTO_INCREMENT_ID,
      FIELD_TYPE_ID.TIME,
      FIELD_TYPE_ID.DATE,
      FIELD_TYPE_ID.DATE_TIME,
      FIELD_TYPE_ID.CONTACT,
      FIELD_TYPE_ID.FORMULA,
      FIELD_TYPE_ID.RUPEE,
      FIELD_TYPE_ID.REMINDER,
      FIELD_TYPE_ID.UNIT,
      FIELD_TYPE_ID.BARCODE,
      FIELD_TYPE_ID.URL,
      FIELD_TYPE_ID.EMAIL,
      FIELD_TYPE_ID.CREATED_INFO,
      FIELD_TYPE_ID.USER_COLUMN,
      FIELD_TYPE_ID.LIST,
      FIELD_TYPE_ID.NO_OF_DAYS,
      FIELD_TYPE_ID.STRING_FORMULA,
    ].includes(colType);

    const OBJECT_TYPE_VALUES = [
      FIELD_TYPE_ID.SELECT_POP_UP,
      FIELD_TYPE_ID.LABEL,
      FIELD_TYPE_ID.DOCUMENT,
      FIELD_TYPE_ID.VIDEO,
      FIELD_TYPE_ID.IMAGE,
      FIELD_TYPE_ID.AUDIO,
      FIELD_TYPE_ID.ASSIGN_TASK,
    ].includes(colType);

    const BOOLEAN_TYPE_VALUES = [
      FIELD_TYPE_ID.SWITCH,
      FIELD_TYPE_ID.CHECKBOX,
      FIELD_TYPE_ID.OTP,
    ].includes(colType);

    if (TEXT_SEARCH_TYPES) {
      /**
       * Set initial value for text based columns.
       * Note : These column types are not explicitly checked for values.
       */
      let val = typeof cellValue === 'number' ? `${cellValue}` : cellValue;

      if (colType === FIELD_TYPE_ID.SWITCH) {
        val = cellValue ? 'Yes' : 'No';
      }
      if (colType === FIELD_TYPE_ID.LIST) {
        val = cellValue ? 'Yes' : '';
      } else if (colType === FIELD_TYPE_ID.MAPS) {
        val = checkCellContainsMap(cellValue, rowData) ? cellValue : '';
      } else if (colType === FIELD_TYPE_ID.COMMENT) {
        val = checkCellContainsComment(cellValue, rowData) ? 'Yes' : '';
      } else if (colType === FIELD_TYPE_ID.DATE) {
        val = getPrintDate(cellValue, colData?.dateFormat);
      } else if (colType === FIELD_TYPE_ID.REMINDER) {
        val =
          getReminderCellValue(cellValue, rowData) +
          ' ' +
          rowData?.reminderMessage;
      } else if (colType === FIELD_TYPE_ID.CREATED_INFO) {
        val = getCreatedInfoCellValue(cellValue, rowObj?.rowProperties);
      } else if (colType === FIELD_TYPE_ID.USER_COLUMN) {
        val = getUserColumnCellValue(rowObj?.rowProperties);
      } else if (colType === FIELD_TYPE_ID.TIME) {
        val = getPrintTime(cellValue);
      } else if (colType === FIELD_TYPE_ID.DATE_TIME) {
        val = getPrintDateTime(cellValue);
      } else if (
        colType === FIELD_TYPE_ID.UNIT ||
        (colType === FIELD_TYPE_ID.FORMULA &&
          colData.subType === FIELD_TYPE_ID.UNIT)
      ) {
        const unitVal = formatUnit(cellValue, {}, 'IN');
        val = unitVal !== INVALID_INPUT ? unitVal.replace(/ /gm, '') : '';
      } else if (colType === FIELD_TYPE_ID.CONTACT) {
        val = cellValue;
      } else if (colType === FIELD_TYPE_ID.FORMULA) {
        if ([subType, parentSubType].includes(FIELD_TYPE_ID.RUPEE)) {
          val = formatCurrency(cellValue, 'IN', colData);
        } else if ([subType, parentSubType].includes(FIELD_TYPE_ID.DATE)) {
          val = formatDateDiff(cellValue, {lang: 'EN'});
        } else if ([subType, parentSubType].includes(FIELD_TYPE_ID.TIME)) {
          val = formatTimeDiff(cellValue, {lang: 'EN'});
        }
      } else if (colType === FIELD_TYPE_ID.EMAIL) {
        return isEmail(val);
      }
      if (
        !isNil(val) && typeof val === 'number' ? `${val}`.length : val?.length
      ) {
        return getInRawFormat ? val : true;
      }
    } else if (OBJECT_TYPE_VALUES) {
      if (colType === FIELD_TYPE_ID.SELECT_POP_UP) {
        return checkIfCellContainsSelectBox(rowData);
      } else if (colType === FIELD_TYPE_ID.LABEL) {
        return getLabelValueArray(rowData?.val).length !== 0;
      } else if (colType === FIELD_TYPE_ID.AUDIO) {
        return rowData?.val?.length;
      } else if (colType === FIELD_TYPE_ID.DOCUMENT) {
        return rowData?.val?.length;
      } else if (colType === FIELD_TYPE_ID.IMAGE) {
        return rowData?.val?.length;
      } else if (colType === FIELD_TYPE_ID.VIDEO) {
        return rowData?.val?.length && rowData?.val?.[0]?.uri;
      } else if (colType === FIELD_TYPE_ID.ASSIGN_TASK) {
        return rowData?.val?.assignee?.contact?.length;
      } else if (colType === FIELD_TYPE_ID.AUTO_INCREMENT_ID) {
        return rowData?.val?.length;
      }
    } else if (BOOLEAN_TYPE_VALUES) {
      if (colType === FIELD_TYPE_ID.OTP) {
        return isBoolean(rowData?.val);
      }
      return rowData?.val ? true : false;
    }
  } catch (err) {
    captureError(err);
  }
  return false;
};

const checkDefaultValueIsValid = (defaultVal, colData) => {
  if (colData.fieldType === FIELD_TYPE_ID.TABLE) {
    return !isEmpty(defaultVal);
  }
  return checkIfValueExistOnCell(defaultVal, colData);
};

const isSearchValueExistsByType = ({
  searchText,
  colData,
  rowData,
  fileObj,
  userCountry,
  language,
  rowObj,
}) => {
  let exist = false;
  try {
    const BOOLEAN_FIELDS = [
      FIELD_TYPE_ID.SWITCH,
      FIELD_TYPE_ID.CHECKBOX,
      FIELD_TYPE_ID.OTP,
    ];
    let val = '';
    let colType = colData?.fieldType;
    if (colType === FIELD_TYPE_ID.TABLE) {
      colType = colData?.subType || null;
    }
    const cellValue = rowData?.val;
    if (isNil(cellValue) && !BOOLEAN_FIELDS.includes(colType)) {
      return false;
    }
    searchText = `${searchText}`.toLowerCase?.();
    const TEXT_SEARCH_TYPES = [
      FIELD_TYPE_ID.MAPS,
      FIELD_TYPE_ID.TEXT,
      FIELD_TYPE_ID.COMMENT,
      FIELD_TYPE_ID.NUMBER,
      FIELD_TYPE_ID.TIME,
      FIELD_TYPE_ID.DATE,
      FIELD_TYPE_ID.CONTACT,
      FIELD_TYPE_ID.SWITCH,
      FIELD_TYPE_ID.FORMULA,
      FIELD_TYPE_ID.RUPEE,
      FIELD_TYPE_ID.REMINDER,
      FIELD_TYPE_ID.UNIT,
      FIELD_TYPE_ID.BARCODE,
      FIELD_TYPE_ID.TABLE,
      FIELD_TYPE_ID.URL,
    ].includes(colType);

    const OBJECT_TYPE_VALUES = [
      FIELD_TYPE_ID.SELECT_POP_UP,
      FIELD_TYPE_ID.LABEL,
      FIELD_TYPE_ID.ASSIGN_TASK,
      FIELD_TYPE_ID.CREATED_INFO,
      FIELD_TYPE_ID.USER_COLUMN,
    ].includes(colType);

    if (TEXT_SEARCH_TYPES && checkIfPlainText(cellValue)) {
      val = typeof cellValue === 'number' ? `${cellValue}` : cellValue;
      if (colType === FIELD_TYPE_ID.RUPEE) {
        searchText = searchText?.replace?.(/,/g, '');
      } else if (colType === FIELD_TYPE_ID.SWITCH) {
        val = cellValue ? 'Yes' : 'No';
      } else if (colType === FIELD_TYPE_ID.COMMENT) {
        val = checkCellContainsComment(cellValue, rowData)
          ? getCommentCellValue(cellValue)
          : '';
      } else if (colType === FIELD_TYPE_ID.DATE) {
        val = getPrintDate(cellValue, colData?.dateFormat);
      } else if (colType === FIELD_TYPE_ID.REMINDER) {
        val =
          getReminderCellValue(cellValue, rowData) +
          ' ' +
          rowData.reminderMessage;
      } else if (colType === FIELD_TYPE_ID.CREATED_INFO) {
        val = getCreatedInfoCellValue(cellValue, rowObj.rowProperties);
      } else if (colType === FIELD_TYPE_ID.USER_COLUMN) {
        val = getUserColumnCellValue(rowObj.rowProperties);
      } else if (colType === FIELD_TYPE_ID.TIME) {
        val = getPrintTime(cellValue);
      } else if (
        colType === FIELD_TYPE_ID.UNIT ||
        (colType === FIELD_TYPE_ID.FORMULA &&
          colData.subType === FIELD_TYPE_ID.UNIT)
      ) {
        val = formatUnit(
          cellValue,
          colData.subType === FIELD_TYPE_ID.UNIT
            ? colData.unitDependentColumn
              ? fileObj?.[colData.unitDependentColumn]
              : {}
            : fileObj?.[colData.id],
          userCountry,
        );
      } else if (colType === FIELD_TYPE_ID.CONTACT) {
        const contactName = rowData?.contactName || '';
        val = `${cellValue} ${contactName}`;
      } else if (colType === FIELD_TYPE_ID.URL) {
        const customText = rowData?.customText || '';
        val = `${cellValue} ${customText}`;
      }
      exist = val?.toLowerCase?.().indexOf?.(searchText) != -1;
    } else if (OBJECT_TYPE_VALUES) {
      if (colType === FIELD_TYPE_ID.SELECT_POP_UP) {
        val = getSelectBoxCell(rowData?.val, rowData, language, true);
        exist = val?.toLowerCase?.().indexOf?.(searchText) != -1;
      } else if (colType === FIELD_TYPE_ID.LABEL) {
        const labelArray = getLabelValueArray(cellValue);
        exist = labelArray.some((vo) =>
          `${vo?.val ?? ''}`.toLowerCase().includes(searchText),
        );
      } else if (colType === FIELD_TYPE_ID.ASSIGN_TASK) {
        if (checkCellContainsAssignTask(cellValue)) {
          const {name, dueDate, isCompleted} = getAssignTaskCellData(cellValue);
          exist =
            `${name}`.toLowerCase().includes(searchText) ||
            (!isCompleted && dueDate?.length
              ? `${dueDate}`.toLowerCase().includes(searchText)
              : false);
        }
      } else if (
        colType === FIELD_TYPE_ID.CREATED_INFO &&
        checkCellContainsCreatedInfo(cellValue, rowData)
      ) {
        const {createdByContact, createdTimestamp} = getCreatedInfoCellValue(
          cellValue,
          rowData,
          true,
        );
        exist =
          `${createdByContact}`.toLowerCase().includes(searchText) ||
          `${createdTimestamp}`.toLowerCase().includes(searchText);
      } else if (
        colType === FIELD_TYPE_ID.USER_COLUMN &&
        checkCellContainesUserColumn(cellValue, rowData)
      ) {
        const {m_name} = getUserColumnCellValue(rowData, true);
        exist = `${m_name}`.toLowerCase().includes(searchText);
      }
    }
  } catch (error) {
    captureError(error);
  }
  return exist;
};

/**
 * Function to return cell value of labels
 * @param {object} cellValue
 * @returns Labels Array
 */
const getLabelValueArray = (cellValue) => {
  /**
   * !IMP : Any change in this function should also be made in cloud-functions
   */
  let res = [];
  try {
    if (isArray(cellValue) && cellValue.length) {
      res = cellValue.filter(
        (obj) => !isEmpty(obj && obj.displayName) && !isNil(obj.val),
      ); //array elements which have displayName key && valid value
    }
  } catch (e) {}
  return res;
};

const getPrintAudio = (userPref, length) => {
  return `${length} ${getLocalText(userPref, 'audio file attached')}`;
};

const getPrintDateTime = (rowValue) => {
  try {
    if (rowValue) {
      return moment(rowValue).format('DD/MM/YYYY hh:mm A');
    }
  } catch (e) {}
  return '';
};

const getPrintDate = (unixTime, dateFormat) => {
  try {
    if (unixTime) {
      const date = moment.unix(unixTime);
      switch (dateFormat) {
        case DATE_FORMATS.MONTH: {
          return date.format('MMMM YYYY');
        }
        case DATE_FORMATS.QUARTER: {
          const quarter = date.quarter();
          return quarter >= 0 && quarter <= 4
            ? `${date.startOf('quarter').format('MMM')}-${date
                .endOf('quarter')
                .format('MMM')} ${date.format('YYYY')}`
            : '';
        }
        case DATE_FORMATS.YEAR: {
          return date.format('YYYY');
        }
      }
      return date.format('DD/MM/YYYY');
    }
  } catch (e) {}
  return '';
};

const getPrintTime = (unixTime) => {
  try {
    if (unixTime) {
      return moment.unix(unixTime).format(`hh:mm A`);
    }
  } catch (e) {}
  return '';
};

const getPrintAttachment = (userPref, length) => {
  return `${length} ${getLocalText(userPref, 'file attached')}`;
};

const getPrintVideo = (userPref, rowValue) => {
  return `${rowValue[0].name}`;
};

const getRandomColor = () => {
  var letters = 'ABCDEF'.split('');
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

const combineArrayWithObjects = (array1, array2) => {
  const combinedArray = [];
  for (let i = 0; i < array1.length; i++) {
    combinedArray.push({...array1[i], ...array2[i]});
  }
  return combinedArray;
};

const formatAndValidateEmail = (email) => {
  let result = '';
  try {
    if (email) {
      result = email.replace(/ /g, '');
    }
  } catch (error) {
    captureError(error);
  }
  //checks name@name.com format
  let isBasicValid = false;
  try {
    isBasicValid = /\S+@\S+\.\S+/.test(result);
  } catch {}
  return [isBasicValid, result];
};

const checkIfEmail = (contact) => {
  try {
    return contact.includes('@');
  } catch (e) {}
};

const checkIfPlainText = (text) => {
  try {
    const type = typeof text;
    return ['string', 'number', 'boolean', 'bigint'].includes(type);
  } catch (e) {}
};

const toPlainString = (text) => {
  try {
    if (checkIfPlainText(text)) {
      return String(text);
    }
  } catch (e) {}
  return '';
};

function getLabelStyleFromObj(obj, isTextColor = false) {
  const oldStyleObj = obj?.styleObj?.style?.react?.text;
  const newStyleObj = obj?.valStyleObj;
  const isStylingAvailable = !isEmpty(oldStyleObj);
  const isNewStylingObjAvailable = !isEmpty(newStyleObj);
  const getColor =
    isStylingAvailable && oldStyleObj?.color
      ? oldStyleObj.color
      : isNewStylingObjAvailable && newStyleObj?.color
      ? newStyleObj.color
      : '#000000';
  const getBackgroundColor =
    isStylingAvailable && oldStyleObj?.backgroundColor
      ? oldStyleObj.backgroundColor
      : isNewStylingObjAvailable && newStyleObj?.backgroundColor
      ? newStyleObj.backgroundColor
      : isTextColor
      ? '#000000'
      : '#FFFFFF';
  return {
    color: getColor,
    backgroundColor: getBackgroundColor,
    isStylingAvailable,
    isNewStylingObjAvailable,
  };
}

const convertCellDataToTextForWeb = (
  cellObj,
  headerObj,
  userCountry,
  userPref,
  fileObj,
  rowObj,
  getInRawFormat = false,
  extra = {
    showCurrencySymbol: true,
    forceSkipFormulaFormatting: false,
  },
) => {
  let rowValue = [
    FIELD_TYPE_ID.CREATED_INFO,
    FIELD_TYPE_ID.USER_COLUMN,
  ].includes(headerObj.fieldType)
    ? rowObj.rowProperties
    : cellObj?.val ?? '';

  if (rowValue !== '') {
    fileObj = Object.assign({}, fileObj);
    if (headerObj.fieldType === FIELD_TYPE_ID.TABLE) {
      if (!TABLE_LINK_DISABLED_COLOUMN_TYPES.includes(headerObj.subType)) {
        return convertCellDataToTextForWeb(
          cellObj,
          Object.assign({}, headerObj, {fieldType: headerObj.subType}),
          userCountry,
          userPref,
          fileObj,
          rowObj,
        );
      } else return '';
    }
    if (headerObj.fieldType === FIELD_TYPE_ID.RUPEE) {
      rowValue = formatCurrency(rowValue, userCountry, headerObj, true);
    } else if (headerObj.fieldType === FIELD_TYPE_ID.DATE) {
      rowValue = getPrintDate(rowValue, headerObj.dateFormat);
    } else if (headerObj.fieldType === FIELD_TYPE_ID.TIME) {
      rowValue = getPrintTime(rowValue);
    } else if (headerObj.fieldType === FIELD_TYPE_ID.IMAGE) {
      rowValue = checkCellContainsImage(rowValue)
        ? `${rowValue.length} ${getLocalText(userPref, 'Image')}`
        : '';
    } else if (
      headerObj.fieldType === FIELD_TYPE_ID.SWITCH ||
      headerObj.fieldType === FIELD_TYPE_ID.CHECKBOX
    ) {
      rowValue = getBooleanFieldValue(rowValue)
        ? getLocalText(userPref, 'Yes')
        : getLocalText(userPref, 'No');
    } else if (headerObj.fieldType === FIELD_TYPE_ID.FORMULA) {
      const isDisplayInPercentage =
        headerObj?.columnProperties?.[
          COLUMN_PROPERTY_KEYS.DISPLAY_IN_PERCENTAGE
        ];

      if (extra.forceSkipFormulaFormatting) {
        if (rowValue === 'Invalid Input') {
          return '';
        }
        return rowValue;
      } else if (
        [headerObj.subType, headerObj.parentSubType].includes(
          FIELD_TYPE_ID.RUPEE,
        ) &&
        !isDisplayInPercentage
      ) {
        rowValue = formatCurrency(
          rowValue,
          userCountry,
          headerObj,
          extra.showCurrencySymbol,
        );
      } else if (
        [headerObj.subType, headerObj.parentSubType].includes(
          FIELD_TYPE_ID.DATE,
        )
      ) {
        rowValue = formatDateDiff(rowValue, userPref);
      } else if (
        [headerObj.subType, headerObj.parentSubType].includes(
          FIELD_TYPE_ID.TIME,
        )
      ) {
        rowValue = formatTimeDiff(rowValue, userPref);
      } else if (
        headerObj.subType === FIELD_TYPE_ID.UNIT &&
        !isDisplayInPercentage
      ) {
        rowValue = formatUnit(rowValue, fileObj?.[headerObj.id], userCountry);
      }

      //Percetage Handling % : TODO:Note Date and Time not handled
      if (
        ![FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.TIME].includes(
          headerObj?.subType,
        ) &&
        isDisplayInPercentage &&
        !isNaN(parseFloat(rowValue)) &&
        checkIfPlainText(rowValue)
      ) {
        rowValue = getInRawFormat
          ? `${parseFloat(rowValue)}`
          : `${parseFloat(rowValue)}%`;
      }
    } else if (headerObj.fieldType === FIELD_TYPE_ID.STRING_FORMULA) {
      rowValue = checkIfPlainText(rowValue) ? rowValue : '';
    } else if (
      headerObj.fieldType === FIELD_TYPE_ID.SELECT_POP_UP &&
      !isEmpty(cellObj)
    ) {
      rowValue = getSelectBoxCell(rowValue, cellObj, userPref?.lang, true);
    } else if (
      headerObj.fieldType === FIELD_TYPE_ID.LABEL &&
      !isEmpty(cellObj)
    ) {
      const labelValues = getLabelValueArray(rowValue);
      rowValue =
        labelValues && isArray(labelValues)
          ? labelValues.map((item) => item.val).join(', ')
          : '';
    } else if (headerObj.fieldType === FIELD_TYPE_ID.DOCUMENT) {
      rowValue = checkCellContainsAttachment(rowValue)
        ? getPrintAttachment(userPref, rowValue.length)
        : '';
    } else if (headerObj.fieldType === FIELD_TYPE_ID.REMINDER) {
      rowValue = getReminderCellValue(rowValue, cellObj) ?? '';
    } else if (headerObj.fieldType === FIELD_TYPE_ID.CREATED_INFO) {
      rowValue = getCreatedInfoCellValue(rowValue, rowObj.rowProperties);
    } else if (headerObj.fieldType === FIELD_TYPE_ID.AUDIO) {
      rowValue = checkCellContainsAudio(rowValue)
        ? getPrintAudio(userPref, rowValue.length)
        : '';
    } else if (headerObj.fieldType === FIELD_TYPE_ID.MAPS) {
      if (!checkIfPlainText(rowValue) || isEmpty(cellObj.coordinate)) {
        rowValue = '';
      }
    } else if (headerObj.fieldType === FIELD_TYPE_ID.UNIT) {
      rowValue = formatUnit(rowValue, fileObj?.[headerObj.id], userCountry);
    } else if (headerObj.fieldType === FIELD_TYPE_ID.ASSIGN_TASK) {
      rowValue = getAssignTaskAsPlainText(rowValue, userPref);
    } else if (headerObj.fieldType === FIELD_TYPE_ID.URL) {
      rowValue = getUrlCellValue(rowValue, cellObj.customText);
    } else if (headerObj.fieldType === FIELD_TYPE_ID.COMMENT) {
      rowValue = getCommentCellValue(rowValue);
    } else if (headerObj.fieldType === FIELD_TYPE_ID.CONTACT) {
      rowValue = getContactCellValue(rowValue, cellObj.contactName);
    }
  }

  return checkIfPlainText(rowValue) ? rowValue : '';
};

const getDateFilterRangeForConditionCheck = (
  dateString,
  dateFormat,
  isEndDate = false,
) => {
  try {
    const format = 'DD/MM/YYYY';
    dateFormat = dateFormat in DATE_FORMATS ? dateFormat : DATE_FORMATS.DATE;
    const date = moment(dateString, format);
    return isEndDate
      ? date.endOf(dateFormat).format(format)
      : date.startOf(dateFormat).format(format);
  } catch {}
  return [];
};

const getDateTimePickType = ({isTime, dateFormat}) =>
  isTime
    ? 'minute'
    : dateFormat === DATE_FORMATS.MONTH
    ? 'month'
    : dateFormat === DATE_FORMATS.QUARTER
    ? 'quarter'
    : dateFormat === DATE_FORMATS.YEAR
    ? 'year'
    : 'day';

const getDateTimeCellObj = (
  currentCellObj,
  isTime = false,
  isNew = false,
  dateFormat,
) => {
  const operation = isTime ? getPrintTime : getPrintDate;
  if (isNew) {
    const pickBy = getDateTimePickType({isTime, dateFormat});
    currentCellObj = Object.assign({}, currentCellObj, {
      val: moment().startOf(pickBy).unix(),
    });
  }
  return Object.assign(
    {},
    currentCellObj,
    {
      [isTime ? 'displayTime' : 'displayDate']: operation(
        currentCellObj?.val,
        null,
      ),
    },
    !isTime && dateFormat != null && dateFormat !== DATE_FORMATS.DATE
      ? {formatted: operation(currentCellObj?.val, dateFormat)}
      : null,
  );
};

const convertCellDataToText = (
  cellObj,
  headerObj,
  userCountry,
  userPref,
  fileObj,
  rowObj,
  isOrganisationMode,
  organisationUserInfo,
  getInRawFormat = false,
  calculateDaysFormulaValAutoFillTableLink = false,
  options = {
    getDisplayDate: false,
    forceSkipFormulaFormatting: false,
    getOTPCellAsText: false,
  },
) => {
  let rowValue = cellObj?.val ?? '';
  if (headerObj?.fieldType === FIELD_TYPE_ID.LIST) {
    rowValue = true;
  }
  if (
    rowValue !== '' ||
    (([FIELD_TYPE_ID.CREATED_INFO, FIELD_TYPE_ID.USER_COLUMN].includes(
      headerObj.fieldType,
    ) ||
      (FIELD_TYPE_ID.TABLE === headerObj.fieldType &&
        headerObj.subType === FIELD_TYPE_ID.USER_COLUMN)) &&
      !isEmpty(rowObj.rowProperties))
  ) {
    fileObj = Object.assign({}, fileObj);
    if (headerObj.fieldType === FIELD_TYPE_ID.TABLE) {
      if (
        !TABLE_LINK_DISABLED_COLOUMN_TYPES.includes(headerObj.subType) &&
        headerObj?.subType !== FIELD_TYPE_ID.TABLE
      ) {
        return convertCellDataToText(
          cellObj,
          Object.assign({}, headerObj, {
            fieldType: headerObj.subType,
            subType: headerObj?.parentSubType,
          }),
          userCountry,
          userPref,
          fileObj,
          rowObj,
          isOrganisationMode,
          organisationUserInfo,
          getInRawFormat,
        );
      } else return '';
    }
    if (headerObj.fieldType === FIELD_TYPE_ID.RUPEE) {
      rowValue = getInRawFormat
        ? rowValue
        : formatCurrency(rowValue, userCountry, headerObj, true, true);
    } else if (headerObj.fieldType === FIELD_TYPE_ID.DATE) {
      rowValue = getPrintDate(
        rowValue,
        options?.getDisplayDate ? null : headerObj.dateFormat,
      );
    } else if (headerObj.fieldType === FIELD_TYPE_ID.TIME) {
      rowValue = getPrintTime(rowValue);
    } else if (headerObj.fieldType === FIELD_TYPE_ID.DATE_TIME) {
      rowValue = getPrintDateTime(rowValue);
    } else if (headerObj.fieldType === FIELD_TYPE_ID.IMAGE) {
      rowValue = getInRawFormat
        ? `${rowValue.length}`
        : checkCellContainsImage(rowValue)
        ? `${rowValue.length} ${getLocalText(userPref, 'Image')}`
        : '';
    } else if (
      headerObj.fieldType === FIELD_TYPE_ID.SWITCH ||
      headerObj.fieldType === FIELD_TYPE_ID.CHECKBOX
    ) {
      rowValue = getInRawFormat
        ? getBooleanFieldValue(rowValue)
        : getBooleanFieldValue(rowValue)
        ? getLocalText(userPref, 'Yes')
        : getLocalText(userPref, 'No');
    } else if (headerObj.fieldType === FIELD_TYPE_ID.FORMULA) {
      const displayInPercentage =
        headerObj?.columnProperties?.[
          COLUMN_PROPERTY_KEYS.DISPLAY_IN_PERCENTAGE
        ];

      if (options?.forceSkipFormulaFormatting) {
        if (rowValue === 'Invalid Input') return '';
      }

      if (headerObj.subType === FIELD_TYPE_ID.RUPEE && !displayInPercentage) {
        rowValue = getInRawFormat
          ? rowValue
          : formatCurrency(rowValue, userCountry, headerObj, true, true);
      } else if (headerObj.subType === FIELD_TYPE_ID.DATE) {
        rowValue = formatDateDiff(rowValue, userPref);
      } else if (headerObj.subType === FIELD_TYPE_ID.TIME) {
        rowValue = formatTimeDiff(rowValue, userPref);
      } else if (
        headerObj.subType === FIELD_TYPE_ID.UNIT &&
        !displayInPercentage
      ) {
        rowValue = getInRawFormat
          ? rowValue
          : formatUnit(rowValue, fileObj?.[headerObj.id], userCountry);
      }

      //Percetage Handling % : TODO:Note Date and Time not handled
      if (
        ![FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.TIME].includes(
          headerObj?.subType,
        ) &&
        displayInPercentage &&
        !isNaN(parseFloat(rowValue)) &&
        checkIfPlainText(rowValue)
      ) {
        rowValue = getInRawFormat
          ? `${parseFloat(rowValue)}`
          : `${parseFloat(rowValue)}%`;
      }
    } else if (
      headerObj.fieldType === FIELD_TYPE_ID.SELECT_POP_UP &&
      !isEmpty(cellObj)
    ) {
      rowValue = getSelectBoxCell(
        rowValue,
        cellObj,
        getInRawFormat ? 'EN' : userPref?.lang,
        true,
      );
    } else if (
      headerObj.fieldType === FIELD_TYPE_ID.LABEL &&
      !isEmpty(cellObj)
    ) {
      const labelValues = getLabelValueArray(rowValue);
      rowValue =
        labelValues && isArray(labelValues)
          ? labelValues.map((item) => item.val).join(', ')
          : '';
    } else if (headerObj.fieldType === FIELD_TYPE_ID.DOCUMENT) {
      rowValue = checkCellContainsAttachment(rowValue)
        ? getInRawFormat
          ? `${rowValue.length}`
          : getPrintAttachment(userPref, rowValue.length)
        : '';
    } else if (headerObj.fieldType === FIELD_TYPE_ID.VIDEO) {
      rowValue = checkCellContainsVideo(rowValue)
        ? getPrintVideo(userPref, rowValue)
        : '';
    } else if (headerObj.fieldType === FIELD_TYPE_ID.REMINDER) {
      rowValue = getReminderCellValue(rowValue, cellObj) ?? '';
    } else if (headerObj.fieldType === FIELD_TYPE_ID.CREATED_INFO) {
      rowValue = getCreatedInfoCellValue(
        rowValue,
        rowObj?.rowProperties,
        false,
        false,
        ENV ? '\n' : ', ',
        false,
        isOrganisationMode,
        organisationUserInfo,
      );
    } else if (headerObj.fieldType === FIELD_TYPE_ID.USER_COLUMN) {
      rowValue = getUserColumnCellValue(rowObj?.rowProperties);
    } else if (headerObj.fieldType === FIELD_TYPE_ID.AUDIO) {
      rowValue = checkCellContainsAudio(rowValue)
        ? getPrintAudio(userPref, rowValue.length)
        : '';
    } else if (headerObj.fieldType === FIELD_TYPE_ID.MAPS) {
      if (!checkIfPlainText(rowValue) || isEmpty(cellObj.coordinate)) {
        rowValue = '';
      }
    } else if (headerObj.fieldType === FIELD_TYPE_ID.UNIT) {
      rowValue = getInRawFormat
        ? rowValue
        : formatUnit(rowValue, fileObj?.[headerObj.id], userCountry, headerObj);
    } else if (headerObj.fieldType === FIELD_TYPE_ID.ASSIGN_TASK) {
      rowValue = getAssignTaskAsPlainText(
        rowValue,
        userPref,
        false,
        null,
        isOrganisationMode,
        getInRawFormat,
      );
    } else if (headerObj.fieldType === FIELD_TYPE_ID.URL) {
      rowValue = getInRawFormat
        ? rowValue
        : getUrlCellValue(rowValue, cellObj.customText);
    } else if (headerObj.fieldType === FIELD_TYPE_ID.COMMENT) {
      rowValue = getCommentCellValue(rowValue);
    } else if (headerObj.fieldType === FIELD_TYPE_ID.CONTACT) {
      rowValue = getInRawFormat
        ? rowValue
        : getContactCellValue(rowValue, cellObj.contactName);
    } else if (headerObj.fieldType === FIELD_TYPE_ID.LIST) {
      rowValue = getListCellValue(rowValue, headerObj, rowObj);
    } else if (
      headerObj?.fieldType === FIELD_TYPE_ID.NUMBER &&
      checkIfPlainText(rowValue)
    ) {
      if (
        !isNil(headerObj?.summaryInfo) &&
        !isNil(headerObj?.uptoDecimalPlaces)
      ) {
        const {uptoDecimalPlaces} = headerObj;
        const precision = uptoDecimalPlaces ?? 0;
        if (userCountry !== 'IN') {
          rowValue = rowValue
            .toFixed(precision)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        } else {
          rowValue = rowValue
            .toFixed(precision)
            .replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
        }
      }
      const displayInPercentage =
        headerObj?.columnProperties?.[
          COLUMN_PROPERTY_KEYS.DISPLAY_IN_PERCENTAGE
        ];
      rowValue = getInRawFormat
        ? `${rowValue}`
        : `${rowValue}${displayInPercentage ? '%' : ''}`;
    }
    // else if (headerObj.fieldType === FIELD_TYPE_ID.STRING_FORMULA) {
    //   rowValue = checkIfPlainText(rowValue) ? rowValue : '';
    // }
    else if (headerObj.fieldType === FIELD_TYPE_ID.OTP) {
      if (getInRawFormat) {
        return rowValue;
      }
      rowValue = options?.getOTPCellAsText
        ? OTP_STATUS_ARR.find((status) => status.val === rowValue)?.text
        : getCellValueOtp({
            cellObj,
            isOrganisationMode: true,
          });
    }
  }
  return checkIfPlainText(rowValue)
    ? `${rowValue}`
    : calculateDaysFormulaValAutoFillTableLink &&
      formatDateDiff(rowValue, userPref, false, false, true)
    ? `${formatDateDiff(rowValue, userPref, false, false, true)}`
    : '';
};

const formatDateAndTimeAsText = (obj, type, userPref) => {
  let val = '';
  try {
    if (obj.val.err) {
      return val;
    }
    if (type === FIELD_TYPE_ID.DATE) {
      val = obj.val.years
        ? `${obj.val.years} ${getLocalText(userPref, 'Year')} `
        : '';
      val += obj.val.months
        ? `${obj.val.months} ${getLocalText(userPref, 'Month')} `
        : '';
      val += obj.val.days
        ? `${obj.val.days} ${getLocalText(userPref, 'Day')} `
        : '';
      val += obj.val.ago ? `${getLocalText(userPref, 'Ago')}` : '';
      return val;
    } else {
      val = obj.val.hours
        ? `${obj.val.hours} ${getLocalText(userPref, 'Hour')} `
        : '';
      val += obj.val.minutes
        ? `${obj.val.minutes} ${getLocalText(userPref, 'Minute')} `
        : '';
    }
  } catch (error) {
    captureError(error);
  }
  return val;
};

const formatMsgForWhatsappWeb = ({
  tableData,
  headerData,
  userCountry,
  userPref,
  fileObj = {},
}) => {
  try {
    let message = '';
    for (let j = 0; j < tableData.length; j++) {
      const rowObj = Object.assign({}, tableData[j]);
      try {
        if (!every(rowObj, (cell) => isNil(cell?.val))) {
          message +=
            tableData.length > 1
              ? `${getLocalText(userPref, 'Record')} ${j + 1} \n`
              : '';
          for (let i = 0; i < headerData.length; i++) {
            const headerObj = headerData[i];
            let rowValue = [
              FIELD_TYPE_ID.CREATED_INFO,
              FIELD_TYPE_ID.USER_COLUMN,
            ].includes(headerObj.fieldType)
              ? rowObj.rowProperties
              : rowObj[headerObj.id] && !isNil(rowObj[headerObj.id].val)
              ? rowObj[headerObj.id].val
              : '';
            if (rowValue === '') {
              continue;
            }
            rowValue = convertCellDataToTextForWeb(
              rowObj[headerObj.id],
              headerObj,
              userCountry,
              userPref,
              fileObj,
              rowObj,
            );
            if (checkIfPlainText(rowValue) && rowValue !== '') {
              message += `${headerObj.val} : ${rowValue} \n`;
            }
          }
        }
      } catch (error) {
        captureError(error);
      }

      if (!isNil(message) && message.length) {
        message += '\n';
      }
    }

    return message;
  } catch (error) {
    captureError(error);
  }
};
const formatMsgForWhatsapp = ({
  tableData,
  headerData,
  userCountry,
  userPref,
  fileObj = {},
}) => {
  try {
    let message = '';
    for (let j = 0; j < tableData.length; j++) {
      const rowObj = Object.assign({}, tableData[j]);
      try {
        if (!every(rowObj, (cell) => isNil(cell?.val))) {
          message +=
            tableData.length > 1
              ? `${getLocalText(userPref, 'Record')} ${j + 1} \n`
              : '';
          for (let i = 0; i < headerData.length; i++) {
            const headerObj = headerData[i];
            let rowValue =
              rowObj[headerObj.id] && !isNil(rowObj[headerObj.id].val)
                ? rowObj[headerObj.id].val
                : '';
            if (rowValue === '') {
              continue;
            }
            rowValue = convertCellDataToText(
              rowObj[headerObj.id],
              headerObj,
              userCountry,
              userPref,
              fileObj,
              rowObj,
            );
            if (checkIfPlainText(rowValue) && rowValue !== '') {
              message += `${headerObj.val} : ${rowValue} \n`;
            }
          }
        }
      } catch (error) {
        captureError(error);
      }

      if (!isNil(message) && message.length) {
        message += '\n';
      }
    }

    return message;
  } catch (error) {
    captureError(error);
  }
};

const checkPhoneNumberValidity = (phoneNumber) => {
  /**
   * Accepts a phoneNumber (with countryCode) and returns a object
   * {
   *    isValid : boolean , whether to accept this phone number or not
   *    error : String , if isValid === false : hold the error message to show, else : undefined
   * }
   */

  const res = {
    isValid: false,
  };
  try {
    const {validatePhoneNumberLength} = require('libphonenumber-js');
    const errorString = validatePhoneNumberLength(phoneNumber);
    if (typeof errorString === 'string' && errorString.length) {
      //this phone number is not possible
      switch (errorString) {
        case 'INVALID_LENGTH':
        case 'TOO_SHORT':
        case 'TOO_LONG': {
          res.error = 'Please enter a phone number of valid length';
          break;
        }
        case 'NOT_A_NUMBER': {
          res.error = 'Please enter a valid phone number';
          break;
        }
        case 'INVALID_COUNTRY': {
          if (phoneNumber[0] !== '+') {
            res.error = 'Please enter a valid phone number';
          } else {
            //this phone number might be possible but this library can't decide it
            res.isValid = true;
          }
          break;
        }
      }
    } else {
      res.isValid = true;
    }
  } catch (error) {
    captureError(error);
  }
  if (!res.isValid && typeof res.error !== 'string') {
    res.error = 'Something went wrong, please try again';
  }
  return res;
};

const getUserPref = async (uid) => {
  try {
    return firestore().collection('users').doc(uid).get();
  } catch (error) {
    captureError(error);
  }
};

const setRegisteredPhoneNumber = (data, time) => {
  try {
    firestore().collection('registeredPhoneNumbers').doc(data).set({
      timestamp: time,
    });
  } catch (error) {
    captureError(error);
  }
};

const afterLogin = async (
  user,
  userCountry,
  loadUserAuth,
  setUserPref,
  setUserCountry,
) => {
  try {
    await loadUserAuth(user);
    const timestamp = moment().unix();
    if (user.phoneNumber) {
      // setUserIdAnalytics({
      //   uid: user.uid,
      //   phoneNumber: user.phoneNumber,
      //   country: userCountry,
      // });
      setRegisteredPhoneNumber(`${user.phoneNumber}`, timestamp);
    } else if (user.email) {
      // setUserIdAnalytics({
      //   uid: user.uid,
      //   email: user.email,
      //   country: userCountry,
      // });
      setRegisteredPhoneNumber(`${user.email}`, timestamp);
    }
    const userPref = await getUserPref(user.uid);
    await setUserCountry(userCountry);
    if (userPref && userPref.data() && userPref.data().pref) {
      const prefData = Object.assign({}, userPref.data().pref, {
        country: userCountry,
      });
      await setUserPref(prefData);
    } else {
      await setUserPref({country: userCountry});
    }
  } catch (error) {
    captureError(error);
  }
};

const createPDF = async ({
  obj,
  userPref,
  userCountry = 'IN',
  isPages,
  setLoaderText = null,
}) => {
  let docsData = [];

  if (isPages) {
    const {pages, pageObj} = obj.metaData;
    const pageArray = [...pages];
    if (pageObj.type === 'CLIENT') {
      pageArray.sort((x, y) => y.createdTimestamp - x.createdTimestamp);
    }
    docsData = await downloadPagesAndGetFileObj(pageArray, userPref);
  }

  let htmlDataResponse;

  if (isPages) {
    obj.docsData = docsData.reverse();
    htmlDataResponse = obj.isBlockViewActive
      ? await getPagesHtmlBlockView(
          obj,
          {
            recordText: getLocalText(userPref, 'Record'),
            totalText: getLocalText(userPref, 'Total'),
            yesText: getLocalText(userPref, 'Yes'),
            noText: getLocalText(userPref, 'No'),
          },
          {
            getPrintDate,
            formatCurrency,
            formatDateDiff,
            formatTimeDiff,
            getPrintTime,
            getPrintAttachment,
            userPref,
            userCountry,
            formatUnit,
            getAssignTaskAsPlainText,
            getUrlAsPlainText,
          },
        )
      : await getPagesHtml(
          obj,
          {
            yesText: getLocalText(userPref, 'Yes'),
            noText: getLocalText(userPref, 'No'),
          },
          {
            getPrintDate,
            formatCurrency,
            formatDateDiff,
            formatTimeDiff,
            getPrintTime,
            getPrintAttachment,
            userPref,
            userCountry,
            formatUnit,
            setLoaderText,
            getAssignTaskAsPlainText,
            getUrlAsPlainText,
          },
        );
  } else {
    htmlDataResponse = obj.isBlockViewActive
      ? await getHtmlBlockView(
          obj,
          {
            recordText: getLocalText(userPref, 'Record'),
            totalText: getLocalText(userPref, 'Total'),
            yesText: getLocalText(userPref, 'Yes'),
            noText: getLocalText(userPref, 'No'),
          },
          {
            getPrintDate,
            formatCurrency,
            formatDateDiff,
            formatTimeDiff,
            getPrintTime,
            getPrintAttachment,
            userPref,
            userCountry,
            formatUnit,
            getAssignTaskAsPlainText,
            getUrlAsPlainText,
          },
        )
      : await GetWebHtml(
          obj,
          {
            yesText: getLocalText(userPref, 'Yes'),
            noText: getLocalText(userPref, 'No'),
          },
          {
            getPrintDate,
            formatCurrency,
            formatDateDiff,
            formatTimeDiff,
            getPrintTime,
            getPrintAttachment,
            userPref,
            userCountry,
            formatUnit,
            getAssignTaskAsPlainText,
            getUrlAsPlainText,
          },
        );
  }

  let htmlData = null;
  let totalWidth = 0;
  let allColumnInOnePage;

  if (obj.isBlockViewActive) {
    htmlData = htmlDataResponse;
  } else {
    htmlData = htmlDataResponse.html;
    totalWidth = htmlDataResponse.totalWidth;
    allColumnInOnePage = htmlDataResponse.allColumnInOnePage;
  }
  const options = {
    html: htmlData,
    fileName: obj.filename
      ? obj.filename.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '_')
      : 'RB_Doc',
  };
  if (
    options.fileName === '' ||
    options.fileName === undefined ||
    options.fileName === null
  ) {
    options.fileName = `file_${moment().valueOf()}`;
  }
  if (!obj.isBlockViewActive && totalWidth > 840) {
    if (allColumnInOnePage) {
      options.height = totalWidth * 0.65;
      options.width = totalWidth;
    } else {
      options.height = 800;
      options.width = 1056;
    }
  }

  const fileNameLength = options.fileName?.length;
  if (fileNameLength < 4) {
    options.fileName = options.fileName + '____';
  } else if (fileNameLength > 70) {
    options.fileName = options.fileName.substring(0, 70);
  }

  return options;
};

const downloadPagesAndGetFileObj = async (docIdArrays) => {
  try {
    const docsData = [];
    const monthsOrder = [];
    for (const docObj of docIdArrays) {
      //inside for ... of loop (valid) [Sequential promise]
      // eslint-disable-next-line no-await-in-loop
      const docData = await DocumentsMethods.getUserDocumentData(docObj.id);
      if (!isEmpty(docData)) {
        const name = docObj.name ?? '';
        const obj = {
          ...docData,
          name,
        };
        monthsOrder.push(name);
        docsData.push(obj);
      }
    }
    return sortBy(docsData, (item) => monthsOrder.indexOf(item.name));
  } catch (error) {
    captureError(error);
  }
};

function getPasteValue(colType, clipboard) {
  if (!(colType && clipboard)) {
    return null;
  }

  const {fieldType, value} = clipboard;
  /*
    - fieldType is field from which value was copied or cut
    - value is value present in clipboard
    - colType is field in which value is being pasted
  */

  /* handling explicitely when value is null */
  if (!value || isEmpty(value)) {
    return null;
  }

  // // handling explicitely for time
  // if (colType === fieldType && colType === FIELD_TYPE_ID.TIME) {
  //   return { ...value, val: moment.unix(value.val) };
  // }

  if (colType === fieldType) {
    // if being pasted in same field type
    // return the value
    return value;
  } else if (
    fieldType === FIELD_TYPE_ID.TEXT ||
    fieldType === FIELD_TYPE_ID.NUMBER ||
    fieldType === FIELD_TYPE_ID.UNIT ||
    fieldType === FIELD_TYPE_ID.CONTACT ||
    fieldType === FIELD_TYPE_ID.RUPEE
  ) {
    // if copied value is number or string or string parseable as number
    // and is being pasted in text, number or unit field
    if (colType === FIELD_TYPE_ID.TEXT) {
      return {...value, val: `${value.val}`};
    } else if (
      colType === FIELD_TYPE_ID.NUMBER ||
      colType === FIELD_TYPE_ID.RUPEE ||
      colType === FIELD_TYPE_ID.UNIT
    ) {
      if (!isNaN(Number(value.val))) {
        return value;
      }
      return null;
    } else if (colType === FIELD_TYPE_ID.CONTACT) {
      // checking if valid phone number
      if (!isNaN(Number(value.val)) && value.val?.toString().length === 10) {
        return value;
      }
      return null;
    }

    return null;
  } else if (colType === FIELD_TYPE_ID.TEXT) {
    // if some value is being pasted in text column
    // return formatted text (format in which it is shown in cell renderer)

    if (fieldType === FIELD_TYPE_ID.TIME) {
      return {
        ...value,
        val: moment(moment.unix(value.val)).format(TIME_FORMAT),
      };
    } else if (fieldType === FIELD_TYPE_ID.DATE) {
      return {
        ...value,
        val: moment(moment.unix(value.val)).format(DATE_FORMAT),
      };
    } else if (fieldType === FIELD_TYPE_ID.REMINDER) {
      const message = value?.reminderMessage;
      const timeStamp = moment.unix(value?.val);
      const styleObj = value.styleObj || {};
      if (message && timeStamp) {
        const res = {
          val: moment(timeStamp).format(REMINDER_FORMAT) + ' ' + message,
        };
        if (styleObj) {
          res.styleObj = styleObj;
        }
        return res;
      }
      return null;
    } else if (fieldType === FIELD_TYPE_ID.MAPS) {
      return value;
    }

    return null;
  }
  return null;
}
const checkIfCellContainsSelectBox = (cellObj) =>
  getSelectBoxCell(cellObj?.val, cellObj, 'EN', true) !== '';

const invertColor = (hex) => {
  try {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);

    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  } catch (error) {
    return '#000000';
  }

  // // invert color components
  // r = (255 - r).toString(16);
  // g = (255 - g).toString(16);
  // b = (255 - b).toString(16);
  // // pad each with zeros and return
  // return '#' + padZero(r) + padZero(g) + padZero(b);
};

const deleteKey = (obj, path) => {
  try {
    const _obj = JSON.parse(JSON.stringify(obj));
    const keys = path.split('.');

    keys.reduce((acc, key, index) => {
      if (index === keys.length - 1) {
        delete acc[key];
        return true;
      }
      return acc[key];
    }, _obj);

    return _obj;
  } catch (error) {
    captureError(error);
    return obj;
  }
};

const decodeLabelEncodedString = (text) => {
  text = `${!isNil(text) ? text : ''}`;
  try {
    const encoder = DASHBOARD_LABEL_HELPER.ENCODER;
    const isEncoded = text.startsWith(encoder) && text.endsWith(encoder);
    if (isEncoded) {
      return text.replaceAll(encoder, '');
    }
  } catch (error) {
    captureError(error);
  }
  return text;
};

const mergeDateTimeAndGetUTCTimeStamp = (dateUnix = null, timeUnix = null) => {
  try {
    const time = moment.unix(timeUnix);
    return timeUnix
      ? moment
          .unix(dateUnix)
          .set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0})
          .add(time.hours(), 'hours')
          .add(time.minutes(), 'minutes')
          .utc()
          .format()
      : moment
          .unix(dateUnix)
          .set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0})
          .utc()
          .format();
  } catch (error) {
    captureError(error);
  }
};

const formatUTCDate = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
};

const getTimezone = () => {
  let timeZone;
  try {
    const {getTimeZone: getDeviceTimezone} = require('react-native-localize');
    timeZone = getDeviceTimezone();
  } catch (e) {
    captureError(e);
  }
  return timeZone || 'Asia/Kolkata';
};

const addRowIdToRowObj = (tableData) => {
  try {
    const tableDataNew = Object.assign([], tableData);
    const rowIndexMap = {};
    const newtableData = tableDataNew.map((element, index) => {
      if (element.rowId) {
        rowIndexMap[element.rowId] = index;
        return element;
      } else {
        const id = getRowId(index);
        rowIndexMap[id] = index;
        return Object.assign({}, element, {
          rowId: id,
        });
      }
    });
    return {
      tableDataNew: newtableData,
      rowIndexMap,
    };
  } catch (error) {
    captureError(error);
  }
};

const getRowId = (index) => {
  //any change in this : update-on-cloud-function-too
  return `${moment().valueOf()}R${
    ![undefined, null].includes(index) ? index : 7
  }`;
};

const getIdWithPrefix = (prefix) => {
  return `${prefix}_${moment().valueOf()}`;
};

const getDaysDifference = (time) => {
  const millisTime = time * 1000;
  const momentTime = moment().valueOf();
  const seconds = (momentTime - millisTime) / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  return Math.round(days);
};

const voteObjectProperty = (originalObj, votingKey, checkKey) => {
  try {
    const votingExpiry = {};
    forOwn(originalObj, (valObj, key) => {
      if (
        valObj?.[votingKey] != null &&
        (typeof checkKey !== 'function' || checkKey(key))
      ) {
        votingExpiry[valObj[votingKey]] =
          (votingExpiry[valObj[votingKey]] || 0) + 1;
      }
    });
    if (!isEmpty(votingExpiry)) {
      return Object.entries(votingExpiry).reduce((prev, current) =>
        prev[1] > current[1] ? prev : current,
      )[0];
    }
  } catch (error) {
    captureError(error);
  }
  return null;
};

const getOriginalColId = (colId) => {
  // return colId as number - if generated col id is converted to string due to some operation
  return !isNaN(colId)
    ? typeof colId === 'string'
      ? parseInt(colId)
      : colId
    : colId;
};

const getOriginalFieldType = (colObj) => {
  /**
   *  if fieldType -> "TABLE"
   *    return -> subType as fieldType
   *  else
   *    return -> fieldType
   */

  return colObj?.fieldType === FIELD_TYPE_ID.TABLE && colObj?.subType
    ? colObj.subType
    : colObj?.fieldType;
};

const getTrailText = (str, trailLength, ellipsis) => {
  return isString(str) && isInteger(trailLength) && str.length > trailLength
    ? trim(str.slice(0, trailLength + 1)) +
        (isString(ellipsis) ? ellipsis : '...')
    : str;
};

const getHeaderTypeMapping = (
  headerData,
  withIndex = false,
  filterBackgroundFields = false,
) => {
  const headerTypeMappingObj = {};
  try {
    let index = 0;
    if (!isEmpty(headerData)) {
      headerData.forEach((hd) => {
        if (filterBackgroundFields && isBackgroundField(hd)) {
          return;
        }
        headerTypeMappingObj[hd.id] = !withIndex ? hd : {...hd, index};
        index++;
      });
    }
  } catch (error) {
    captureError(error);
  }
  return headerTypeMappingObj;
};

const getDefaultUnit = () => {
  const DEFAULT_UNIT = require('./constant').PREDEFINED_UNITS_DETAILS.KILOGRAM;
  return {
    [FIELD_TYPE_ID.UNIT]: {
      isUserDefinedUnit: false,
      unitId: DEFAULT_UNIT.id,
      unitName: DEFAULT_UNIT.name,
      unitSymbol: DEFAULT_UNIT.symbol,
    },
  };
};

const areShallowEqual = (objA, objB) => {
  const is = (x, y) => {
    if (x === y) {
      return x !== 0 || y !== 0 || 1 / x === 1 / y;
    } else {
      return x !== x && y !== y;
    }
  };

  if (is(objA, objB)) {
    return true;
  }

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (let i = 0; i < keysA.length; i++) {
    if (
      !Object.prototype.hasOwnProperty.call(objB, keysA[i]) ||
      !is(objA[keysA[i]], objB[keysA[i]])
    ) {
      return false;
    }
  }

  return true;
};

const getNewColumnName = (headerData, userPref) => {
  let newColName = '';
  try {
    const checkIfNameExist = (newColName, headerData) => {
      let exist = false;
      for (let i = headerData.length - 1; i >= 0; i--) {
        if (headerData[i].val === newColName) {
          exist = true;
          break;
        }
      }
      return exist;
    };
    const {COLUMN_NAMES} = require('./constant');
    const colName = getLocalText(userPref, 'Column');
    const currentLength = headerData?.length ?? 10;
    newColName = `${colName} ${COLUMN_NAMES[currentLength]}`;
    let i = 1;
    let nameExist = checkIfNameExist(newColName, headerData);
    while (nameExist) {
      newColName = `${colName} ${COLUMN_NAMES[currentLength]}${i}`;
      nameExist = checkIfNameExist(newColName, headerData);
      i++;
    }
  } catch (error) {
    captureError(error);
    newColName = 'Column';
  }
  return newColName;
};

const getNewColumnId = (fieldType, suffix) =>
  fieldType === FIELD_TYPE_ID.CREATED_INFO
    ? CREATED_INFO_COLUMN.id
    : fieldType === FIELD_TYPE_ID.USER_COLUMN
    ? USER_INFO_COLUMN.id
    : `Col_${moment().utc().valueOf()}${
        typeof suffix === 'string' && suffix.length ? `_${suffix}` : ''
      }`;

const removeAllSpecialCharactersFromString = (str) => {
  try {
    return str.replace(/[~`!@#$%^&*()+={}\[\];:\'\"<>,\/\\\?-_]/g, '');
  } catch (error) {
    captureError(error);
    return str;
  }
};

const handleCloudError = (error, functionName) => {
  try {
    if (
      error?.code === functions(true)?.HttpsErrorCode?.UNAVAILABLE ||
      error?.includes?.('Internet connection') ||
      error?.message?.includes?.('Internet connection')
    ) {
      return {
        success: false,
        message: 'Please check your internet connection and try again.',
      };
    } else {
      functionName && captureInfo({functionName});
      captureError(error);
      return {
        success: false,
        message: 'Something went wrong, please try again',
      };
    }
  } catch (err) {
    functionName && captureInfo({functionName});
    captureError(err);
  }
};

const handleCloudErrorMsgAndLogging = (
  serverResponse,
  reqParams,
  userPref,
  showMsg = true,
) => {
  let message = serverResponse?.message
    ? serverResponse.message
    : 'Something went wrong, please try again';

  if (serverResponse?.error) {
    captureInfo({message, reqParams, log: serverResponse.log});
    captureError(new Error(serverResponse.error), true);
  }
  message = getLocalText(userPref, message);
  if (showMsg) {
    if (ENV && serverResponse?.isAlert && typeof alert === 'function') {
      alert(message);
    } else {
      ShowToast(message, null, false, true);
    }
  }
  return message;
};

const checkIfFieldImportableForImportExcel = (headerObj) => {
  const fieldType = getColumnFieldType(headerObj);
  if (
    RESTRICTED_FIELDS_FOR_IMPORT_EXCEL.includes(fieldType) ||
    ([FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.DATE_TIME].includes(fieldType) &&
      headerObj.isDateMath) ||
    headerObj?.columnProperties?.[COLUMN_PROPERTY_KEYS.NON_EDITABLE_DATA]
  ) {
    return false;
  }
  return true;
};

const isFilterableColumn = (columnObj) => {
  if (isEmpty(columnObj)) {
    return false;
  }
  const isTable = columnObj.fieldType === FIELD_TYPE_ID.TABLE;
  const fieldType = getColumnFieldType(columnObj);
  return (
    !isBackgroundField(columnObj) &&
    ![FIELD_TYPE_ID.LIST, FIELD_TYPE_ID.PDF].includes(fieldType) &&
    !(
      fieldType === FIELD_TYPE_ID.FORMULA &&
      ([FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.TIME].includes(columnObj.subType) ||
        columnObj.hasPrevRowRef)
    ) &&
    !(isTable && fieldType === FIELD_TYPE_ID.LABEL)
  );
};

const isObjectValid = (obj) => obj && isObject(obj) && !isEmpty(obj);

const serializeError = (err) => {
  try {
    return JSON.stringify(err, Object.getOwnPropertyNames(err));
  } catch (error) {
    return err;
  }
};

const JSONStringifier = (object) => {
  try {
    const getCircularReplacer = () => {
      const seen = new WeakSet();
      return (key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      };
    };
    return JSON.stringify(object, getCircularReplacer());
  } catch {
    try {
      return JSON.stringify(object);
    } catch (error) {
      return JSON.stringify({
        err: 'Error in JSONStringifier',
        errMsg: error?.message,
      });
    }
  }
};

const getDescriptionMessageForRestrictionsModal = (
  type,
  userPref,
  restrictionConfig,
) => {
  try {
    const originalString =
      'Free plan support only upto MAX_RESTRICT REPLACE_TEXT. Get premium plan to do more.';
    switch (type) {
      case RESTRICTION_TYPES_FOR_NON_PREMIUM_USERS.FILE_COUNT: {
        const returnString = originalString.replace(
          'REPLACE_TEXT',
          'documents per account',
        );
        return getLocalText(userPref, returnString).replace(
          'MAX_RESTRICT',
          restrictionConfig.maxFiles,
        );
      }
      case RESTRICTION_TYPES_FOR_NON_PREMIUM_USERS.DOCUMENT_ROW_COUNT: {
        const returnString = originalString.replace(
          'REPLACE_TEXT',
          'rows per document',
        );
        return getLocalText(userPref, returnString).replace(
          'MAX_RESTRICT',
          restrictionConfig.maxRows,
        );
      }
      case RESTRICTION_TYPES_FOR_NON_PREMIUM_USERS.PAGE_COUNT: {
        const returnString = originalString.replace(
          'REPLACE_TEXT',
          'pages per document',
        );
        return getLocalText(userPref, returnString).replace(
          'MAX_RESTRICT',
          restrictionConfig.maxPages,
        );
      }
      case RESTRICTION_TYPES_FOR_NON_PREMIUM_USERS.ASSIGN_TASK_COUNT: {
        const returnString = originalString.replace(
          'REPLACE_TEXT',
          'tasks per document page',
        );
        return getLocalText(userPref, returnString).replace(
          'MAX_RESTRICT',
          TABLE_LIMITS.MAX_ASSIGNED_TASK,
        );
      }
      default: {
        return '';
      }
    }
  } catch (error) {
    captureError(error);
  }
};

const shouldApplyRestrictionsForNonPremiumUsers = (
  type,
  applyUserRestrictions,
  restrictionConfig,
  userPref,
  fileCount = null,
  rowCount = null,
  activeDocumentMeta = {},
) => {
  const response = {applyRestriction: false, isShared: false};
  try {
    const {
      premium: {isUserSubscribed, isTrial},
    } = getReduxState();
    if (
      (!applyUserRestrictions &&
        !activeDocumentMeta?.applyDocRestrictions_v2) ||
      !type ||
      isUserSubscribed ||
      isTrial
    ) {
      return response;
    }
    const config = Object.assign({}, restrictionConfig);
    switch (type) {
      case RESTRICTION_TYPES_FOR_NON_PREMIUM_USERS.FILE_COUNT: {
        if (
          applyUserRestrictions &&
          fileCount &&
          config.maxFiles &&
          fileCount >= config.maxFiles
        ) {
          return {applyRestriction: true, isShared: false};
        } else {
          return response;
        }
      }

      case RESTRICTION_TYPES_FOR_NON_PREMIUM_USERS.DOCUMENT_ROW_COUNT: {
        const isSharedDoc = !isEmpty(activeDocumentMeta?.collab);
        const docConfig = activeDocumentMeta?.restrictionConfig
          ? activeDocumentMeta.restrictionConfig
          : config;
        if (isSharedDoc) {
          if (
            activeDocumentMeta?.applyDocRestrictions_v2 === true &&
            rowCount &&
            docConfig?.maxRows &&
            rowCount >= docConfig.maxRows
          ) {
            return {applyRestriction: true, isShared: true};
          }
        } else {
          if (
            applyUserRestrictions &&
            rowCount &&
            config?.maxRows &&
            rowCount >= config.maxRows
          ) {
            return {applyRestriction: true, isShared: false};
          }
        }
        return response;
      }

      case RESTRICTION_TYPES_FOR_NON_PREMIUM_USERS.PAGE_COUNT: {
        const pagesEnabled =
          activeDocumentMeta?.pageObj?.enabled &&
          activeDocumentMeta.pages?.length
            ? true
            : false;
        const pages = pagesEnabled ? activeDocumentMeta.pages : [];
        const isSharedDoc = !isEmpty(activeDocumentMeta?.collab);
        const docConfig = activeDocumentMeta?.restrictionConfig
          ? activeDocumentMeta.restrictionConfig
          : config;

        if (isSharedDoc) {
          if (
            activeDocumentMeta?.applyDocRestrictions_v2 === true &&
            pagesEnabled &&
            docConfig?.maxPages &&
            pages.length >= docConfig.maxPages
          ) {
            return {applyRestriction: true, isShared: true};
          }
        } else {
          if (
            applyUserRestrictions &&
            pagesEnabled &&
            config?.maxPages &&
            pages.length >= config.maxPages
          ) {
            return {applyRestriction: true, isShared: false};
          }
        }
        return response;
      }
      default: {
        return response;
      }
    }
  } catch (error) {
    captureError(error);
  }
  return response;
};

const sortedInsertionIndex = (
  array,
  value,
  isAsc = false,
  objectKey = null,
) => {
  try {
    let low = 0;
    let high = array.length;

    while (low < high) {
      const mid = (low + high) >>> 1; //divide by 2
      const isAtHigher = objectKey
        ? array[mid]?.[objectKey] < value?.[objectKey]
        : array[mid] < value;
      if (isAsc ? !isAtHigher : isAtHigher) {
        low = mid + 1;
      } else {
        high = mid;
      }
    }
    return low;
  } catch (err) {
    captureError(err);
    return array?.length || 0;
  }
};

function convertObjToArrOfObj(obj, keyName = 'title') {
  // converts {x:1,y:2} to [ {x:1}, {y:2}]
  if (Object.prototype.toString.call(obj) != '[object Object]') return [];
  const res = [];
  for (const key in obj) {
    res.push({
      [keyName]: key,
      value: obj[key],
    });
  }
  return res;
}

const getFrequency = (array, key = null) => {
  const map = {};
  try {
    array?.forEach?.((item) => {
      if (key) {
        if (map[item[key]]) {
          map[item[key]]++;
        } else {
          map[item[key]] = 1;
        }
      } else {
        if (map[item]) {
          map[item]++;
        } else {
          map[item] = 1;
        }
      }
    });
  } catch (error) {
    captureError(error);
  }

  return map;
};

const checkForUniqueVal = (value, uniqueValArr) => {
  if (uniqueValArr?.length > 0 && value && uniqueValArr.includes(value)) {
    return {isUnique: false, uniqueValArr: uniqueValArr.slice()};
  } else if (value) {
    uniqueValArr.push(value);
    return {isUnique: true, uniqueValArr: uniqueValArr.slice()};
  }
  return {isUnique: false, uniqueValArr: uniqueValArr.slice()};
};

const getAssignTaskColumnData = (colObj, tableData, userPref) => {
  const assignTaskNames = new Set(),
    assignTaskDueDates = new Set();
  for (let i = 0; i < tableData.length; i++) {
    if (colObj.id in tableData[i]) {
      const value = tableData[i][colObj.id]?.val ?? {};
      if (checkCellContainsAssignTask(value)) {
        const {name, dueDate} = getAssignTaskCellData(value);
        if (name?.length) {
          assignTaskNames.add(name);
        }
        if (dueDate?.length) {
          assignTaskDueDates.add(dueDate);
        }
      }
    }
  }
  if (assignTaskNames.size < 1 && assignTaskDueDates.size < 1) {
    ShowToast('Column is empty', userPref);
  }
  return {
    names: [...assignTaskNames],
    dueDates: [...assignTaskDueDates],
  };
};

const getColumnData = (colObj, tableData, userPref) => {
  try {
    let isDataPresent = false;
    if (colObj.fieldType === FIELD_TYPE_ID.ASSIGN_TASK) {
      return getAssignTaskColumnData(colObj, tableData, userPref);
    }
    let uniqueValArr = [];
    let filterData = [];

    if (!BOOLEAN_FILTER_FIELDS.includes(colObj.fieldType)) {
      for (let i = 0; i < tableData.length; i++) {
        const type = colObj.fieldType;
        const subType = colObj.subType || null;

        if (colObj.id in tableData[i]) {
          const value = tableData[i][colObj.id]?.val ?? [];
          const filterLen = filterData.length > 0 ? filterData.length : 0;
          if (
            RANGE_FILTER_FIELDS.includes(type) &&
            checkIfPlainText(value) &&
            !isNaN(value)
          ) {
            isDataPresent = true;
            break;
          } else if (
            [
              FIELD_TYPE_ID.TIME,
              FIELD_TYPE_ID.DATE,
              FIELD_TYPE_ID.REMINDER,
            ].includes(type) &&
            checkIfPlainText(value) &&
            new Date(value).getTime() > 0
          ) {
            isDataPresent = true;
            break;
          } else if (
            type === FIELD_TYPE_ID.TABLE &&
            subType === FIELD_TYPE_ID.LABEL &&
            isArray(value)
          ) {
            const cellValue = tableData[i][colObj.id]?.val ?? [];
            if (cellValue && isArray(cellValue) && cellValue.length > 0) {
              for (let j = filterLen; j < cellValue.length; j++) {
                const uni = checkForUniqueVal(cellValue[j]?.val, uniqueValArr);
                uniqueValArr = uni.uniqueValArr;
                if (uni.isUnique) {
                  filterData.push(cellValue[j]);
                }
              }
            }
          } else if (
            TEXT_VALUE_TYPE_FILTER_FIELDS.includes(type) &&
            checkIfPlainText(value) &&
            ![true, false].includes(value)
          ) {
            const uni = checkForUniqueVal(value, uniqueValArr);
            uniqueValArr = uni.uniqueValArr;
            if (uni.isUnique) {
              filterData.push(tableData[i][colObj.id]);
            }
          }
        }
      }
    }

    if (
      [FIELD_TYPE_ID.SELECT_POP_UP, FIELD_TYPE_ID.LABEL].includes(
        colObj.fieldType,
      ) &&
      colObj.selectElements?.length > 0
    ) {
      for (let i = 0; i < colObj.selectElements?.length; i++) {
        const uni = checkForUniqueVal(
          colObj.selectElements[i]?.val,
          uniqueValArr,
        );
        uniqueValArr = uni.uniqueValArr;
      }
      filterData = colObj.selectElements?.slice?.();
    }

    if (
      !BOOLEAN_FILTER_FIELDS.includes(colObj.fieldType) &&
      filterData.length < 1 &&
      !isDataPresent
    ) {
      ShowToast('Column is empty', userPref);
    }
    return filterData;
  } catch (error) {
    captureError(error);
  }
  return [];
};

const getUTCEpoch = () => moment().utc().valueOf();

const firestoreServerTimestamp = () => {
  return firestore(true).FieldValue.serverTimestamp();
};

// Check if a page is the original document page
const checkIfOriginalDocument = (page, originalDocumentId) => {
  let isOriginalDocumentPage = false;

  if (page['id'] === originalDocumentId) {
    isOriginalDocumentPage = true;
  }
  return isOriginalDocumentPage;
};

const getRowObjAtIndex = (tableData, searchFilterState, rowIndex) => {
  try {
    const {isActive, searchFilterTableData, searchFilterRowIdDataMap} =
      searchFilterState === null
        ? getReduxState().searchFilter
        : searchFilterState;
    return (
      (isActive
        ? searchFilterRowIdDataMap?.[searchFilterTableData?.[rowIndex]]
        : tableData?.[rowIndex]) ?? {}
    );
  } catch (e) {
    captureError(e);
  }
  return {};
};

const getRowObjAtIndexForMiniApps = (tableData, rowIdDataMap, rowIndex) =>
  Object.assign({}, rowIdDataMap?.[tableData?.[rowIndex]]);

const currentDocNoOfRows = (table = null) => {
  try {
    const {tableData, areAllRowsFetched, noOfRows} = !isEmpty(table)
      ? table
      : getReduxState().table;
    return areAllRowsFetched ? tableData.length : noOfRows;
  } catch (e) {
    captureError(e);
  }
  return null;
};

const formatTemplateMessageWithKeysValues = (message, keyValues, isColObj) => {
  let updatedVal = message ?? '';
  try {
    for (const item in keyValues) {
      const val = isColObj ? keyValues[item].val : keyValues[item];
      updatedVal = updatedVal.replace(item, val);
    }
    updatedVal = `${updatedVal?.replace?.(/\\n/g, '\n')}`;
  } catch (e) {}
  return updatedVal;
};
const isEmail = (str) => {
  try {
    if (str && typeof str === 'string') {
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/.test(str);
    }
  } catch (e) {}
  return false;
};

const checkIfCellHasTextualData = (cellValue) =>
  checkIfPlainText(cellValue) && cellValue !== '';

function setDateToStartOfMonth(dateObj) {
  dateObj.setHours(0);
  dateObj.setMinutes(0);
  dateObj.setSeconds(0);
  dateObj.setMilliseconds(0);
  dateObj.setDate(1);
  return dateObj;
}

const checkIfEmptyRow = (rowObj, headerData) => {
  const updatedRowObj = omit(rowObj, TABLE_LIMITS.REQUIRED_ROW_KEYS);

  if (!Array.isArray(headerData)) {
    return (
      isEmpty(updatedRowObj) ||
      every(updatedRowObj, (cell) => isNil(cell?.val) || cell?.val === '')
    );
  }

  if (isEmpty(updatedRowObj)) {
    return true;
  }
  for (let i = 0; i < headerData.length; i++) {
    const colId = headerData[i]?.id;
    if (
      colId in updatedRowObj &&
      checkIfValueExistOnCell(updatedRowObj?.[colId], headerData[i], rowObj)
    ) {
      return false;
    }
  }
  return true;
};

const filterListOfData = (
  list,
  searchText,
  minSearchLetters = 1,
  nestedKey = null, //array of keys (as nested key path)
) => {
  try {
    if (
      checkIfPlainText(searchText) &&
      searchText?.length >= minSearchLetters
    ) {
      const text = searchText.toLowerCase();
      return list.filter((listItem) => {
        const value = Array.isArray(nestedKey)
          ? nestedKey.reduce((acc, cur) => acc?.[cur], listItem)
          : listItem;
        return (
          checkIfPlainText(value) && `${value}`.toLowerCase().includes(text)
        );
      });
    }
    return list ?? [];
  } catch (e) {
    captureError(e);
  }
  return [];
};

const checkIfTimeBasedFilter = (option) =>
  `${option}`.startsWith('NEXT') ||
  `${option}`.startsWith('LAST') ||
  [
    DATA_FILTERS.EMPTY_CELLS_ROWS,
    ...Object.keys(DATA_FILTERS.DATE_FILTER_RANGES),
    DATA_FILTERS.ANY_CELLS_ROWS,
  ].find((ele) => `${option}`.toUpperCase().includes(ele));

const timeFormattingForAnalyics = (timestamp) => {
  try {
    return `${new Date(timestamp).toISOString().split('.')[0]} UTC`.replace(
      'T',
      ' ',
    );
  } catch {}
};

const isMixPanelEvent = (eventName, paramsObj) =>
  paramsObj?.p_orgId ||
  require('./analyticsHelper').MIXPANEL_NON_ORG_ALLOWED_EVENTS.includes(
    eventName,
  );

const extractCommonParamsForEventLogs = (paramsObj, store) => {
  try {
    if (typeof store?.getState === 'function') {
      const {
        premium,
        auth,
        home,
        teams,
        automation,
        organisation,
        miniApps,
        elasticDashboards,
        remoteConfig: {isOrganisationMode},
      } = getReduxState();

      const uid = auth.user?.uid;

      const {creationTime, lastSignInTime} = Object.assign(
        {},
        auth?.user?.metadata,
      );
      const userActiveDevices = Object.keys(
        Object.assign({}, auth.devices),
      ).length;

      const getMiniAppParams = (isCleverTapOnly = false) => {
        const {activeAppId, miniApps: allApps, activeScreenId} = miniApps;
        if (activeAppId && activeAppId in allApps) {
          const activeAppMeta = allApps[activeAppId];
          const screens = Object.assign({}, activeAppMeta.screens);
          const activeScreenMeta = Object.assign({}, screens[activeScreenId]);

          const cleverTapOnly = {
            colorName: activeAppMeta.appIconColor,
            iconNameApp: activeAppMeta.appIcon,
            iconNameScreen: activeScreenMeta.screenIcon,
            cardLayout: activeScreenMeta.layoutId,
          };

          if (isCleverTapOnly) {
            return cleverTapOnly;
          }

          const appSharedWith = Object.assign({}, activeAppMeta.sharedWith);

          return {
            p_appId: activeAppId,
            p_appName: activeAppMeta.appName,
            p_appType:
              activeAppMeta.appMeta?.source === 'STORE' ? 'STORE' : 'CUSTOM',
            p_userAppRole: activeAppMeta.isMiniAppOwner
              ? 'OWNER'
              : appSharedWith[uid]?.permission,
            p_activeApps: Object.keys(allApps).length,
            p_screenName: activeScreenMeta.screenName,
            screenId: activeScreenId,
            p_activeScreens: Object.keys(screens).length,
            p_screenType: activeScreenMeta.type,
            p_connectedDocs: activeAppMeta.docIds?.length ?? 0,
            p_activeAutomations: automation.fromMiniApps
              ? Object.keys(Object.assign({}, automation.automationConfig))
                  .length
              : 0,
            p_activeDashboards: Object.keys(
              Object.assign(
                {},
                elasticDashboards.allDashboards?.[activeScreenId]?.dashboards,
              ),
            ).length,
            p_userWithAccess: Object.keys(appSharedWith).length + 1, //+1 for appOwner
            ...cleverTapOnly,
          };
        }
        return {};
      };

      const clevertapEvents = {
        userAppLang: auth.userPref?.lang ?? 'EN',
        isPremiumUser: Boolean(
          isOrganisationMode
            ? organisation.orgSubscriptions.isActive
            : premium.isUserSubscribed,
        ),
        isTrialUser: Boolean(
          isOrganisationMode
            ? organisation.orgSubscriptions.isTrial
            : premium.isTrial,
        ),
        ...(creationTime
          ? {userCreationTime: timeFormattingForAnalyics(creationTime)}
          : {}),
        ...(isOrganisationMode
          ? {
              p_orgId: organisation.activeOrganisationId,
              p_userOrgRole: organisation.isUserOrganisationOwner
                ? 'OWNER'
                : 'MEMBER',
              p_businessType: organisation.profileData?.orgDomain,
              p_selectedUseCase: organisation.profileData?.orgUsecaseName,
              p_teamSize: organisation.profileData?.orgTeamSize,
              p_currentOrgSize:
                Object.keys(Object.assign({}, organisation.membersList))
                  .length + 1, //+1 for orgOwner
              p_purchasedSeats: organisation.orgSubscriptions.totalSeats,
              p_planExpiry: timeFormattingForAnalyics(
                organisation.orgSubscriptions.plan?.activeTill,
              ),
            }
          : {}),
        ...(isOrganisationMode ? getMiniAppParams(true) : {}),
      };

      const allCommonParams = Object.assign(
        {},
        {
          userActiveDevices: userActiveDevices === 0 ? 1 : userActiveDevices,
          userActiveDocs: home.files?.length ?? 0,
          userActivePlanType: isOrganisationMode
            ? organisation.orgSubscriptions.isActive &&
              !organisation.orgSubscriptions.isTrial
              ? organisation.orgSubscriptions.plan?.planName
              : ''
            : premium.activePlanType,
          userActivePlanMonthly: Boolean(
            isOrganisationMode
              ? organisation.orgSubscriptions.plan?.frequency === 'MONTHLY'
              : premium.isMonthly,
          ),
        },
        lastSignInTime
          ? {userLastSignInTime: timeFormattingForAnalyics(lastSignInTime)}
          : null,
        isOrganisationMode
          ? getMiniAppParams(false)
          : {
              isSharedPremium: Boolean(premium.isSharedUserPlan),
              userActiveTeams: Object.assign({}, teams?.teams).length,
            },
      );

      return [
        Object.assign({}, paramsObj, clevertapEvents),
        Object.assign({}, paramsObj, clevertapEvents, allCommonParams),
      ];
    }
  } catch (error) {
    captureError(error, true);
  }
  return [paramsObj, paramsObj];
};

const isConditionalPropertyApplicable = (columnObj, property, options) => {
  try {
    if (COLUMN_PROPERTIES[property]?.isConditional) {
      if (columnObj.fieldType === FIELD_TYPE_ID.TEXT) {
        return Boolean(options?.isOrganisationMode);
      } else if (columnObj.fieldType === FIELD_TYPE_ID.ASSIGN_TASK) {
        return Boolean(options?.isOrganisationMode);
      } else if (columnObj.fieldType === FIELD_TYPE_ID.TABLE) {
        if (
          COLUMN_PROPERTIES[property].propertyName ===
          COLUMN_PROPERTY_KEYS.AUTO_CAPTURE_ON_ENTRY
        ) {
          const subType = columnObj?.subType;
          if (![FIELD_TYPE_ID.ASSIGN_TASK].includes(subType)) {
            return false;
          }
        }
        return Boolean(options?.isOrganisationMode);
      }
    }
    return true;
  } catch (error) {
    captureError(error);
  }
  return false;
};

const getFiltersArrFromSelectedOptionsObj = (
  selectedItemsAllCols,
  headerData,
  userPref,
  isOrganisationMode,
) => {
  const {
    checkIfValidFilterConfig,
  } = require('../actions/actionHelpers/searchFilterActionHelper');

  const headerIdTypeMapping = {};
  headerData.forEach((headerObj) => {
    headerIdTypeMapping[headerObj.id] = getColumnFieldType(headerObj);
  });
  const filterArr = [];
  //validation check
  for (const colId in selectedItemsAllCols) {
    const [isValid, options] = checkIfValidFilterConfig(
      headerIdTypeMapping[colId],
      selectedItemsAllCols[colId],
      userPref,
      true,
    );
    if (!isValid) {
      return {
        success: false,
      };
    }

    let finalOptionsArr = null;
    if (headerIdTypeMapping[colId] === FIELD_TYPE_ID.ASSIGN_TASK) {
      finalOptionsArr = options.map((arr, index) => {
        // Remove old assign task filter if any
        if (
          (isOrganisationMode && index !== 1 && index !== 4) ||
          (isArray(arr) && !arr?.length)
        ) {
          return null;
        }
        return arr;
      });
    } else if (headerIdTypeMapping[colId] === FIELD_TYPE_ID.CREATED_INFO) {
      finalOptionsArr = options.map((arr, index) => {
        // Remove old assign task filter if any
        if (isArray(arr) && !arr?.length) {
          return null;
        }
        return arr;
      });
    }
    filterArr.push({
      colId,
      selectedOptions: finalOptionsArr ?? options,
      fieldType: headerIdTypeMapping[colId],
      ...([FIELD_TYPE_ID.ASSIGN_TASK, FIELD_TYPE_ID.CREATED_INFO].includes(
        headerIdTypeMapping[colId],
      )
        ? {isCustom: true}
        : {}),
    });
  }
  return {
    success: true,
    filterArr,
  };
};

const getMiniAppsLogObject = (store) => {
  const {
    miniApps: {miniApps},
    auth: {
      user: {uid},
    },
  } = store;
  if (uid?.length) {
    let p_ownApps = 0,
      sharedApps = 0;
    Object.values(miniApps).forEach((item) => {
      if (item.appOwner.uid !== uid) {
        ++sharedApps;
      } else {
        ++p_ownApps;
      }
    });
    return {
      p_ownApps,
      sharedApps,
    };
  }
  return {};
};

const getCloudCallParams = (commonParamsMode, params) => {
  try {
    const getAppLevelCommonParams = () => {
      const {
        auth: {
          userPref: {timezone, lang},
        },
        miniApps: {activeAppId},
      } = getReduxState();
      return {
        miniAppId: activeAppId,
        c_appVersion: getDeviceVersion(),
        c_userTimezone: timezone,
        c_userLang: lang,
        c_platform: getPlatform(),
      };
    };
    switch (commonParamsMode) {
      case CLOUD_FUNCTION_COMMON_PARAMS.ELASTIC_REQUEST: {
        const {
          organisation: {
            activeOrganisationId,
            elasticIndex,
            isUserOrganisationOwner,
          },
          miniApps: miniAppsState,
        } = getReduxState();
        const extraParams = {};
        const {activeAppId, activeScreenId, docsData, miniApps} = miniAppsState;
        const docId =
          params?.docId ??
          miniApps[activeAppId]?.screens?.[activeScreenId]?.docs?.[0]?.docId;
        const fileObj = docsData?.[docId]?.fileObj;
        if (activeAppId && activeScreenId && docId && fileObj) {
          extraParams.customSorting = getTableSortingOrder(fileObj);
        }
        return {
          orgId: activeOrganisationId,
          elasticIndex,
          role: isUserOrganisationOwner ? 'OWNER' : 'MEMBER',
          ...getAppLevelCommonParams(),
          ...extraParams,
        };
      }
      default: {
        return getAppLevelCommonParams();
      }
    }
  } catch (e) {
    captureError(e);
  }
  return {};
};

const callCloudFunction = async (
  functionName,
  params,
  options,
  commonParamsMode,
) => {
  try {
    const extraParams = getCloudCallParams(commonParamsMode, params);
    const functionInstance = require('../imports')
      .functions()
      .httpsCallable(functionName, Object.assign({}, options));
    const response = await functionInstance(
      Object.assign({}, extraParams, params),
    );
    return response?.data;
  } catch (error) {
    return handleCloudError(error, functionName);
  }
};

const getColumnFieldType = (headerObj) =>
  headerObj?.fieldType === FIELD_TYPE_ID.TABLE && headerObj.subType
    ? headerObj?.subType
    : headerObj?.fieldType;

const getOrganisationContactObject = (memberObject, uid) => {
  const contactObj = {
    name: memberObject?.m_name?.length
      ? memberObject?.m_name
      : memberObject?.phoneNumber?.length
      ? memberObject?.phoneNumber
      : memberObject?.email,
    contact: memberObject?.phoneNumber?.length
      ? memberObject?.phoneNumber
      : memberObject?.email,
    uid,
  };

  USER_COLUMN_DEFAULT_FIELD_CONFIG_FIELDS.forEach((item) => {
    if (!memberObject?.[item.fieldId]?.length) {
      // if (item.fieldId === 'm_name') {
      //   contactObj[item.fieldId] = contactObj.name ?? '';
      // } else
      if (item.fieldId === 'm_email') {
        contactObj[item.fieldId] = memberObject?.email ?? '';
      } else if (item.fieldId === 'm_phone') {
        contactObj[item.fieldId] = memberObject?.phoneNumber ?? '';
      } else {
        contactObj[item.fieldId] = memberObject?.[item.fieldId] ?? '';
      }
    } else {
      contactObj[item.fieldId] = memberObject?.[item.fieldId] ?? '';
    }
  });
  contactObj['m_uid'] = uid;
  return contactObj;
};

const getOrgMembersUIDMapping = (organisationObj) => {
  const membersObj = organisationObj?.membersList;
  const ownerObj = organisationObj?.profileData?.owner;
  const ownerUID = organisationObj?.profileData?.ownerUID;

  const getMemberDetails = (memberObj, uid) => {
    const name = memberObj?.m_name?.length ? memberObj.m_name : null;
    const email = memberObj?.email?.length ? memberObj.email : null;
    const m_mail = memberObj?.m_email?.length ? memberObj.m_email : null;
    const phoneNumber = memberObj?.m_phone?.length
      ? memberObj.m_phone
      : memberObj?.phoneNumber?.length
      ? memberObj.phoneNumber
      : null;
    return Object.assign(
      phoneNumber ? {[phoneNumber]: uid} : {},
      email ? {[email]: uid} : {},
      m_mail ? {[m_mail]: uid} : {},
      name ? {[name]: uid} : {},
    );
  };

  return !isEmpty(membersObj)
    ? Object.keys(membersObj).reduce((prev, curr) => {
        return Object.assign(prev, getMemberDetails(membersObj[curr], curr));
      }, getMemberDetails(ownerObj, ownerUID))
    : {};
};

//Dont user this method inside render method as it contains reduxStore get calls
/**
 *
 * @returns [{ "name": "","contact": "","uid": "","m_name": "","m_user_image": "","m_email": "","m_phone": "","m_uid": ""}]
 */
const getOrganisationMemberList = () => {
  try {
    const {
      auth: {user},
      organisation: {membersList, profileData, isUserOrganisationOwner},
    } = getReduxState();

    const loggedInUser = user;
    const membersListUpdated = Object.assign(
      {},
      omit(Object.assign({}, membersList), [loggedInUser.uid]),
    );

    const ownerInfo = profileData?.owner;
    const ownerUID = profileData?.ownerUID;
    let selfContact = {};
    if (isUserOrganisationOwner) {
      selfContact = getOrganisationContactObject(ownerInfo, ownerUID);
    } else {
      const memberInfo = membersList[loggedInUser?.uid];
      selfContact = getOrganisationContactObject(memberInfo, loggedInUser?.uid);
    }

    const updatedMemberList = [selfContact];
    if (!isUserOrganisationOwner) {
      updatedMemberList.push(getOrganisationContactObject(ownerInfo, ownerUID));
    }

    Object.keys(membersListUpdated).forEach((uid) => {
      updatedMemberList.push(
        getOrganisationContactObject(membersListUpdated[uid], uid),
      );
    });

    return updatedMemberList;
  } catch (err) {
    captureError(err);
  }
  return [];
};

const convertURLHashToObject = (hash) => {
  let hashObj = {};
  try {
    if (typeof hash === 'string' && hash.length) {
      hashObj = hash
        .substring(1)
        .split('&')
        .reduce(function (res, item) {
          const parts = item.split('=');
          res[parts[0]] = parts[1];
          return res;
        }, {});
    }
  } catch (err) {
    captureError(err);
  }
  return hashObj;
};

const isQuickFilterableColumn = (colObj) => {
  try {
    return (
      COLUMNS_SUPPORTED_FOR_QUICK_FILTER.includes(getColumnFieldType(colObj)) &&
      !isBackgroundField(colObj)
    );
  } catch (error) {
    captureError(error);
  }
  return false;
};

/**
 * This will handle the scenario if some column selected for quick filter is removed from headerData
 * @param {Array} quickFilterColumns - array of
 * @param {Array} headerData
 * @returns Array of columnIds for quickFilter
 */
const getQuickFilterColumns = (quickFilterColumns, headerDataAsObj) => {
  return (quickFilterColumns ?? []).filter((quickFilterColumnId) =>
    Boolean(headerDataAsObj[quickFilterColumnId]),
  );
};

const getFirebaseTimestampAsDateObj = (timestamp) => {
  if (isNumber(timestamp) && !isNaN(timestamp)) {
    return new Date(timestamp);
  }
  const ts = Math.round(
    timestamp?._seconds * 1e3 + timestamp?._nanoseconds * 1e-6,
  );
  if (isNumber(ts) && !isNaN(ts)) {
    return new Date(ts);
  }
  const tsAlter = Math.round(
    timestamp?.seconds * 1e3 + timestamp?.nanoseconds * 1e-6,
  );
  return new Date(tsAlter);
};

const checkIfFirestoreTimestamp = (timestamp) => {
  return (
    timestamp instanceof firestore(true).Timestamp ||
    (isPlainObject(timestamp) &&
      (isNumber(timestamp._nanoseconds) || isNumber(timestamp.nanoseconds)) &&
      (isNumber(timestamp._seconds) || isNumber(timestamp.seconds)))
  );
};

const getScreenIdListFromDocId = (
  docId,
  withoutHiddenScreensAndGroups = true,
) => {
  const {
    miniApps: {activeAppId, miniApps, activeCustomRoleInfo},
    auth: {user},
  } = getReduxState();
  let screenIdArr = [];
  const sortedScreens =
    getSortedMiniAppsScreens(
      miniApps[activeAppId],
      user.uid,
      activeCustomRoleInfo,
      false,
      withoutHiddenScreensAndGroups,
    ) ?? [];
  screenIdArr = sortedScreens
    .filter((screen) => screen?.docs?.[0]?.docId === docId)
    ?.map((screen) => screen?.screenId);

  return screenIdArr ?? [];
};

const getScreensListFromDocIds = (
  state,
  docIdsArr = [],
  withoutHiddenScreensAndGroups = true,
  withoutKanbanScreens = true,
) => {
  const {
    miniApps: {activeAppId, miniApps, activeCustomRoleInfo},
    auth: {user},
  } = state;
  const sortedScreens =
    getSortedMiniAppsScreens(
      miniApps[activeAppId],
      user.uid,
      activeCustomRoleInfo,
      false,
      withoutHiddenScreensAndGroups,
      withoutKanbanScreens,
    ) ?? [];
  return sortedScreens.filter((screen) =>
    docIdsArr.includes(screen?.docs?.[0]?.docId),
  );
};

const getHeaderDataSummaryColumn = (
  subListHeaderData = [],
  listHeaderObject = {},
) => {
  return subListHeaderData.filter((columnObj) => {
    const columnType = getColumnFieldType(columnObj);
    const subtype = columnObj?.subType ?? '';
    if (
      columnType === FIELD_TYPE_ID.FORMULA &&
      [FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.TIME].includes(subtype)
    ) {
      return false;
    }
    if (isBackgroundField(columnObj)) {
      return false;
    }
    return (
      ALLOWED_FIELD_TYPES_FOR_SUMMARY_COLUMN.includes(columnType) &&
      columnObj.id !== listHeaderObject?.listConfig?.subListPrimaryColId
    );
  });
};
const checkIfColumnFieldAllowed = (headerObj) => {
  //used currently in Sort By Feature
  const {fieldType, subType} = headerObj;
  if (!fieldType || !SORT_BY_COLUMN.ALLOWED_TYPES.includes(fieldType)) {
    return false;
  } else if (FIELD_TYPE_ID.TABLE === fieldType) {
    return SORT_BY_COLUMN.ALLOWED_TYPES.includes(subType);
  } else if (FIELD_TYPE_ID.FORMULA === fieldType) {
    return (
      !headerObj?.hasPrevRowRef &&
      ![FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.TIME].includes(subType)
    );
  }
  return true;
};

const checkScreensAffectedOnSort = (screens, docId) => {
  return Object.keys(screens).reduce((affectedScreenIds, screenId) => {
    if (
      MINI_APPS.DOC_BASED_SCREEN_TYPES.includes(screens[screenId]?.type) &&
      screens[screenId].docs[0]?.docId === docId
    ) {
      affectedScreenIds.push(screens[screenId].screenName);
    }
    return affectedScreenIds;
  }, []);
};

const getDatesWithDayNames = (year, month) => {
  const datesWithDayNames = [];

  const startDate = moment({year, month: month - 1, day: 1});
  const endDate = startDate.clone().endOf('month');
  const currentDate = startDate.clone();

  while (currentDate.isSameOrBefore(endDate, 'day')) {
    const day = currentDate.date();
    const dayName = currentDate.format('ddd');
    const formattedDate = currentDate.format('YYYY-MM-DD');

    const id = new Date(formattedDate);
    id.setHours(0, 0, 0, 0);

    datesWithDayNames.push({id, dayName, date: day});

    currentDate.add(1, 'day');
  }
  return datesWithDayNames;
};

//Used for mobile as Promise.allSettled is not working on React Native v0.64
const allSettled = (promises) => {
  return Promise.all(
    promises.map((promise) =>
      promise
        .then((value) => ({status: 'fulfilled', value}))
        .catch((reason) => ({status: 'rejected', reason})),
    ),
  );
};

export const getAllPrimaryColumnIdsForList = (headerData = []) => {
  return headerData.reduce((primaryColIdList, colObj) => {
    if (colObj.fieldType === FIELD_TYPE_ID.LIST) {
      const {primaryColId} = colObj.listConfig || {};
      primaryColId && primaryColIdList.push(primaryColId);
    }
    return primaryColIdList;
  }, []);
};
const getColumnIdWithScreen = (col) =>
  `${col.id}@${col.screenId}@${col.operation}`;

const checkIfSelfServingFlowAllowed = (userPref, orgId) => {
  if (!isProduction) {
    return true;
  }

  return CHECK_USERPREF_FOR_SELF_SERVING
    ? userPref?.showSelfServingFlow ||
        [
          'org_Ni69Jz60ADW7ecRlfj7ccDKqCMf2_1697701120',
          'org_R9ci9Ra0o0XN8oD23WXgro7FjpI2_1682576473',
          'org_AImc0v2LhEdUkjjFjBOcme5LU6w1_1682341046',
        ].includes(orgId)
    : true;
};

const getAssignTaskObjForOrgUser = (userOrgInfo) => {
  // Function to get assign task object for a user in 'organisation' mode

  if (!userOrgInfo) return null;

  const assignee = {
    name: userOrgInfo?.[ORG_MEMBER_PROFILE_FIELDS.NAME] || '',
    contact: userOrgInfo?.phoneNumber || userOrgInfo?.email,
    uid: userOrgInfo?.uid,
  };

  return {
    assignee,
    // dueData: null,
    // note: null,
    // priority: 'NONE',
  };
};

const isUpdatingAutoCapturedValueAllowed = (activeAppMeta, userId) => {
  if (!activeAppMeta || !userId) {
    const {
      auth: {user},
      miniApps,
    } = getReduxState();

    const {activeAppId, miniApps: miniAppsObj} = miniApps;
    activeAppMeta = miniAppsObj[activeAppId];
    userId = user.id;
  }

  if (!activeAppMeta) {
    return true;
  }

  const currentUserAppPermission = activeAppMeta.isMiniAppOwner
    ? MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.OWNER
    : activeAppMeta.sharedWith?.[userId]?.permission;

  return ALLOWED_PERMISSIONS_FOR_AUTO_CAPTURE_CHANGE.includes(
    currentUserAppPermission,
  );
};

function canEnableAutoCaptureProperty(
  headerData = [],
  colId = '',
  fieldType = '',
  subType = '',
  parentDocId = '',
  userPref = {},
  displayToast = false,
) {
  switch (fieldType) {
    case FIELD_TYPE_ID.ASSIGN_TASK: {
      const canEnable = !headerData.some(
        (colObj) =>
          colObj.id !== colId &&
          colObj.fieldType === FIELD_TYPE_ID.ASSIGN_TASK &&
          Boolean(
            colObj?.columnProperties?.[
              COLUMN_PROPERTY_KEYS.AUTO_CAPTURE_ON_ENTRY
            ],
          ),
      );

      if (!canEnable && displayToast) {
        ShowToast(
          'Cannot have more than one Assign Task Column with Auto Capture on Entry',
          userPref,
        );
      }

      return canEnable;
    }

    case FIELD_TYPE_ID.TABLE:
      if (subType === FIELD_TYPE_ID.ASSIGN_TASK) {
        const canEnable = !headerData.some(
          (colObj) =>
            colObj.id !== colId &&
            colObj.fieldType === FIELD_TYPE_ID.TABLE &&
            colObj?.subType === FIELD_TYPE_ID.ASSIGN_TASK &&
            Object.keys(colObj?.linkedMeta ?? {})?.[0] === parentDocId &&
            Boolean(
              colObj?.columnProperties?.[
                COLUMN_PROPERTY_KEYS.AUTO_CAPTURE_ON_ENTRY
              ],
            ),
        );
        if (!canEnable && displayToast) {
          ShowToast(
            'Cannot have more than one Table Linked Assign Task Column Linked to Same Parent with Auto Capture on Entry',
            userPref,
          );
        }

        return canEnable;
      }
      break;
    default:
      break;
  }

  return false;
}

function isAutoCaptureEnabledForSameParentColumn(
  headerData = [],
  fieldType = '',
  subType = '',
  parentDocId = '',
) {
  if (!(fieldType === FIELD_TYPE_ID.TABLE)) return false;

  if (!subType) return false;

  return headerData.some(
    (colObj) =>
      Object.keys(colObj?.linkedMeta ?? {})?.[0] === parentDocId &&
      Boolean(
        colObj?.columnProperties?.[COLUMN_PROPERTY_KEYS.AUTO_CAPTURE_ON_ENTRY],
      ),
  );
}

const checkIfAutoCapturedTableColumnPresent = (headerData = []) => {
  return headerData.some((colObj) => {
    const {fieldType, subType, columnProperties} = colObj ?? {};

    return (
      fieldType === FIELD_TYPE_ID.TABLE &&
      subType === FIELD_TYPE_ID.ASSIGN_TASK &&
      Boolean(columnProperties?.[COLUMN_PROPERTY_KEYS.AUTO_CAPTURE_ON_ENTRY])
    );
  });
};

const getDashboardValueColor = (color, value) => {
  if (typeof color === 'string') {
    return color;
  }
  if (value > 0 && isObject(color)) {
    return color['POSITIVE'];
  } else if (value <= 0 && isObject(color)) {
    return color['NEGATIVE'];
  }
  return '#000000';
};
function getDarkColor() {
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += Math.floor(Math.random() * 10);
  }
  return color;
}

const getDateTimeFormattedDashboardValue = (minutes, subType, operation) => {
  if (operation === 'COUNT') {
    return minutes;
  }

  // Calculate the number of days
  const days = Math.floor(minutes / 1440);

  // Calculate the number of hours
  const hours = Math.floor((minutes % 1440) / 60);
  const daysToHours = days * 24 + hours;
  // Calculate the number of minutes
  const remainingMinutes = minutes % 60;

  if (subType === FIELD_TYPE_ID.DATE) {
    return `${Math.abs(days)} days ${days < 0 ? 'ago' : ''}`;
  } else if (subType === FIELD_TYPE_ID.TIME) {
    return `${daysToHours} hr ${
      remainingMinutes !== 0 ? `${remainingMinutes} min` : ''
    }`;
  } else {
    return minutes;
  }
};

const isDateTimeFormulaColumn = (fieldType, subType) => {
  return (
    fieldType === FIELD_TYPE_ID.FORMULA &&
    [FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.TIME].includes(subType)
  );
};

const IsDateTimeDiff = (item) =>
  isObject(item) &&
  ((item.fieldType === FIELD_TYPE_ID.FORMULA &&
    [FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.TIME].includes(item.subType)) ||
    (item.fieldType === DASHBOARD_FORMULA &&
      [FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.TIME].includes(item.subType)) ||
    (item.subType === FIELD_TYPE_ID.FORMULA &&
      [FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.TIME].includes(item.parentSubType)));

function formatFireStorePdfFormatToLocalPdfFormat(
  pdfConfig,
  showViewAccesColumn = false,
) {
  const input = {
    pdfConfig: {
      ...(showViewAccesColumn ? {showViewAccesColumn} : {}),
      // sectionsMeta: {},
      sections: [],
    },
  };
  for (const sectionIndex in pdfConfig) {
    if (pdfConfig?.[sectionIndex]) {
      const section = pdfConfig[sectionIndex];
      const sectionObj = {
        ...section,
        minHeight: section.minHeight,
        portions: [],
      };
      for (const portionIndex in section.portions) {
        if (section?.portions?.[portionIndex]) {
          const portion = section.portions[portionIndex];
          const portionObj = {
            ...portion,
            alignment: portion.alignment,
            columns: [],
          };
          for (const columnId in portion.columns) {
            if (portion?.columns?.[columnId]) {
              const column = portion.columns[columnId];
              portionObj.columns.push(column);
            }
          }

          portionObj.columns = sortBy(portionObj.columns, 'columnSortKey');
          sectionObj.portions.push(portionObj);
        }
      }
      sectionObj.portions = sortBy(sectionObj.portions, 'portionSortKey');
      input.pdfConfig.sections.push(sectionObj);
    }
  }
  input.pdfConfig.sections = sortBy(input.pdfConfig.sections, 'sectionSortKey');

  return input;
}

const getPdfConfigFireStore = async (configId) => {
  try {
    const db = firestore();

    const pdfConfigSnapShot = await db
      .collection('pdfTemplates')
      .doc(configId)
      .get();
    if (pdfConfigSnapShot.exists) {
      const data = await pdfConfigSnapShot.data();
      return formatFireStorePdfFormatToLocalPdfFormat(data);
    } else {
      return {
        // sectionsMeta: {},
        sections: [],
      };
    }
  } catch (err) {
    console.error(err);
    return {};
  }
};

const checkIfUserIsOwnerOrAdminOfApp = (activeAppMeta, uid) => {
  const isMiniAppOwner = activeAppMeta?.isMiniAppOwner ?? false;
  const userPermission = activeAppMeta?.sharedWith?.[uid]?.permission;

  if (
    isMiniAppOwner ||
    userPermission === MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.ADMIN
  ) {
    return true;
  } else {
    return false;
  }
};

const getColumnsForVisibilityCondition = (colObj, isRunAutomation) => {
  const fieldType = getColumnFieldType(colObj);
  return (
    MINI_APPS.ACTION_BUTTONS.VISIBLE_FILTER_FIELDS.includes(fieldType) &&
    (isRunAutomation ? ![FIELD_TYPE_ID.ASSIGN_TASK].includes(fieldType) : true)
  );
};

const handleSlackCustomAppRequest = async (data) => {
  const {
    auth: {user, userPref},
  } = getReduxState();
  await callCloudFunction(CLOUD_FUNCTION_PATHS.POST_API_HANDLER, {
    body: {
      userName: userPref.name,
      userCountry: userPref.country,
      phoneNumber: user?.phoneNumber ?? 'NA',
      email: user?.email ?? 'NA',
      uid: user.uid,
      customAppInfo: data.customAppInfo,
      currentSystem: data.currentSystem,
      platform: data.platform,
      env: isProduction ? 'PROD' : 'DEV',
    },
    type: API_TYPES.SLACK_CUSTOM_APP_REQUEST,
  })
    .then(() => {
      ShowToast('Request Sent Successfully', userPref);
    })
    .catch((error) => {
      console.error('Error while sending request for custom app', error);
    });
};

const getAllowedColumnsForTableLinkFilters = (headerData = []) => {
  return headerData?.filter(
    (column) => getColumnFieldType(column) !== FIELD_TYPE_ID.TIME,
  );
};
const getGroupedScreenLength = (screenGroupData = []) => {
  let screenLength = 0;
  screenGroupData.forEach((item) => {
    if (item?.screenId) {
      screenLength += 1;
    } else if (item?.screenIds) {
      screenLength += item?.screenIds?.length;
    }
  });
  return screenLength;
};

const isScreenOrGroupHideOperationAllowed = (
  hiddenGroupIds,
  hiddenScreenIds,
  screenGroupData,
  screenData,
  isGroupHideOperation, // denotes if it is a group hide operation
  hideElementId, // denoted groupId if isGroupHideOperation is true or else denotes screenId
) => {
  let result = true;
  let totalHiddenScreens = 0;
  hiddenGroupIds?.forEach((hiddenGroupId) => {
    const groupIndex = screenGroupData.findIndex(
      (item) => item?.groupId === hiddenGroupId,
    );
    totalHiddenScreens += screenGroupData?.[groupIndex]?.[`screenIds`].length;
  });
  hiddenScreenIds?.forEach((hiddenScreenId) => {
    const parentGroupId = screenData?.[hiddenScreenId]?.[`groupId`];
    if (isNil(parentGroupId)) {
      totalHiddenScreens += 1;
    } else {
      if (!hiddenGroupIds.includes(parentGroupId)) {
        totalHiddenScreens += 1;
      }
    }
  });
  if (isGroupHideOperation) {
    const selectedGroupId = hideElementId;
    const selectedGroupIdIndex = screenGroupData.findIndex(
      (item) => item?.groupId === selectedGroupId,
    );
    const selectedGroupScreenIds =
      screenGroupData?.[selectedGroupIdIndex]?.[`screenIds`];
    let visibleScreensCountInSelectedGroup = 0;
    const hiddenScreenIdsObject = {};
    hiddenScreenIds.forEach(
      (screenId) => (hiddenScreenIdsObject[screenId] = true),
    );
    selectedGroupScreenIds.forEach((screenId) => {
      if (!hiddenScreenIdsObject[screenId]) {
        visibleScreensCountInSelectedGroup += 1;
      }
    });
    result = !(
      (getGroupedScreenLength(screenGroupData) ?? 0) ===
      totalHiddenScreens + visibleScreensCountInSelectedGroup
    );
  } else {
    // check if parent group is already hidden
    // then allow hiding the screen
    const parentGroupId = screenData?.[hideElementId]?.[`groupId`];
    if (hiddenGroupIds.includes(parentGroupId)) {
      return true;
    }
    // check if total number of hidden screen till
    // now is less than total number of screens
    result = !(
      (getGroupedScreenLength(screenGroupData) ?? 0) ===
      totalHiddenScreens + 1
    );
  }
  return result;
};

const areAllScreenHidden = (
  screenGroupData = [],
  hiddenScreenIdsArr = [],
  hiddenGroupIdArr = [],
  screenIdToDelete = null,
) => {
  let visibleScreenCount = 0;
  screenGroupData.forEach((item) => {
    const {screenId: itemScreenId} = item;
    if (itemScreenId) {
      !hiddenScreenIdsArr.includes(itemScreenId) &&
        itemScreenId !== screenIdToDelete &&
        visibleScreenCount++;
    } else {
      if (!hiddenGroupIdArr.includes(item?.groupId)) {
        const {screenIds} = item;
        !isNil(screenIds) &&
          screenIds.forEach(
            (screenId) =>
              screenId !== screenIdToDelete &&
              !hiddenScreenIdsArr.includes(screenId) &&
              visibleScreenCount++,
          );
      }
    }
  });

  return visibleScreenCount === 0;
};

const isFieldVisibilityConfigurationAllowed = (colObj) => {
  if (
    [FIELD_TYPE_ID.AUTO_INCREMENT_ID].includes(getColumnFieldType(colObj)) ||
    !isNil(colObj?.summaryInfo) ||
    !isEmpty(colObj?.linkedToList)
  ) {
    return false;
  }
  return true;
};

// gives filtered headerData for visibility conditions for selectedField
// filters out all the columns below the selected column and the selected field
const getFilteredHeaderDataForVisibilityConditions = (fieldId, headerData) => {
  const selectedFieldIndex = headerData.findIndex(
    (colObj) => colObj.id === fieldId,
  );
  return headerData.slice(0, selectedFieldIndex);
};

// Get dependency mapping for visibility of a field
/**  {
    colA : { colB: true }
} 
interpretation - colB is dependent on colA for visibility
*/
const getFieldVisibilityDepedencyMapAndTopoSort = (headerData) => {
  const fieldVisibilityDependencyMapping = {};
  (headerData ?? []).forEach((colObj) => {
    const conditionsArr = colObj?.visibilityCondition?.conditions;

    if (Array.isArray(conditionsArr) && conditionsArr.length > 0) {
      conditionsArr.forEach((conditionObj) => {
        if (fieldVisibilityDependencyMapping[conditionObj.colId]) {
          fieldVisibilityDependencyMapping[conditionObj.colId][
            colObj.id
          ] = true;
        } else {
          fieldVisibilityDependencyMapping[conditionObj.colId] = {
            [colObj.id]: true,
          };
        }
      });
    }
  });

  // Get topological sort for dependency map
  const visited = {};
  function dfsTopSortHelper(v, topoStack, dependencyGraph) {
    visited[v] = true;
    const dependentCols = Object.keys(dependencyGraph[v] ?? {});
    if (Array.isArray(dependentCols)) {
      for (const dependentCol of dependentCols) {
        if (!visited[dependentCol]) {
          dfsTopSortHelper(dependentCol, topoStack, dependencyGraph);
        }
      }
    }
    return topoStack.push(v);
  }
  function dfsTopSort(graph) {
    const vertices = Object.keys(graph);
    const topoStack = [];
    for (const v of vertices) {
      if (!visited[v]) {
        dfsTopSortHelper(v, topoStack, graph);
      }
    }
    return [...topoStack].reverse();
  }

  const dependencyTopoSort = dfsTopSort(fieldVisibilityDependencyMapping);

  const notVisitedColIds = (headerData ?? [])
    .filter((colObj) => !visited[colObj.id])
    .map((colObj) => colObj.id);
  dependencyTopoSort.push(...notVisitedColIds);

  return [fieldVisibilityDependencyMapping, dependencyTopoSort];
};

/**
 * Function to get Assign Task Cell Value for Mini App
 * @param {{
 * displayName: string;
 * name: string;
 * email: string;
 * phoneNumber: string;
 * m_name: string;
 * }} orgMemberObject - Organisation Member Object
 * @param {string} uid - User ID
 * @returns {object}
 */
const getAssignTaskCellForMiniApp = (orgMemberObject, uid) => {
  const {
    displayName = '',
    name = '',
    email = '',
    phoneNumber = '',
    m_name = '',
  } = orgMemberObject ?? {};

  return {
    val: {
      assignee: {
        name: m_name || displayName || name || '',
        contact: phoneNumber || email,
        uid,
      },
      createdTimestamp: moment().unix(),
      taskId: 'ORGANISATION_TASK',
    },
  };
};

/**
 *
 * @param {*} toColData
 * @param {*} fromColData
 * @param {*} cellObj
 * @param {*} userCountry
 * @param {*} timezone
 * @param {*} fileObj
 * @param {{
 * ownerUID
 * owner
 * members
 * }} organisationData
 * @returns
 */
const convertCellToDifferentField = (
  toColData,
  fromColData,
  cellObj,
  userCountry,
  timezone,
  fileObj,
  organisationData,
  isRawVal = false,
) => {
  const toText = (text) => {
    return typeof text === 'string'
      ? text.trim()
      : typeof text === 'number'
      ? `${text}`.trim()
      : '';
  };

  const toSelectBoxCell = (text) => {
    const finalText = typeof text === 'number' ? text : text || '';
    return {
      val: finalText,
      displayName: {EN: finalText},
    };
  };

  const responseCellObj = {};

  if (cellObj?.childLinks) {
    responseCellObj.childLinks = cellObj?.childLinks;
  }

  if (cellObj?.parentMeta) {
    responseCellObj.parentMeta = cellObj?.parentMeta;
  }

  // ------------------------------------------------------------------------
  // ------------------ Convert To Different Field --------------------------
  // ------------------------------------------------------------------------

  const toOriginalFieldType = getOriginalFieldType(toColData);
  const fromOriginalFieldType = getOriginalFieldType(fromColData);

  // We are not getting `raw` value as we need display value.
  const getTextVal = () =>
    convertCellDataToText(
      cellObj,
      fromColData,
      userCountry,
      {lang: 'EN'},
      fileObj,
      {},
      true,
      {},
      isRawVal,
    );

  if (
    toOriginalFieldType === FIELD_TYPE_ID.TEXT ||
    toOriginalFieldType === FIELD_TYPE_ID.BARCODE
  ) {
    const text = getTextVal();
    responseCellObj.val = toText(text);
    return responseCellObj;
  }

  if (toOriginalFieldType === FIELD_TYPE_ID.CONTACT) {
    if (fromOriginalFieldType === FIELD_TYPE_ID.TEXT) {
      const val = trim(cellObj?.val || '');

      responseCellObj.val = val;
      responseCellObj.contactName = val;
      return responseCellObj;
    }
  }

  if (
    [
      FIELD_TYPE_ID.NUMBER,
      FIELD_TYPE_ID.RUPEE,
      FIELD_TYPE_ID.UNIT,
      FIELD_TYPE_ID.NO_OF_DAYS,
    ].includes(toOriginalFieldType)
  ) {
    if (PURE_NUMBER_FIELDS.includes(fromOriginalFieldType)) {
      const val = Number(cellObj?.val);
      responseCellObj.val = val;
      return responseCellObj;
    }
  }

  if (toOriginalFieldType === FIELD_TYPE_ID.SWITCH) {
    if (fromOriginalFieldType === FIELD_TYPE_ID.CHECKBOX) {
      responseCellObj.val = cellObj?.val || false;
      return responseCellObj;
    }
  }

  if (toOriginalFieldType === FIELD_TYPE_ID.CHECKBOX) {
    if (fromOriginalFieldType === FIELD_TYPE_ID.SWITCH) {
      responseCellObj.val = cellObj?.val || false;
      return responseCellObj;
    }
  }

  if (toOriginalFieldType === FIELD_TYPE_ID.OTP) {
    if (
      [FIELD_TYPE_ID.SWITCH, FIELD_TYPE_ID.CHECKBOX].includes(
        fromOriginalFieldType,
      )
    ) {
      responseCellObj.val = cellObj?.val || false;
      return responseCellObj;
    }
  }

  if (toOriginalFieldType === FIELD_TYPE_ID.DATE_TIME) {
    // TO:
    // "Col_1693553812772": { // DATE_TIME
    //   "timezone": "Asia/Kolkata",
    //   "recurringType": "DOES_NOT_REPEAT",
    //   "val": "2023-12-01T21:15:00Z" // Asia/Kolkata
    // },
    if (fromOriginalFieldType === FIELD_TYPE_ID.DATE) {
      // FROM
      // "default_colC": { // Date
      //   "displayDate": "02/12/2023",
      //   "val": 1701455400 // UTC
      // },

      const val =
        moment(cellObj?.val * 1000).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
      const recurringType = MINI_APPS_DATE_RECURRING_TYPES.DOES_NOT_REPEAT;

      responseCellObj.val = val;
      responseCellObj.timezone = timezone;
      responseCellObj.recurringType = recurringType;
      return responseCellObj;
    }
  }

  if (toOriginalFieldType === FIELD_TYPE_ID.DATE) {
    // TO
    // "default_colC": { // Date
    //   "displayDate": "02/12/2023",
    //   "val": 1701455400 // Asia/Kolkata
    // },

    if (fromOriginalFieldType === FIELD_TYPE_ID.DATE_TIME) {
      // FROM:
      // "Col_1693553812772": { // DATE_TIME
      //   "timezone": "Asia/Kolkata",
      //   "recurringType": "DOES_NOT_REPEAT",
      //   "val": "2023-12-01T21:15:00Z" // UTC
      // },
      const val = moment(cellObj?.val).unix();
      return Object.assign(
        {},
        responseCellObj,
        getDateTimeCellObj(val, false, false, toColData.dateFormat),
      );
    }
  }

  if (toOriginalFieldType === FIELD_TYPE_ID.DOCUMENT) {
    // TO:
    // "Col_1701600533807": { // Document/Attachment
    //   "val": [
    //     {
    //       "contentType": "application/pdf",
    //       "docRef": "dWOCb1sGxfg9od7q8TCKj0VsB3k2/dWOCb1sGxfg9od7q8TCKj0VsB3k2_1693553170868/Col_1701600533807/documents/c4611_sample_explain_1701466121074",
    //       "uri": "https://firebasestorage.googleapis.com/v0/b/registerbook-development.appspot.com/o/dWOCb1sGxfg9od7q8TCKj0VsB3k2%2FdWOCb1sGxfg9od7q8TCKj0VsB3k2_1693553170868%2FCol_1701600533807%2Fdocuments%2Fc4611_sample_explain_1701466121074?alt=media&token=3c5bed16-7084-466d-9e07-9682bbd5b91c",
    //       "fileName": "c4611_sample_explain.pdf"
    //     },
    //     {
    //       "contentType": "application/pdf",
    //       "docRef": "dWOCb1sGxfg9od7q8TCKj0VsB3k2/dWOCb1sGxfg9od7q8TCKj0VsB3k2_1693553170868/Col_1701600533807/documents/c4611_sample_explain (1)_1701466121082",
    //       "uri": "https://firebasestorage.googleapis.com/v0/b/registerbook-development.appspot.com/o/dWOCb1sGxfg9od7q8TCKj0VsB3k2%2FdWOCb1sGxfg9od7q8TCKj0VsB3k2_1693553170868%2FCol_1701600533807%2Fdocuments%2Fc4611_sample_explain%20(1)_1701466121082?alt=media&token=7dc3387d-ec6c-4c86-bfc1-9e6374bef90c",
    //       "fileName": "c4611_sample_explain (1).pdf"
    //     }
    //   ]
    // },
    if (fromOriginalFieldType === FIELD_TYPE_ID.IMAGE) {
      // FROM:
      // "Col_1701131387162": { // Image
      //   "val": [
      //     {
      //       "uri": "https://firebasestorage.googleapis.com/v0/b/registerbook-development.appspot.com/o/dWOCb1sGxfg9od7q8TCKj0VsB3k2%2FdWOCb1sGxfg9od7q8TCKj0VsB3k2_1693553170868%2FCol_1701131387162%2Fimages%2Fimage_1701465962032?alt=media&token=c74d0eca-dbae-48cd-b4d2-263649fe1b80",
      //       "imageRef": "dWOCb1sGxfg9od7q8TCKj0VsB3k2/dWOCb1sGxfg9od7q8TCKj0VsB3k2_1693553170868/Col_1701131387162/images/image_1701465962032"
      //     },
      //     {
      //       "uri": "https://firebasestorage.googleapis.com/v0/b/registerbook-development.appspot.com/o/dWOCb1sGxfg9od7q8TCKj0VsB3k2%2FdWOCb1sGxfg9od7q8TCKj0VsB3k2_1693553170868%2FCol_1701131387162%2Fimages%2Fimage_1701465971198?alt=media&token=fabacb01-213b-40ef-a2ab-2509e950631e",
      //       "imageRef": "dWOCb1sGxfg9od7q8TCKj0VsB3k2/dWOCb1sGxfg9od7q8TCKj0VsB3k2_1693553170868/Col_1701131387162/images/image_1701465971198"
      //     }
      //   ]
      // },

      responseCellObj.val = cellObj?.val?.map((imageObj) => ({
        contentType: 'application/pdf',
        docRef: imageObj?.imageRef,
        uri: imageObj?.uri,
        fileName: imageObj?.uri?.split('/').pop(),
      }));
      return responseCellObj;
    }
  }

  if (toOriginalFieldType === FIELD_TYPE_ID.SELECT_POP_UP) {
    if (fromOriginalFieldType === FIELD_TYPE_ID.TEXT) {
      const {val, displayName} = toSelectBoxCell(cellObj?.val);
      responseCellObj.val = val;
      responseCellObj.displayName = displayName;
      return responseCellObj;
    }
  }

  if (toOriginalFieldType === FIELD_TYPE_ID.LABEL) {
    if (fromOriginalFieldType === FIELD_TYPE_ID.TEXT) {
      const split = require('lodash/split');
      const val = split(cellObj?.val, ',');
      responseCellObj.val = val.length
        ? val.map((str) => toSelectBoxCell(str.trim()))
        : [];

      return responseCellObj;
    }
  }

  if (toOriginalFieldType === FIELD_TYPE_ID.ASSIGN_TASK) {
    if (fromOriginalFieldType === FIELD_TYPE_ID.TEXT) {
      const val = trim(cellObj?.val || '');
      const memberUID =
        require('./organistionUtils').getOrgContactToUIDMapping(
          organisationData,
        )[val];
      const orgMemberObject = require('./organistionUtils').getMemberUsingUID(
        organisationData,
        memberUID,
      );

      if (!isEmpty(orgMemberObject)) {
        const {val} = getAssignTaskCellForMiniApp(orgMemberObject, memberUID);
        responseCellObj.val = val;
        return responseCellObj;
      }
    }
  }

  // Default return
  return cellObj;
};

//!ANY CHANGES HERE SHOULD also be changed in rb-cloud-functions
const checkIfConditionIsTrue = (
  conditionsArr,
  conditionType,
  {rowObj, headerDataAsObj, fileObj, userMetaObj, uid, orgId},
  isConditionNestedStructured = false, //if the condition config is nested
) => {
  if (!(conditionType && Array.isArray(conditionsArr))) {
    return false;
  }

  const conditionCheck = ({
    colId: columnId,
    val: filterVal,
    type: filterType,
    valType,
  }) =>
    conditionCheckOnRecord(
      columnId,
      filterVal,
      filterType,
      valType,
      rowObj,
      headerDataAsObj,
      fileObj,
      userMetaObj,
      uid,
      {defaultReturn: false},
    );

  const convertNestedStructuredObj = (conditionObj) =>
    conditionCheck(convertConditionsToIdValStructure(conditionObj));

  return conditionType ===
    MINI_APPS.AUTOMATION.TRIGGER_CONDITION_CONFIG.ALL_SHOULD_BE_TRUE
    ? conditionsArr.every(
        isConditionNestedStructured
          ? conditionCheck
          : convertNestedStructuredObj,
      )
    : conditionsArr.some(
        isConditionNestedStructured
          ? conditionCheck
          : convertNestedStructuredObj,
      );
};

// for a given rowData and headerData and a set of conditions
// along with it's type, returns whether condition is satisfied or not
//!ANY CHANGES HERE SHOULD also be changed in rb-cloud-functions
const conditionCheckOnRecord = (
  columnId,
  filterVal,
  filterType,
  valType,
  rowObj,
  headerDataAsObj,
  fileObj,
  userMetaObj, //userCountry
  uid,
  options = {defaultReturn: undefined},
) => {
  const {TRIGGER_CONDITIONS} = MINI_APPS.AUTOMATION;
  const columnObj = Object.assign({}, headerDataAsObj?.[columnId]);
  const fieldType = getColumnFieldType(columnObj);

  const getCellVal = () => {
    const val = convertCellDataToText(
      rowObj?.[columnId] ?? {},
      columnObj,
      userMetaObj?.userCountry ?? {},
      {lang: 'EN'},
      fileObj,
      rowObj,
      false,
      {},
      true,
      false,
      {getDisplayDate: true},
    );

    return typeof val === 'string' ? val.trim().toLowerCase() : val;
  };

  const {
    auth: {userPref},
    organisation: {profileData, membersList},
  } = getReduxState();

  const organisationData = {
    ownerUID: profileData?.ownerUID,
    members: membersList,
    owner: profileData?.owner,
  };

  const getFilterColumnVal = () => {
    const valueColFieldType = getColumnFieldType(headerDataAsObj?.[filterVal]);
    let cellData = rowObj?.[filterVal] ?? {};

    if (valueColFieldType !== fieldType) {
      cellData = convertCellToDifferentField(
        columnObj,
        headerDataAsObj?.[filterVal],
        cellData,
        userMetaObj.userCountry,
        userPref?.timezone,
        fileObj,
        organisationData,
      );
    }

    const val = convertCellDataToText(
      cellData,
      valueColFieldType !== fieldType
        ? columnObj
        : headerDataAsObj?.[filterVal],
      userMetaObj.userCountry,
      userPref,
      fileObj,
      rowObj,
      false,
      {},
      true,
    );

    return typeof val === 'string' ? val.trim().toLowerCase() : val;
  };

  let filterValToUse =
    valType === SETVALUE_COLUMN_VALUE_TYPES.DEFAULT_CONST_VALUE
      ? ColumnUtility.getTypeBasedDefaultValueForConditions({
          type: filterVal,
          colObj: columnObj,
          fileObj,
          uid,
          newUserUID: null,
          organisationData,
          rowProperties: rowObj?.rowProperties,
          timezone: userPref?.timezone,
          userCountry: userMetaObj.userCountry,
        })
      : valType === SETVALUE_COLUMN_VALUE_TYPES.COLUMN
      ? getFilterColumnVal()
      : filterVal;

  if (typeof filterValToUse === 'string') {
    if (
      fieldType === FIELD_TYPE_ID.ASSIGN_TASK &&
      (filterValToUse === DATA_FILTERS.LOGGED_IN_USER ||
        filterValToUse.trim().toLowerCase() === 'logged in user')
    ) {
      filterValToUse = uid;
    }
    filterValToUse = filterValToUse.trim().toLowerCase();
  }

  const getCellExistence = () =>
    checkIfValueExistOnCell(rowObj?.[columnId], columnObj, rowObj);

  const isNumericColumn = PURE_NUMBER_FIELDS.includes(fieldType);
  const isCustomValueTimeBased =
    valType === SETVALUE_COLUMN_VALUE_TYPES.DEFAULT_CONST_VALUE &&
    [FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.DATE_TIME, FIELD_TYPE_ID.TIME].includes(
      fieldType,
    );
  const formatStr = isCustomValueTimeBased
    ? fieldType === FIELD_TYPE_ID.DATE
      ? 'DD/MM/YYYY'
      : fieldType === FIELD_TYPE_ID.DATE_TIME
      ? 'DD/MM/YYYY hh:mm a'
      : fieldType === FIELD_TYPE_ID.TIME
      ? 'hh:mm a'
      : ''
    : '';
  switch (filterType) {
    case TRIGGER_CONDITIONS.EQUAL: {
      const cellVal = getCellVal();
      if (isNumericColumn) {
        return cellVal !== '' && Number(cellVal) === Number(filterValToUse);
      }
      return cellVal === filterValToUse;
    }
    case TRIGGER_CONDITIONS.NOT_EQUAL: {
      const cellVal = getCellVal();
      if (isNumericColumn) {
        return cellVal === '' || Number(cellVal) !== Number(filterValToUse);
      }
      return cellVal !== filterValToUse;
    }
    case TRIGGER_CONDITIONS.GREATER_THAN: {
      const cellVal = getCellVal();
      if (isNumericColumn) {
        return cellVal !== '' && Number(cellVal) > Number(filterValToUse);
      }
      if (isCustomValueTimeBased && typeof cellVal === 'string') {
        if (cellVal.length) {
          return moment(cellVal, formatStr).isAfter(
            moment(filterValToUse, formatStr),
          );
        } else {
          return options?.defaultReturn;
        }
      }
      return cellVal > filterValToUse;
    }
    case TRIGGER_CONDITIONS.LESS_THAN: {
      const cellVal = getCellVal();
      if (isNumericColumn) {
        return cellVal !== '' && Number(cellVal) < Number(filterValToUse);
      }

      if (isCustomValueTimeBased && typeof cellVal === 'string') {
        if (cellVal.length) {
          return moment(cellVal, formatStr).isBefore(
            moment(filterValToUse, formatStr),
          );
        } else {
          return options?.defaultReturn;
        }
      }

      return cellVal < filterValToUse;
    }
    case TRIGGER_CONDITIONS.GREATER_THAN_OR_EQUAL: {
      const cellVal = getCellVal();
      if (isNumericColumn) {
        return cellVal !== '' && Number(cellVal) >= Number(filterValToUse);
      }
      if (isCustomValueTimeBased && typeof cellVal === 'string') {
        if (cellVal.length) {
          return moment(cellVal, formatStr).isSameOrAfter(
            moment(filterValToUse, formatStr),
          );
        } else {
          return options?.defaultReturn;
        }
      }
      return cellVal >= filterValToUse;
    }
    case TRIGGER_CONDITIONS.LESS_THAN_OR_EQUAL: {
      const cellVal = getCellVal();
      if (isNumericColumn) {
        return cellVal !== '' && Number(cellVal) <= Number(filterValToUse);
      }
      if (isCustomValueTimeBased && typeof cellVal === 'string') {
        if (cellVal.length) {
          return moment(cellVal, formatStr).isSameOrBefore(
            moment(filterValToUse, formatStr),
          );
        } else {
          return options?.defaultReturn;
        }
      }
      return cellVal <= filterValToUse;
    }
    case TRIGGER_CONDITIONS.IS_EMPTY: {
      return Boolean(!getCellExistence());
    }
    case TRIGGER_CONDITIONS.ANY_VALUE: {
      return Boolean(getCellExistence());
    }
    case TRIGGER_CONDITIONS.UPTO_FIFTY_METERS: {
      return areLocationsWithinRadius(
        rowObj?.[columnId]?.coordinate,
        filterVal?.coordinate,
        0.05,
      );
    }
    case TRIGGER_CONDITIONS.UPTO_HUNDERED_METERS: {
      return areLocationsWithinRadius(
        rowObj?.[columnId]?.coordinate,
        filterVal?.coordinate,
        0.1,
      );
    }
    case TRIGGER_CONDITIONS.UPTO_TO_THREEHUNDERED_METERS: {
      return areLocationsWithinRadius(
        rowObj?.[columnId]?.coordinate,
        filterVal?.coordinate,
        0.3,
      );
    }
  }
};

const getHiddenFieldsBasedOnVisibilityConditions = (
  headerDataAsObj,
  rowData,
  fieldVisibilityDependencyMapping,
  dependencyTopoSort,
  fileObj,
  userMetaObj,
  uid,
) => {
  const hiddenFields = {};

  const getConditionsAndType = (colId) => {
    const {visibilityCondition} = headerDataAsObj?.[colId] || {};
    return visibilityCondition ?? {};
  };

  dependencyTopoSort.forEach((colId) => {
    const {conditions, type} = getConditionsAndType(colId);
    if (type && Array.isArray(conditions) && conditions.length) {
      const conditionCheck = ({
        colId: columnId,
        val: filterVal,
        type: filterType,
        valType,
      }) => {
        //TODO : REMOVE THIS TO HANDLE FIELDS WHICH ARE HIDDEN
        // if (updatedHiddenFields[columnId]) {
        //   // if the concerned field in condition is hidden because
        //   // of visibility conditions on that particular field,
        //   // that condition will be considered as not satisfied
        //   return false;
        // }

        return conditionCheckOnRecord(
          columnId,
          filterVal,
          filterType,
          valType,
          rowData,
          headerDataAsObj,
          fileObj,
          userMetaObj,
          uid,
          {defaultReturn: false},
        );
      };
      const isVisible =
        type ===
        MINI_APPS.AUTOMATION.TRIGGER_CONDITION_CONFIG.ALL_SHOULD_BE_TRUE
          ? conditions.every(conditionCheck)
          : conditions.some(conditionCheck);

      hiddenFields[colId] = !isVisible;

      if (hiddenFields[colId]) {
        Object.keys(fieldVisibilityDependencyMapping[colId] ?? {}).forEach(
          (fieldId) => {
            const fieldVisibilityConditionType =
              getConditionsAndType(fieldId)?.type;

            // If 'colId' hidden, and 'AND' type visibility condition for dependent field -> hide 'fieldId'
            if (
              fieldVisibilityConditionType ===
              MINI_APPS.AUTOMATION.TRIGGER_CONDITION_CONFIG.ALL_SHOULD_BE_TRUE
            ) {
              hiddenFields[fieldId] = true;
            }
          },
        );
      }
    }
  });

  return hiddenFields;
};

// check if updated header data is in correct order
// wrt to visibility conditions of all the fields
// A field should always be below in order the fields it is dependent on
/**
 * returns true for valid order
 **/
const checkIfFieldReorderIsValidForVisibilityConditions = (headerData) => {
  let isAllowed = true;

  headerData.forEach((colObj, index) => {
    if (!isAllowed) return;

    if (!isEmpty(colObj.visibilityCondition)) {
      const {visibilityCondition} = colObj;
      const {conditions} = visibilityCondition;
      if (Array.isArray(conditions) && conditions.length) {
        conditions.forEach((conditionObj) => {
          if (!isAllowed) return;

          const {colId, val, valType} = conditionObj;
          const dependentColumnIndex = headerData.findIndex(
            (obj) => obj.id === colId,
          );
          if (dependentColumnIndex > -1 && dependentColumnIndex > index) {
            isAllowed = false;
          }

          // check if RHS is column and check for
          // it's position as well w.r.t concerned column
          if (valType === SETVALUE_COLUMN_VALUE_TYPES.COLUMN) {
            const dependentColumnIndexOnRHS = headerData.findIndex(
              (obj) => obj.id === val,
            );
            if (
              dependentColumnIndexOnRHS > -1 &&
              dependentColumnIndexOnRHS > index
            ) {
              isAllowed = false;
            }
          }
        });
      }
    }
  });
  return isAllowed;
};

/**
 * Function to remove hidden fields (based on visibility conditions) from rowData
 */
const removeHiddenFieldsFromRowData = (rowData, hiddenFieldsMap) => {
  const hiddenFields = Object.keys(hiddenFieldsMap ?? {}).filter(
    (colId) => hiddenFieldsMap[colId],
  );
  return Object.assign(
    {},
    rowData,
    ...hiddenFields.map((colId) => ({[colId]: {}})),
  );
};

const getDefaultValuesForAddEntry = ({
  headerData = [],
  extras = {primaryColFromTable: []},
}) => {
  const parentMeta = {};

  return {
    defaultValue: headerData?.reduce?.((obj, curr) => {
      if (curr?.columnProperties?.[COLUMN_PROPERTY_KEYS.DEFAULT_VALUE]) {
        //In case of AssignTask some extra Params needs to be added
        switch (curr?.fieldType) {
          case FIELD_TYPE_ID.ASSIGN_TASK: {
            const defaultCellValue =
              curr?.columnProperties?.[COLUMN_PROPERTY_KEYS.DEFAULT_VALUE]?.val;
            if (extras?.isWeb) {
              obj[curr?.id] = {
                val: Object.assign({}, pick(defaultCellValue, [`assignee`]), {
                  // inputForAction: {
                  //   taskObj: Object.assign({}, defaultCellValue, {
                  //     assignee: defaultCellValue?.assignee,
                  //   }),
                  //   columnId: curr?.id,
                  //   rowId: undefined,
                  //   rowIndex: undefined,
                  //   isEdit: false,
                  //   oldTaskObj: undefined,
                  // },
                  taskId: 'ORGANISATION_TASK',
                }),
              };
            } else {
              obj[curr?.id] =
                curr.columnProperties[COLUMN_PROPERTY_KEYS.DEFAULT_VALUE];
            }
            break;
          }
          case FIELD_TYPE_ID.TABLE: {
            const primaryCols = extras?.primaryColFromTable || [];

            const tableDefaults =
              curr?.columnProperties?.[COLUMN_PROPERTY_KEYS.DEFAULT_VALUE];

            if (primaryCols?.includes?.(curr?.id)) {
              Object.assign(obj, tableDefaults?.rowValues);
              if (!isEmpty(tableDefaults?.parentMeta)) {
                Object.assign(parentMeta, tableDefaults?.parentMeta);
              }
            }
            break;
          }

          default: {
            obj[curr?.id] =
              curr.columnProperties[COLUMN_PROPERTY_KEYS.DEFAULT_VALUE];
          }
        }
      }
      return obj;
    }, {}),
    parentMeta,
  };
};

const handleLogoutWeb = async (
  {
    loaderShow,
    loaderHide,
    setLoginToken,
    setUserPref,
    deactivateLoginListener,
    logOut,
    removeCurrentDevice,
    deleteAccountDetails = {},
  }, //actions
  {uid, userPref, isForceLogout = false, isDeleteAccount = false}, //objects
  {navigation}, //handlers
) => {
  try {
    const lang = userPref?.lang || 'EN';
    await setUserPref(
      {lang, ...(isDeleteAccount ? {...deleteAccountDetails} : {})},
      true,
    );

    if (isFunction(deactivateLoginListener)) {
      await deactivateLoginListener();
    }

    if (!isDeleteAccount && isForceLogout && navigation.canGoBack()) {
      navigation.popToTop();
    }

    loaderShow({isLoggingOut: true});
    setLoginToken(uid, true);

    /**
     * if not force logout
     * then remove current device from active devices on logout
     */
    if (!isForceLogout) {
      await removeCurrentDevice(uid);
    }

    await Promise.all([logOut(lang), firebaseAuth().signOut()]);

    MixPanelInstance.reset();
    // branch.logout();
    //Singular.unsetCustomUserId();

    loaderHide();

    logAnalyticsEvent('LOGOUT');

    return Promise.resolve();
  } catch (error) {
    ShowToast('Something went wrong, please try again', userPref);
    loaderHide();
    captureError(error);
    return Promise.reject(error);
  }
};

function customHash(str) {
  // Basic custom hash function (you may want to replace this with a more robust one)
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return hash;
}

function getColorForId(id, colorsArray) {
  // Use the custom hash function to generate a consistent hash for the ID
  const hashedId = customHash(id);

  // Ensure the hash is positive and map it to the colors array length
  const index =
    ((hashedId % colorsArray.length) + colorsArray.length) % colorsArray.length;

  // Return the color for the given ID
  return colorsArray[index];
}
const isValidDate = (dateString) => {
  if (!dateString?.length || dateString.split('/').length !== 3) return false;
  const date = new Date(dateString.split('/')?.reverse()?.join('-'));
  return !isNaN(date.getTime()); // If it's a valid date, getTime() won't be NaN
};

const isStringNumeric = (string) =>
  !isNaN(string) && !isNaN(parseFloat(string));

const getSortedScreensForDocId = (
  activeAppMeta = {},
  userUID,
  activeCustomRoleInfo,
  docId,
  withoutHiddenScreensAndGroups = false,
) => {
  if (!docId) return null;

  return getSortedMiniAppsScreens(
    activeAppMeta,
    userUID,
    activeCustomRoleInfo,
    false,
    withoutHiddenScreensAndGroups,
  ).filter(
    (screenData) =>
      MINI_APPS.DOC_BASED_SCREEN_TYPES.includes(screenData.type) &&
      screenData.docs[0].docId === docId,
  );
};
const getDependencyMappingBasedOnheaderData = (headerData = []) => {
  const tableColIds = [];
  const headerIndexMapping = {};
  let primaryColumnMapping = {};
  const colData = headerData;

  for (let i = 0; i < colData.length; i++) {
    headerIndexMapping[colData[i].id] = i;
    const fieldType = colData[i].fieldType;
    if (fieldType === FIELD_TYPE_ID.TABLE) {
      primaryColumnMapping = checkAndUpdatePrimaryColumnMapping(
        colData[i],
        primaryColumnMapping,
      );
      tableColIds.push(colData[i].id);
    }
  }
  const tableIds =
    tableColIds && isArray(tableColIds) && tableColIds.length
      ? tableColIds.reverse().slice()
      : [];

  const dependencyObj = {};
  let dependencyArrObj = {};

  for (let i = 0; i < tableIds.length; i++) {
    const headerObjDependent = headerData[headerIndexMapping[tableIds[i]]];
    if (
      headerObjDependent?.columnProperties?.[
        COLUMN_PROPERTY_KEYS.IS_DEPENDENT_COLUMN
      ]
    ) {
      for (let j = i + 1; j < tableIds.length; j++) {
        const headerObj = headerData[headerIndexMapping[tableIds[j]]];
        const headerObjLinkedMetaKeys = Object.keys?.(
          headerObj?.linkedMeta ?? {},
        );
        if (
          (!headerObj?.columnProperties?.[
            COLUMN_PROPERTY_KEYS.IS_DEPENDENT_COLUMN
          ] &&
            primaryColumnMapping[headerObjLinkedMetaKeys[0]] === headerObj.id &&
            Object.keys(headerObjDependent?.linkedMeta).some((item) =>
              headerObjLinkedMetaKeys.includes(item),
            )) ||
          (headerObj?.columnProperties?.[
            COLUMN_PROPERTY_KEYS.IS_DEPENDENT_COLUMN
          ] &&
            Object.keys(headerObjDependent?.linkedMeta).some((item) =>
              headerObjLinkedMetaKeys.includes(item),
            ))
        ) {
          dependencyObj[headerObjDependent.id] = headerObj.id;

          if (
            !isEmpty(dependencyArrObj) &&
            headerObjLinkedMetaKeys[0] in dependencyArrObj
          ) {
            const dependentArrItems =
              dependencyArrObj[headerObjLinkedMetaKeys[0]];
            if (!dependentArrItems.includes(headerObjDependent.id)) {
              dependentArrItems.push(headerObjDependent.id);
            }
            dependentArrItems.push(headerObj.id);
            dependencyArrObj[headerObjLinkedMetaKeys[0]] = dependentArrItems;
          } else {
            dependencyArrObj = {
              ...dependencyArrObj,
              [headerObjLinkedMetaKeys[0]]: [
                headerObjDependent.id,
                headerObj.id,
              ],
            };
          }
          break;
        } else {
          continue;
        }
      }
    }
  }

  if (!isEmpty(dependencyArrObj)) {
    for (const parentDocId in dependencyArrObj) {
      dependencyArrObj[parentDocId] = dependencyArrObj[parentDocId]?.reverse();
    }
  }
  return dependencyArrObj;
};
const isSectionAndHeaderReorderValid = (
  sortedSectionDataAsArray,
  originalHeaderData,
  originalHeaderDataAsObj,
  updatedHeaderData,
) => {
  const updatedHeaderDataBasedOnSections = getOrderedHeaderDataBasedOnSections(
    sortedSectionDataAsArray,
    originalHeaderDataAsObj,
    originalHeaderData,
    updatedHeaderData,
  );
  if (sortedSectionDataAsArray?.length > 0) {
    // these check are only performed if there are any sections
    const lastSectionItem =
      sortedSectionDataAsArray[sortedSectionDataAsArray.length - 1];
    const isOthersSectionPresent = sortedSectionDataAsArray.find(
      (item) => item?.sectionId === OTHERS_SECTION_ID,
    )
      ? true
      : false;
    if (isOthersSectionPresent) {
      // this check is only performed if there is any other section present
      if (lastSectionItem.sectionId !== OTHERS_SECTION_ID) {
        // checks if last section is not others
        return {
          isValid: false,
          type: INVALID_REORDER_TYPES.OTHER_SECTION_NOT_IN_LAST,
        };
      }
    }
    const sectionItemsWithNoColIds = sortedSectionDataAsArray.filter(
      (sectionItem) =>
        sectionItem?.colIds?.length === 0 &&
        sectionItem?.sectionId !== OTHERS_SECTION_ID,
    );
    if (sectionItemsWithNoColIds.length > 0) {
      // checks if any section is empty
      // that is colIds is empty
      return {isValid: false, type: INVALID_REORDER_TYPES.EMPTY_SECTION};
    }
  }
  if (
    // checks for table link dependent columns
    // A dependent column should not be placed
    // above its primary column
    !isEqual(
      getDependencyMappingBasedOnheaderData(originalHeaderData),
      getDependencyMappingBasedOnheaderData(updatedHeaderDataBasedOnSections),
    )
  ) {
    return {
      isValid: false,
      type: INVALID_REORDER_TYPES.WRONG_DEPENDENT_COLUMN_ORDER,
    };
  }
  if (
    !checkIfFieldReorderIsValidForVisibilityConditions(
      updatedHeaderDataBasedOnSections,
    )
  ) {
    // checks for field visibility columns
    // a column should not be dependent on a column
    // below in order for visibility conditions
    return {
      isValid: false,
      type: INVALID_REORDER_TYPES.INVALID_VISIBILITY_CONDITIONS_ORDER,
    };
  }
  return {isValid: true};
};

const isDisplayInPercentageValid = (colObj = {}, operation = '') => {
  return (
    [FIELD_TYPE_ID.NUMBER, FIELD_TYPE_ID.FORMULA].includes(
      getColumnFieldType(colObj),
    ) &&
    colObj?.columnProperties?.[COLUMN_PROPERTY_KEYS.DISPLAY_IN_PERCENTAGE] &&
    ['TOTAL', 'AVERAGE'].includes(operation)
  );
};

const getTableLinkMetaDetails = (headerData, columnsToOmit = []) => {
  const tableColIds = [];
  const headerIndexMap = {};
  let primaryColumnMap = {};

  const headerDataToUse = (headerData ?? []).filter(
    (colObj) => !columnsToOmit.includes(colObj.id),
  );
  const colData = headerDataToUse;

  for (let i = 0; i < colData.length; i++) {
    headerIndexMap[colData[i].id] = i;
    const fieldType = colData[i].fieldType;
    if (fieldType === FIELD_TYPE_ID.TABLE) {
      primaryColumnMap = checkAndUpdatePrimaryColumnMapping(
        colData[i],
        primaryColumnMap,
      );
      tableColIds.push(colData[i].id);
    }
  }

  return {primaryColumnMap, tableColIds, headerIndexMap, headerDataToUse};
};
const getAssignTaskFilterFormat = (filterValue = '') => {
  return [null, [filterValue], null, null];
};

const isImportTemplateValid = ({headers = [], templateMapping = {}}) => {
  if (isEmpty(templateMapping)) {
    return null;
  }

  const {
    documentData: {headerData},
  } = mapMiniAppStates(getReduxState(), false, true);

  let templateMatch = true;

  const templatePreMapping = {};
  const templateCustomValueMapping = {};
  const customHeaderArr = [];

  // const headersIdObj = headers.reduce((obj, headerObj) => {
  //   obj[headerObj?.id] = headerObj;
  //   return obj;
  // }, {});

  const importNameHeadersMap = headers.reduce((obj, headerObj) => {
    obj[headerObj?.val?.trim?.()?.toLowerCase?.()] = headerObj;
    return obj;
  }, {});

  const headerDataAsObj = headerData.reduce((obj, headerObj) => {
    obj[headerObj?.id] = headerObj;
    return obj;
  }, {});

  forOwn(templateMapping, (mappingValue, key) => {
    if (!templateMatch) {
      return;
    }
    const isCustomValue =
      mappingValue?.valueType === SETVALUE_COLUMN_VALUE_TYPES.CUSTOM_VALUE;
    const ifDefaultConstantVal =
      mappingValue?.valueType ===
      SETVALUE_COLUMN_VALUE_TYPES.DEFAULT_CONST_VALUE;
    const isColumn =
      mappingValue?.valueType === SETVALUE_COLUMN_VALUE_TYPES.COLUMN;

    const mappingId = mappingValue.val?.trim?.()?.toLowerCase?.();

    if (
      isColumn &&
      has(headerDataAsObj, key) &&
      has(importNameHeadersMap, mappingId) // Checks if mapping value included in uploaded file or not.
      // &&
      // isEqual(headersIdObj[mappingValue.id]?.val, mappingValue.val)
    ) {
      Object.assign(templatePreMapping, {
        [key]: importNameHeadersMap[mappingId],
      });
    } else if (
      (isCustomValue || ifDefaultConstantVal) &&
      has(headerDataAsObj, key)
    ) {
      if (
        isCustomValue &&
        checkIfValueExistOnCell(mappingValue?.value, headerDataAsObj?.[key])
      ) {
        Object.assign(templatePreMapping, {
          [key]: {
            id: SETVALUE_COLUMN_VALUE_TYPES.CUSTOM_VALUE,
            val: 'Custom Value',
            type: mappingValue.valueType,
          },
        });
        Object.assign(
          templateCustomValueMapping,
          Object.assign({[key]: mappingValue}),
        );
      }
      if (ifDefaultConstantVal) {
        Object.assign(templatePreMapping, {
          [key]: {
            id: SETVALUE_COLUMN_VALUE_TYPES.CUSTOM_VALUE,
            val: MAPPING_DEFAULT_VALUES_DETAILS?.[mappingValue?.value]?.val,
            type: mappingValue.valueType,
          },
        });
        Object.assign(
          templateCustomValueMapping,
          Object.assign({[key]: mappingValue}),
        );
      }
      customHeaderArr.push(headerDataAsObj?.[key]);
    } else {
      templateMatch = false;
    }
  });

  if (!templateMatch) {
    return {templateMatch};
  }
  return {
    templatePreMapping,
    templateCustomValueMapping,
    templateMatch,
    customHeaderArr,
  };
};

const isYouTubeLink = (url) => {
  try {
    const youtubePattern =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^/]+\?v=|embed\/|v\/|watch\?.+&v=)|youtu\.be\/)[\w-]{11}/;
    return youtubePattern.test(url);
  } catch (error) {
    captureError(error);
  }
};

const getParentSubTypeOrSubType = (colObj) =>
  colObj?.fieldType === FIELD_TYPE_ID.TABLE
    ? colObj?.parentSubType ?? colObj?.subType
    : colObj?.subType;

const autoFillMappedCalendarDateBasedOnSelectedEvent = (
  calendarViewScreenConfig,
  selectedDate,
  headerDataAsObj,
) => {
  const autofilledRowData = {};
  const {START_DATE: startDateColId} = calendarViewScreenConfig?.config ?? {};

  const hasAutoFillProperty = (colId, property) =>
    headerDataAsObj?.[colId]?.columnProperties?.[property];

  const getAutoFillValueBasedOnFieldType = (
    value,
    fieldType,
    dateFormat = undefined,
  ) => {
    if (fieldType === FIELD_TYPE_ID.DATE) {
      return getDateTimeCellObj(
        {val: moment(value).unix()},
        false,
        false,
        dateFormat,
      );
    } else if (fieldType === FIELD_TYPE_ID.DATE_TIME) {
      const currentDate = moment();
      const selectedDate = moment(value);
      selectedDate.set({
        hour: currentDate.hour(),
        minute: currentDate.minute(),
        second: currentDate.second(),
      });
      const dateUnix = moment(selectedDate).unix();
      const timeUnix = moment(selectedDate).unix();
      const mergedDateTime = mergeDateTimeAndGetUTCTimeStamp(
        dateUnix,
        timeUnix,
      );
      return {
        val: mergedDateTime,
        timezone: getTimezone(),
        recurringType: MINI_APPS_DATE_RECURRING_TYPES.DOES_NOT_REPEAT,
      };
    }
    return {};
  };

  if (
    startDateColId &&
    (hasAutoFillProperty(
      startDateColId,
      COLUMN_PROPERTY_KEYS.AUTO_FILL_ON_CLICK,
    ) ||
      hasAutoFillProperty(
        startDateColId,
        COLUMN_PROPERTY_KEYS.AUTO_FILL_ON_ENTRY,
      ))
  ) {
    return {};
  }

  if (startDateColId) {
    const {dateFormat} = headerDataAsObj?.[startDateColId] ?? {};
    const {fieldType} =
      calendarViewScreenConfig?.colInfo?.[startDateColId] ?? {};
    Object.assign(autofilledRowData, {
      [startDateColId]: getAutoFillValueBasedOnFieldType(
        selectedDate,
        fieldType,
        dateFormat,
      ),
    });
  }
  return autofilledRowData;
};
const isColumnValidationConfigurationAllowed = (
  colObj,
  isDateMathConfigPresent,
) => {
  const fieldType = getColumnFieldType(colObj);
  return !(
    !isEmpty(colObj?.summaryInfo) ||
    COLUMN_VALIDATION_NON_SUPPORTED_COLUMNS.includes(fieldType) ||
    (isDateMathConfigPresent &&
      [FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.DATE_TIME].includes(fieldType)) ||
    !isNil(colObj?.summaryInfo)
  );
};
const convertCellValueToTextWithImages = (
  cellObj = {},
  colObj = {},
  isRawImageArray = false,
  rowObj = {},
  userInfoForDataAddedBy = {},
  userCountry = 'IN',
  userPref = {},
  fileObj = {},
) => {
  if (getColumnFieldType(colObj) === FIELD_TYPE_ID.IMAGE && isRawImageArray) {
    return cellObj.val;
  }
  return convertCellDataToText(
    cellObj,
    colObj,
    userCountry,
    userPref,
    fileObj,
    rowObj,
    true,
    userInfoForDataAddedBy,
  );
};
const getCurrFileAndSameLinkCol = (colObj, headerData = []) => {
  let currentFileId, sameLinkedFileColumn;
  const sameLinkedFileColumnIdMap = [];
  if (colObj.fieldType === FIELD_TYPE_ID.TABLE) {
    currentFileId = Object.keys(colObj?.linkedMeta)?.[0] || '';

    sameLinkedFileColumn = headerData.filter(
      (col) =>
        col &&
        !isEmpty(col?.linkedMeta) &&
        Object.keys(col?.linkedMeta)?.[0] === currentFileId,
    );
    sameLinkedFileColumn.forEach((col) => {
      sameLinkedFileColumnIdMap.push(col?.id);
    });
    // setDiffTableFileMapping((prev) => ({
    //   ...prev,
    //   [currentFileId]: {
    //     arrMap: sameLinkedFileColumnIdMap,
    //     selectedCol: colObj.id,
    //   },
    // }));
  }
  return {sameLinkedFileColumn, sameLinkedFileColumnIdMap, currentFileId};
};

const getTableDataFromRowIdMap = (rowIdDataMap = {}, rowIdArr = []) => {
  const tableData = [];
  for (let i = 0; i < rowIdArr.length; i++) {
    const rowObj = getRowObjAtIndexForMiniApps(rowIdArr, rowIdDataMap, i);
    tableData.push(rowObj);
  }

  return tableData;
};

const getInitAutomationToastId = (toastId) => `INIT_AUTO_${toastId}`;

const getColumnValidatorDependencyMapping = (headerData = []) => {
  const validatorDependencyMapping = {};
  headerData.forEach((colObj) => {
    const conditionsArr = colObj?.validationCondition?.conditions;
    const colId = colObj?.id;

    if (Array.isArray(conditionsArr) && conditionsArr.length > 0) {
      if (has(validatorDependencyMapping, colId)) {
        validatorDependencyMapping[colId] = Object.assign(
          {},
          validatorDependencyMapping[colId],
          {[colId]: true},
        );
      } else {
        validatorDependencyMapping[colId] = {[colId]: true};
      }

      conditionsArr.forEach((conditionObj) => {
        if (
          conditionObj?.['VALUE']?.type === SETVALUE_COLUMN_VALUE_TYPES.COLUMN
        ) {
          const conditionalColId = conditionObj?.['VALUE']?.value?.id;
          if (has(validatorDependencyMapping, conditionalColId)) {
            validatorDependencyMapping[conditionalColId] = Object.assign(
              {},
              validatorDependencyMapping[conditionalColId],
              {[colId]: true},
            );
          } else {
            validatorDependencyMapping[conditionalColId] = {[colId]: true};
          }
        }
      });
    }
  });
  return validatorDependencyMapping;
};

const columnValidator = (
  headerData = [],
  headerDataAsObj = {},
  rowData = {},
  fileObj,
  userMetaObj,
  uid,
  organisationData,
  userPref,
) => {
  const getCellValueForValidation = (
    cellObj = {},
    headerObj = {},
    rawFormat = true,
  ) =>
    convertCellDataToText(
      cellObj,
      headerObj,
      userMetaObj?.userCountry,
      {lang: 'EN'},
      fileObj,
      rowData,
      false,
      {},
      rawFormat,
      false,
      {
        getDisplayDate: false,
        getOTPCellAsText: true,
      },
    );
  const conditionCheck = (condition, colId, filterValColId) => {
    const isCustomValue =
      (condition?.['VALUE']?.type !== SETVALUE_COLUMN_VALUE_TYPES.COLUMN &&
        condition?.['VALUE']?.type !==
          SETVALUE_COLUMN_VALUE_TYPES.DEFAULT_CONST_VALUE) ||
      condition?.['VALUE']?.type === SETVALUE_COLUMN_VALUE_TYPES.CUSTOM_VALUE;
    const isDefaultConstValue =
      condition?.['VALUE']?.type ===
      SETVALUE_COLUMN_VALUE_TYPES.DEFAULT_CONST_VALUE;
    const isMapFieldType =
      getColumnFieldType(headerDataAsObj?.[colId]) === FIELD_TYPE_ID.MAPS;

    //ByPass - Background Field Checks => return true
    if (isBackgroundField(headerDataAsObj?.[colId])) {
      return true;
    }

    const filterVal = isDefaultConstValue
      ? condition?.['VALUE']?.value
      : isCustomValue
      ? isMapFieldType
        ? condition?.['VALUE']?.value
        : getCellValueForValidation(
            condition?.['VALUE']?.value ?? {},
            headerDataAsObj?.[colId] ?? {},
          )
      : isMapFieldType
      ? rowData?.[filterValColId] || {}
      : filterValColId;

    const filterType = condition?.['CONDITION']?.value;
    const columnId = colId;

    return conditionCheckOnRecord(
      columnId,
      filterVal,
      filterType,
      condition?.['VALUE']?.type,
      rowData,
      headerDataAsObj,
      fileObj,
      userMetaObj,
      uid,
      {defaultReturn: true},
    );
  };

  const conditionText = (
    conditionsArr = [],
    colName = '',
    OR = false,
    colId,
  ) => {
    let text = '';
    conditionsArr.forEach((conditions, index) => {
      /**
       * Current DATE / DATE-TIME / TIME are allowed for custom values in `column validation`
       * Tags - `ColumnValidation`
       * @returns {any}
       */
      const getDefaultCustomVal = () => {
        return ColumnUtility.getTypeBasedDefaultValueForConditions({
          type: conditions?.['VALUE']?.value,
          colObj: headerDataAsObj?.[colId],
          fileObj,
          uid,
          newUserUID: null,
          organisationData,
          rowProperties: {},
          timezone: userPref?.timezone,
          userCountry: userMetaObj.userCountry,
          formattedWithDateFormat: true,
        });
      };

      const shouldRenderToText =
        conditions?.['CONDITION']?.text !== 'Greater Than' &&
        conditions?.['CONDITION']?.text !== 'Less Than';
      text = text?.concat(
        `${colName} Should Be ${conditions?.['CONDITION']?.text}${
          shouldRenderToText ? ' To' : ''
        } ${
          conditions?.['VALUE']?.type ===
          SETVALUE_COLUMN_VALUE_TYPES.DEFAULT_CONST_VALUE
            ? `"${getDefaultCustomVal()}"`
            : conditions?.['VALUE']?.type === SETVALUE_COLUMN_VALUE_TYPES.COLUMN
            ? `Column ${
                headerDataAsObj?.[conditions?.['VALUE']?.value?.id]?.val
              }`
            : `"${getCellValueForValidation(
                conditions?.['VALUE']?.value,
                headerDataAsObj?.[colId],
                false,
              )}"`
        }`,
      );
      if (index !== conditionsArr.length - 1) {
        text = text.concat(OR ? ' (or) ' : ' (and) ');
      }
    });
    return text;
  };

  return headerData?.reduce((validityObj, colObj) => {
    const {conditions, conditionConfig} = colObj?.validationCondition || {};
    if (!isEmpty(conditions) && !isEmpty(rowData?.[colObj.id])) {
      const columnValidStatus =
        conditionConfig === 'OR'
          ? conditions?.some((condition) =>
              conditionCheck(
                condition,
                colObj.id,
                condition?.['VALUE']?.value?.id,
              ),
            )
          : conditions?.every((condition) =>
              conditionCheck(
                condition,
                colObj.id,
                condition?.['VALUE']?.value?.id,
              ),
            );

      if (!columnValidStatus) {
        validityObj[colObj?.id] = conditionText(
          conditions,
          colObj?.val,
          conditionConfig === 'OR',
          colObj?.id,
        );
      } else {
        validityObj[colObj?.id] = false;
      }
      if (
        isEmpty(rowData?.[colObj.id]?.val) &&
        typeof rowData?.[colObj.id]?.val !== 'number' &&
        typeof rowData?.[colObj.id]?.val !== 'boolean'
      ) {
        validityObj[colObj?.id] = false;
      }
    }
    return validityObj;
  }, {});
};

export const getLabelForDropdownValue = (label, configId, colObj) => {
  if (configId !== AUTOMATION_COLUMN_VALUE_TYPES.NOW) return label;

  const fieldType = getColumnFieldType(colObj);

  if (fieldType === FIELD_TYPE_ID.DATE) {
    return `Current ${capitalize(colObj?.dateFormat) ?? 'Date'}`;
  } else if (fieldType === FIELD_TYPE_ID.TIME) {
    return 'Current Time';
  } else if (fieldType === FIELD_TYPE_ID.DATE_TIME) {
    return 'Current Date Time';
  }
};

const getValidationFailedErrorText = ({
  conditionsArr,
  colId,
  headerDataAsObj,
  conditionConfig = 'AND',
  userCountry,
  userPref,
  fileObj,
  rowObj,
  uid,
  organisationData,
}) => {
  let text = '';
  const colName = headerDataAsObj?.[colId]?.val;
  const getCustomVal = (valObj) => {
    if (headerDataAsObj?.[colId]?.fieldType === FIELD_TYPE_ID.MAPS) {
      return valObj?.val?.val ?? '';
    }

    if (getColumnFieldType(headerDataAsObj?.[colId]) === FIELD_TYPE_ID.OTP) {
      if (valObj?.val === true) {
        return 'Verified';
      } else if (valObj?.val === false) {
        return 'Not Verified';
      }
    }

    // for current date / date time / time validation condtions
    if (
      typeof valObj === 'string' &&
      valObj === AUTOMATION_COLUMN_VALUE_TYPES.NOW
    ) {
      return ColumnUtility.getTypeBasedDefaultValueForConditions({
        type: valObj,
        colObj: headerDataAsObj?.[colId],
        fileObj,
        uid,
        newUserUID: null,
        organisationData,
        rowProperties: {},
        timezone: userPref?.timezone,
        userCountry: userCountry,
        formattedWithDateFormat: true,
      });
    }
    return convertCellDataToText(
      valObj,
      headerDataAsObj?.[colId] ?? {},
      userCountry,
      userPref,
      fileObj,
      rowObj,
      true,
      {},
      false,
    );
  };
  const getTriggerConfigString = (triggerConditionObj = {}) => {
    const {value: triggerId, text: renderStr = ''} = triggerConditionObj;
    const {TRIGGER_CONDITIONS} = MINI_APPS.AUTOMATION;
    let triggerStr = renderStr;
    switch (triggerId) {
      case TRIGGER_CONDITIONS.EQUAL:
      case TRIGGER_CONDITIONS.NOT_EQUAL:
      case TRIGGER_CONDITIONS.GREATER_THAN_OR_EQUAL:
      case TRIGGER_CONDITIONS.LESS_THAN_OR_EQUAL:
        triggerStr += ` To`;
        break;
      case TRIGGER_CONDITIONS.UPTO_FIFTY_METERS:
      case TRIGGER_CONDITIONS.UPTO_HUNDERED_METERS:
      case TRIGGER_CONDITIONS.UPTO_TO_THREEHUNDERED_METERS:
        triggerStr += ` From`;
        break;

      default:
    }

    return triggerStr;
  };
  conditionsArr.forEach((conditions, index) => {
    text = text?.concat(
      `${colName} Should Be ${getTriggerConfigString(
        conditions?.['CONDITION'],
      )} ${
        conditions?.['VALUE']?.type === SETVALUE_COLUMN_VALUE_TYPES.COLUMN
          ? `${headerDataAsObj?.[conditions?.['VALUE']?.value?.id]?.val}`
          : `"${getCustomVal(conditions?.['VALUE']?.value)}"`
      }`,
    );
    if (index !== conditionsArr.length - 1) {
      text = text.concat(conditionConfig === 'OR' ? ' (or) ' : ' (and) ');
    }
  });

  return text;
};

function areLocationsWithinRadius(
  primaryLocation = {}, // Object containing latitude and longitude of the primary location
  secondaryLocation = {}, // Object containing latitude and longitude of the secondary location
  radius, // Radius in kilometers to define the circular area around the primary location
) {
  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  const {latitude: lat1 = 0, longitude: lon1 = 0} = primaryLocation;
  const {latitude: lat2 = 0, longitude: lon2 = 0} = secondaryLocation;

  if (
    !isFinite(lat1) ||
    !isFinite(lat2) ||
    !isFinite(lon1) ||
    !isFinite(lon2)
  ) {
    return false;
  }

  const R = 6371; // Radius of the earth in kilometers (assumed constant)

  // Calculate differences in radians
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);

  // Intermediate calculations for Haversine formula
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Calculate final distance using Earth radius and intermediate value
  const distance = R * c; // Distance in kilometers

  return distance <= radius; // Check if distance is within the specified radius
}

const getRowStatusObj = ({mapping, rowObj, cellObj}) => {
  const colId = () => mapping?.[MINI_APPS_FIELDS.rowStatus];
  const cellValObj = cellObj ?? rowObj?.[colId()] ?? {};
  return {
    val: cellValObj?.val,
    color: cellValObj?.valStyleObj?.backgroundColor ?? '#0A0A0A',
    backgroundColor: cellValObj?.valStyleObj?.color ?? '#FFFFFF',
  };
};
function matchText(str, searchText) {
  if (
    !isString(searchText) ||
    !isString(str) ||
    !searchText.trim() ||
    !str.trim()
  ) {
    return {renderStr: [], isSearchTextPresent: false};
  }

  const lowerStr = str.toLowerCase();
  const lowerSearch = searchText.toLowerCase();
  const index = lowerStr.indexOf(lowerSearch);

  if (index !== -1) {
    const unmatchedPrefix = index > 0 ? str.substring(0, index) : '';
    const matchedSubstring = str.substring(index, index + lowerSearch.length);
    const unmatchedSuffix = str.substring(index + lowerSearch.length);

    return {
      renderStr: [unmatchedPrefix, matchedSubstring, unmatchedSuffix],
      isSearchTextPresent: true,
    };
  }

  return {renderStr: [], isSearchTextPresent: false};
}

const getRowData = ({
  tableData,
  rowIdDataMap,
  index,
  mapping,
  mappedValuesConfig,
  docData,
  userCountry = 'IN',
  userPref = {},
  isLoading = false,
  organisationUserInfo = {},
}) => {
  const rowObj = getRowObjAtIndexForMiniApps(tableData, rowIdDataMap, index);

  const [mappedValues, mappingConfig] = getLayoutMappedValues(
    mapping,
    mappedValuesConfig,
    rowObj,
    docData,
    userCountry,
    userPref,
    isLoading,
    true,
    organisationUserInfo,
  );

  const renderRowStatus = !isEmpty(mapping?.[MINI_APPS_FIELDS.rowStatus]);
  let rowStatusObj = {};
  if (renderRowStatus) {
    rowStatusObj = getRowStatusObj({mapping, rowObj});
  }

  let rowDataToRender = MINI_APPS_FIELDS_ORDER.reduce((prevColObj, type) => {
    if (!has(mappedValues, type) || !has(mappingConfig, type)) {
      return prevColObj;
    }
    const colObj =
      docData?.headerDataAsObj?.[mapping?.[MINI_APPS_FIELDS?.[type]]];
    return [
      ...prevColObj,
      {
        colName: mappingConfig?.[type]?.label,
        colVal: mappedValues?.[type],
        colValColor: mappingConfig?.[type]?.textColor,
        isColValImage: type === 'imageA',
        colObj,
      },
    ];
  }, []);

  if (
    has(mappedValues, 'catalog') &&
    has(mappingConfig, 'catalog') &&
    isArray(mappedValues.catalog)
  ) {
    rowDataToRender = mappedValues?.catalog?.reduce((prev, mappedVal, ind) => {
      const colName = mappingConfig?.catalog?.[ind]?.label;
      const colVal = mappedVal;
      const colObj =
        docData?.headerDataAsObj?.[mapping?.[MINI_APPS_FIELDS.catalog]?.[ind]];
      return [
        ...prev,
        {
          colName,
          colVal,
          colObj,
        },
      ];
    }, rowDataToRender);
  }

  if (!isEmpty(rowStatusObj)) {
    rowDataToRender.push({isRowStatusObj: true, rowStatusObj});
  }

  return rowDataToRender;
};

const getRowBoundIndex = ({
  rowsUnderProcess = {},
  tableData = [],
  index,
  rowIdDataMap,
}) => {
  const rowId = tableData[index] ?? {};
  if (has(rowsUnderProcess, rowId)) {
    return rowsUnderProcess?.[rowId]?.index;
  }
  return getRowObjAtIndexForMiniApps(tableData, rowIdDataMap, index)?.index;
};
const isBackgroundField = (colObj = {}) =>
  colObj?.columnProperties?.[COLUMN_PROPERTY_KEYS?.BACKGROUND_FIELD] === true;

const getHeaderDataWithoutBackgroundFields = (headerData = []) =>
  headerData.filter((colObj) => !isBackgroundField(colObj));

const getHeaderDataAsObjWithoutBackgroundFields = (headerData = []) => {
  return headerData.reduce((headerObj, curr) => {
    if (!isBackgroundField(curr)) {
      headerObj[curr.id] = curr;
    }
    return headerObj;
  }, {});
};

const rgbToHex = (rgb) => {
  // Extract the numbers from the input string
  const result = rgb.match(/\d+/g);

  // If there are no numbers found, return an empty string or handle the error
  if (!result) {
    return '';
  }

  // Extract the RGB components
  const r = parseInt(result[0]).toString(16).padStart(2, '0');
  const g = parseInt(result[1]).toString(16).padStart(2, '0');
  const b = parseInt(result[2]).toString(16).padStart(2, '0');

  // If the input includes an alpha value, handle the conversion
  let hex;
  if (result.length === 4) {
    const a = Math.round(parseFloat(result[3]) * 255)
      .toString(16)
      .padStart(2, '0');
    hex = `#${r}${g}${b}${a}`;
  } else {
    hex = `#${r}${g}${b}`;
  }

  return hex;
};

const getSelectBoxBgColor = (colorHexValue) => {
  const lightColor = new ColorValues(colorHexValue);
  return lightColor.tint(lightColor.getBrightness() > 50 ? 90 : 85).rgbString();
};

const getLightenColor = (color, percent = 90) => {
  if (!color) return 'transparent';
  // Parse the hex color value
  let hex = color;
  const hexPattern = new RegExp('^#(?:[0-9a-fA-F]{3}){1,2}$');
  if (!hexPattern.test(color)) {
    hex = rgbToHex(color);
  }
  hex = hex.replace(/^#/, '');

  // Convert to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the new RGB values
  const newR = Math.min(255, r + (255 - r) * (percent / 100));
  const newG = Math.min(255, g + (255 - g) * (percent / 100));
  const newB = Math.min(255, b + (255 - b) * (percent / 100));

  // Convert the new RGB values back to hex
  return `#${Math.round(newR).toString(16).padStart(2, '0')}${Math.round(newG)
    .toString(16)
    .padStart(2, '0')}${Math.round(newB).toString(16).padStart(2, '0')}`;
};

const getSelectBoxStyleObj = (cellObj = {}, extra = {}) => {
  const {shouldShowDefaultBg} = extra;

  const {color, backgroundColor} = cellObj?.valStyleObj ?? {};

  return {
    color: backgroundColor ?? '#262626',
    backgroundColor:
      ['#ffffff', '#FFFFFF'].includes(color) && !shouldShowDefaultBg
        ? 'transparent'
        : getLightenColor(backgroundColor),
  };
};

const getPdfConfig = async (configId, collectionName = 'pdfTemplates') => {
  try {
    const db = firestore();

    const pdfConfigSnapShot = await db
      .collection(collectionName)
      .doc(configId)
      .get();
    if (pdfConfigSnapShot.exists) {
      const data = await pdfConfigSnapShot.data();
      if (collectionName === 'miniAppScreenEntryConfig') {
        return formatFireStorePdfFormatToLocalPdfFormat(
          data?.sections ?? {},
          data?.showViewAccesColumn,
        );
      }
      return formatFireStorePdfFormatToLocalPdfFormat(data);
    }
  } catch (err) {
    console.error(err);
    return {};
  }
};

const fetchChildLinks = async (data) => {
  try {
    const returnObj = {
      success: false,
    };

    if (data && data.isParent && data.childLink && !isEmpty(data?.parentData)) {
      if (!isEmpty(data.childLink)) {
        const {childDocId, childColId} = data.childLink;
        const parentDocId = data.parentData?.parentDocId;
        const parentRowId = data.parentData?.parentRowId;

        const limit = 1000;

        const docRef = firestore()
          .collection('userDocuments')
          .doc(childDocId)
          .collection('rows')
          .where(`rowObj.${childColId}.parentMeta.docId`, '==', parentDocId)
          .where(`rowObj.${childColId}.parentMeta.rowId`, '==', parentRowId)
          .limit(limit);

        const responseArr = [];

        const getChildRows = () => {
          const getBatchData = (startAfter) => {
            return (
              startAfter ? docRef.startAfter(startAfter).get() : docRef.get()
            )
              .then((result) => {
                result?.docs?.forEach((doc) => responseArr.push(doc));
                if (result?.docs?.length === limit) {
                  return getBatchData(result?.docs[result?.docs?.length - 1]);
                }
              })
              .catch((error) => console.log(error));
          };
          return getBatchData();
        };
        await getChildRows();

        const childDocData = processRowsDataForMappedRowId({
          docs: responseArr,
        });
        returnObj.data = {
          data: childDocData,
          success: true,
          message: 'Child Documents rows fetched successfully!',
        };
        return returnObj;
      }
      returnObj.data = {success: false, message: 'Child Link data not found'};
      return returnObj;
    } else {
      if (!isEmpty(data?.parentMeta)) {
        const {docId, rowId} = data.parentMeta ?? {};
        const parentDocData = {};
        const docDataSnap = await firestore()
          .collection('userDocuments')
          .doc(docId)
          .get();
        const docData = docDataSnap.exists ? docDataSnap.data() : {};

        const parentRowDataSnap = await firestore()
          .collection('userDocuments')
          .doc(docId)
          .collection('rows')
          .doc(rowId)
          .get();
        const tableDataRowId = parentRowDataSnap?.id;
        const rowData = parentRowDataSnap.exists
          ? parentRowDataSnap.data()
          : {};
        if (isEmpty(rowData) || rowData.isDeleted === true) {
          returnObj.success = false;
          returnObj.message =
            'Parent Row does not exist. It might have been deleted.';
          return returnObj;
        }
        const tableData = [];
        tableData.push(
          getRowObjStructured(
            rowData?.rowObj,
            tableDataRowId,
            rowData?.index,
            rowData?.rowProperties,
            rowData?.sortKey,
          ),
        );
        Object.assign(parentDocData, {
          headerData: docData?.headerData,
          docId: docDataSnap?.id,
          tableData,
        });
        returnObj.data = {
          success: true,
          message: 'Parent Row Data fetched Successfully!',
          data: Object.assign({}, parentDocData),
        };
        return returnObj;
      }
      returnObj.data = {
        success: false,
        message: 'Parent Meta not available',
      };
      return returnObj;
    }
  } catch (error) {
    console.error(error);
    return {
      data: {
        success: false,
        message: 'Child Link Data cannot be fetched due to some error',
        error: error.message,
      },
    };
  }
};

// checks if a field is Editable based on 2 conditions
// 1) child column is present in sublist file
// 2) Any ADD/EDIT operation has been made in list column

const isChildPresentInListCol = (colObj, rowId, docId, listColumnState) => {
  const {childInList} = colObj;
  if (isNil(childInList) || isEmpty(childInList)) {
    return {isFieldEditable: true};
  }

  const instanceId = getKeyForParentDocRowMap(docId, rowId);

  for (const listColId of Object.keys(childInList)) {
    const {dataMap} = listColumnState ?? {};
    const listColumnMapping =
      dataMap?.[instanceId]?.[`listColumnMapping`] ?? {};
    const rowIdDataMap = listColumnMapping?.[listColId]?.rowIdDataMap ?? {};
    if (!isEmpty(rowIdDataMap)) {
      return {isFieldEditable: false, listColIdWithChildRows: listColId};
    }
  }
  return {isFieldEditable: true};
};

const getCommaSepratedValueForCurrency = (val = '', currency = '') => {
  try {
    const parts = val.toString().split('.') || [];
    let integerPart = parts[0];
    let leadingZeros = '';
    const leadingZeroMatch = integerPart.match(/^0+/);
    if (leadingZeroMatch) {
      leadingZeros = leadingZeroMatch[0];
      integerPart = integerPart.slice(leadingZeros.length);
    }
    integerPart =
      currency !== 'INR'
        ? integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : integerPart.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
    parts[0] = leadingZeros + integerPart;
    return parts?.join('.') || '';
  } catch (error) {
    captureError(error);
    return val;
  }
};

function validateNumber(inputString) {
  const pattern = /^(?:\d+(\.\d*)?|\.\d+|\.)?$/;
  return pattern.test(inputString);
}

const getSelectBoxFilterVal = (screenObj = {}, colId) => {
  if (isEmpty(screenObj) || isNil(colId)) {
    return '';
  }
  const {docs} = screenObj;
  const {filterOptions} = docs?.[0] ?? {};
  if (isEmpty(filterOptions)) {
    return '';
  }
  const selectBoxColIdFilter =
    filterOptions?.filter((filterObj) => filterObj?.colId === colId) ?? {};
  if (isEmpty(selectBoxColIdFilter)) {
    return '';
  }
  return selectBoxColIdFilter?.[0]?.selectedOptions?.[0] ?? '';
};

const isKanbanConfigValid = (
  activeScreenId,
  screenData,
  docsData,
  screensObj,
) => {
  const {headerDataAsObj} = docsData;
  const kanbanConfig =
    screenData?.view?.[MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN];
  const {kanbanSelectBoxColId, kanbanScreenIds} = kanbanConfig ?? {};

  if (isNil(headerDataAsObj?.[kanbanSelectBoxColId])) {
    // handles when selectbox column is deleted
    return false;
  }
  if (
    headerDataAsObj?.[kanbanSelectBoxColId]?.columnProperties?.[
      COLUMN_PROPERTY_KEYS.UNIQUE_VALUES
    ]
  ) {
    return false;
  }
  if (
    headerDataAsObj?.[kanbanSelectBoxColId]?.columnProperties?.[
      COLUMN_PROPERTY_KEYS.BACKGROUND_FIELD
    ]
  ) {
    return false;
  }
  const {selectElements = []} = headerDataAsObj?.[kanbanSelectBoxColId] ?? {};

  const allScreensHaveValidKanbanMeta = kanbanScreenIds.every((screenId) => {
    const screenObj = screensObj?.[screenId];
    if (isNil(screenObj) || isEmpty(screenObj)) {
      return false;
    }
    const {kanbanMeta} = screenObj;
    if (isNil(kanbanMeta) || isEmpty(kanbanMeta)) {
      return false;
    }
    return true;
  });

  if (!allScreensHaveValidKanbanMeta) {
    return false;
  }

  const selectBoxOptions = kanbanScreenIds
    .map((screenId) => {
      const kanbanScreenObj = screensObj?.[screenId];
      return getSelectBoxFilterVal(kanbanScreenObj, kanbanSelectBoxColId);
    })
    .filter((val) => val !== DATA_FILTERS.EMPTY_CELLS_ROWS);
  return selectBoxOptions.every((option) =>
    selectElements?.some((element) => element.val === option),
  );
};
const canUpdateOrganisationProfile = (organisation, uid) => {
  return (
    organisation?.isUserOrganisationOwner ||
    organisation?.membersList?.[uid]?.access_level?.includes(
      ORG_ROLE_CONSTANT.ADMIN,
    )
  );
};

const canUpdateTeam = (organisation, uid) => {
  return (
    organisation?.isUserOrganisationOwner ||
    organisation?.membersList?.[uid]?.access_level?.includes(
      ORG_ROLE_CONSTANT.ADMIN,
    ) ||
    organisation?.membersList?.[uid]?.access_level?.includes(
      ORG_ROLE_CONSTANT.TEAM_MANAGEMENT,
    )
  );
};
const getOptionLabelQF = (qfOption = null, colObj = {}) => {
  const fieldType = getColumnFieldType(colObj);
  if (fieldType === FIELD_TYPE_ID.CHECKBOX) {
    return qfOption
      ? {label: 'Checked', id: true}
      : {label: 'Unchecked', id: false};
  } else if (fieldType === FIELD_TYPE_ID.OTP) {
    return qfOption
      ? {label: 'Verified', id: true}
      : {label: 'Not Verified', id: false};
  } else if (fieldType === FIELD_TYPE_ID.SWITCH) {
    return qfOption ? {label: 'On', id: true} : {label: 'Off', id: false};
  } else if (fieldType === FIELD_TYPE_ID.ASSIGN_TASK) {
    return {
      label:
        qfOption[ORG_MEMBER_PROFILE_FIELDS.NAME] ||
        qfOption[ORG_MEMBER_PROFILE_FIELDS.PHONE] ||
        qfOption[ORG_MEMBER_PROFILE_FIELDS.EMAIL] ||
        '',
      id: qfOption?.uid ?? null,
    };
  }
  return {label: qfOption, id: qfOption};
};

const getUpdateToastType = (
  notificationType,
  isToastActive = false,
  service,
) => {
  if (!notificationType) return null;

  switch (notificationType) {
    case NOTIFICATION_TYPE.INFO: {
      return isToastActive ? 'UPDATE_INFO' : NOTIFICATION_TYPE.INFO;
    }
    case NOTIFICATION_TYPE.SUCCESS: {
      return 'UPDATE_SUCCESS';
    }
    case NOTIFICATION_TYPE.ERROR: {
      return 'UPDATE_FAILURE';
    }
    case NOTIFICATION_TYPE.REQUESTED: {
      if ([SERVICE_TYPE.EXPORT].includes(service)) {
        return 'INFO';
      } else {
        return 'LOADING';
      }
    }
    default:
      return notificationType;
  }
};

export {
  handleSlackCustomAppRequest,
  checkIfSelfServingFlowAllowed,
  IsDateTimeDiff,
  isDateTimeFormulaColumn,
  getDateTimeFormattedDashboardValue,
  isQuickFilterableColumn,
  getQuickFilterColumns,
  allSettled,
  getScreenIdListFromDocId,
  getOrganisationMemberList,
  getOrganisationContactObject,
  getColumnFieldType,
  getMiniAppsLogObject,
  checkIfEmptyRow,
  JSONStringifier,
  addRowIdToRowObj,
  afterLogin,
  areShallowEqual,
  callCloudFunction,
  checkCellContainsAssignTask,
  checkCellContainsAttachment,
  checkCellContainsAudio,
  checkCellContainsComment,
  checkCellContainsImage,
  checkCellContainsMap,
  checkCellContainsContact,
  checkCellContainsUrl,
  checkCellContainsReminder,
  checkCellContainsDateTime,
  checkIfCellContainsSelectBox,
  checkIfCellHasTextualData,
  checkIfEmail,
  checkIfOriginalDocument,
  checkIfPlainText,
  checkIfValueExistOnCell,
  checkDefaultValueIsValid,
  checkPhoneNumberValidity,
  combineArrayWithObjects,
  convertCellDataToText,
  convertCellDataToTextForWeb,
  convertObjToArrOfObj,
  convertURLHashToObject,
  createPDF,
  currentDocNoOfRows,
  decodeLabelEncodedString,
  deleteKey,
  filterListOfData,
  findUserCountry,
  firestoreServerTimestamp,
  formatAndValidateEmail,
  formatCurrency,
  formatDateAndTimeAsText,
  formatDateDiff,
  formatMsgForWhatsapp,
  formatMsgForWhatsappWeb,
  formatPhoneNumber,
  formatTimeDiff,
  formatUnit,
  getAddRowLimit,
  getAssignTaskAsPlainText,
  getAssignTaskCellData,
  getBooleanFieldValue,
  getCommentCellValue,
  getCommentAddedByName,
  getContactCellValue,
  getCountryData,
  getCreatedInfoCellValue,
  getDateTimeCellObj,
  getDaysDifference,
  getDefaultUnit,
  getUnit,
  getDescriptionMessageForRestrictionsModal,
  getFooterVisiblityAndType,
  getFirebaseTimestampAsDateObj,
  getFrequency,
  getHeaderTypeMapping,
  getLabelStyleFromObj,
  getLabelValueArray,
  getLocalText,
  getNewColumnName,
  getNewColumnId,
  getNewRowPropertiesObject,
  getOriginalColId,
  getOriginalFieldType,
  getPasteValue,
  getPrintAttachment,
  getPrintAudio,
  getPrintDateTime,
  getPrintDate,
  getPrintTime,
  getRandomColor,
  getReminderCellValue,
  getRowId,
  getRowObjAtIndex,
  getSelectBoxCell,
  getTimezone,
  getTrailText,
  getUTCEpoch,
  getUrlAsPlainText,
  getUrlCellValue,
  getUserCallingCode,
  getUserCurrency,
  getUserPref,
  handleCloudError,
  handleCloudErrorMsgAndLogging,
  invertColor,
  isFilterableColumn,
  isObjectValid,
  isSearchValueExistsByType,
  removeAllSpecialCharactersFromString,
  serializeError,
  shouldApplyRestrictionsForNonPremiumUsers,
  solveEqn,
  sortedInsertionIndex,
  getColumnData,
  toPlainString,
  voteObjectProperty,
  formatTemplateMessageWithKeysValues,
  isEmail,
  isConditionalPropertyApplicable,
  setDateToStartOfMonth,
  getDisplayTimeStamp,
  checkIfTimeBasedFilter,
  getRowObjAtIndexForMiniApps,
  checkCellContainsVideo,
  isMixPanelEvent,
  extractCommonParamsForEventLogs,
  getUserColumnCellValue,
  checkCellContainesUserColumn,
  getOrgMembersUIDMapping,
  timeFormattingForAnalyics,
  getFiltersArrFromSelectedOptionsObj,
  getIdWithPrefix,
  checkIfFirestoreTimestamp,
  getDateFilterRangeForConditionCheck,
  getHeaderDataSummaryColumn,
  getDatesWithDayNames,
  getColumnIdWithScreen,
  checkIfColumnFieldAllowed,
  checkScreensAffectedOnSort,
  mergeDateTimeAndGetUTCTimeStamp,
  formatUTCDate,
  getAssignTaskObjForOrgUser,
  isUpdatingAutoCapturedValueAllowed,
  canEnableAutoCaptureProperty,
  isAutoCaptureEnabledForSameParentColumn,
  checkIfAutoCapturedTableColumnPresent,
  getDashboardValueColor,
  checkIfFieldImportableForImportExcel,
  formatFireStorePdfFormatToLocalPdfFormat,
  getPdfConfigFireStore,
  checkIfUserIsOwnerOrAdminOfApp,
  getColumnsForVisibilityCondition,
  getAllowedColumnsForTableLinkFilters,
  getGroupedScreenLength,
  isScreenOrGroupHideOperationAllowed,
  areAllScreenHidden,
  getFilteredHeaderDataForVisibilityConditions,
  isFieldVisibilityConfigurationAllowed,
  getFieldVisibilityDepedencyMapAndTopoSort,
  getHiddenFieldsBasedOnVisibilityConditions,
  checkIfConditionIsTrue,
  conditionCheckOnRecord,
  checkIfFieldReorderIsValidForVisibilityConditions,
  removeHiddenFieldsFromRowData,
  getDefaultValuesForAddEntry,
  handleLogoutWeb,
  getColorForId,
  isStringNumeric,
  isValidDate,
  getSortedScreensForDocId,
  getDarkColor,
  isSectionAndHeaderReorderValid,
  getScreensListFromDocIds,
  isDisplayInPercentageValid,
  getTableLinkMetaDetails,
  getDateTimePickType,
  getAssignTaskFilterFormat,
  isImportTemplateValid,
  isYouTubeLink,
  getParentSubTypeOrSubType,
  autoFillMappedCalendarDateBasedOnSelectedEvent,
  isColumnValidationConfigurationAllowed,
  convertCellValueToTextWithImages,
  getCurrFileAndSameLinkCol,
  getTableDataFromRowIdMap,
  convertCellToDifferentField,
  getInitAutomationToastId,
  columnValidator,
  getColumnValidatorDependencyMapping,
  getValidationFailedErrorText,
  getRowStatusObj,
  matchText,
  getRowData,
  getRowBoundIndex,
  isBackgroundField,
  getHeaderDataWithoutBackgroundFields,
  getHeaderDataAsObjWithoutBackgroundFields,
  getSelectBoxBgColor,
  getPdfConfig,
  getSelectBoxStyleObj,
  getLightenColor,
  fetchChildLinks,
  isChildPresentInListCol,
  getCommaSepratedValueForCurrency,
  validateNumber,
  getSelectBoxFilterVal,
  isKanbanConfigValid,
  canUpdateTeam,
  canUpdateOrganisationProfile,
  getOptionLabelQF,
  getUpdateToastType,
  getCellValueOtp,
};
