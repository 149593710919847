import FirestoreDB from '../../FirestoreHandlers/FirestoreDB';
import {isEmpty} from 'lodash';
import {
  firestore,
  captureError,
  ENV,
  versionCompare,
  logAnalyticsEvent,
} from '../../imports';
import {CLOUD_FUNCTION_PATHS} from '../../utils/constant';
import {callCloudFunction} from '../../utils/utils';

/**
 * Firebase Update data to provided document id
 * @param {string} docId document id
 * @param {object} obj update object
 * @returns Firebase Update Response
 */
const updateAutomationConfigDocument = (docId, obj) => {
  try {
    return FirestoreDB.automations.config(docId).update(obj);
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

/**
 * Firebase Set data to provided document id
 * @param {string} docId document id
 * @param {object} obj set object
 * @param {boolean} merge should merge data or not (default = true)
 * @returns Firebase Set Response
 */
const setAutomationConfigDocument = (docId, obj, merge = true) => {
  try {
    return FirestoreDB.automations
      .config(docId)
      .set(obj, merge ? {merge: true} : {});
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

/**
 * Firebase Set data to provided document id
 * @param {string} docId document id
 * @param {object} obj set object
 * @param {boolean} merge should merge data or not (default = true)
 * @returns Firebase Set Response
 */
const setDailyAutomationDocData = (docId, obj, merge = true) => {
  try {
    return FirestoreDB.automations
      .daily(docId)
      .set(obj, merge ? {merge: true} : {});
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

/**
 * Firebase Get data for provided document id
 * @param {string} docId document id
 * @returns Firebase Get Response
 */
const getDailyAutomationDocData = (docId, options = {}) => {
  try {
    return FirestoreDB.automations.daily(docId).get(options);
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

/**
 * Firebase Get data for provided document id
 * @param {string} docId document id
 * @returns Firebase Get Response
 */
const getAutomationConfigDocument = (docId, options = {}) => {
  try {
    return FirestoreDB.automations.config(docId).get(options);
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

/**
 * Firebase Set data to provided document id
 * @param {string} docId document id
 * @param {object} obj set object
 * @param {boolean} merge should merge data or not (default = true)
 * @returns Firebase Set Response
 */
const setPendingAutomationDocument = (docId, obj, merge = true) => {
  try {
    return FirestoreDB.automations
      .pending(docId)
      .set(obj, merge ? {merge: true} : {});
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

/**
 * Firebase Update data to provided document id
 * @param {string} docId document id
 * @param {object} obj update object
 * @returns Firebase Update Response
 */
const updatePendingAutomationDocument = (docId, obj) => {
  try {
    return FirestoreDB.automations.pending(docId).update(obj);
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

/**
 * Firebase Update data to provided document id
 * @param {string} docId document id
 * @returns Firebase Update Response
 */
const getPendingAutomationDocument = (docId, options = {}) => {
  try {
    return FirestoreDB.automations.pending(docId).get(options);
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

const getAutomationWhatsappTemplates = async (businessProfileId) => {
  const docs = [];

  try {
    const snapshot = await firestore()
      .collection('userWhatsappTemplates')
      .where('businessProfileId', '==', businessProfileId)
      .get();

    if (snapshot.empty) {
      return docs;
    }

    snapshot.docs.forEach((doc) => {
      const docData = doc?.data?.();
      if (!isEmpty(docData)) {
        docs.push({...docData, templateId: doc.id});
      }
    });

    return docs;
  } catch (error) {
    captureError(error);
    return docs;
  }
};

/**
 * Firebase Get "automationTemplates" Collection
 * @param {string} docId document id
 * @returns Firebase Get Response
 */
const getAutomationTemplateCollection = ({
  options = {},
  userBizCategory = '',
  automationConfig = {},
}) => {
  try {
    if (userBizCategory === '') {
      return FirestoreDB.automations.automationTemplatesCol().get(options);
    } else {
      const queryArray = ['ALL', `${userBizCategory}`];
      const runningAutomationArray = Object.keys(automationConfig);
      if (runningAutomationArray.length > 0) {
        for (let i = 0; i < runningAutomationArray.length; i++) {
          automationConfig?.[runningAutomationArray[i]]?.notificationTemplateId
            ? queryArray.push(
                automationConfig?.[runningAutomationArray[i]]
                  ?.notificationTemplateId,
              )
            : null;
        }
      }
      return FirestoreDB.automations
        .automationTemplatesCol()
        .where('businessCategoryArr', 'array-contains-any', queryArray)
        .get(options);
    }
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

const setAutomation = async (obj, docId, extra = {}) => {
  try {
    setAutomationConfigDocument(docId, obj).then(() => {
      if (extra?.analyticsObj) {
        logAnalyticsEvent(
          extra?.isEdit
            ? 'UPDATE_AUTOMATION_SUCCESS'
            : 'CREATE_AUTOMATION_SUCCESS',
          extra.analyticsObj,
        );
      }
    });
  } catch (error) {
    captureError(error);
  }
};

const updateAutomation = async (obj, docId, extra = {}) => {
  try {
    updateAutomationConfigDocument(docId, obj).then(() => {
      if (extra?.analyticsObj) {
        logAnalyticsEvent(
          extra?.isEdit
            ? 'UPDATE_AUTOMATION_SUCCESS'
            : 'CREATE_AUTOMATION_SUCCESS',
          extra.analyticsObj,
        );
      }
    });
  } catch (error) {
    captureError(error);
  }
};

const formatTemplateData = (templateData) => {
  const {message, ...rest} = templateData;
  const getFromatMessage = (str) => {
    const __str = str.replace(/\n/, '\\n');
    const regex = /{{\d}}/g;
    const matches = __str.match(regex);
    if (!matches) {
      return str;
    }
    const matchesWithSpaces = matches.map((match) => ` ${match} `);
    return __str
      .replace(regex, (match) => matchesWithSpaces.shift())
      .trim()
      .replace(/\\n/g, '\n');
  };

  const messageObj = message?.length
    ? {message: getFromatMessage(message)} // replace }} with `}} ` to avoid error in template
    : {};
  return {...rest, ...messageObj};
};

const getIntegrationsTemplates = async (interaktId) => {
  const integrationTemplates = await FirestoreDB.automations
    .integrationTemplates(interaktId)
    .get();

  const templates = [];

  integrationTemplates.docs.forEach((doc) => {
    const docData = doc.data();

    templates.push({...formatTemplateData(docData), templateId: doc.id});
  });

  return templates;
};

const getAutomationTemplates = async (
  userBizCategory = '',
  automationConfig = {},
) => {
  try {
    let appVersion,
      isValidMinVersion = false,
      isAndroidDevice = true;

    const {getDeviceVersion, isAndroid} = require('../../imports');
    appVersion = getDeviceVersion();
    isAndroidDevice = isAndroid;

    const snapshot = await getAutomationTemplateCollection({
      userBizCategory,
      automationConfig,
    });

    const templates = [];

    if (!snapshot.empty) {
      snapshot.docs.forEach((doc) => {
        const docData = doc?.data?.() ?? {};
        if (!docData?.minVersion) {
          return;
        }
        // prettier-ignore
        isValidMinVersion = (!ENV || isAndroidDevice)
            ? versionCompare(appVersion, `${docData?.minVersion ?? ''}`)
            : docData?.minVersionIos
            ? versionCompare(appVersion, `${docData?.minVersionIos ?? ''}`)
            : true;
        if (isValidMinVersion >= 0) {
          templates.push({...formatTemplateData(docData), templateId: doc.id});
        }
      });
    }
    return templates;
  } catch (error) {
    captureError(error);
  }
};

const updatePhoneCol = ({docId, type, updatedPhoneCol}) => {
  try {
    const path = `${type}.phoneColId`;
    updateAutomationConfigDocument(docId, {[path]: updatedPhoneCol});
  } catch (error) {
    captureError(error);
  }
};

const deleteSelectedIdPendingAutomationsHelper = (docId, updateObj) => {
  try {
    updatePendingAutomationDocument(docId, updateObj);
  } catch (error) {
    captureError(error);
  }
};
const deleteAllPendingAutomationsHelper = (docId) => {
  try {
    setPendingAutomationDocument(docId, {}, false);
  } catch (error) {
    captureError(error);
  }
};

const deleteAutomation = async ({docId, ids}, extra = {}) => {
  try {
    const batch = firestore().batch();
    const docRef = FirestoreDB.automations.config(docId);
    for (let i = 0; i < ids.length; i++) {
      batch.update(docRef, {[ids[i]]: firestore(true).FieldValue.delete()});
    }
    await batch.commit();
    if (extra?.analyticsObj) {
      logAnalyticsEvent('DELETE_AUTOMATION_SUCCESS', extra?.analyticsObj);
    }
  } catch (error) {
    captureError(error);
  }
};

const fetchAllGlobalAutomations = async ({uid}) => {
  try {
    const globalAutomationDocRef = await FirestoreDB.automations
      .dailyAutomationsCol()
      .where('uidArray', 'array-contains', uid)
      .get();
    const globalAutomationsData = [];
    let timestamp;
    const allGlobalAutomationFileData = {};
    if (!globalAutomationDocRef.empty) {
      globalAutomationDocRef.docs.forEach((doc) => {
        const docData = doc.data();
        if (doc && docData.docId) {
          globalAutomationsData.push(docData.docId);
          allGlobalAutomationFileData[docData.docId] = docData;
          if (!timestamp) {
            const userTimestampMapping = docData.userTimestampMapping;
            for (const element in userTimestampMapping) {
              if (userTimestampMapping[element][uid]) {
                timestamp = element;
              }
            }
          }
        }
      });
      return {
        Documents: globalAutomationsData,
        timestamp,
        allGlobalAutomationFileData,
      };
    }
  } catch (error) {
    captureError(error);
    throw error;
  }
};

/**
 * Firebase Set "date pending Automation" Collection
 * @param {string} docId document id
 * @param {string} timestamp document id
 * @param {object} automationObj document id
 * @returns Firebase Get Response
 */
const setDateSpecificAutomation = ({
  docId,
  timestamp,
  pendingAutomation,
  isUpdate = false,
}) => {
  try {
    const docRef = FirestoreDB.automations.scheduled(docId, timestamp);
    if (isUpdate) {
      docRef.update(pendingAutomation);
    } else {
      docRef.set(pendingAutomation, {merge: true});
    }
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

/**
 * Firebase Set "date pending Automation" Collection
 * @param {string} docId document id
 * @param {string} timestamp document id
 * @param {object} automationObj document id
 * @returns Firebase Get Response
 */
const deleteDateSpecificAutomation = ({docId, timestamp, updateObj}) => {
  try {
    return FirestoreDB.automations
      .scheduled(docId, timestamp)
      .update(updateObj);
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

/**
 * Firebase Delete "Timestamp" in pendingAutomation Collection
 * @param {string} docId document id
 * @param {string} timestamp document id
 * @returns Firebase Get Response
 */
const deleteDateSpecificAutomationTimestamp = ({docId, timestamp}) => {
  try {
    return FirestoreDB.automations
      .pending(docId)
      .update({timestamp: firestore(true).FieldValue.arrayRemove(timestamp)});
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

/**
 * Firebase Add "Timestamp" in pendingAutomation Collection
 * @param {string} docId document id
 * @param {string} timestamp document id
 * @returns Firebase Get Response
 */
const addDateSpecificAutomationTimestamp = ({
  docId,
  timestamp,
  isSet = false,
  isMetaObj = false,
  metaObj = {},
}) => {
  try {
    if (isSet) {
      return FirestoreDB.automations.pending(docId).set(
        {
          ...(isMetaObj ? metaObj : {}),
          timestamp: firestore(true).FieldValue.arrayUnion(timestamp),
        },
        {merge: true},
      );
    } else {
      return FirestoreDB.automations.pending(docId).update({
        timestamp: firestore(true).FieldValue.arrayUnion(timestamp),
        ...(isMetaObj ? metaObj : {}),
      });
    }
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

/**
 * Firebase fetch scheduled collection from pendingAutomation Collection
 * @param {string} activeDocumentId document id
 * @returns Firebase Get Response
 */
const loadScheduledAutomations = ({activeDocumentId}) => {
  try {
    return FirestoreDB.automations.scheduledCol(activeDocumentId).get();
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

const deleteScheduledTimestamps = async (docId, timestampArray) => {
  for (const timestamp of timestampArray) {
    try {
      deleteDateSpecificAutomationTimestamp({
        docId,
        timestamp,
      });
      FirestoreDB.automations.scheduled(docId, timestamp).delete();
    } catch (error) {
      captureError(error);
    }
  }
};

const triggerMiniAppAutomationManuallyCF = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.TRIGGER_AUTOMATION_MANUALLY, obj);

const createInteraktMapping = async (docId, docAutomationConfigId) => {
  try {
    return FirestoreDB.miniAppsUserWhatsAppTemplates
      .doc(docId)
      .collection('mappedAutomations')
      .doc('mapping')
      .set({[docAutomationConfigId]: true}, {merge: true});
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

export {
  deleteScheduledTimestamps,
  loadScheduledAutomations,
  deleteDateSpecificAutomationTimestamp,
  addDateSpecificAutomationTimestamp,
  setDateSpecificAutomation,
  deleteDateSpecificAutomation,
  deleteAllPendingAutomationsHelper,
  deleteAutomation,
  deleteSelectedIdPendingAutomationsHelper,
  getAutomationTemplates,
  setAutomation,
  updateAutomation,
  updatePhoneCol,
  getPendingAutomationDocument,
  updatePendingAutomationDocument,
  updateAutomationConfigDocument,
  getAutomationConfigDocument,
  setDailyAutomationDocData,
  getDailyAutomationDocData,
  fetchAllGlobalAutomations,
  setPendingAutomationDocument,
  // getAutomationFiltersDocument,
  getAutomationWhatsappTemplates,
  triggerMiniAppAutomationManuallyCF,
  getIntegrationsTemplates,
  createInteraktMapping,
};

// export const automationActionHelper = {
//   setAutomationConfigDocument,
//   updateAutomationConfigDocument,
//   getAutomationConfigDocument,
// };
