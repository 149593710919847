/* eslint-disable local-rules/rb-redux-requires */
/**
 * WEB SPECIFIC IMPORTS ------ start
 */
import firebase from 'firebase/app';
import ReactPixel from 'react-facebook-pixel';
import * as Sentry from '@sentry/react';
import Toastify from 'toastify-js';
const isAndroid = false;
import {store} from '../web-app/src/index';
import {reducers} from './reducers/index';
import {DEFAULT_WEBAPP_VERSION} from './utils/constant';
import mixpanel from 'mixpanel-browser';
import {singularSdk, SingularConfig} from 'singular-sdk';
import isEmpty from 'lodash/isEmpty';
const ENV = false; //env is web (ENV->false)
const isProduction = process.env.REACT_APP_IS_DEV !== 'true';

let ToastInstance = {};
export function initializeUtilityFunctions({toast}) {
  ToastInstance = toast;
}

let remoteConfig;
// // const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const getReduxState = () =>
  typeof store?.getState === 'function' ? store.getState() : reducers;

const setReduxState = (obj) => {
  try {
    return store.dispatch(obj);
  } catch (error) {
    captureInfo(error, true);
    captureError(new Error(`Unable to setting log event to redux store`));
  }
};

const singularInit = () => {
  const config = new SingularConfig(
    process.env.REACT_APP_SINGULAR_SDK_KEY,
    process.env.REACT_APP_SINGULAR_SDK_SECRET,
    process.env.REACT_APP_SINGULAR_BUNDLE_ID,
  );
  // .withAutoPersistentSingularDeviceId("getlio.com")
  singularSdk.init(config);
};

const branchInit = () => {
  (function (b, r, a, n, c, h, _, s, d, k) {
    if (!b[n] || !b[n]._q) {
      for (; s < _.length; ) c(h, _[s++]);
      d = r.createElement(a);
      d.async = 1;
      d.src = 'https://cdn.branch.io/branch-latest.min.js';
      k = r.getElementsByTagName(a)[0];
      k.parentNode.insertBefore(d, k);
      b[n] = h;
    }
  })(
    window,
    document,
    'script',
    'branch',
    function (b, r) {
      b[r] = function () {
        b._q.push([r, arguments]);
      };
    },
    {_q: [], _v: 1},
    'addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking'.split(
      ' ',
    ),
    0,
  );
  // init Branch
  window.branch.init(process.env.REACT_APP_BRANCH_KEY);
};

const initializeApp = () => {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  // branchInit();
  singularInit();
  firebase.initializeApp(firebaseConfig);
  remoteConfig = firebase.remoteConfig();
  firebase.firestore().settings({ignoreUndefinedProperties: true});
  // firebase.functions().useFunctionsEmulator('http://localhost:5001');
  ReactPixel.init(process.env.REACT_APP_FB_SDK, {}, options);
};
//
const firestore = (isVar = false) => {
  /**
   * @param isVar(boolean) : is variable
   * @returns depending on the isVar either of variable(isVar=true) or instance(isVar=false)
   * Example : firestore().collection() :Used as instance
   *           firestore.FieldValue : used as variable
   */
  require('firebase/firestore');
  const fs = firebase.firestore;
  return isVar ? fs : fs();
};

const firebaseAuth = (isVar = false) => {
  require('firebase/auth');
  const fn = firebase.auth;
  return isVar ? fn : fn();
};

const database = () => {
  require('firebase/database');
  return firebase.database();
};

const functions = (isVar = false) => {
  require('firebase/functions');
  const fn = firebase.functions;
  return isVar ? fn : fn();
};

const storage = () => {
  require('firebase/storage');
  return firebase.storage();
};

const bucketStorage = (storageURL) => {
  require('firebase/storage');
  return firebase.app().storage(`gs://${storageURL}`);
};

const captureError = (error) => {
  try {
    if (!isProduction) {
      console.error(error);
    }
    if (!(error instanceof Error)) {
      if (typeof error === 'string') {
        error = new Error(error);
      } else {
        error = new Error(JSON.stringify(error));
      }
    }
    Sentry.captureException(error);
  } catch {}
};

const SharedPreferenceStorage = () => {
  // const DefaultPreference = require('react-native-default-preference').default;
  // if (isAndroid && packageId?.length) {
  //   DefaultPreference.setName(`${packageId}_preferences`);
  // }
  // return DefaultPreference;
};

const captureInfo = (data) => {
  try {
    //data should be a plain object
    //!IMP : if deeply nested object, use JSON.stringify
    /**
     * Example :
     * captureInfo({
     *  "name" : "Alpha",
     *  "pages" : JSON.stringify(pages), //pages is an array
     *  "info" : JSON.stringify({deeplyNestedObj : true}) //info is another object
     * })
     */
    if (data) {
      if (!isProduction) {
        console.error(data);
      }
      Sentry.addBreadcrumb({
        // level: Sentry.Severity.Info,
        data,
      });
    }
  } catch (e) {}
};

/**
 * return values as follow
 *  false = left < right
 *  onEqualReturn(default val = true) = equal
 *  true = left > right
 */
const versionCompare = (left, right, onEqualReturn = true) => {
  if (typeof left + typeof right != 'stringstring') {
    return false;
  }
  var a = left.split('.'),
    b = right.split('.'),
    i = 0,
    len = Math.max(a.length, b.length);

  for (; i < len; i++) {
    if (
      (a[i] && !b[i] && parseInt(a[i]) > 0) ||
      parseInt(a[i]) > parseInt(b[i])
    ) {
      return true;
    } else if (
      (b[i] && !a[i] && parseInt(b[i]) > 0) ||
      parseInt(a[i]) < parseInt(b[i])
    ) {
      return false;
    }
  }
  return onEqualReturn;
};

const logAnalyticsEvent = (eventName, paramsObj) => {
  try {
    require('firebase/analytics');
    if (eventName) {
      if (typeof store?.getState === 'function') {
        const [, allEventParams] =
          require('./utils/utils').extractCommonParamsForEventLogs(
            paramsObj,
            store,
          );
        const params = isEmpty(allEventParams)
          ? [eventName]
          : [eventName, allEventParams];

        if (require('./utils/utils').isMixPanelEvent(...params)) {
          mixpanel.track(...params);
        }
        firebase?.analytics()?.logEvent(...params);
        ReactPixel?.trackCustom(...params); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
        // window?.branch?.logEvent(...params);
        singularSdk.event(eventName, paramsObj);
        // ReactPixel.pageView(); // For tracking page view
        // ReactPixel.track(eventName, paramsObj); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
        //  // ReactPixel.trackSingle('PixelId', eventName, paramsObj); // For trpacking default events.
        // ReactPixel.trackSingleCustom('PixelId', eventName, paramsObj);
      }
    }
  } catch (error) {
    captureError(error, true);
    captureError(new Error(`Unable to log event - ${eventName}`), false, true);
  }
};

const ShowToast = (msg, userPref, convertText = true, isLongToast = false) => {
  try {
    if (convertText && userPref) {
      const {getLocalText} = require('./utils/utils');
      msg = getLocalText(userPref, msg);
    }

    if (window) {
      Toastify({
        text: msg,
        position: 'center',
        style: {
          textAlign: 'center',
          background: '#313131',
          borderRadius: '4px !important',
        },
      }).showToast();
    }
  } catch (e) {}
};

const AsyncStorage = () => {
  class LocalStorage {
    constructor() {}

    setItem(key, value) {
      return new Promise((resolve) => {
        resolve(key, value);
      });
    }

    getItem(key) {
      return new Promise((resolve) => {
        resolve(key);
      });
    }

    multiSet(keyValArr) {
      return new Promise((resolve) => {
        resolve(keyValArr);
      });
    }

    multiGet(keyArr) {
      return new Promise((resolve) => {
        resolve(keyArr);
      });
    }
  }
  return new LocalStorage();
  //TODO:  LocalStorage instance but similar to asyncStorage of mobile
};

const openStoreLink = () => {};

const openLink = (url) => {
  return new Promise((resolve) => {
    try {
      //TODO: check if this implementation is correct
      //1. window variable is accessible here?
      //2. compatible with all the browsers?
      const res = window.open(url, '_blank');
      resolve(res);
    } catch (error) {
      captureError(error);
      resolve(false);
    }
  });
};

const openLinkV2 = (url) => {
  let urlToOpen = url;
  urlToOpen = urlToOpen.toString();
  if (!(urlToOpen.startsWith('http://') || urlToOpen.startsWith('https://'))) {
    // appending protocol if doesn't exist
    urlToOpen = 'https://' + urlToOpen;
  }
  window.open(urlToOpen, '_blank');
};

const getDeviceVersion = () => {
  return DEFAULT_WEBAPP_VERSION; //!IMP to keep updating this for web
};

const getDeviceID = () =>
  Promise.resolve(getReduxState().persistedData?.uniqueDeviceId);

const getPlatform = () => 'WEB';

const MixPanelInstance = mixpanel;

const initializeIntlPolyfillForMobile = null;

export {
  ENV,
  isAndroid,
  // store,
  getPlatform,
  getReduxState,
  firestore,
  firebaseAuth,
  database,
  functions,
  initializeApp,
  storage,
  bucketStorage,
  SharedPreferenceStorage,
  captureInfo,
  captureError,
  setReduxState,
  AsyncStorage,
  logAnalyticsEvent,
  ShowToast,
  initializeIntlPolyfillForMobile,
  openLink,
  openLinkV2,
  versionCompare,
  getDeviceID,
  getDeviceVersion,
  openStoreLink,
  remoteConfig,
  isProduction,
  MixPanelInstance,
  ToastInstance,
};
